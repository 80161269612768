import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Button, Menu, MenuItem, ListItemIcon, IconButton, useMediaQuery } from '@mui/material';
import { collection, query, getDocs, orderBy, deleteDoc, doc, writeBatch, where, getDoc, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SkeletonLoader from './SkeletonLoader';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
import QuizIcon from '@mui/icons-material/Quiz';
import { formatDate } from './functions';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import BookmarkIcon from '@mui/icons-material/BookmarkBorder';
import LoadingSpinner from './LoadingSpinner';
import DOMPurify from 'dompurify';

export const addNote = async (userId, content, role, conversationType) => {
    try {
        const docRef = await addDoc(collection(db, "notes"), {
            userId: userId || 'anonymous',
            content: content || '',
            role: role || '',
            conversationType: conversationType || 'Allmänt',
            createdAt: new Date()
        });
        return { success: true, noteId: docRef.id };
    } catch (error) {
        console.error('Error saving note: ', error);
        return { success: false, error };
    }
};

export const deleteNote = async (noteId) => {
    try {
        if (!noteId) return { success: false, error: 'No noteId provided' };
        await deleteDoc(doc(db, "notes", noteId));
        return { success: true };
    } catch (error) {
        console.error('Error deleting note: ', error);
        return { success: false, error };
    }
};

const NotesView = ({ user }) => {
    const [notes, setNotes] = useState([]);
    const [teachers, setTeachers] = useState({});
    const [flashcardCollections, setFlashcardCollections] = useState({});
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [quizzes, setQuizzes] = useState({});
    const theme = useTheme();
    const styles = getStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchNotes = async () => {
            const q = query(collection(db, 'notes'), where('userId', '==', user.uid));
            const querySnapshot = await getDocs(q);
            const fetchedNotes = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setNotes(fetchedNotes);

            // Fetch teacher data
            const teacherIds = [...new Set(fetchedNotes
                .filter(note => !['chat', 'history', 'spanish'].includes(note.conversationType) && !note.conversationType.startsWith('flashcards-'))
                .map(note => note.conversationType))];
    
            const teachersData = {};
            for (const id of teacherIds) {
                const teacherDocRef = doc(db, "teachers", id);
                const teacherDocSnap = await getDoc(teacherDocRef);
                if (teacherDocSnap.exists()) {
                    teachersData[id] = teacherDocSnap.data().characterName;
                }
            }
            setTeachers(teachersData);

            // Fetch flashcard collection data
            const flashcardIds = [...new Set(fetchedNotes
                .filter(note => note.conversationType.startsWith('flashcards-'))
                .map(note => note.conversationType.split('-')[1]))];

            const flashcardData = {};
            for (const id of flashcardIds) {
                const flashcardDocRef = doc(db, "flashcardCollections", id);
                const flashcardDocSnap = await getDoc(flashcardDocRef);
                if (flashcardDocSnap.exists()) {
                    flashcardData[id] = flashcardDocSnap.data().collectionName;
                }
            }
            setFlashcardCollections(flashcardData);


            // Fetch quiz data
            const quizIds = [...new Set(fetchedNotes
                .filter(note => note.conversationType.startsWith('quiz-'))
                .map(note => note.conversationType.split('-')[1]))];

            const quizData = {};
            for (const id of quizIds) {
                const quizDocRef = doc(db, "quiz", id);
                const quizDocSnap = await getDoc(quizDocRef);
                if (quizDocSnap.exists()) {
                    quizData[id] = quizDocSnap.data().quizName;
                }
            }
            setQuizzes(quizData);


            setLoading(false);
        };

        fetchNotes();
    }, [user.uid]);

    const getIcon = (conversationType) => {
        switch (conversationType) {
            case 'chat':
                return <ChatBubbleIcon />;
            case 'history':
                return <MenuBookIcon />;
            case 'spanish':
                return <LanguageIcon />;
            default:
                if (conversationType.startsWith('flashcards-')) {
                    return <QuizIcon />;
                } else if (conversationType.startsWith('quiz-')) {
                    return <AssessmentIcon />;
                } else {
                    return <PersonIcon />;
                }
        }
    };

    const deleteNote = async (id) => {
        await deleteDoc(doc(db, "notes", id));
        setNotes(notes.filter(note => note.id !== id));
    };

    const deleteAllNotes = async () => {
        const batch = writeBatch(db);
        notes.forEach(note => {
            const docRef = doc(db, "notes", note.id);
            batch.delete(docRef);
        });
        await batch.commit();
        setNotes([]);
    };


// Funktion för att sanera HTML
const sanitizeHtml = (html) => {
  // Konfigurera DOMPurify för att endast tillåta <strong> och <br> taggar
  const config = {
    ALLOWED_TAGS: ['strong', 'br'],
    ALLOWED_ATTR: [] // Inga attribut tillåts
  };
  return DOMPurify.sanitize(html, config);
};


   const renderSection = (type, notes) => {
        let sectionTitle;
let sectionType = null;
        if (type === 'chat') {
            sectionTitle = 'Fråga AI';
        } else if (type === 'history') {
            sectionTitle = 'Historia';
        } else if (type === 'spanish') {
            sectionTitle = 'Spanska';
        } else if (type.startsWith('flashcards-')) {
            const flashcardId = type.split('-')[1];
            sectionTitle = flashcardCollections[flashcardId] || 'Flashcards';
            sectionType = 'Flashcards';
        } else if (type.startsWith('quiz-')) {
            const quizId = type.split('-')[1];
            sectionTitle = quizzes[quizId] || 'Quiz';
            sectionType = 'Quiz';
        } else {
            sectionTitle = teachers[type] || type;
        }

        return (
            <Paper elevation={0} sx={styles.paper}>
            {sectionType && (
                <Typography variant="caption" sx={{ 
                    display: 'block', 
                    marginBottom: '4px', 
                    color: theme.palette.text.secondary,
                    fontSize: '0.75rem',
                    fontWeight:'bold'
                }}>
                    {sectionType}
                </Typography>
            )}
                <Typography variant="h6" sx={styles.sectionHeader}>
                    {sectionTitle}
                </Typography>
                <List>
                    {notes.map((note, index) => (
                        <React.Fragment key={note.id}>
                            <ListItem sx={styles.listItem}>
                                <ListItemIcon sx={styles.listItemIcon}>
                                    {getIcon(type)}
                                </ListItemIcon>
                                <ListItemText
                                    primary={<div dangerouslySetInnerHTML={{ 
                                        __html: sanitizeHtml(note.content) 
                                    }} />}



                                    
                                    secondary={formatDate(note.createdAt)}
                                />
                                <IconButton onClick={() => deleteNote(note.id)} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            {index < notes.length - 1}
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        );
    };

    const groupedNotes = notes.reduce((acc, note) => {
        if (!acc[note.conversationType]) {
            acc[note.conversationType] = [];
        }
        acc[note.conversationType].push(note);
        return acc;
    }, {});

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text('Sparat på Studera.AI', 10, 10);
        const tableColumn = ["Typ", "Innehåll", "Sparat"];
        const tableRows = [];
        notes.forEach(note => {
            const noteData = [
                note.conversationType,
                note.content,
                new Date(note.createdAt.toDate()).toLocaleString(),
            ];
            tableRows.push(noteData);
        });
        doc.autoTable({ head: [tableColumn], body: tableRows, startY: 20 });
        doc.save('Sparat_Studera_AI.pdf');
    };

    const exportToWord = () => {
        const doc = new Document({
            sections: [{
                properties: {},
                children: notes.map(note =>
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: note.conversationType,
                                bold: true,
                            }),
                            new TextRun({
                                text: ` - ${note.content} (${new Date(note.createdAt.toDate()).toLocaleString()})`,
                            }),
                        ],
                    }),
                ),
            }],
        });
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, "study_notes.docx");
        }).catch(error => {
            console.error('Error while exporting to Word:', error);
        });
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.innerContainer}>
                <Typography variant="h4" sx={styles.header}>
                    Sparat
                </Typography>

<Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>
                    {isMobile ? (
                        <Button 
                            variant="contained"  
                            color="primary"  
                            disableRipple  
                            onClick={handleMenuClick} 
                            startIcon={<FileDownloadIcon />} 
                            sx={{...styles.exportButton, ...styles.shrinkButton}} 
                        /> 
                    ) : (
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleMenuClick} 
                            sx={styles.exportButton} 
                            startIcon={<FileDownloadIcon />}
                        >
                            Exportera...
                        </Button>
                    )}

                    {isMobile ? (
                        <Button 
                            variant="contained"  
                            color="secondary"  
                            disableRipple  
                            onClick={deleteAllNotes} 
                            startIcon={<DeleteForeverIcon />} 
                            sx={{...styles.deleteButtonNotes, ...styles.shrinkButton}} 
                        /> 
                    ) : (
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={deleteAllNotes} 
                            sx={styles.deleteButtonNotes} 
                            startIcon={<DeleteForeverIcon />}
                        >
                            Rensa anteckningar
                        </Button>
                    )}
                </Box>

                <Box sx={{marginTop:'40px'}}>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} >
                        <MenuItem onClick={() => { exportToPDF(); handleMenuClose(); }}>PDF</MenuItem>
                        <MenuItem>
                            <CSVLink
                                data={notes}
                                headers={[
                                    { label: "Typ", key: "conversationType" },
                                    { label: "Innehåll", key: "content" },
                                    { label: "Datum", key: "createdAt" },
                                ]}
                                filename="study_notes.csv"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                CSV
                            </CSVLink>
                        </MenuItem>
                        <MenuItem onClick={() => { exportToWord(); handleMenuClose(); }}>Word</MenuItem>
                    </Menu>
                    {loading ? (
                     <LoadingSpinner />

                    ) : notes.length > 0 ? (
                        Object.keys(groupedNotes).map(type => (
                            <React.Fragment key={type}>
                                {renderSection(type, groupedNotes[type])}
                            </React.Fragment>
                        ))
                    ) : (
<Typography variant="h6" sx={{ 
    textAlign: 'left', 
    marginTop: '30px', 
    fontSize: '15px', 
    fontStyle: 'italic', 
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center'
}}>
    Här visas allt du sparar genom att klicka på <BookmarkIcon sx={{ mx: 1, fontSize: '22px' }} /> på olika sektioner, t.ex. flashcards, quiz-frågor, frågor från högskoleprovet etc.
</Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default NotesView;