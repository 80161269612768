import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Category';
import NotesIcon from '@mui/icons-material/Notes';
import BoltIcon from '@mui/icons-material/Bolt';
import SpeedIcon from '@mui/icons-material/Speed';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';

const LanguagePrompts = ({ onSendMessage, messages, language = 'spanish' }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuPosition, setMenuPosition] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const styles = getStyles(theme);

    const languageMap = {
        spanish: 'spanska',
        french: 'franska',
        german: 'tyska'
    };

    const currentLanguage = languageMap[language] || 'spanska';

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuPosition(0);
        setSelectedCategory(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuPosition(0);
        setSelectedCategory(null);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setMenuPosition(1);
    };

    const handleMenuItemClick = (prompt) => {
        let customMessage = '';

        switch(prompt) {
            case 'Enkla':
                customMessage = `Du ska ge mig meningar på ${currentLanguage} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara enkel.`;
                break;
            case 'Medelsvåra':
                customMessage = `Du ska ge mig meningar på ${currentLanguage} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara svår.`;
                break;
            case 'Väldigt svåra':
                customMessage = `Du ska ge mig meningar på ${currentLanguage} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara väldigt svår.`;
                break;                        
            case 'Presens (nutid)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar presens i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Preteritum (dåtid)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar preteritum i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Perfekt (dåtid)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar perfekt i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Pluskvamperfekt (dåtid)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar pluskvamperfekt i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Futurum (framtid)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar futurum i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Imperativ (befallande form)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar imperativ i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Gerundium (pågående form)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar gerundium i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Infinitiv (grundform)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar infinitiv i ${currentLanguage}. Ge mig en mening i taget.`;
                break;        
            case 'Konjunktiv (dåtid)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i dåtid i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Konjunktiv (presens)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i presens i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Konjunktiv (futurum)':
                customMessage = `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i futurum i ${currentLanguage}. Ge mig en mening i taget.`;
                break;
            case 'Beställa mat på restaurang':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är en servitör och jag är en gäst som ska beställa mat. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Boka ett hotellrum':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är en hotellreceptionist och jag är en gäst som ska sköta bokningen. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Handla i en klädbutik':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är en klädförsäljare som hjälper till med att hitta rätt storlek och stil och jag är en gäst som ska prova kläder. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Besök hos en läkare':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är en läkare som ställer frågor om symtom och ger råd och jag är en patient som beskriver sina symtom och följer råd. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Fråga om vägen':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är en lokal invånare som ger vägbeskrivningar och jag är en turist som frågar om vägen till olika platser. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Besök på en marknad':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är en enträgen marknadshandlare som säljer olika produkter och jag är en kund som prutar och köper frukt, grönsaker och andra varor. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Småprata med en kompis':
                customMessage = `Simulera en lång konversation på ${currentLanguage} där du är barndomsvän som pratar om intressen, familj och vardag och jag är din bästa vän som vill småprata lite. Ge mig en fråga i taget och vänta på svar.`;
                break;
            case 'Berättelse (presens)':
                customMessage = `Skriv en kort berättelse på ${currentLanguage} i presens. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
                break;
            case 'Berättelse (dåtid)':
                customMessage = `Skriv en kort berättelse på ${currentLanguage} i dåtid. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
                break;
            case 'Berättelse (futurum)':
                customMessage = `Skriv en kort berättelse på ${currentLanguage} i futurum. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
                break;
            case 'Berättelse (blandade tempus)':
                customMessage = `Skriv en kort berättelse på ${currentLanguage} med konjunktiv, infinitiv, pluskvamperfekt, gerundium och andra tempus. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
                break;
            default:
                customMessage = 'Ogiltig prompt vald.';
        }

        onSendMessage(customMessage);
        handleClose();
    };

    const evaluateLevel = () => {
        if (messages.length < 20) {
            showSnackbar("Du måste skriva fler än 20 meddelanden innan du kan utvärderas.", 'info');
            return;
        }

        let customMessage = `Utvädera min ${currentLanguage} (role=user) baserat på meddelandena i denna konversation. Skriv vilka misstag jag gjort och vad jag gjort rätt.`;
        onSendMessage(customMessage);
        handleClose();
    };

    const prompts = [
        {
            category: 'Rollspel',
            icon: <QuestionAnswerIcon />,
            items: ['Småprata med en kompis', 'Beställa mat på restaurang', 'Handla i en klädbutik', 'Boka ett hotellrum', 'Besök hos en läkare', 'Fråga om vägen', 'Besök på en marknad']
        },
        {
            category: 'Verb',
            icon: <CategoryIcon />,
            items: ['Presens (nutid)', 'Preteritum (dåtid)', 'Perfekt (dåtid)', 'Pluskvamperfekt (dåtid)', 'Futurum (framtid)', 'Imperativ (befallande form)', 'Gerundium (pågående form)', 'Infinitiv (grundform)', 'Konjunktiv (dåtid)', 'Konjunktiv (presens)', 'Konjunktiv (futurum)']
        },
        {
            category: 'Läsförståelse',
            icon: <NotesIcon />,
            items: ['Berättelse (presens)', 'Berättelse (dåtid)', 'Berättelse (futurum)', 'Berättelse (blandade tempus)']
        },
        {
            category: 'Fyll _ ordet',
            icon: <BoltIcon />,
            items: ['Enkla', 'Medelsvåra', 'Väldigt svåra']
        },
        {
            category: 'Utvärdera min nivå',
            icon: <SpeedIcon />,
            action: evaluateLevel
        }
    ];

    return (
        <>
            <Button
                variant="outlined"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
                startIcon={<QuestionAnswerIcon />}
                sx={{...styles.promptBtn, marginLeft:'148px'}}
            >
                Språkövningar
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                sx: {
                  ...styles.roundMenu,
                  borderRadius: '8px',
                  '& ul': {
                    border: theme.palette.mode === 'dark' ? '1px solid #35414db2' : '1px solid #eee',
                    padding: '0px',
                    paddingTop:'10px',
                    paddingBottom:'10px',
                    borderRadius: '8px' 
                  }
                }
              }}
            >
                {menuPosition === 0 && prompts.map((prompt, index) => (
                    <MenuItem
                        key={index}
                        onClick={prompt.action ? prompt.action : () => handleCategoryClick(prompt.category)}
                        sx={styles.menuItemStyle}
                    >
                        <ListItemIcon>{prompt.icon}</ListItemIcon>
                        <ListItemText>{prompt.category}</ListItemText>
                        {prompt.items && <KeyboardArrowRightIcon />}
                    </MenuItem>
                ))}
                {menuPosition === 1 && selectedCategory && (
                    <>
                        <MenuItem onClick={() => setMenuPosition(0)} sx={styles.menuItemStyle}>
                            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
                            <ListItemText>Tillbaka</ListItemText>
                        </MenuItem>
                        <Divider />
                        {prompts.find(p => p.category === selectedCategory).items.map((item, itemIndex) => (
                            <MenuItem
                                key={itemIndex}
                                onClick={() => handleMenuItemClick(item)}
                                sx={styles.menuItemStyle}
                            >
                                <ListItemText>{item}</ListItemText>
                            </MenuItem>
                        ))}
                    </>
                )}
            </Menu>
        </>
    );
};

export default LanguagePrompts;