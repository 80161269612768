import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { 
  Grid, Card, Typography, Button, TextField, Box, InputAdornment, CardContent,
  CircularProgress, Fade, Collapse, FormControl, InputLabel, Select, MenuItem, Tooltip, Chip, Avatar, Divider, IconButton
} from '@mui/material';
import { collection, getDocs, addDoc, query, where, getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { formatDate } from './functions';
import LoadingSpinner from './LoadingSpinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatDistanceToNow } from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import { sv } from 'date-fns/locale';
import { useMediaQuery } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getAIModelImage } from './TestComponents2';
import DrawerComponent from './quiz/DrawerComponent';
import BallotIcon from '@mui/icons-material/Ballot';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SegmentIcon from '@mui/icons-material/Segment';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import QuizCarousel from './QuizCarousel';
const FilterComponent = lazy(() => import('./quiz/FilterComponent'));
import QuizIcon from '@mui/icons-material/Quiz';
import Logger from './Logger';

const PublicQuiz = ({ user }) => {
  const [publicQuizzes, setPublicQuizzes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingQuizzes, setIsLoadingQuizzes] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userEmails, setUserEmails] = useState({});
  const [questionCountFilter, setQuestionCountFilter] = useState('');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [featuredQuizzes, setFeaturedQuizzes] = useState([]);
  const [categorizedQuizzes, setCategorizedQuizzes] = useState({});

  const [visibleQuizzes, setVisibleQuizzes] = useState({});

  const [filterOpen, setFilterOpen] = useState(false);
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [modelFilter, setModelFilter] = useState('');

  const [difficulties, setDifficulties] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [models, setModels] = useState([]);

  const featuredQuizIds = ['quiz1', 'quiz2', 'quiz3']; // Replace with actual featured quiz IDs

  const featuredRef = useRef(null);
  const recentlyGeneratedRef = useRef(null);
  const categoryRefs = useRef({});
  const [highestRatedQuizzes, setHighestRatedQuizzes] = useState([]);
  const highestRatedRef = useRef(null);
  const [recentlyGeneratedQuizzes, setRecentlyGeneratedQuizzes] = useState([]);
  const [userInfo, setUserInfo] = useState({});

const [drawerOpen, setDrawerOpen] = useState(false);

  const [questionTypeFilter, setQuestionTypeFilter] = useState('');
  const [questionTypes, setQuestionTypes] = useState([]);



  useEffect(() => {
    // Initialize refs for each category
    Object.keys(categorizedQuizzes).forEach(category => {
      categoryRefs.current[category] = React.createRef();
    });
  }, [categorizedQuizzes]);


  useEffect(() => {
    const fetchPublicQuizzes = async () => {
      setIsLoadingQuizzes(true);
      try {
        const quizzesRef = collection(db, 'quiz');
        const q = query(quizzesRef, where('isPublic', '==', true));
        const quizzesSnapshot = await getDocs(q);
        const quizzesList = quizzesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const sortedQuizzes = quizzesList.sort((a, b) => b.createdAt - a.createdAt);
        setRecentlyGeneratedQuizzes(sortedQuizzes.slice(0, 10)); // Get the 10 most recent quizzes


        setFeaturedQuizzes(quizzesList.filter(quiz => featuredQuizIds.includes(quiz.id)));

        // Sort quizzes by upvotes for the Highest Rated section
        const sortedByUpvotes = [...quizzesList].sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
        setHighestRatedQuizzes(sortedByUpvotes.slice(0, 10)); // Get top 10 highest rated quizzes

        const uniqueQuestionTypes = [...new Set(quizzesList.map(quiz => quiz.questionType))];
        setQuestionTypes(uniqueQuestionTypes);


        const categorized = quizzesList.reduce((acc, quiz) => {
          const category = quiz.category === "Välj kategori" ? "Okategoriserade" : quiz.category;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(quiz);
          return acc;
        }, {});

        setCategorizedQuizzes(categorized);

        const initialVisibleQuizzes = {
          ...Object.keys(categorized).reduce((acc, category) => {
            acc[category] = 2;
            return acc;
          }, {}),
          recentlyGenerated: 2,
          highestRated: 2
        };

        setVisibleQuizzes(initialVisibleQuizzes);

        setPublicQuizzes(quizzesList);

        const uniqueDifficulties = [...new Set(quizzesList.map(quiz => quiz.difficulty))];
        const uniqueLanguages = [...new Set(quizzesList.map(quiz => quiz.language))];
        const uniqueModels = [...new Set(quizzesList.map(quiz => quiz.aiModel))];

        setDifficulties(uniqueDifficulties);
        setLanguages(uniqueLanguages);
        setModels(uniqueModels);

      const userIds = [...new Set(quizzesList.map(quiz => quiz.userId).filter(Boolean))];
        const userInfoObj = {};
        for (const userId of userIds) {
          const userDoc = await getDoc(doc(db, 'users', userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();

            userInfoObj[userId] = {
              displayName: userData.displayName || userData.email || 'Okänd',
              avatarUrl: userData.photoURL || null
            };
          }
        }
        setUserInfo(userInfoObj);



      } catch (error) {
        console.error('Error fetching public quizzes:', error);
        showSnackbar('Error fetching public quizzes. Please try again.', 'error');
      } finally {
        setIsLoadingQuizzes(false);
      }
    };

    fetchPublicQuizzes();
  }, []);

  const filterQuizzes = (quizzes) => {
    return quizzes.filter(quiz => 
      quiz.quizName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (difficultyFilter === '' || quiz.difficulty === difficultyFilter) &&
      (questionTypeFilter === '' || quiz.questionType === questionTypeFilter) &&
      (modelFilter === '' || quiz.aiModel === modelFilter) &&
      (questionCountFilter === '' || getQuestionCountRange(quiz.results ? quiz.results.length : 0) === questionCountFilter)
    );
  };




   const getQuestionCountRange = (count) => {
    if (count >= 1 && count <= 5) return '1-5';
    if (count >= 6 && count <= 10) return '6-10';
    if (count >= 11 && count <= 20) return '11-20';
    return '20+';
  }; 

  const handleCreateNewQuiz = () => {
    navigate(`/quiz`);
  };

const toggleDrawer = (open) => () => {
  setDrawerOpen(open);
};

  // const formatDate = (timestamp) => {
  //   const date = new Date(timestamp);
  //   const now = new Date();
  //   const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  //   if (diffInDays === 0) {
  //     const distance = formatDistanceToNow(date, { addSuffix: true, locale: sv });
  //     // Custom shortening logic
  //     return distance
  //       .replace('ungefär ', '')
  //       .replace(' sekunder', ' sek')
  //       .replace(' minuter', ' min')
  //       .replace(' timmar', ' timmar')
  //       .replace(' timme', ' timme')
  //       .replace('mindre än en minut', '< 1 min');
  //   } else if (diffInDays === 1) {
  //     return 'Igår';
  //   } else if (diffInDays < 7) {
  //     return `${diffInDays} dagar sedan`;
  //   } else {
  //     return format(date, 'd MMM yyyy', { locale: sv });
  //   }
  // };


  const createUrlSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/å/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/ö/g, 'o')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };


  const copyPublicQuiz = async (publicQuiz, event) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      if (publicQuiz.userId === user.uid) {
        showSnackbar('Du kan inte kopiera ditt eget quiz.', 'info');
        return;
      }

      const q = query(
        collection(db, 'quiz'),
        where('userId', '==', user.uid),
        where('originalQuizId', '==', publicQuiz.id)
      );
      const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // User has already copied this quiz
      const copiedQuiz = querySnapshot.docs[0].data();
      const urlSlug = createUrlSlug(copiedQuiz.quizName);
      showSnackbar('Du har redan kopierat detta quiz. Navigerar till din kopia.', 'info');
      navigate(`/quiz/${querySnapshot.docs[0].id}/${urlSlug}`);
      return;
    }

      const { id, ...quizData } = publicQuiz;
      const newQuizRef = await addDoc(collection(db, 'quiz'), {
        ...quizData,
        userId: user.uid,
        createdAt: new Date(),
        isPublic: false,
        isCopied: true,
        originalQuizId: id,
        originalUserId: quizData.userId
      });

      Logger.log("Quiz copied with ID: ", newQuizRef.id);
      showSnackbar('Quiz kopierat framgångsrikt!', 'success');
      
      const urlSlug = createUrlSlug(publicQuiz.quizName);
      navigate(`/quiz/${newQuizRef.id}/${urlSlug}`); // Navigate to the new quiz with SEO-friendly URL
    } catch (error) {
      console.error('Error copying public quiz:', error);
      showSnackbar('Ett fel uppstod vid kopiering av quizet.', 'error');
    }
  };



  const handleLoadMore = (category) => {
    setLoading(true);
    setTimeout(() => {
      setVisibleQuizzes(prev => ({
        ...prev,
        [category]: prev[category] + 4
      }));
      setLoading(false);
    }, 500);
  };

 const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getDifficultyLabel = (difficulty) => {
    switch (difficulty) {
      case 'Enkel':
        return 'Enkel';
      case 'Medel':
        return 'Medel';
      case 'Svårt':
        return 'Svårt';
      default:
        return difficulty || 'Okänd';
    }
  };

  const getDifficultyColor = (difficulty, theme) => {
    switch (difficulty) {
      case 'Enkel':
        return {
          bg: theme.palette.mode === 'dark' ? '#1a5928' : '#e6f4e8',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#1a5928'
        };
      case 'Medel':
        return {
          bg: theme.palette.mode === 'dark' ? '#7d5f0a' : '#fff4d9',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#7d5f0a'
        };
      case 'Svårt':
        return {
          bg: theme.palette.mode === 'dark' ? '#8b2626' : '#ffe8e8',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#8b2626'
        };
      default:
        return {
          bg: 'transparent',
          text: theme.palette.text.primary
        };
    }
  };

const getSectionDescription = (section) => {
  switch(section) {
    case 'Utvalda Quiz':
      return 'Utvalda quiz som vi rekommenderar för sin kvalitet och variation.';
    case 'Högst betyg':
      return 'De mest uppskattade quizen baserat på användarnas omdömen.';
    case 'Senast genererade':
      return 'De senaste quizen som har skapats av vårt community.';
    case 'Alla Quiz':
      return 'Utforska hela vår samling av quiz med olika svårighetsgrader och teman.';
    case 'Okategoriserade':
      return 'Quiz som ännu inte har tilldelats en specifik kategori.';
    case 'Historia':
      return 'Res genom tiden med fängslande frågor om historiska händelser och personer.';
    case 'Matematik':
      return 'Utmana din logik och problemlösningsförmåga med matematiska quiz.';
    case 'Vetenskap':
      return 'Utforska naturens mysterier och vetenskapliga upptäckter.';
    case 'Språk':
      return 'Testa dina språkkunskaper och lär dig nya fascinerande ord och uttryck.';
    case 'Litteratur':
      return 'Dyk in i böckernas värld med frågor om författare, verk och litterära epoker.';
    case 'Film':
      return 'Pröva dina kunskaper om filmer, regissörer och ikoniska filmcitat i dessa film-quiz.';
    case 'Konst':
      return 'Upptäck konstens värld genom frågor om konstnärer, stilar och berömda verk.';
    case 'Musik':
      return 'Testa ditt gehör och musikkunskaper med frågor från olika genrer och epoker.';
    case 'Idrott':
      return 'Utmana dig själv med frågor om sporter, atleter och idrottshistoria.';
    case 'Teknik':
      return 'Dyk in i teknikens värld med frågor om innovationer och digitala trender.';
    case 'Filosofi':
      return 'Utforska tankeväckande filosofiska koncept och tänkare.';
    case 'Religion':
      return 'Lär dig mer om världens religioner, traditioner och andliga läror.';
    case 'Psykologi':
      return 'Utforska människans sinne och beteende genom psykologiska frågeställningar.';
    default:
      return 'Upptäck spännande quiz anpassade för olika behov och preferenser.';
  }
};


  const clearFilters = () => {
    setDifficultyFilter('');
    setQuestionTypeFilter('');
    setModelFilter('');
    setQuestionCountFilter('');
  };

  const getQuestionTypeLabel = (questionType) => {
    const options = [
      { value: 'multipleChoice', label: 'Flervalsfrågor', icon: <BallotIcon /> },
      { value: 'fill-in-the-blank', label: 'Lucktext', icon: <ShortTextIcon /> },
      { value: 'true-or-false', label: 'Sant/Falskt', icon: <FactCheckIcon /> },
    ];
    const option = options.find(opt => opt.value === questionType);
    return option ? option.label : questionType;
  };


const renderQuizCard = (quiz, isCarousel = false) => {
  const urlSlug = createUrlSlug(quiz.quizName);
  const modelImage = getAIModelImage(quiz.aiModel);
  const difficultyColor = getDifficultyColor(quiz.difficulty, theme);
    const user = userInfo[quiz.userId] || { displayName: 'Okänd', avatarUrl: null };

  return (
    <Grid item xs={12} sm={6} md={4} key={quiz.id}>
      <Card 
        sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '15px',
          overflow: 'hidden',
          boxShadow: '0 10px 20px rgba(0,0,0,0.1)',

          width: isCarousel ? '300px' : '100%',
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
            clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0 100%)',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: '700', color: 'white', mb: 2, wordBreak: 'break-word' }}>
            {quiz.quizName}
          </Typography>
        </Box>

        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
<Box>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '20px' }}>
                  <ThumbUpOutlinedIcon sx={{ fontSize: 20, mb: 0.5, color: '#41bf13'  }} />
                  <Typography variant="body2" sx={{ color: '#41bf13', fontWeight: 'bold' }}>{quiz.upvotes || 0}</Typography>
                </Box>

 

              </Grid>
              <Grid item xs={4}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar 
                      src={user.avatarUrl || '/default-avatar.png'} 
                      alt={user.displayName}
                      sx={{ width: 20, height: 20, mb: 0.5 }}
                    />
                    <Typography variant="body2" noWrap>{user.displayName}</Typography>
                  </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {modelImage && (
                    <Avatar src={modelImage} alt={quiz.aiModel} sx={{ width: 20, height: 20, mb: 0.5 }} />
                  )}
                  <Typography variant="body2" noWrap>{quiz.aiModel}</Typography>
                </Box>
              </Grid>
            </Grid>

            <Typography variant="body2" sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AccessTimeIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {formatDate(quiz.createdAt)}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2">Svårighetsgrad:</Typography>
              <Chip
                label={getDifficultyLabel(quiz.difficulty)}
                size="small"
                sx={{
                  bgcolor: difficultyColor.bg,
                  color: difficultyColor.text,
                  fontWeight: 'bold',
                }}
              />
            </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="body2">Frågetyp:</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>{getQuestionTypeLabel(quiz.questionType)}</Typography>
              </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body2">Antal frågor:</Typography>
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>{quiz.results ? quiz.results.length : 'Okänt'}</Typography>
            </Box>
          </Box>


          <Link 
            to={`/quiz/${quiz.id}/${urlSlug}`} 
            onClick={(e) => copyPublicQuiz(quiz, e)}
            style={{ textDecoration: 'none', marginTop: '16px' }}
          >
            <Button 
              variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
              color="primary" 
              fullWidth
              sx={{ 
                borderRadius: '25px',
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              Starta Quiz
            </Button>
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
};





  if (isLoadingQuizzes) {
    return <LoadingSpinner />;
  }

  return (

<>

<DrawerComponent 
  user={user}
  theme={theme}
  open={drawerOpen}
  onClose={toggleDrawer(false)}
/>

    <Fade in={!isLoadingQuizzes}>
      <Box sx={styles.container}>
        <Box sx={{...styles.innerContainer, width:{xs: '100%', md: '100%'}, margin:'0 auto'}}>





         <Box sx={{ width: { xs: '100%', md: '80%', margin:'0 auto'}}}>
         <Box sx={{textAlign:{xs: 'left', md: 'center'}}}>
          <Typography variant="h1" sx={{...styles.header, mt:5}}>
            Alla quiz
          </Typography>
         
          <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:3}}>
            Upptäck och skapa anpassade quiz genererade av olika AI-modeller med varierande svårighetsgrad, kategorier, antal frågor och språk.
         
          </Box>
         
 </Box>
          <Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>
            {isMobile ? (
              <Button variant="contained" color="primary" disableRipple onClick={handleCreateNewQuiz} startIcon={<AddIcon />} sx={styles.shrinkButton}/> 
            ) : (
              <Button variant="contained" color="primary" onClick={handleCreateNewQuiz} startIcon={<AddIcon />}>Nytt quiz</Button>
            )}


<Button
                    variant="contained" 
                    color="primary" 
                    disableRipple
                    onClick={toggleDrawer(true)}
                    startIcon={<SegmentIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                /> 

          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2}}>
            <TextField
              placeholder="Sök quiz"
              value={searchTerm}
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{...styles.whiteBg}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{fontSize:'20px'}} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 3, flexWrap: 'wrap' }}>

          {/*  <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(featuredRef)}>Utvalda</Button>*/}
          

          <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(highestRatedRef)}>Högst betyg</Button>
            <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(recentlyGeneratedRef)}>Senast genererade</Button>
            {Object.keys(categorizedQuizzes).map((category, index) => (
              <Button 
                key={index}
                sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} 
                variant="outlined" 
                onClick={() => scrollToSection(categoryRefs.current[category])}
              >
                {category}
              </Button>
            ))}
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            mb: 2, 
            textAlign: 'center' 
          }}>
            <Button variant="outlined" sx={{...styles.beautyBtnTres}} startIcon={<FilterListIcon />} onClick={() => setFilterOpen(!filterOpen)}>
              Filtrera
            </Button>
          </Box>




<Suspense 
  fallback={
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <CircularProgress size={16} />
    </div>
  }
>
  {filterOpen && (
    <FilterComponent
      difficultyFilter={difficultyFilter}
      setQuestionTypeFilter={setQuestionTypeFilter}
      setDifficultyFilter={setDifficultyFilter}
      languageFilter={languageFilter}
      setLanguageFilter={setLanguageFilter}
      modelFilter={modelFilter}
      setModelFilter={setModelFilter}
      questionCountFilter={questionCountFilter}
      setQuestionCountFilter={setQuestionCountFilter}
      difficulties={difficulties}
      questionTypes={questionTypes}
      models={models}
      clearFilters={clearFilters}
      styles={styles}
    />
  )}
</Suspense>


</Box>

{/* Featured Section
<Box ref={featuredRef}>
  <Typography variant="h3" sx={{...styles.h3, mt:8, mb: 2}}>
    Utvalda Quiz
  </Typography>
  <Typography sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333', mb: 4}}>
    {getSectionDescription('Utvalda Quiz')}
  </Typography>
  <QuizCarousel
    quizzes={filterQuizzes(featuredQuizzes)}
    renderQuizCard={(quiz) => renderQuizCard(quiz, true)}
  />
</Box>
 */}



{/* Highest Rated Section */}
<Box ref={highestRatedRef}>
  <Typography variant="h3" sx={{...styles.h3, mt:8, mb: 2}}>
    Högst betyg
  </Typography>
  <Typography sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333', mb: 4}}>
    {getSectionDescription('Högst betyg')}
  </Typography>
  <QuizCarousel
    quizzes={filterQuizzes(highestRatedQuizzes)}
    renderQuizCard={(quiz) => renderQuizCard(quiz, true)}
  />
</Box>

{/* Recently Generated Section */}
<Box ref={recentlyGeneratedRef}>
  <Typography variant="h3" sx={{...styles.h3, mt:8, mb: 2}}>
    Senast genererade
  </Typography>
  <Typography sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333', mb: 4}}>
    {getSectionDescription('Senast genererade')}
  </Typography>
  <QuizCarousel
    quizzes={filterQuizzes(recentlyGeneratedQuizzes)}
    renderQuizCard={(quiz) => renderQuizCard(quiz, true)}
  />
</Box>

{/* Categorized Sections */}
{Object.entries(categorizedQuizzes).map(([category, quizzes]) => (
  <Box key={category} ref={categoryRefs.current[category]}>
    <Typography variant="h3" sx={{...styles.h3, mt:8, mb: 2}}>
      {category}
    </Typography>
    <Typography sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333', mb: 4}}>
      {getSectionDescription(category)}
    </Typography>
    <QuizCarousel
      quizzes={filterQuizzes(quizzes)}
      renderQuizCard={(quiz) => renderQuizCard(quiz, true)}
    />
  </Box>
))}


   <Box 
      sx={{...styles.bottomCta}}
    >
      <QuizIcon sx={{ fontSize: {xs:40, md:60}, color: theme.palette.primary.main, marginBottom: 2 }} />
      <Typography variant="h4" sx={{...styles.bottomCtaTitle}}>
        Skapa ditt eget quiz med AI!
      </Typography>
      <Typography variant="body1" sx={{...styles.bottomCtaBody}}>
        Generera skräddarsydda quiz med hjälp av AI. Anpassa svårighetsgrad, ämnesområden och frågetyper för en unik och utmanande quizupplevelse.
      </Typography>
      <Button 
        component={Link}
        to="/quiz"
           variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
        color="primary"
        size="large"
        startIcon={<AddIcon />}
        sx={{ 
          textTransform: 'none',
          fontWeight: 'bold',
          padding: '10px 20px',
          fontSize: '1.1rem',
          marginTop: '20px'
        }}
      >
        Skapa quiz med AI
      </Button>
    </Box>


        </Box>
      </Box>
    </Fade>
    </>
  );
};

export default PublicQuiz;