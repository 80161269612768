// AskAI.js
import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Menu, MenuItem, ListItemIcon, Tooltip, Avatar, Drawer, List, ListItem, ListItemText, IconButton, TextField } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import Prompts from './Prompts';
import MessageBubble from './MessageBubble';
import TextInput from './TextInput';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BoltIcon from '@mui/icons-material/Bolt';
import AddIcon from '@mui/icons-material/Add';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Helmet } from 'react-helmet-async';
import SegmentIcon from '@mui/icons-material/Segment';
import SkeletonLoader from './SkeletonLoader';
import { styles } from './index';
import useGeneralChat from './useGeneralChat';
import { useTheme } from '@mui/material/styles';
import PromptDialog from './PromptDialog';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useSnackbar } from './SnackbarHandler';
import { getStyles } from './index';
import LanguagePrompts from './LanguagePrompts';
import Logger from './Logger';
import LoadingSpinner from './LoadingSpinner';

import ExploreIcon from '@mui/icons-material/Explore';


import { sanitizeInput } from './functions';


const TeacherChat = ({ user, isOpen, isDark}) => {
    

const { teacherId } = useParams();
const [convoType, setConvoType] = useState(teacherId);
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState(null);
const [isLoading, setIsLoading] = useState(true);
const [displayChatName, setDisplayChatName] = useState('');
const [oldishLoading, setOldishLoading] = useState(true);
const [userAvatar, setUserAvatar] = useState("/path-to-default-user-avatar.png");
const [teacherAvatar, setTeacherAvatar] = useState("/path-to-default-ai-avatar.png");

  const [isLanguageBot, setIsLanguageBot] = useState(false);
const [dynamicTitle, setDynamicTitle] = useState("T");
const [dynamicDescription, setDynamicDescription] = useState("T");

  const theme = useTheme();
  const styles = getStyles(theme);

const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { showSnackbar } = useSnackbar();

    const {
        input,
        messages,
        loading,
        loadingMessage,
        anchorEl,
        clientAnchorEl,
        selectedPrompt,
        messagesEndRef,
        inputRef,
        drawerOpen,
        chats,
        currentChatId,
        editingChat,
        loadingLatestChat,
        loadingChat,
        creatingNewChat,
        savingChatName,
        loadingOldChats,
        base64Image,
        handleImageUpload,
        setInput,
        setAnchorEl,
        setClientAnchorEl,
        setSelectedPrompt,
        setDrawerOpen,
        handleCreateNewChat,
        handleChatSelect,
        handleChatNameChange,
        handleSaveChatName,
        handleStartEditing,
        handleSendMessage,
        handleInputChange,
        handleDeleteAllMsgs,
        setLoadingMessage,
        setMessages,
        sendMessage,
        handleGenerateIdeas,
        optimisticUpdate,
        streamingMessage,
        handleStreamingMessage, handleStreamComplete,
        completedStreamingId, isLive,
        isStreaming,
    handleStopStreaming,
    currentChatName,
    prompts,
  promptDialogOpen,
  setPromptDialogOpen,
  addPrompt,
  deletePrompt,

    } = useGeneralChat(user, convoType, undefined, teacher);


const handleEditTeacher = () => {
    Logger.log("handleEditTeacher");
    Logger.log("teacherId: ", teacherId);
    navigate(`/larare/${teacherId}`);
  };

    const handleClientClick = (event) => {
        setClientAnchorEl(event.currentTarget);
    };






useEffect(() => {
  if (user) {
    Logger.log("user.photoURL: ", user.photoURL);

    setUserAvatar(user.photoURL || "/path-to-default-user-avatar.png");
  }
}, [user]);

useEffect(() => {
  if (teacher) {
    setTeacherAvatar(teacher.imageUrl || "/path-to-default-ai-avatar.png");
    
    if (teacher.category === "Språk" && teacher.languageToLearn) {
      const languageMap = {
        french: "franska",
        spanish: "spanska",
        german: "tyska"
      };
      const languageName = languageMap[teacher.languageToLearn] || teacher.languageToLearn;
      
      setDynamicTitle(`${getLanguageText(teacher.languageToLearn)} — Lär dig ${languageName} med AI — Studera.AI`);
      setDynamicDescription(`Förbättra dina ${languageName}-kunskaper genom interaktiva AI-drivna konversationer. Anpassad inlärning för alla nivåer.`);
    } else {
      setDynamicTitle(`${teacher.characterName} — AI-chat — Studera.AI`);
      setDynamicDescription(`${teacher.description}`);
    }
    
    setIsLanguageBot(teacher.category === "Språk");
    Logger.log("teacher.languageToLearn: ", teacher.languageToLearn);
  }
}, [teacher]);

function getLanguageText(languageToLearn) {
  switch (languageToLearn) {
    case 'spanish':
      return 'Spansklärare';
    case 'french':
      return 'Fransklärare';
    case 'german':
      return 'Tysklärare';
    default:
      return 'Språklärare';
  }
}


useEffect(() => {
  if (teacherId) {
    setConvoType(teacherId);



    // Moonshine: Ska den verkligen rensa här?
    setMessages([]); // Rensa befintliga meddelanden

  }
}, [teacherId]);
useEffect(() => {
  Logger.log("currentTeach: ", teacherId);



  const fetchTeacher = async () => {
    setIsLoading(true);
    try {
      const teacherDoc = await getDoc(doc(db, 'teachers', teacherId));
      if (teacherDoc.exists()) {
        setTeacher(teacherDoc.data());




        setConvoType(teacherId);
        Logger.log("teacherDoc: ", teacherDoc.data());
      } else {
        Logger.log("Ingen lärare hittades med detta ID");
      }
    } catch (error) {
      console.error("Error fetching teacher:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  setConvoType(teacherId);
  setMessages([]); // Clear existing messages
  fetchTeacher();
}, [teacherId, setMessages, convoType]);

  useEffect(() => {

Logger.log("convoType ändras useeffect: ", convoType);
  }, [convoType]);

  useEffect(() => {
    if(!loadingOldChats)
      setOldishLoading(false);
  }, [loadingOldChats]);

  useEffect(() => {
    setDisplayChatName(currentChatName);
  }, [currentChatName]);



const handleOpenPromptDialog = () => {
  setPromptDialogOpen(true);
};



    const handleClientClose = () => {
        setClientAnchorEl(null);
    };



const handleMenuItemClick = async (prompt) => {

    setSelectedPrompt(prompt);
    setAnchorEl(null);

Logger.log("setLoadingMessage: ", loadingMessage);
    if (user && user.uid && currentChatId && prompt.trim() !== '') {

        const newMessage = optimisticUpdate(prompt, true);

        
        if (newMessage) {

            try {
                await handleSendMessage(prompt, newMessage);

            } catch (error) {
                console.error('Error in sendMessage:', error);
                setMessages(prevMessages => prevMessages.filter(msg => msg.id !== newMessage.id));
            } finally {

            }
        } else {
            Logger.log("optimisticUpdate returned null");
        }
    } else {
        console.error('User ID, chatId is missing, or prompt is empty when trying to send message');
    }

};

if (isLoading) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingSpinner />
    </Box>
  );
}

if (!teacher) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography>No teacher found with this ID.</Typography>
    </Box>
  );
}

    return (
<>
    <Helmet>
      <title>{dynamicTitle}</title>
      <meta name="description" content={dynamicDescription} />
    </Helmet>

        <Box sx={{...styles.container, backgroundColor: isDark === true ? '#15202B' : ''}} >
        <Box sx={styles.innerContainer}>
            <Typography variant="h1" sx={{...styles.header, borderBottom:'0px'}}>{sanitizeInput(teacher.characterName)}</Typography>

            <Button
                variant="outlined"
                onClick={handleClientClick}
                startIcon={<BoltIcon />}
                endIcon={<ArrowDropDownIcon />}
                sx={{...styles.smallerBtn,  display: {xs:'none', md:'flex'}, left:'auto !important', right:'260px', top:'23px'  } }
            >
                Studera AI
            </Button>

<Menu
    anchorEl={clientAnchorEl}
    open={Boolean(clientAnchorEl)}
    onClose={handleClientClose}

>



<Tooltip title="Stöd för fler AI-modeller kommer snart">
<div>
        <MenuItem selected sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}} disabled={true}>
        <ListItemIcon>
                <img src="/img/AI.png" style={styles.roundImage} alt="Studera.AI"  />
        </ListItemIcon>
        Studera.AI
        </MenuItem>

        <MenuItem  sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}} disabled={true}>
        <ListItemIcon>
                <img src="/img/ChatGPT.png" style={styles.roundImage} alt="ChatGPT 4o-mini"  />
        </ListItemIcon>
        ChatGPT 4o-mini
        </MenuItem>

        
<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/GPT4o.png" style={styles.roundImage} alt="GPT 4o"  />
        </ListItemIcon>
        GPT 4o
        </MenuItem>  

<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Claude 3 Sonnet.png" style={styles.roundImage} alt="Claude 3 Sonett"  />
        </ListItemIcon>
        Claude 3 Sonnet
        </MenuItem>  



<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Claude 3.5 Sonnet.png" style={styles.roundImage} alt="Claude 3.5 Sonett"  />
        </ListItemIcon>
        Claude 3.5 Sonnet
        </MenuItem>  
<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Gemini Pro.png" style={styles.roundImage} alt="Gemini Pro"  />
        </ListItemIcon>
        Gemini Pro
        </MenuItem>  
<MenuItem disabled={true} sx={{...styles.menuItems, fontSize:'14px', fontWeight:'600'}}>
        <ListItemIcon>
                <img src="/img/Mistral Large.png" style={styles.roundImage} alt="Mistral Large"  />
        </ListItemIcon>
        Mistral Large
        </MenuItem>          
        </div>
</Tooltip>
</Menu>
<Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>


       <Typography sx={{fontSize:'16px', marginRight:'30px', color: isDark ? '#b9b9b9' : '#6c6c6c', display: {xs:'none', md:'inherit'}}}>

{/*

<TypeAnimation
key={displayChatName} 
  sequence={[
    displayChatName,
    1000,
  ]}
  speed={50}
  cursor={false}

  repeat={1}
/>
*/}


</Typography>

                <Tooltip title={messages.length === 0 ? "Skriv nåt i denna chatten först" : ""}>
                    <span>



{isMobile ? (
  <Button variant="contained"  color="primary"  disableRipple  onClick={handleCreateNewChat} startIcon={<AddIcon />} sx={styles.shrinkButton}/> 
) : (
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleCreateNewChat}
                            startIcon={<AddIcon />}
                            disabled={messages.length === 0 || creatingNewChat}
                        >
                            {creatingNewChat ? 'Skapar...' : 'Ny chatt'}
                        </Button>
)}



                    </span>
                </Tooltip>


    <Button 
      variant="contained"
      color="primary"
      disableRipple
      onClick={(e) => {
        e.preventDefault();
        navigate('/personal');
      }}
      startIcon={<ExploreIcon sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6'}} />}
      sx={styles.shrinkButton}
    >

    </Button>


                <Button
                    variant="contained" 
                    color="primary" 
                    disableRipple
                    onClick={() => setDrawerOpen(true)}
                    startIcon={<SegmentIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                />
            </Box>

<Drawer
        sx={{ width: { xs: '75%', sm: '50%', md: '30%' }, paddingTop: '0px' }}
        PaperProps={{ sx: { width: { xs: '75%', sm: '50%', md: '30%' }, padding:'20px', paddingTop: '0px' } }}


            anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>

<Box sx={styles.flexContainerSidebar}>
      <Typography variant="h6" sx={styles.header}>Historik</Typography>

<IconButton onClick={() => setDrawerOpen(false)} color="inherit">
            <MenuOpenIcon />
          </IconButton>
</Box>



{oldishLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'top', justifyContent: 'top', height: '100%' }}>
<LoadingSpinner noWrapper="true" />
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
               
            </Typography>
        </Box>
    ) : (
        <List>
            {chats.map((chat) => (
                <ListItem  sx={{
    padding: 0,
    margin: 0,
    backgroundColor: isDark ? '#3c4853' : 'inherit'

  }}
   key={chat.id} button={editingChat.id !== chat.id} onClick={() => editingChat.id !== chat.id && handleChatSelect(chat.id)}>
                    <ListItemIcon sx={{ minWidth:'33px !important' }}>
                        <ChatIcon  sx={{  marginRight:'10px',  marginLeft:'8px' }} />
                    </ListItemIcon>
                    {editingChat.id === chat.id ? (
                        <>
                            <TextField
                                value={editingChat.name}
                                onChange={handleChatNameChange}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSaveChatName();
                                    }
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '4px',
                                    },
                                }}
                                autoFocus
                                onClick={(e) => e.stopPropagation()}
                            />
                            {savingChatName ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}>
                                    <ClipLoader size={20} color={"#123abc"} />
                                </Box>
                            ) : (
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    handleSaveChatName();
                                }}>
                                    <SaveIcon />
                                </IconButton>
                            )}
                        </>
                    ) : (
                        <>
                            <ListItemText primary={chat.name} sx={{ paddingLeft: 0, marginLeft: 0 }} />
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                handleStartEditing(chat.id, chat.name);
                            }}>
                                <EditIcon />
                            </IconButton>
                        </>
                    )}
                </ListItem>
            ))}
        </List>
    )}

<Box>
<Button 
            startIcon={<SettingsIcon />}
            onClick={() => handleEditTeacher()}
            variant={theme.palette.mode === 'light' ? "purp" : "contained"}  color="primary" 
          >
            Redigera lärare
          </Button>
</Box>
    
            </Drawer>

<style jsx="true" global="true">{`
  html, body {
    overflow: hidden; /* Prevent body scroll */
    height: 100%; /* Ensure body takes full height */
    margin: 0; /* Remove default margin */
  }
`}</style>

<Box sx={{ 
    height: 'calc(100vh - 200px)', // Adjust this value based on your layout
    display: 'flex',
    flexDirection: 'column',
}}>
    <Box sx={{ 
        flexGrow: 1,
        overflowY: 'auto',
        padding: {xs: '0px', md: '16px'},
        '&::-webkit-scrollbar': {
            display: 'block',
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '4px',
        },
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(0,0,0,.2) transparent',
    }}>
        <Box
 sx={{ 
        width: {
          xs: '100%', 
          sm: isOpen ? '100%' : '70%',
        },
        margin: '0 auto'
      }}
        >
   
           
                {loading ? (
                    <SkeletonLoader width="100%" height="700px" />
                ) : loadingLatestChat ? (
                    <Box sx={styles.loaderContainer}>
<LoadingSpinner noWrapper="true" />
                        <Typography variant="body1" sx={{ marginLeft: 2 }}>
                            Laddar din senaste chat...
                        </Typography>
                    </Box>
                ) : loadingChat ? (
                    <Box sx={styles.loaderContainer}>
<LoadingSpinner noWrapper="true" />
                        <Typography variant="body1" sx={{ marginLeft: 2 }}>
                            Laddar in vald chat...
                        </Typography>
                    </Box>
                ) : creatingNewChat ? (
                    <Box sx={styles.loaderContainer}>
<LoadingSpinner noWrapper="true" />
                        <Typography variant="body1" sx={{ marginLeft: 2 }}>
                            Skapar ny chat...
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <MessageBubble 
                            role="bot" 
                            content={sanitizeInput(teacher.greeting)}
                            generateIdeas={handleGenerateIdeas}
                            isDark= {isDark}
                            avatar={teacherAvatar}
                            userId={user.uid}
                            isLanguageBot={isLanguageBot}
                        />
{messages.map((msg) => {

  return (
    <MessageBubble
      key={msg.id}
      {...msg}
      generateIdeas={handleGenerateIdeas}
      currentChatId={currentChatId}
      id={msg.id}
      isSaved={msg.isSaved || false}
      noteId={msg.noteId || null}
      role={msg.role || (msg.isUser ? 'user' : 'bot')}
      conversationType={msg.conversationType}
      userId={user.uid}
      isDark={isDark}
      avatar={msg.role === 'user' ? userAvatar : teacherAvatar}
      isStreaming={msg.isStreaming}
      isLanguageBot={isLanguageBot}
    />
  );
})}
                    </>
                )}

                <div ref={messagesEndRef} />
            </Box>

<Box sx={{
  ...styles.inputContainer,
  width: {
    xs: '100%',
    md: isOpen ? 'calc(100% - 23% - 7px)' : '75%'
  },
  left: { 
    xs: 0,
    md: isOpen ? '290px' : 'calc(15% + 7px)' 
  }
}}>


{isLanguageBot && (
  <LanguagePrompts onSendMessage={handleMenuItemClick} messages={messages} language={teacher.languageToLearn} />
)}


<Prompts
  anchorEl={anchorEl}
  setAnchorEl={setAnchorEl}
  handleMenuItemClick={handleMenuItemClick}
  onAddPrompt={handleOpenPromptDialog}
              userPrompts={prompts}
/>


<PromptDialog
  open={promptDialogOpen}
  onClose={() => setPromptDialogOpen(false)}
  prompts={prompts}
  onAddPrompt={addPrompt}
  onDeletePrompt={deletePrompt}
/>
                <TextInput  
                    input={input}
                    setInput={handleInputChange}
                    sendMessage={handleSendMessage}
                    currentChatId={currentChatId}
                    onImageUpload={handleImageUpload}
                    ref={inputRef}
                    awaitingReply={loadingMessage}
                    isStreaming={isStreaming}
      onStopStreaming={handleStopStreaming}
      isDark={isDark}
                />
            </Box>
        </Box>

        </Box>
        </Box>
        </Box>
        </>
    );
};

export default TeacherChat;