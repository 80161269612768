import React from 'react';
import {
  LineChart, Line, BarChart, Bar, ScatterChart, Scatter,
  AreaChart, Area, PieChart, Pie, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DynamicMathChart = ({ chartType, chartData, chartConfig }) => {
  const validChartData = Array.isArray(chartData) && chartData.length > 0 
    ? chartData 
    : [{ x: 0, y: 0, z: 0 }];

  const safeChartConfig = {
    xAxisLabel: chartConfig?.xAxisLabel || 'X-Axis',
    yAxisLabel: chartConfig?.yAxisLabel || 'Y-Axis',
    series: Array.isArray(chartConfig?.series) ? chartConfig.series : [{ dataKey: 'y', name: 'Default', color: COLORS[0] }]
  };

  const getDataKey = (series) => {
    return series.dataKey || 'y';
  };

  const getNameKey = () => {
    const keys = Object.keys(validChartData[0]);
    return keys.find(key => typeof validChartData[0][key] === 'string') || 'x';
  };

  const getValueKey = () => {
    const keys = Object.keys(validChartData[0]);
    return keys.find(key => typeof validChartData[0][key] === 'number') || 'y';
  };

  const isFormulaData = (data) => {
    return data.some(item => typeof item.y === 'string' && (item.y.includes('*') || item.y.includes('+') || item.y.includes('-') || item.y.includes('/')));
  };

  const renderFormulaView = (data) => {
    return (
      <div className="formula-view">
        <h3>{safeChartConfig.xAxisLabel}</h3>
        <p className="formula">{data[0].y}</p>
      </div>
    );
  };

  const renderChart = () => {
    if (isFormulaData(validChartData)) {
      return renderFormulaView(validChartData);
    }

    switch (chartType) {
      case 'LineChart':
      case 'BarChart':
      case 'AreaChart':
        const ChartComponent = chartType === 'LineChart' ? LineChart : (chartType === 'BarChart' ? BarChart : AreaChart);
        const DataComponent = chartType === 'LineChart' ? Line : (chartType === 'BarChart' ? Bar : Area);
        return (
          <ChartComponent data={validChartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" label={{ value: safeChartConfig.xAxisLabel, position: 'insideBottom', offset: -10 }} />
            <YAxis label={{ value: safeChartConfig.yAxisLabel, angle: -90, position: 'insideLeft', offset: 10 }} />
            <Tooltip />
            <Legend 
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              wrapperStyle={{ paddingTop: '20px' }}
            />
            {safeChartConfig.series.map((s, index) => (
              <DataComponent 
                key={index} 
                type="monotone" 
                dataKey={getDataKey(s)} 
                stroke={s.color || COLORS[index % COLORS.length]} 
                fill={s.color || COLORS[index % COLORS.length]} 
                name={s.name} 
                dot={chartType === 'LineChart' ? { r: 5 } : undefined}
                activeDot={chartType === 'LineChart' ? { r: 8 } : undefined}
              />
            ))}
          </ChartComponent>
        );
      case 'ScatterChart':
        return (
          <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" name={safeChartConfig.xAxisLabel} label={{ value: safeChartConfig.xAxisLabel, position: 'insideBottom', offset: -10 }} />
            <YAxis dataKey="y" name={safeChartConfig.yAxisLabel} label={{ value: safeChartConfig.yAxisLabel, angle: -90, position: 'insideLeft', offset: 10 }} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend 
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              wrapperStyle={{ paddingTop: '20px' }}
            />
            {safeChartConfig.series.map((s, index) => (
              <Scatter 
                key={index} 
                name={s.name} 
                data={validChartData} 
                fill={s.color || COLORS[index % COLORS.length]} 
              />
            ))}
          </ScatterChart>
        );
      case 'PieChart':
        const nameKey = getNameKey();
        const valueKey = getValueKey();
        return (
          <PieChart>
            <Pie
              data={validChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey={valueKey}
              nameKey={nameKey}
              label={({name, percent}) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {validChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        );
      default:
        return <div>Unsupported chart type or insufficient data</div>;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default DynamicMathChart;