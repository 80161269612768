// PublicTeachers.js
import React, { useState, useEffect, useRef } from 'react';
import { 
  Grid, Card, CardContent, Typography, Button, TextField, Box, Avatar, InputAdornment, Chip,
  CircularProgress, useMediaQuery,   IconButton, Collapse
} from '@mui/material';
import { collection, getDocs, addDoc, query, where, setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import LoadingSpinner from './LoadingSpinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SchoolIcon from '@mui/icons-material/School';
import { styled } from '@mui/material/styles';
import Logger from './Logger';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: 15,
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&:hover': {
    '& .MuiTypography-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
}));

const ImageOverlay = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  fontSize:'15px',
  color: 'white',
  fontWeight:'500',
  padding: theme.spacing(2),
  transition: 'background-color 0.3s',
}));



const PublicTeachers = ({ user }) => {
  const [publicTeachers, setPublicTeachers] = useState([]);
  const [userTeachers, setUserTeachers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [visibleTeachers, setVisibleTeachers] = useState({});
  const [featuredTeachers, setFeaturedTeachers] = useState([]);
  const [featuredTeachersNew, setFeaturedTeachersNew] = useState([]);
  const [featuredTeacherIds] = useState(['14GRiHrsHh2f3YDKilDq', 'X6KLiiwYT6UJXaGYHBQ2', 'aB5MwN7s6sl2n43auVh3', 'kTQK63Gfd4nj9ZOIO3Yy', '3dRmkg2iFEyDa5vPKTUy', '7z140t4UC4jSLpvDevTF', 'nTB3pwXxBdLHbhar839H']);
  const [featuredTeacherNewIds] = useState(['oAc5im2gfmlJXrF6XvPv', 'Vh3Z6JVs4JCL55EHIpk6', 'pqSK7pqWXsyEsOkxpUy0', 'jQOVP4WABFtWpIkHZWYD', '6bs1lGSApjJ1gbqbm3ua', 'dL1NVd9HMYxQvTL2j9VO', 'kvZhgusQRykTBUmNA8dG', 'kuTk3RuecSRMXvximDsx', 'nHNQmF42laxU8ccC6DDg']); // Replace with your desired IDs
  const [loading, setLoading] = useState(false);
  const [isLoadingTeachers, setIsLoadingTeachers] = useState(true);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const categoryRefs = useRef({});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
const traditionalTeachersRef = useRef(null);
  const [expandedCards, setExpandedCards] = useState({});
  

  const [excludedTeacherIds] = useState(['qWm0UZ6E1tWR1C12ud0P', '7dlCEmCaKc7F0UiYIPqs', 'IfSUqxsO1yIKdImFhXyB', '4xY9escRJb0K2dn9VHf3']); // Replace with the actual IDs you want to exclude


  const handleExpandClick = (event, teacherId) => {
    event.preventDefault(); // Prevent the Link from navigating
    event.stopPropagation(); // Stop the event from bubbling up
    setExpandedCards(prev => ({
      ...prev,
      [teacherId]: !prev[teacherId]
    }));
  };


  useEffect(() => {
    const fetchPublicTeachers = async () => {
      setIsLoadingTeachers(true);
      const teachersRef = collection(db, 'publicTeachers');
      const teachersSnapshot = await getDocs(teachersRef);
      const teachersList = teachersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(teacher => !excludedTeacherIds.includes(teacher.id)); // Filter out excluded IDs
      
      // Separate featured teachers
      const featured = teachersList.filter(teacher => featuredTeacherIds.includes(teacher.id));
      setFeaturedTeachers(featured);

      const featuredNew = teachersList.filter(teacher => featuredTeacherNewIds.includes(teacher.id));
      setFeaturedTeachersNew(featuredNew);


      setPublicTeachers(teachersList);




      // Extract categories
// Definiera en array med kategorier som ska exkluderas
const excludedCategories = ['Språk'];

// Uppdatera den befintliga koden
const uniqueCategories = [...new Set(teachersList.map(teacher => teacher.category || 'Övrigt'))]
  .filter(category => !excludedCategories.includes(category));
setCategories(uniqueCategories);

      // Initialize visible teachers
      const initialVisibleTeachers = {};
      uniqueCategories.forEach(category => {
        initialVisibleTeachers[category] = 6; // Show 6 teachers initially (3 per row, 2 rows)
      });
      setVisibleTeachers(initialVisibleTeachers);
      setIsLoadingTeachers(false);
    };

    const fetchUserTeachers = async () => {
      if (user) {
        const userTeachersRef = collection(db, 'teachers');
        const q = query(userTeachersRef, where("userId", "==", user.uid));
        const userTeachersSnapshot = await getDocs(q);
        const userTeachersList = userTeachersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          copiedFromPublicId: doc.data().copiedFromPublicId
        }));
        Logger.log("Fetched userTeachers:", userTeachersList);
        setUserTeachers(userTeachersList);
      }
    };

    fetchPublicTeachers();
    fetchUserTeachers();
  }, [user, featuredTeacherIds, featuredTeacherNewIds, excludedTeacherIds]);

  const handleUseTeacher = async (teacher, event) => {
    event.preventDefault(); 
    try {
      if (!user) {
        showSnackbar("Du måste vara inloggad för att använda en lärare.", 'error');
        return;
      }

      const teacherCopy = {
        ...teacher,
        userId: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        visibility: 'private',
        isPublicCopy: true
      };

      await setDoc(doc(db, "teachers", teacher.id), teacherCopy);
      
      showSnackbar(`${teacher.characterName} har stigit in i klassrummet.`, 'success');
      navigate(`/chat/${teacher.id}`);
    } catch (error) {
      console.error("Fel vid kopiering av lärare: ", error);
      showSnackbar("Det gick inte att kopiera läraren. Försök igen.", 'error');
    }
  };

  const isTeacherInUserCollection = (publicTeacherId) => {
    return userTeachers.some(userTeacher => userTeacher.id === publicTeacherId);
  };

const filteredTeachers = publicTeachers.filter(teacher => 
  (teacher?.characterName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
   teacher?.tagline?.toLowerCase().includes(searchTerm.toLowerCase())) ?? false
);

  const handleReadMore = (teacherId, teacherName) => {
    const formattedName = teacherName.toLowerCase().replace(/\s+/g, '-');
    navigate(`/larare/${teacherId}/${encodeURIComponent(formattedName)}`);
  };

  const handleLoadMore = (category) => {
    setLoading(true);
    setTimeout(() => {
      setVisibleTeachers(prev => ({
        ...prev,
        [category]: prev[category] + 6
      }));
      setLoading(false);
    }, 500); // Simulating network delay
  };

 const scrollToCategory = (category) => {
  if (category === 'utvalda') {
    traditionalTeachersRef.current?.scrollIntoView({ behavior: 'smooth' });
  } else {
    categoryRefs.current[category]?.scrollIntoView({ behavior: 'smooth' });
  }
};

  const handleCreateNewTeacher = () => {
    navigate(`/larare/`);

  };


const getCategoryDescription = (category) => {
  switch(category) {
    case 'Matematik':
      return 'Utforska matematikens värld med våra specialiserade lärare.';
    case 'Språk':
      return 'Lär dig nya språk eller förbättra dina språkkunskaper.';
    case 'Vetenskap':
      return 'Upptäck vetenskapens under med våra kunniga lärare.';
    case 'Historia':
      return 'Res genom tiden med våra passionerade historielärare.';
    case 'Konst':
      return 'Släpp loss din kreativitet med hjälp av våra konstlärare.';
    case 'Litteratur':
      return 'Utforska litteraturens värld med våra inspirerande lärare.';
    case 'Musik':
      return 'Utveckla din musikalitet med våra erfarna musiklärare.';
    case 'Idrott':
      return 'Förbättra din fysiska prestationsförmåga med våra engagerade idrottslärare.';
    case 'Teknik':
      return 'Utforska den senaste tekniken med våra innovativa tekniklärare.';
    case 'Psykologi':
      return 'Förstå människans beteende med hjälp av våra kunniga psykologilärare.';
    case 'Filosofi':
      return 'Utmana ditt tänkande med våra insiktsfulla filosofilärare.';
    case 'Religion':
      return 'Utforska världens religioner med våra objektiva religionslärare.';
    case 'Mental hälsa':
      return 'Lär dig verktyg för bättre mental hälsa med våra empatiska lärare.';
    case 'Övrigt':
      return 'Upptäck nya och spännande ämnen med våra mångsidiga lärare.';
    case 'Galna karaktärer':
      return 'Några gränslösa karaktärer för variationens skull.';
    default:
      return 'Utforska spännande ämnen med våra erfarna lärare.';
  }
};

  const getTeacherType = (teacherName) => {
    switch (teacherName) {
      case 'Johan Bauer':
        return 'Tysklärare';
      case 'David Eriksson':
        return 'Mattelärare';
      case 'Katarina Lindström':
        return 'Historialärare';
      case 'Maria Lindström':
        return 'Mental hälsa/Psykolog';
      case 'Emma Johnson':
        return 'Engelsklärare';
      case 'Marie Dubois':
        return 'Fransklärare';
      case 'Isabel García':
        return 'Spansklärare';
      // Add more cases as needed
      default:
        return 'General Teacher';
    }
  };


  return (
    <Box sx={styles.container}>
<Box sx={{...styles.innerContainer, width:{xs: '100%', md: '75%'}, margin:'0 auto'}}>

      <Box sx={{textAlign: {xs:'left', md:'center'}}}>
        <Typography variant="h1" sx={{...styles.header, mt:5}}>
          Hitta din nya favoritlärare
        </Typography>


<Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>



{isMobile ? (
  <Button variant="contained"  color="primary"  disableRipple  onClick={handleCreateNewTeacher} startIcon={<AddIcon />} sx={styles.shrinkButton}/> 
) : (
<Button variant="contained" color="primary"  onClick={handleCreateNewTeacher} startIcon={<AddIcon />}>Ny lärare</Button>
)}



</Box>

<Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:3}}>
Hitta eller skapa din egen AI-lärare eller karaktär som är perfekt anpassad efter dina unika inlärningsbehov och mål.
</Box>

       <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2}}>
          <TextField

            placeholder="Sök lärare"
            value={searchTerm}
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
sx={{...styles.whiteBg}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{fontSize:'20px'}} />
                </InputAdornment>
              ),
            }}
          />
        </Box>


        {/* Category buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
        <Button
              key="utvalda"
              variant="outlined"
              onClick={() => scrollToCategory('utvalda')}
              sx={{
                ...styles.beautyBtn,
                fontWeight: 'normal',
                fontSize: '15px',
                backgroundColor: theme.palette.mode === 'dark' ? '#2f3d4a' : '#fff',
                border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#ededed'}`,
                paddingTop: {xs:'2px', md: '8px'},
                paddingBottom: {xs:'2px', md: '8px'},
                borderRadius:{xs:'30px', md: '5px'},
                fontWeight:'500',
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                "&:hover, &:focus, &:active, &.Mui-focusVisible": {
                  border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#f9f9f9'}`,
                  backgroundColor: theme.palette.mode === 'dark' ? '#384755' : '#eee',
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                },
              }}
            >
              Traditionella lärare
            </Button>
          {categories.map(category => (
            <Button
              key={category}
              variant="outlined"
              onClick={() => scrollToCategory(category)}
              sx={{
                ...styles.beautyBtn,
                fontWeight: 'normal',
                fontSize: '15px',
                backgroundColor: theme.palette.mode === 'dark' ? '#2f3d4a' : '#fff',
                border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#ededed'}`,
                paddingTop: {xs:'2px', md: '8px'},
                paddingBottom: {xs:'2px', md: '8px'},
                borderRadius:{xs:'30px', md: '5px'},
                fontWeight:'500',
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                "&:hover, &:focus, &:active, &.Mui-focusVisible": {
                  border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#f9f9f9'}`,
                  backgroundColor: theme.palette.mode === 'dark' ? '#384755' : '#eee',
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                },
              }}
            >
              {category}
            </Button>
          ))}
        </Box>

 
</Box>


{isLoadingTeachers ? (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
    <LoadingSpinner />
  </Box>
) : (
  <>




       {featuredTeachersNew.length > 0 && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h3" sx={{...styles.h3, mt:8}}>
              Användarnas favoriter
            </Typography>
            <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
              Chatta med de mest populära lärarna bland våra användare.
            </Box>
            <Grid container spacing={2}>
      {filteredTeachers.filter(teacher => featuredTeacherNewIds.includes(teacher.id)).map(teacher => (
                <Grid item xs={12} sm={6} md={4} key={teacher.id}>

          <Link 
            to={`/chat/${teacher.id}`} 
            onClick={(e) => handleUseTeacher(teacher, e)}
            style={{ textDecoration: 'none'}}
          >
                  <ImageBox

                    sx={{
                      backgroundImage: `url(${teacher.imageUrlAI})`,
                    }}
                  >
                    <ImageOverlay variant="body2">
                      {teacher.characterName}
                    </ImageOverlay>
                  </ImageBox>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}


        {/* Featured Teachers Section */}
{featuredTeachers.length > 0 && searchTerm === '' && (
          <Box   ref={traditionalTeachersRef} key="utvalda" sx={{ marginBottom: 4 }}>
            <Typography variant="h3" sx={{...styles.h3, mt:8}}>
              Traditionella lärare
            </Typography>
            <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
Lärare du känner igen från klassrummet.
</Box>
            <Grid container spacing={2}>
              {featuredTeachers.map(teacher => (





             <Grid item xs={12} sm={6} md={6} key={teacher.id}>



          <Link 
            to={`/chat/${teacher.id}`} 
            onClick={(e) => handleUseTeacher(teacher, e)}
            style={{ textDecoration: 'none'}}
          >



                  <Card sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    border: 'none',
background: theme.palette.mode === 'dark' ? 'linear-gradient(10deg, #192734 0%, #35414d 100%)' : 'linear-gradient(135deg, #8b5cf6 0%, #6942c1 100%)',
                    color: 'white',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0 6px 25px rgba(0,0,0,0.2)',
                    },
                  }}>
                    <CardContent  sx={{position:'relative'}}>



                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<Box sx={{ display: 'flex', alignItems: 'center' }}>
  <Avatar
    src={teacher.imageUrl}
    alt={teacher.characterName}
    sx={{ 
      width: 70, 
      height: 70, 
      marginRight: 2, 
      borderRadius: '50%',
      border: '2px solid white',
      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    }}
  />
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize:'20px' }}>
      {teacher.characterName}
    </Typography>


{getTeacherType(teacher.characterName) ? (
<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', marginTop:'3px' }}>
  <Chip
    label={getTeacherType(teacher.characterName)}
    size="small"
    sx={{
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(0, 0, 0, 0.37)',
      color: theme.palette.mode === 'dark' ? '#fff' : '#fff',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '0.7rem',
      width: 'fit-content',
      maxWidth: '100%',
      '& .MuiChip-label': {
        padding: '0 8px',
        textAlign: 'left',
        display: 'block',
        width: '100%',
      },
    }}
  />
</Box>
) : (
  <Button
    startIcon={<ExpandMoreIcon />}
    onClick={(e) => handleExpandClick(e, teacher.id)}
    sx={{
      color: 'white',
      textTransform: 'none',
      marginLeft: '0px',
      marginTop: '2px',
      padding: '0px',
      paddingLeft: '0px',
      paddingRight: '6px',
      borderRadius: '100px',
      alignSelf: 'flex-start',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
      '& .MuiButton-startIcon': {
        marginRight: '4px',
      },
    }}
  >
    Läs mer
  </Button>
)}
  </Box>
</Box>
<IconButton
  sx={{ color: 'white', marginLeft: 'auto' }}
>
  <ChevronRightIcon />
</IconButton>
</Box>

                      <Collapse in={expandedCards[teacher.id]} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 2 }}>
{teacher.tagline && (
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1, color: 'rgba(255,255,255,0.9)' }}>
    {teacher.tagline}
  </Typography>
)}

                          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                            {teacher.description
                              ? teacher.description.length > 150 
                                ? teacher.description.substring(0, 150) + '...' 
                                : teacher.description
                              : 'Ingen beskrivning tillgänglig.'}
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                            <Button 
                              variant="contained" 
                              color="primary" 
                              onClick={(e) => handleUseTeacher(teacher, e)}
                              disabled={isTeacherInUserCollection(teacher.id)}
                              sx={{
                                textTransform: 'none',
                                backgroundColor: 'rgba(255,255,255,0.2)',
                                color: 'white',
                                padding: '10px 20px',
                                fontWeight: 'bold',
                                '&:hover': {
                                  backgroundColor: 'rgba(255,255,255,0.3)',
                                },
                                '&:disabled': {
                                  backgroundColor: 'rgba(255,255,255,0.1)',
                                  color: 'rgba(255,255,255,0.5)',
                                }
                              }}
                            >
                              {isTeacherInUserCollection(teacher.id) ? 'Din lärare' : 'Börja chatta'}
                            </Button>
                          </Box>
                        </Box>
                      </Collapse>


                    </CardContent>
                  </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}


          {categories.map(category => {
            const categoryTeachers = filteredTeachers.filter(teacher => 
              (teacher.category || 'Övrigt') === category && !featuredTeacherIds.includes(teacher.id)
            );
            if (categoryTeachers.length === 0) return null;
          return (
            <Box key={category} ref={el => categoryRefs.current[category] = el}>
             <Typography variant="h3" sx={{...styles.h3, mt:8}}>

                {category}
              </Typography>

<Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
{getCategoryDescription(category)}
</Box>

              <Grid container spacing={2}>
                {categoryTeachers.slice(0, visibleTeachers[category]).map(teacher => (
               <Grid item xs={12} sm={6} md={6} key={teacher.id}>



          <Link 
            to={`/chat/${teacher.id}`} 
            onClick={(e) => handleUseTeacher(teacher, e)}
            style={{ textDecoration: 'none'}}
          >

                  <Card sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    border: 'none',
                    background: theme.palette.mode === 'dark' ? 'linear-gradient(10deg, #192734 0%, #35414d 100%)' : 'linear-gradient(135deg, #8b5cf6 0%, #6942c1 100%)',
                    color: 'white',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0 6px 25px rgba(0,0,0,0.2)',
                    },
                  }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<Box sx={{ display: 'flex', alignItems: 'center' }}>
  <Avatar
    src={teacher.imageUrl}
    alt={teacher.characterName}
    sx={{ 
      width: 70, 
      height: 70, 
      marginRight: 2, 
      borderRadius: '50%',
      border: '2px solid white',
      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    }}
  />
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize:'20px' }}>
      {teacher.characterName}
    </Typography>
    <Button
      startIcon={<ExpandMoreIcon />}
      onClick={(e) => handleExpandClick(e, teacher.id)}
      sx={{
        color: 'white',
        textTransform: 'none',
        marginLeft:'0px',
        marginTop:'2px',
        padding:'0px',
        paddingLeft:'0px',
        paddingRight:'6px',
        borderRadius:'100px',

        alignSelf: 'flex-start',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)',
        },
                                '& .MuiButton-startIcon': {
                                  marginRight: '4px', // Adjust this value to reduce space between icon and text
                                },
      }}
    >
      Läs mer
    </Button>
  </Box>
</Box>
<IconButton
  sx={{ color: 'white', marginLeft: 'auto' }}
>
  <ChevronRightIcon />
</IconButton>
</Box>

                      <Collapse in={expandedCards[teacher.id]} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 2 }}>
  {teacher.tagline && (
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1, color: 'rgba(255,255,255,0.9)' }}>
    {teacher.tagline}
  </Typography>
)}
                          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                            {teacher.description
                              ? teacher.description.length > 150 
                                ? teacher.description.substring(0, 150) + '...' 
                                : teacher.description
                              : 'Ingen beskrivning tillgänglig.'}
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                            <Button 
                              variant="contained" 
                              color="primary" 
                              onClick={(e) => handleUseTeacher(teacher, e)}
                              disabled={isTeacherInUserCollection(teacher.id)}
                              sx={{
                                textTransform: 'none',
                                backgroundColor: 'rgba(255,255,255,0.2)',
                                color: 'white',
                                padding: '10px 20px',
                                fontWeight: 'bold',
                                '&:hover': {
                                  backgroundColor: 'rgba(255,255,255,0.3)',
                                },
                                '&:disabled': {
                                  backgroundColor: 'rgba(255,255,255,0.1)',
                                  color: 'rgba(255,255,255,0.5)',
                                }
                              }}
                            >
                              {isTeacherInUserCollection(teacher.id) ? 'Din lärare' : 'Börja chatta'}
                            </Button>
                          </Box>
                        </Box>
                      </Collapse>


                    </CardContent>
                  </Card>
                  </Link>
                </Grid>
                ))}

              </Grid>
              {categoryTeachers.length > visibleTeachers[category] && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleLoadMore(category)}
                    disabled={loading}
                    sx={{...styles.beautyBtn, width:'100%', borderRadius:'5px', mb:2}}
                    startIcon={loading ? <CircularProgress size={14} /> : <KeyboardArrowDownIcon />}
                  >
                    {loading ? 'Se fler' : 'Se fler'}
                  </Button>
                </Box>
              )}
            </Box>
          );
        })}
          </>
)}

   <Box 
      sx={{...styles.bottomCta}}
    >
          <SchoolIcon sx={{ fontSize: {xs:40, md:60}, color: theme.palette.primary.main, marginBottom: 2 }} />
          <Typography variant="h4" sx={{...styles.bottomCtaTitle}}>
            Hittar du inte din drömlärare? Skapa din egen AI-bot!
          </Typography>
          <Typography variant="body1" sx={{...styles.bottomCtaBody}}>
            Utforma en unik AI-lärare som passar just dina behov. Anpassa kunskapsområden, personlighet och undervisningsstil för en skräddarsydd läroupplevelse.
          </Typography>
          <Button 
            component={Link}
            to="/larare"
           variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
            color="primary"
            size="large"
            startIcon={<AddIcon />}
            sx={{ 
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '10px 20px',
              fontSize: '1.1rem',
              marginTop:'20px'
            }}
          >
            Skapa din egen lärare
          </Button>
        </Box>


      </Box>
    </Box>
  );
};

export default PublicTeachers;