// Prompts.js
import React from 'react';
import { Button, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import Typography from '@mui/material/Typography';
const Prompts = ({ anchorEl, setAnchorEl, handleMenuItemClick, onAddPrompt, userPrompts }) => {
    const predefinedPrompts = ['Förkorta svaret', 'Gör svaret längre', 'Berätta mer', 'Brainstorma lite', 'Ge exempel'];
       const theme = useTheme();
       const styles = getStyles(theme);
    const isDark = theme.palette.mode === 'dark';
    const handlePromptClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handlePromptClose = () => {
        setAnchorEl(null);
    };

    return (
<>
            <Button
                variant="outlined"
                onClick={handlePromptClick}
                startIcon={<LayersIcon />}
                endIcon={<ArrowDropDownIcon />}
                sx={{...styles.promptBtn}}
            >
                Prompts
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handlePromptClose}
                PaperProps={{
                sx: {
                  ...styles.roundMenu,
                  borderRadius: '8px',
                  '& ul': {
                    border: theme.palette.mode === 'dark' ? '1px solid #35414db2' : '1px solid #eee',
                    padding: '0px',
                    paddingTop:'10px',
                    paddingBottom:'10px',
                    borderRadius: '8px' 
                  }
                }
              }}
            >
                {predefinedPrompts.map((prompt, index) => (
                    <MenuItem
                        key={`predefined-${index}`}

                        onClick={() => handleMenuItemClick(prompt)}
                        sx={styles.menuItemStyle}
                    >
                        {prompt}
                    </MenuItem>
                ))}
                
                {userPrompts && userPrompts.length > 0 && (
                    <>
                        <Divider />
                        <Typography variant="subtitle2" sx={{ padding: '8px 16px', color: isDark ? '#b0b0b0' : '#707070' }}>
                            Dina prompts
                        </Typography>
                        {userPrompts.map((prompt) => (
                            <MenuItem
                                key={`user-${prompt.id}`}
                                onClick={() => handleMenuItemClick(prompt.text)}
                                 sx={styles.menuItemStyle}
  
                            >
                                {prompt.text}
                            </MenuItem>
                        ))}
                    </>
                )}
                
                <Divider />
                <MenuItem onClick={onAddPrompt}   sx={styles.menuItemStyle}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    Lägg till prompt
                </MenuItem>
            </Menu>
</>
    );
};

export default Prompts;