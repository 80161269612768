import React, { useState, useEffect, useRef } from 'react';
import { 
  Grid, Card, Typography, Button, TextField, Box, InputAdornment, CardContent,
  CircularProgress, Select, MenuItem, FormControl, InputLabel, Tooltip, Collapse, IconButton, Checkbox, Alert, ListItemText, Fade, CardHeader, Chip, CardActions,
  Table, TableBody, TableRow, TableCell, List, ListItem, Avatar
} from '@mui/material';
import { collection, getDocs, setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import LoadingSpinner from './LoadingSpinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import { formatDistanceToNow, format } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import { sv } from 'date-fns/locale';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useMediaQuery } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { getAIModelImage } from './TestComponents2';
import HPDrawerComponent from './hp/HPDrawerComponent';
import SegmentIcon from '@mui/icons-material/Segment';
import Logger from './Logger';
import { School, Language, Today, Person, Speed } from '@mui/icons-material';

const PublicHP = ({ user }) => {
  const [publicHPs, setPublicHPs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingHPs, setIsLoadingHPs] = useState(true);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [modelFilter, setModelFilter] = useState('');
  const [questionTypeFilter, setQuestionTypeFilter] = useState([]);
  const [difficulties, setDifficulties] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [models, setModels] = useState([]);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userEmails, setUserEmails] = useState({});

  const [featuredHPs, setFeaturedHPs] = useState([]);
  const [highestRatedHPs, setHighestRatedHPs] = useState([]);
  const [recentlyGeneratedHPs, setRecentlyGeneratedHPs] = useState([]);

  const [filterOpen, setFilterOpen] = useState(false);
const [drawerOpen, setDrawerOpen] = useState(false);
  const [visibleFeaturedHPs, setVisibleFeaturedHPs] = useState(4);
  const [visibleHighestRatedHPs, setVisibleHighestRatedHPs] = useState(6);
  const [visibleRecentlyGeneratedHPs, setVisibleRecentlyGeneratedHPs] = useState(4);
  const [visibleAllHPs, setVisibleAllHPs] = useState(4);
  const [officialHPs, setOfficialHPs] = useState([]);
  const [visibleOfficialHPs, setVisibleOfficialHPs] = useState(6);
const [userInfo, setUserInfo] = useState({});
  const featuredHPIds = ['i93TdIOp1oFVSv4MzMMp'];

  const featuredRef = useRef(null);
  const highestRatedRef = useRef(null);
  const recentlyGeneratedRef = useRef(null);
  const allTestsRef = useRef(null);
  const officialRef = useRef(null);



  useEffect(() => {
    const fetchPublicHPs = async () => {
      setIsLoadingHPs(true);
      try {
        const hpsRef = collection(db, 'hpPublic');
        const hpsSnapshot = await getDocs(hpsRef);
        const hpsList = hpsSnapshot.docs.map(doc => {
          const data = doc.data();
          Logger.log('Document data:', data);
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt ? (typeof data.createdAt === 'number' ? data.createdAt : data.createdAt.toMillis()) : 0
          };
        });

        Logger.log('Processed hpsList:', hpsList);

        setFeaturedHPs(hpsList.filter(hp => featuredHPIds.includes(hp.id)));
setHighestRatedHPs(
  hpsList
    .filter(hp => (hp.upvotes || 0) >= 5)  // Filtrera för att endast inkludera objekt med minst 5 upvotes
    .sort((a, b) => ((b.upvotes || 0) - (a.upvotes || 0)))  // Sortera i fallande ordning baserat på upvotes
    .slice(0, 10)  // Begränsa resultatet till de 10 högst rankade
);
        setRecentlyGeneratedHPs(hpsList.filter(hp => !hp.official).sort((a, b) => ((b.createdAt || 0) - (a.createdAt || 0))).slice(0, 10));
        setPublicHPs(hpsList.filter(hp => !hp.official));
        setOfficialHPs(hpsList.filter(hp => hp.official === true));

        const difficultySet = new Set(hpsList.map(hp => hp.difficulty).filter(Boolean));
        const languageSet = new Set(hpsList.map(hp => hp.selectedLanguage).filter(Boolean));
        const modelSet = new Set(hpsList.map(hp => hp.selectedModel).filter(Boolean));

        setDifficulties(Array.from(difficultySet));
        setLanguages(Array.from(languageSet));
        setModels(Array.from(modelSet));

    const userIds = [...new Set(hpsList.map(hp => hp.originallyCreatedByUserId).filter(Boolean))];
      const userInfoObj = {};
      for (const userId of userIds) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          userInfoObj[userId] = {
            displayName: userData.displayName || userData.email || 'Okänd',
            avatarUrl: userData.photoURL || null
          };
        }
      }
      setUserInfo(userInfoObj);

      } catch (error) {
        console.error('Error fetching public HPs:', error);
        showSnackbar('Error fetching public HPs. Please try again.', 'error');
      } finally {
        setIsLoadingHPs(false);
      }
    };

    fetchPublicHPs();
  }, []);

const getUniqueQuestionTypes = (hps) => {
  const types = new Set();
  hps.forEach(hp => {
    if (hp.data && hp.data.questions) {
      hp.data.questions.forEach(q => types.add(q.questionType));
    }
  });
  return Array.from(types);
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    const distance = formatDistanceToNow(date, { addSuffix: true, locale: sv });
    // Custom shortening logic
    return distance
      .replace('ungefär ', '')
      .replace(' sekunder', ' sek')
      .replace(' minuter', ' min')
      .replace(' timmar', ' tim')
      .replace(' timme', ' timme')
      .replace('mindre än en minut', '< 1 min');
  } else if (diffInDays === 1) {
    return 'Igår';
  } else if (diffInDays < 7) {
    return `${diffInDays} dagar sedan`;
  } else {
    return format(date, 'd MMM yyyy', { locale: sv });
  }
};

  const handleUseHP = async (hp) => {
    try {
      if (!user) {
        showSnackbar("Du måste vara inloggad för att använda ett HP-test.", 'error');
        return;
      }

      const hpCopy = {
        ...hp,
        uid: user.uid,
        createdAt: new Date(),
        isPublicCopy: true,
        official: hp.official
      };

      await setDoc(doc(db, "hpTestData", hp.id), hpCopy);
      
      showSnackbar(`Högskoleprovet har kopierats till dina tester.`, 'success');
    } catch (error) {
      console.error("Fel vid kopiering av HP-test: ", error);
      showSnackbar("Det gick inte att kopiera HP-testet. Försök igen.", 'error');
    }
  };

  const handleLoadMore = (section) => {
    setLoading(true);
    setTimeout(() => {
      switch(section) {
        case 'featured':
          setVisibleFeaturedHPs(prev => prev + 4);
          break;
        case 'highestRated':
          setVisibleHighestRatedHPs(prev => prev + 4);
          break;
        case 'recentlyGenerated':
          setVisibleRecentlyGeneratedHPs(prev => prev + 4);
          break;
        case 'official':
          setVisibleOfficialHPs(prev => prev + 6);
          break;
        case 'all':
          setVisibleAllHPs(prev => prev + 4);
          break;
      }
      setLoading(false);
    }, 500);
  };

  const handleCreateNewHP = () => {
    navigate(`/hp/`);
  };

  const renderThemes = (themes) => {
    if (!themes) return 'Inga teman';
    return Object.entries(themes)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  };

const scrollToSection = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
};

  const getDifficultyLabel = (difficulty) => {
    switch (difficulty) {
      case 'easy':
        return 'Enkel';
      case 'medium':
        return 'Medel';
      case 'hard':
        return 'Svår';
      default:
        return difficulty;
    }
  };

  const getSectionDescription = (section) => {
    switch(section) {
      case 'Featured HP-tester':
        return 'Utvalda högskoleprov som vi rekommenderar för sin kvalitet och variation.';
      case 'Högst betyg':
        return 'De mest uppskattade högskoleproven baserat på användarnas omdömen.';
      case 'Senast genererade':
        return 'De senaste högskoleproven som har skapats av vår community.';
      case 'Alla HP-tester':
        return 'Utforska hela vår samling av AI-genererade högskoleprov med olika svårighetsgrader och teman.';
      default:
        return 'Upptäck spännande HP-tester anpassade för olika behov och preferenser.';
    }
  };

const clearFilters = () => {
  setDifficultyFilter('');
  setLanguageFilter('');
  setModelFilter('');
  setQuestionTypeFilter([]);
};



  const filterHPs = (hps) => hps.filter(hp => 
    hp?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (difficultyFilter === '' || hp.difficulty === difficultyFilter) &&
    (languageFilter === '' || hp.selectedLanguage === languageFilter) &&
    (modelFilter === '' || hp.selectedModel === modelFilter) &&
    (questionTypeFilter.length === 0 || (hp.data && hp.data.questions && hp.data.questions.some(q => questionTypeFilter.includes(q.questionType))))
  );


  const getDifficultyColor = (difficulty, theme) => {
    switch (difficulty) {
      case 'easy':
        return {
          bg: theme.palette.mode === 'dark' ? '#1a5928' : '#e6f4e8',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#1a5928'
        };
      case 'medium':
        return {
          bg: theme.palette.mode === 'dark' ? '#7d5f0a' : '#fff4d9',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#7d5f0a'
        };
      case 'hard':

        return {
          bg: theme.palette.mode === 'dark' ? '#8b2626' : '#ffe8e8',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#8b2626'
        };
      default:
        return {
          bg: 'transparent',
          text: theme.palette.text.primary
        };
    }
  };


const SplitNameDisplay = ({ name }) => {
  const [firstPart, secondPart] = name.split('—').map(part => part.trim());

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100px', display: 'flex' }}>
      <Box
        sx={{
          width: '50%',
          height: '100%',
          background: '#995945',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding:'5px'
        }}
      >
        <Typography variant="h6" sx={{ color: 'white', fontSize: '17px', textAlign:'center' }}>
          {firstPart}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '50%',
          height: '100%',
          background: '#7a9e7e',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'white', fontSize: '17px', textAlign:'center' }}>
          {secondPart}
        </Typography>
      </Box>
      <Box
        component="svg"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
d="M50 0 C53 25, 47 75, 50 100"
                    fill="none"
          stroke="white"
          strokeWidth="1.6"
        />
      </Box>
    </Box>
  );
};

const renderOfficialHPCard = (hp) => (
    <Grid item xs={12} sm={6} md={4} key={hp.id}>
      <Card 
        sx={{ 
          backgroundColor: theme.palette.mode === 'dark' ? '#192734' : '#fff',
          border: theme.palette.mode === 'dark' ? '1px solid #405264' : '1px solid #b0bec569',
          borderBottom: theme.palette.mode === 'dark' ? '7px solid #405264' : '7px solid #dee4e7',
          borderRadius: '10px',
          boxShadow: 'none',
          padding: '0px',
          position: 'relative',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >


      <Box
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            backgroundColor: theme.palette.mode === 'dark' ? '#405264' : '#e0e0e0',
            color: theme.palette.mode === 'dark' ? 'white' : '#333',
            padding: '2px 8px',
            borderRadius: '7px',
            fontSize: '0.7rem',
            fontWeight: 'bold',
            zIndex: 1,
          }}
        >
          Officiellt
        </Box>


        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative', padding:'0px' }}>
          <Box>

<SplitNameDisplay name={hp.name} />


            <Box sx={{padding:'15px', paddingBottom:'0px'}}>
              <Box sx={{ margin:0, padding:0 }}>

<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  {/* Vänster sida: Frågetype-boxar */}
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      flex: 1, // Tar upp tillgängligt utrymme
    }}
  >
    {hp.data && hp.data.questions 
      ? [...new Set(hp.data.questions.map(q => q.questionType))].map((type, index) => (
          <Typography 
            key={index} 
            variant="body2" 
            sx={{ 
              fontWeight: '500', 
              bgcolor: theme.palette.mode === 'dark' ? '#495e73' : '#e0e0e0',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              padding: '3px 10px',
              margin: '0 6px 5px 0', // Ändrad för att ge lite utrymme mellan items
              borderRadius: '30px',
            }}
          >
            {type}
          </Typography>
        ))
      : 'Inga frågetyper tillgängliga'}
  </Box>
  
  {/* Höger sida: Tumme upp och antal röster */}
  <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
    <Tooltip title="Du kan rösta när du använder ett quiz.">
      <ThumbUpOutlinedIcon sx={{ fontSize: 18, mr: 0.5, color: '#7caa81' }} />
    </Tooltip>
    <Typography variant="body2" sx={{color: '#7caa81', fontWeight:'bold'}}>{hp.upvotes || 0}</Typography>
  </Box>
</Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt:2 }}>
<Link
  to={`/hp/${hp.id}`}
  style={{ 
    textDecoration: 'none', 
    width: '100%',
    pointerEvents: hp.originallyCreatedByUserId === user?.uid ? 'none' : 'auto'
  }}
  onClick={async (e) => {
    if (hp.originallyCreatedByUserId === user?.uid) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    try {
      await handleUseHP(hp);
      navigate(`/hp/${hp.id}`);
    } catch (error) {
      console.error("Error in handleUseHP:", error);
    }
  }}
>
  <Button 
    variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
    color="primary" 
    disabled={hp.originallyCreatedByUserId === user?.uid}
    sx={{
      width:'100%',
      textTransform: 'none',
      fontWeight:'bold'
    }}
  >
    Öva på högskoleprovet
  </Button>
</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
);




const renderHPCard = (hp) => {
  const modelImage = getAIModelImage(hp.selectedModel);
  const difficultyColor = getDifficultyColor(hp.difficulty, theme);


  return (
    <Grid item xs={12} sm={6} md={4} key={hp.id}>
      <Card 
        sx={{ 
          backgroundColor: theme.palette.mode === 'dark' ? '#1a2635' : '#fff',
          borderRadius: '20px',
          boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
          overflow: 'hidden',
          position: 'relative',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100px',
            background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%)',
          }}
        />
        <CardContent sx={{ position: 'relative', zIndex: 1, pt: 3 }}>
          <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', mb: 2, textAlign:'center' }}>
            {hp.name} {/* {hp.id} */}
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3, mt:5 }}>
            {hp.data && hp.data.questions 
              ? [...new Set(hp.data.questions.map(q => q.questionType))].map((type, index) => (
                  <Chip 
                    key={index} 
                    label={type}
                    size="small"
                    sx={{ 
                      borderRadius: '20px',
                      fontWeight:'600'
                    }}
                  />
                ))
              : <Typography variant="body2" sx={{ color: 'white' }}>Inga frågetyper tillgängliga</Typography>}
          </Box>

          <Box sx={{ 
            bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)', 
            borderRadius: '15px', 
            p: 2,
            mb: 2
          }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a0aec0' : '#4a5568', marginBottom:'3px', fontSize:'13px' }}>Svårighetsgrad</Typography>
                <Chip
                label={getDifficultyLabel(hp.difficulty)}
                size="small"
                sx={{
                  bgcolor: difficultyColor.bg,
                  color: difficultyColor.text,
                  fontWeight: 'bold',
                }}
              />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a0aec0' : '#4a5568', marginBottom:'3px', fontSize:'13px' }}>Språk</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize:'13px' }}>{hp.selectedLanguage}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a0aec0' : '#4a5568', marginBottom:'3px', fontSize:'13px' }}>AI-modell</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {modelImage && (
                    <Avatar src={modelImage} alt={hp.selectedModel} sx={{ width: 20, height: 20, mr: 1 }} />
                  )}
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize:'13px' }}>{hp.selectedModel}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a0aec0' : '#4a5568', marginBottom:'3px', fontSize:'13px' }}>Genererad</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize:'13px' }}>{formatDate(hp.createdAt)}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
             <Avatar 
                src={userInfo[hp.originallyCreatedByUserId]?.avatarUrl || null}
                sx={{ width: 24, height: 24, mr: 1, bgcolor: theme.palette.primary.main }}
              >
                {(userInfo[hp.originallyCreatedByUserId]?.displayName || 'O')[0].toUpperCase()}
              </Avatar>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                {userInfo[hp.originallyCreatedByUserId]?.displayName || 'Okänd'}
              </Typography>
            </Box>
            <Tooltip title="Du kan rösta när du övar på ett högskoleprov">
              <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(65, 191, 19, 0.1)', borderRadius: '20px', px: 1, py: 0.5 }}>
                <ThumbUpOutlinedIcon sx={{ fontSize: 16, mr: 0.5, color: '#41bf13' }} />
                <Typography variant="body2" sx={{ color: '#41bf13', fontWeight: 'bold' }}>{hp.upvotes || 0}</Typography>
              </Box>
            </Tooltip>
          </Box>

          <Link
            to={`/hp/${hp.id}`}
            style={{ 
              textDecoration: 'none', 
              pointerEvents: hp.originallyCreatedByUserId === user?.uid ? 'none' : 'auto'
            }}
            onClick={async (e) => {
              if (hp.originallyCreatedByUserId === user?.uid) {
                e.preventDefault();
                return;
              }
              e.preventDefault();
              try {
                await handleUseHP(hp);
                navigate(`/hp/${hp.id}`);
              } catch (error) {
                console.error("Error in handleUseHP:", error);
              }
            }}
          >
            <Button 
              variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
              color="secondary" 
              fullWidth
              disabled={hp.originallyCreatedByUserId === user?.uid}
                sx={{ 
                  borderRadius: '25px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
            >
              Öva på högskoleprovet
            </Button>
          </Link>
        </CardContent>

      </Card>
    </Grid>
  );
};









  if (isLoadingHPs) {
 return (
  <LoadingSpinner />
  );
  }



  return (

  <Fade in={!isLoadingHPs}>
    <Box sx={styles.container}>
      <Box sx={{...styles.innerContainer, width:{xs: '100%', md: '100%'}, margin:'0 auto'}}>

<Box sx={{ width: { xs: '100%', md: '80%', margin:'0 auto'}}}>

        <Box sx={{textAlign: {xs: 'left', md: 'center'}}}>
          <Typography variant="h1" sx={{...styles.header, mt:5}}>
            Alla högskoleprov
          </Typography>

<Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>
            


{isMobile ? (
  <Button variant="contained"  color="primary"  disableRipple  onClick={handleCreateNewHP} startIcon={<AddIcon />} sx={styles.shrinkButton}/> 
) : (
<Button variant="contained" color="primary"  onClick={handleCreateNewHP} startIcon={<AddIcon />}>Nytt högskoleprov</Button>
)}


<Button
                    variant="contained" 
                    color="primary" 
                    disableRipple
                    onClick={() => setDrawerOpen(true)}
                    startIcon={<SegmentIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                />


          </Box>


<HPDrawerComponent 
  user={user}
  theme={theme}
  open={drawerOpen}
  onClose={() => setDrawerOpen(false)}
/>

          <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:3}}>
            Upptäck och skapa anpassade högskoleprov genererade av olika AI-modeller med varierande svårighetsgrad, tema och språk. Eller varför inte träna på ett gammalt högskoleprov?

          </Box>

  <Typography
    variant="h5"
    sx={{...styles.h5,
      display: 'inline-flex',
      alignItems: 'center',
mt:1
    }}
  >
    <InfoIcon sx={{ mr: 1, fontSize:'17px' }} />
<i>Jag jobbar på att få bättre resultat från AI-modellen. Ge mig gärna feedback i länken till vänster.</i>
  </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2}}>
            <TextField
              placeholder="Sök HP-test"
              value={searchTerm}
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{...styles.whiteBg}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{fontSize:'20px'}} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 3, flexWrap: 'wrap' }}>
            {/*<Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(featuredRef)}>Utvalda</Button>*/}
            <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(highestRatedRef)}>Högst betyg</Button>
            <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(recentlyGeneratedRef)}>Senast genererade</Button>
<Button 
  sx={{
    ...styles.beautyBtn, 
    ...styles.catBtn,
    position: 'relative',
    borderRadius:'30px', fontSize:'14px'
  }} 
  variant="outlined" 
  onClick={() => scrollToSection(officialRef)}
>
  Gamla högskoleprov
  <NewReleasesIcon 
    sx={{
      position: 'absolute',
      top: -3,
      right: -3,
      fontSize: '1rem',
      color: theme.palette.secondary.main,
    }}
  />
</Button>         
            <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(allTestsRef)}>Alla test</Button>
          </Box>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          mb: 2, 
          textAlign: 'center' 
        }}>
          <Button variant="outlined" sx={{...styles.beautyBtnTres}} startIcon={<FilterListIcon />} onClick={() => setFilterOpen(!filterOpen)}>
            Filtrera
          </Button>
        </Box>
        <Collapse in={filterOpen}>

          <Box sx={{...styles.smallBox, width:'100%', marginTop:'0px', borderRadius:'30px', paddingTop:'20px', paddingBottom:'20px', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center', mb: 3 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Svårighetsgrad</InputLabel>
              <Select
                value={difficultyFilter}
                onChange={(e) => setDifficultyFilter(e.target.value)}
                label="Svårighetsgrad"
              >
                <MenuItem value="">Alla</MenuItem>
                {difficulties.map(difficulty => (
                  <MenuItem key={difficulty} value={difficulty}>
                    {getDifficultyLabel(difficulty)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Språk</InputLabel>
              <Select
                value={languageFilter}
                onChange={(e) => setLanguageFilter(e.target.value)}
                label="Språk"
              >
                <MenuItem value="">Alla</MenuItem>
                {languages.map(language => (
                  <MenuItem key={language} value={language}>{language}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>AI-modell</InputLabel>
              <Select
                value={modelFilter}
                onChange={(e) => setModelFilter(e.target.value)}
                label="AI-modell"
              >
                <MenuItem value="">Alla</MenuItem>
                {models.map(model => (
                  <MenuItem key={model} value={model}>{model}</MenuItem>
                ))}
              </Select>
            </FormControl>

<FormControl sx={{ minWidth: 200 }}>
  <InputLabel>Frågetyper</InputLabel>
  <Select
    multiple
    value={questionTypeFilter}
    onChange={(e) => setQuestionTypeFilter(e.target.value)}
    renderValue={(selected) => selected.join(', ')}
    label="Frågetyper"
  >
    {getUniqueQuestionTypes(publicHPs).map(type => (
      <MenuItem key={type} value={type}>
        <Checkbox checked={questionTypeFilter.indexOf(type) > -1} />
        <ListItemText primary={type} />
      </MenuItem>
    ))}
  </Select>
</FormControl>

<Button 
      variant="outlined" 
      onClick={clearFilters}
      sx={{...styles.beautyBtnTres}}
    >
      Rensa filter
    </Button>

          </Box>
        </Collapse>
</Box>
        {isLoadingHPs ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <LoadingSpinner />
          </Box>
        ) : (
          <Box>
            {/* Featured Section 
            <Box ref={featuredRef}>
              <Typography variant="h3" sx={{...styles.h3, mt:8, mb: 2}}>
                Utvalda högskoleprov
              </Typography>
              <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
                {getSectionDescription('Featured HP-tester')}
              </Box>
              <Grid container spacing={2}>
                {filterHPs(featuredHPs).slice(0, visibleFeaturedHPs).map(hp => renderHPCard(hp))}
              </Grid>
              {filterHPs(featuredHPs).length > visibleFeaturedHPs && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleLoadMore('featured')}
                    disabled={loading}
                    sx={{...styles.beautyBtn, width:'100%', borderRadius:'5px', mb:2}}
                    startIcon={loading ? <CircularProgress size={14} /> : <KeyboardArrowDownIcon />}
                  >
                    {loading ? 'Laddar fler' : 'Se fler'}
                  </Button>
                </Box>
              )}
            </Box>
*/}



            {/* Highest Rated Section */}
      <Box ref={highestRatedRef}>
        <Typography variant="h3" sx={{...styles.h3, mt:4}}>
          Högst betyg
        </Typography>
        <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
          {getSectionDescription('Högst betyg')}
        </Box>
        <Grid container spacing={2}>
          {filterHPs(highestRatedHPs).slice(0, visibleHighestRatedHPs).map(hp => 
            hp.official ? renderOfficialHPCard(hp) : renderHPCard(hp)
          )}
        </Grid>
        {filterHPs(highestRatedHPs).length > visibleHighestRatedHPs && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              variant="outlined"
              onClick={() => handleLoadMore('highestRated')}
              disabled={loading}
              sx={{...styles.beautyBtn, width:'100%', borderRadius:'5px', mb:2}}
              startIcon={loading ? <CircularProgress size={14} /> : <KeyboardArrowDownIcon />}
            >
              {loading ? 'Laddar fler' : 'Se fler'}
            </Button>
          </Box>
        )}
      </Box>

            {/* Recently Generated Section */}
            <Box ref={recentlyGeneratedRef}>
              <Typography variant="h3" sx={{...styles.h3, mt:8}}>
                Senast genererade
              </Typography>
              <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
                {getSectionDescription('Senast genererade')}
              </Box>
              <Grid container spacing={2}>
                {filterHPs(recentlyGeneratedHPs).slice(0, visibleRecentlyGeneratedHPs).map(hp => renderHPCard(hp))}
              </Grid>
              {filterHPs(recentlyGeneratedHPs).length > visibleRecentlyGeneratedHPs && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleLoadMore('recentlyGenerated')}
                    disabled={loading}
                    sx={{...styles.beautyBtn, width:'100%', borderRadius:'5px', mb:2}}
                    startIcon={loading ? <CircularProgress size={14} /> : <KeyboardArrowDownIcon />}
                  >
                    {loading ? 'Laddar fler' : 'Se fler'}
                  </Button>
                </Box>
              )}
            </Box>


{/* Official Section */}
<Box ref={officialRef}>
  <Typography variant="h3" sx={{...styles.h3, mt:8, mb: 2}}>
    Gamla högskoleprov
  </Typography>
  <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
    Utforska de gamla högskoleproven, de kvantitativa delerna är på väg — så även prov från tidigare år! 
  </Box>
  <Grid container spacing={2}>
    {filterHPs(officialHPs).slice(0, visibleOfficialHPs).map(hp => renderOfficialHPCard(hp))}
  </Grid>
  {filterHPs(officialHPs).length > visibleOfficialHPs && (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
      <Button
        variant="outlined"
        onClick={() => handleLoadMore('official')}
        disabled={loading}
        sx={{...styles.beautyBtn, width:'100%', borderRadius:'5px', mb:2}}
        startIcon={loading ? <CircularProgress size={14} /> : <KeyboardArrowDownIcon />}
      >
        {loading ? 'Laddar fler' : 'Se fler'}
      </Button>
    </Box>
  )}
</Box>


            {/* All Tests Section */}
            <Box ref={allTestsRef}>
              <Typography variant="h3" sx={{...styles.h3, mt:8}}>
                Alla högskoleprov
              </Typography>
              <Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4'  : '#333', mb:4, mt:1}}>
                {getSectionDescription('Alla HP-tester')}
              </Box>
              <Grid container spacing={2}>
                {filterHPs(publicHPs).slice(0, visibleAllHPs).map(hp => renderHPCard(hp))}
              </Grid>
              {filterHPs(publicHPs).length > visibleAllHPs && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleLoadMore('all')}
                    disabled={loading}
                    sx={{...styles.beautyBtn, width:'100%', borderRadius:'5px', mb:2}}
                    startIcon={loading ? <CircularProgress size={14} /> : <KeyboardArrowDownIcon />}
                  >
                    {loading ? 'Laddar fler' : 'Se fler'}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}


    <Box 
      sx={{...styles.bottomCta}}
    >
      <SchoolIcon sx={{ fontSize: {xs:40, md:60}, color: theme.palette.primary.main, marginBottom: 2 }} />
      <Typography variant="h4" sx={{...styles.bottomCtaTitle}}>
        Skapa ditt eget högskoleprov med AI!
      </Typography>
      <Typography variant="body1" sx={{...styles.bottomCtaBody}}>
        Generera skräddarsydda högskoleprov med hjälp av AI. Anpassa svårighetsgrad, ämnesområden och frågetyper för ett unikt och utmanande prov.
      </Typography>
      <Button 
        component={Link}
        to="/hogskoleprovet"
        variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
        color="primary"
        size="large"
        startIcon={<AddIcon />}
        sx={{ 
          textTransform: 'none',
          fontWeight: 'bold',
          padding: '10px 20px',
          fontSize: '1.1rem',
          marginTop: '20px'
        }}
      >
        Skapa högskoleprov med AI
      </Button>
    </Box>

      </Box>
    </Box>
    </Fade>
  );
};

export default PublicHP;