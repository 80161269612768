import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ClipLoader } from 'react-spinners';
import { Mosaic } from 'react-loading-indicators';

const LoadingSpinner = ({ 
  size = 20, 
  color = "#123abc", 
  loading = true, 
  noWrapper = false,
  speedMultiplier = 1 // Add this prop
}) => {
  // const spinner = (
  //   <ClipLoader 
  //     size={size} 
  //     color={color} 
  //     loading={loading} 
  //     speedMultiplier={speedMultiplier} // Add this prop
  //   />
  // );

    const theme = useTheme();
  const spinnerColor = theme.palette.mode === 'dark' ? '#fff' : '#123abc';  
const spinner = (<ClipLoader size={20} color={spinnerColor} loading={true} />);

  // const spinner = (
  //   <Mosaic color={["#33CCCC", "#33CC36", "#B8CC33", "#1097DA"]} />
  // );


  if (noWrapper) {
    return spinner;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {spinner}
    </Box>
  );
};

export default LoadingSpinner;