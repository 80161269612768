import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Paper, Typography, Avatar, IconButton, Menu, MenuItem, CircularProgress, Tooltip, Popover } from '@mui/material';
import { motion } from 'framer-motion';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CheckIcon from '@mui/icons-material/Check';
import { db } from './firebase';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { debounce } from 'lodash';

import GenerateIcon from '@mui/icons-material/AutoAwesome';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import { callApi } from './api';

import DOMPurify from 'dompurify';
import Voice from './Voice';  // Importera den nya Voice-komponenten
import { useTheme } from '@mui/material/styles';

import { marked } from 'marked';

// Programmeringslärare

const parseMarkdown = (content) => {
  return marked.parse(content);
};


const MemoizedContent = React.memo(({ role, htmlContent, isStreaming, messageId, isDark }) => {

    console.log(`MemoizedContent rendering: messageId=${messageId}, isStreaming=${isStreaming}, content: ${htmlContent.substring(0, 20)}...`);
  const [displayContent, setDisplayContent] = useState('');
  const contentRef = useRef(null);
  const [showLoadingDot, setShowLoadingDot] = useState(false);

  const debouncedSetDisplayContent = useMemo(
    () => debounce(setDisplayContent, 100),
    []
  );

  useEffect(() => {
    debouncedSetDisplayContent(htmlContent);
    return () => debouncedSetDisplayContent.cancel();
  }, [htmlContent, debouncedSetDisplayContent]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [displayContent]);

  useEffect(() => {
    let timer;
    if (isStreaming && displayContent.length === 0) {
      timer = setTimeout(() => setShowLoadingDot(true), 200);
    } else {
      setShowLoadingDot(false);
    }
    return () => clearTimeout(timer);
  }, [isStreaming, displayContent]);

  const parseMarkdownWithCursor = useMemo(() => {
    return (content) => {

   if (role === 'user') {
        // For user messages, just return the content as plain text
        return content;
      }


      const cursorPlaceholder = '∎';
      let contentWithPlaceholder = content;
      
      if (isStreaming && content.length < htmlContent.length) {
        contentWithPlaceholder += cursorPlaceholder;
      }
      const parsedContent = marked.parse(contentWithPlaceholder);
      const sanitizedContent = DOMPurify.sanitize(parsedContent);
      return sanitizedContent.replace(
        cursorPlaceholder,
        ' <span class="pulsating-dot"></span>'
      );
    };
  }, [isStreaming, htmlContent]);

  const parsedContent = useMemo(() => parseMarkdownWithCursor(displayContent), [parseMarkdownWithCursor, displayContent]);

  return (
    <Paper 
      ref={contentRef}
      sx={{
        padding: 1,
        backgroundColor: role === 'user' 
          ? (isDark ? '#212c36' : '#8b5cf6')
          : (isDark ? '#15202b' : '#fff'),
        color: role === 'user' 
          ? (isDark ? '#fff' : '#ffffff')
          : (isDark ? '#fff' : '#111'),
        maxWidth: {xs: '100%', md: '60%'},
        borderRadius: '10px',
        textAlign: 'left',
        boxShadow: 'none',
        paddingLeft: '20px',
        paddingRight: '25px',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderTopLeftRadius: role === 'user' ? '10px' : '0px',
        borderTopRightRadius: role === 'user' ? '0px' : '10px',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        minHeight:'40px',
        fontSize: '15px',
        "& h3,h2,h1,h4,h5,h6": { marginBottom: '0px', marginTop: '30px', paddingBottom: '0px' },
        transition: 'background-color 0.3s ease-in-out',
      }}
    >

      {displayContent.length === 0 && isStreaming ? (
        <p><span className="pulsating-dot"></span></p>
      ) : role === 'user' ? (

        <p>{parsedContent}</p>
      ) : (

        <span dangerouslySetInnerHTML={{ __html: parsedContent }} />
      )}
    </Paper>
  );
});


const MessageBubble = ({ role, content, userId, conversationType, isSaved: initialIsSaved, noteId: initialNoteId, id, generateIdeas, isStreaming = false, isDark, avatar, isLanguageBot = false }) => {
  const [isSavedState, setIsSaved] = useState(initialIsSaved);
  const [noteId, setNoteId] = useState(initialNoteId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [translateAnchorEl, setTranslateAnchorEl] = useState(null);
  const [ideas, setIdeas] = useState([]);
  const [ideasLoading, setIdeasLoading] = useState(false);
  const [translateLoading, setTranslateLoading] = useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const [copied, setCopied] = useState(false);
  const theme = useTheme();



  useEffect(() => {
    setIsSaved(initialIsSaved);
    setNoteId(initialNoteId);
  }, [initialIsSaved, initialNoteId]);

  const handleTranslate = async (event) => {
    setTranslateLoading(true);
    setTranslateAnchorEl(event.currentTarget);
    const response = await callApi([{ role: 'system', content: "Översätt texten till svenska." }, { role: 'user', content: content }], 600);
    setTranslatedText(response);
    setTranslateLoading(false);
  };

  const handleGenerateIdeas = async (event) => {
    setIdeasLoading(true);
    setIdeas([]);
    setAnchorEl(event.currentTarget);
    const generatedIdeas = await generateIdeas(content);
    setIdeasLoading(false);
    setIdeas(generatedIdeas);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleIdeaClick = (idea) => {
    generateIdeas(idea, true);
    handleCloseMenu();
  };

  const copyToClipboard = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    navigator.clipboard.writeText(content).catch(err => console.error('Failed to copy: ', err));
  };

  const saveNote = async () => {
    try {
      const docRef = await addDoc(collection(db, "notes"), {
        userId: userId || 'anonymous',
        content: content || '',
        role: role,
        conversationType: conversationType || 'Allmänt',
        createdAt: new Date()
      });
      setIsSaved(true);
      setNoteId(docRef.id);
    } catch (error) {
      console.error('Error saving note: ', error);
    }
  };

  const deleteNote = async () => {
    try {
      if (!noteId) return;
      await deleteDoc(doc(db, "notes", noteId));
      setIsSaved(false);
      setNoteId(null);
    } catch (error) {
      console.error('Error deleting note: ', error);
    }
  };

  const handleSaveOrDelete = () => {
    if (isSavedState) {
      deleteNote();
    } else {
      saveNote();
    }
  };

  const handleClosePopover = () => {
    setTranslateAnchorEl(null);
  };

  //const htmlContent =  marked(content || '');
  const htmlContent =  content;




  return (
    <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }} key={id}>
      <Box sx={{ display: 'flex', flexDirection: role === 'user' ? 'row-reverse' : 'row', alignItems: 'flex-start', marginBottom: 1 }}>



<Avatar 
  src={avatar || (role === 'user' ? "/path-to-user-avatar.png" : "/ai.png")}
  alt={role === 'user' ? "User Avatar" : "AI Avatar"}
  sx={{
    margin: 1,
    display: {xs: role === 'user' ? 'none' : 'block', md:'inherit'},
    ...(avatar === "/ai.png" || (!avatar && role !== 'user') ? {
      width: '30px',
      height: '30px',

      borderRadius:'100px',


    } : {})
  }}
/>
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '60%', alignItems: role === 'user' ? 'flex-end' : 'flex-start' }}>
          
      <MemoizedContent role={role} htmlContent={htmlContent} isStreaming={isStreaming} messageId={id} isDark={isDark} />
{/*<span>messageId: {id}</span>*/}
          <Box sx={{ display: 'flex', justifyContent: role === 'user' ? 'flex-end' : 'flex-start', width: '100%', marginTop: 0.5, paddingLeft: '0px', paddingRight: '0px' }}>
            <Voice content={content} userId={userId} fontSize={20} />
            <IconButton onClick={handleSaveOrDelete} size="small">
              {isSavedState ? (
                <Tooltip title="Ta bort från anteckningar" arrow>
                  <CheckIcon fontSize="small" sx={{ color: 'green' }} />
                </Tooltip>
              ) : (
                <Tooltip title="Spara i anteckningar" arrow>
                  <BookmarkBorderIcon fontSize="small" />
                </Tooltip>
              )}
            </IconButton>
            <Tooltip title={copied ? "Kopierat" : "Kopiera"} arrow>
              <IconButton onClick={copyToClipboard} size="small">
                {copied ? <CheckIcon fontSize="small" sx={{ color: 'green' }} /> : <ContentCopyIcon sx={{ fontSize: '17px' }} />}
              </IconButton>
            </Tooltip>

            {(conversationType !== "spanish" && !isLanguageBot) && (
              <>
                <Tooltip title="Generera idéer" arrow>
                  <IconButton onClick={handleGenerateIdeas} size="small">
                    <GenerateIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  PaperProps={{ sx: { borderRadius: '10px' } }}
                >
                  {ideasLoading && (
                    <MenuItem>
                      <CircularProgress size={15} />
                    </MenuItem>
                  )}
                  {ideas.map((idea, index) => (
                    <MenuItem key={index} onClick={() => handleIdeaClick(idea)} sx={{ fontSize: '14px' }}>
                      {idea}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            {(conversationType === "spanish" || isLanguageBot) && (
              <>
                <Tooltip title="Översätt till svenska" arrow>
                  <IconButton onClick={handleTranslate} size="small">
                    <GTranslateIcon sx={{ fontSize: '17px' }} />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={Boolean(translateAnchorEl)}
                  anchorEl={translateAnchorEl}
                  onClose={handleClosePopover}
                  PaperProps={{ sx: { borderRadius: '20px' } }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  {translateLoading ? (
                    <Box sx={{ p: 2 }}>
                      <CircularProgress size={15} />
                    </Box>
                  ) : (
                    <Box sx={{ p: 2, maxWidth: 300, maxHeight: 200, overflow: 'auto' }}>
                      <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        {translatedText}
                      </Typography>
                    </Box>
                  )}
                </Popover>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default React.memo(MessageBubble);
