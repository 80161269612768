import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const OptionButton = styled(Button)(({ theme }) => ({
  marginBottom: '15px',
  width: '100%',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c' : '#ffffff',
  color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#000000',
  fontSize: '1rem',
  borderRadius: '10px',
  borderColor: theme.palette.mode === 'dark' ? '#4a5d6b' : '#eaeaea',
  borderBottom: theme.palette.mode === 'dark' ? undefined : '4px solid #eaeaea',
  display: 'flex',
  justifyContent: 'left',
  textAlign: 'left',
  padding: theme.spacing(1.5),
    '&:hover': {
   borderColor: theme.palette.mode === 'dark' ? '#4a5d6b' : '#eaeaea',
  borderBottom: theme.palette.mode === 'dark' ? undefined : '4px solid #eaeaea',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6',
  },
  '&:active': {
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6',
    backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#eee9ff',
  },
  '&:focus': {
    outline: 'none',
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6',
    backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#eee9ff',
  },
  '&.MuiButton-contained': {
    outline: 'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#1097da' : '#28146c',
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
    borderColor: theme.palette.mode === 'dark' ? '#1675a3' : '#543e9f',
    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
    },
  },
}));

export default OptionButton;