import React, { useRef, useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Button, TextField, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Alert, Tabs, Tab, useMediaQuery, CircularProgress } from '@mui/material';
import { Google as GoogleIcon, Chat, School, QuestionAnswer, Refresh, Quiz, Settings, Lightbulb, StyleOutlined, Person, Language } from '@mui/icons-material';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import { GoogleLogin } from '@react-oauth/google';
import Logger from './Logger';
import { useSnackbar } from './SnackbarHandler';


const translateFirebaseError = (error) => {
  const errorCode = error.code;
  const errorMessage = error.message;

  const errorTranslations = {
    'auth/weak-password': 'Lösenordet bör vara minst 6 tecken långt.',
    'auth/email-already-in-use': 'E-postadressen används redan av ett annat konto.',
    'auth/invalid-email': 'E-postadressen är ogiltig.',
    'auth/user-disabled': 'Detta användarkonto har inaktiverats.',
    'auth/user-not-found': 'Ingen användare hittades med denna e-postadress.',
    'auth/wrong-password': 'Felaktigt lösenord.',
  };

  return errorTranslations[errorCode] || errorMessage;
};

const CreateAccountView = ({ setUser, isLogin }) => {
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const confirmPasswordRef = useRef('');
const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { user, handleGoogleLogin, createUserWithEmailAndPassword, signIn } = useAuth();
const { showSnackbar } = useSnackbar();

    const [hasExceededTokenLimit, setHasExceededTokenLimit] = useState(false);
    const theme = useTheme();
    const styles = getStyles(theme);
    const [tabValue, setTabValue] = useState(0);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [error, setError] = useState('');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
        try {
            if (tabValue === 0) {
                await signIn(emailRef.current.value, passwordRef.current.value);
            } else {
                if (passwordRef.current.value !== confirmPasswordRef.current.value) {
                    showSnackbar('Lösenorden matchar inte', 'info');
                    return;
                }
                await createUserWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
            }
            navigate('/');
            //window.location.href = '/';
        } catch (error) {
            const swedishErrorMessage = translateFirebaseError(error);
            showSnackbar(swedishErrorMessage, 'info');
        } finally {
            setIsLoading(false);
        }
    };

    const benefitsList = [
        { text: "Skapa karaktärer med AI", icon: <Person /> },
        { text: "Skapa högskoleprov med AI", icon: <School /> },
        { text: "Träna på användargenererade eller gamla högskoleprov", icon: <QuestionAnswer /> },
        { text: "Se dina pågående högskoleprov och skifta mellan flera pågående prov", icon: <Refresh /> },
        
        { text: "Tillgång till Fråga AI-chattbot", icon: <Chat /> },
        { text: "Skapa quiz med AI", icon: <Quiz /> },
        { text: "Välj AI-modell när du genererar innehåll", icon: <Settings /> },
        { text: "Möjlighet att komplettera instruktioner till AI:n för bättre resultat", icon: <Lightbulb /> },
        { text: "Skapa flashcards med AI", icon: <StyleOutlined /> },
        { text: "Tillgång till samtliga AI-lärare som t.ex. spanska-, franska-, och historielärare", icon: <Language /> },
    ];

    useEffect(() => {
        if (user) {
            const tokenLimit = user.guest ? process.env.REACT_APP_GUEST_TOKEN_LIMIT : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT;
            if (user.tokens >= tokenLimit) {
                setHasExceededTokenLimit(true);
            }
        }
    }, [user]);

    const LoginSignupForm = () => (
        <Box sx={{ width: '100%' }}>
            <Typography sx={{ mb: 2, mt: 0, fontWeight: 'bold', fontSize: '22px' }}>
                Logga in/Skapa konto med Google
            </Typography>
            <div style={{ width: '100%' }}>
                <GoogleLogin
                    style={{ width: '100%' }}
                    onSuccess={handleGoogleLogin}
                    onError={() => {
                        Logger.log('Login Failed');
                    }}
                />
            </div>
            <Box sx={{ mt: {xs:4, md:8}, mb: 1 }}>
                <HeadlineWithLines />
            </Box>
            <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" sx={{ mb: 3 }}>
                <Tab label="Logga in" />
                <Tab label="Skapa konto" />
            </Tabs>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-post"
                    name="email"
                    autoComplete="email"
                    inputRef={emailRef}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Lösenord"
                    type="password"
                    id="password"
                    autoComplete={tabValue === 0 ? "current-password" : "new-password"}
                    inputRef={passwordRef}
                />
                {tabValue === 1 && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Bekräfta lösenord"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        inputRef={confirmPasswordRef}
                    />
                )}
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            <Button
                type="submit"
                fullWidth
                variant={theme.palette.mode === 'light' ? "purp" : "contained"}
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={16} color="inherit" />}
            >
                {isLoading 
                    ? (tabValue === 0 ? "Loggar in..." : "Skapar konto och loggar in...")
                    : (tabValue === 0 ? "Logga in" : "Skapa konto")
                }
            </Button>
            </Box>
        </Box>
    );

    const BenefitsList = () => (
        <Box sx={{ width: '100%' }}>
            <Typography sx={{ mb: 2, mt: 0, fontWeight: 'bold', fontSize: '22px' }}>
                Välkommen till Studera.AI!
            </Typography>
            <List sx={{ backgroundColor: 'transparent' }}>
                {benefitsList.map((benefit, index) => (
                    <ListItem key={index} sx={{
                        padding: '4px 0',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            cursor: 'default'
                        },
                        '&:hover .MuiListItemIcon-root': {
                            backgroundColor: 'transparent',
                            cursor: 'default'
                        }
                    }}>
                        <ListItemIcon sx={{ minWidth: '36px' }}>
                            {benefit.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={benefit.text}
                            primaryTypographyProps={{ fontSize: '14px' }}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={styles.container}>
            <Box sx={{ ...styles.innerContainer }}>
                <Typography variant="h1" sx={{ ...styles.header, mt: 5, mb: 4 }}>
                    Skapa konto — ja, det är helt gratis
                </Typography>
                {hasExceededTokenLimit && (
                    <Alert severity="info" variant="filled" sx={{ mb: 2, borderRadius: '10px', fontWeight: 'bold' }}>
                        {user && user.guest
                            ? "Du har nått gränsen för gästkontot. Skapa ett konto för att få ytterligare 50 000 tokens och fortsätta använda tjänsten. Behöver du ännu mer? Kontakta oss via Feedback-länken till vänster!"
                            : "Du har överskridit din token-gräns. Behöver du mer så kontakta oss genom Feedback-länken till vänster!"
                        }
                    </Alert>
                )}
                <Box sx={{ ...styles.newBox, padding: '40px', width: '100%' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        width: '100%'
                    }}>
                        {isMobile ? (
                            <>
                                <Box sx={{ mb: 4 }}>
                                    <LoginSignupForm />
                                </Box>
                                <Box sx={{
                                    borderTop: '1px solid',
                                    borderColor: 'divider',
                                    pt: 4
                                }}>
                                    <BenefitsList />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={{ flex: 1, pr: 5, borderRight: '1px solid', borderColor: 'divider' }}>
                                    <BenefitsList />
                                </Box>
                                <Box sx={{ flex: 1, pl: 5 }}>
                                    <LoginSignupForm />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const HeadlineWithLines = () => {
  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="center"
      width="100%"
    >
      <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'divider' }} />
      <Typography variant="h6" component="span" sx={{ marginX: 2, fontSize:'17px', fontWeight:'500' }}>
        eller
      </Typography>
      <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'divider' }} />
    </Box>
  );
};

export default CreateAccountView;