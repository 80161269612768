import React, { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper, Grid, 
  LinearProgress,
} from '@mui/material';
import { callApi } from './api'; // Assuming this is your API call function

const LanguageTest = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [targetLanguage, setTargetLanguage] = useState('');
  const [nativeLanguage, setNativeLanguage] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState('');
  const [score, setScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [testFinished, setTestFinished] = useState(false);
  const [evaluation, setEvaluation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [difficulty, setDifficulty] = useState(5); // Difficulty level 1-10
  const [questionResults, setQuestionResults] = useState([]);



  const questionTypes = ['multiple_choice', 'true_false', 'fill_in_blank', 'word_comprehension', 'reading_comprehension'];

  const getCommonInstructions = () => `
    Generate a quiz.
    
    Difficulty: ${difficulty} (on a scale of 1-10, where 1 is beginner and 10 is advanced).
  `;

  const getMultipleChoiceInstructions = () => `
    ${getCommonInstructions()}
    Generate a multiple-choice question in ${targetLanguage}.
    Provide 4 options, with one correct answer and 3 wrong answers.
    
    Respond with a JSON string containing the following fields:
    {
      "type": "multiple_choice",
      "question": "The question text in ${targetLanguage}",
      "options": ["Option 1", "Option 2", "Option 3", "Option 4"],
      "correctAnswer": "The correct option",
      "difficulty": The actual difficulty (1-10)
    }
  `;

  const getTranslationInstructions = () => `
    ${getCommonInstructions()}
    Generate a translation question from ${nativeLanguage} to ${targetLanguage}.
    
    Respond with a JSON string containing the following fields:
    {
      "type": "translation",
      "question": "The sentence to translate in ${nativeLanguage}",
      "correctAnswer": "The correct translation in ${targetLanguage}",
      "difficulty": The actual difficulty (1-10)
    }
  `;

  const getTrueFalseInstructions = () => `
    ${getCommonInstructions()}
    Generate a true/false question in ${targetLanguage}.
    The statement should be either true or false.
    
    Respond with a JSON string containing the following fields:
    {
      "type": "true_false",
      "question": "The statement in ${targetLanguage}",
      "correctAnswer": "true" or "false",
      "difficulty": The actual difficulty (1-10)
    }
  `;

  const getFillInBlankInstructions = () => `
    ${getCommonInstructions()}
    Generate a fill-in-the-blank question in ${targetLanguage}.
    Use "_____" to indicate the blank in the sentence.
    Provide 4 options, with one correct answer.
    
    Respond with a JSON string containing the following fields:
    {
      "type": "fill_in_blank",
      "question": "The sentence with _____ for the blank in ${targetLanguage}",
      "options": ["Option 1", "Option 2", "Option 3", "Option 4"],
      "correctAnswer": "The correct option",
      "difficulty": The actual difficulty (1-10)
    }
  `;


  const getReadingComprehensionInstructions = () => `
    ${getCommonInstructions()}
    Generate a reading comprehension question in ${targetLanguage}.
    Provide a text of at least 100 words, followed by a question about the text.
    Include 4 options, with one correct answer and 3 wrong answers.
    
    Respond with a JSON string containing the following fields:
    {
      "type": "reading_comprehension",
      "text": "The reading passage of at least 100 words in ${targetLanguage}",
      "question": "A question about the text in ${targetLanguage}",
      "options": ["Option 1", "Option 2", "Option 3", "Option 4"],
      "correctAnswer": "The correct option",
      "difficulty": The actual difficulty (1-10)
    }
  `;

  const getWordComprehensionInstructions = () => `
    ${getCommonInstructions()}
    Generate a word comprehension question in ${targetLanguage}.
    Provide one word and at least 6 options that are similar but not correct, plus one correct option.
    
    Respond with a JSON string containing the following fields:
    {
      "type": "word_comprehension",
      "word": "The word to be comprehended in ${targetLanguage}",
      "options": ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5", "Option 6", "Correct Option"],
      "correctAnswer": "The correct option",
      "difficulty": The actual difficulty (1-10)
    }
  `;

  const getQuestionInstructions = () => {
    const randomType = questionTypes[Math.floor(Math.random() * questionTypes.length)];
    switch (randomType) {
      case 'multiple_choice':
        return getMultipleChoiceInstructions();
      case 'translation':
        return getTranslationInstructions();
      case 'true_false':
        return getTrueFalseInstructions();
      case 'fill_in_blank':
        return getFillInBlankInstructions();
      case 'reading_comprehension':
        return getReadingComprehensionInstructions();
      case 'word_comprehension':
        return getWordComprehensionInstructions();
      default:
        return getMultipleChoiceInstructions(); // fallback to multiple choice
    }
  };

  const generateQuestion = async (isFirstQuestion = false) => {
    setErrorMessage('');
    try {
      const questionInstructions = getQuestionInstructions();
      const systemMessage = `
        ${questionInstructions}
        ${!isFirstQuestion ? `User's previous answer was judged as ${currentQuestion?.result === 'yes' ? 'correct' : 'incorrect'}.` : ''}
      `;

      const messages = [
        { role: 'system', content: systemMessage },
        { role: 'user', content: 'Generate a quiz question' }
      ];

      const response = await callApi(messages);
      
      console.log('API response (question):', response);

      let questionData;
      try {
        if (typeof response === 'string') {
          const jsonString = response.replace(/^```json\n?/, '').replace(/\n?```$/, '');
          questionData = JSON.parse(jsonString);
        } else if (typeof response === 'object' && response !== null) {
          questionData = response;
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
        throw new Error('Could not parse JSON from the response');
      }

      console.log('Parsed question data:', questionData);

      // Check for all required fields based on the question type
      if (!questionData.type || !questionData.difficulty) {
        throw new Error('Invalid question structure: missing type or difficulty');
      }

      switch (questionData.type) {
        case 'multiple_choice':
        case 'fill_in_blank':
        case 'reading_comprehension':
          if (!questionData.question || !questionData.options || !questionData.correctAnswer) {
            throw new Error(`Invalid ${questionData.type} question structure`);
          }
          break;
        case 'word_comprehension':
          if (!questionData.word || !questionData.options || !questionData.correctAnswer) {
            throw new Error('Invalid word comprehension question structure');
          }
          break;
        case 'true_false':
          if (!questionData.question || !questionData.correctAnswer) {
            throw new Error('Invalid true/false question structure');
          }
          break;
        case 'translation':
          if (!questionData.question || !questionData.correctAnswer) {
            throw new Error('Invalid translation question structure');
          }
          break;
        default:
          throw new Error('Unknown question type');
      }

      setCurrentQuestion(questionData);
      setTotalQuestions((prev) => prev + 1);
    } catch (error) {
      console.error('Error generating question:', error);
      setErrorMessage(`Failed to generate a question: ${error.message}`);
    }
  };

  const evaluateAnswer = async () => {
    try {
      if (currentQuestion.type === 'translation') {
        const systemMessage = `
          Evaluate if the following translation is correct.
          Question: ${currentQuestion.question}
          Correct answer: ${currentQuestion.correctAnswer}
          User's answer: ${userAnswer}
          Target language: ${targetLanguage}
          
          Respond with a JSON string containing the following fields:
          {
            "result": "yes" if the answer is correct or close enough, "no" if it's incorrect,
            "explanation": "A brief explanation of the evaluation"
          }
        `;

        const messages = [
          { role: 'system', content: systemMessage },
          { role: 'user', content: 'Evaluate the translation' }
        ];

        const response = await callApi(messages);
        
        console.log('API response (evaluation):', response);

        let evaluationData;
        try {
          if (typeof response === 'string') {
            const jsonString = response.replace(/^```json\n?/, '').replace(/\n?```$/, '');
            evaluationData = JSON.parse(jsonString);
          } else if (typeof response === 'object' && response !== null) {
            evaluationData = response;
          } else {
            throw new Error('Unexpected response format');
          }
        } catch (parseError) {
          console.error('Error parsing JSON for evaluation:', parseError);
          throw new Error('Could not parse JSON from the evaluation response');
        }

        console.log('Parsed evaluation data:', evaluationData);

        if (!evaluationData.result || !evaluationData.explanation) {
          throw new Error('Invalid evaluation structure');
        }

        return evaluationData;
      } else {
        // For non-translation questions, evaluate the answer directly
        const isCorrect = userAnswer === currentQuestion.correctAnswer;
        return {
          result: isCorrect ? 'yes' : 'no',
          explanation: isCorrect ? 'Correct!' : `Incorrect. The correct answer is: ${currentQuestion.correctAnswer}`
        };
      }
    } catch (error) {
      console.error('Error evaluating answer:', error);
      setErrorMessage(`Failed to evaluate the answer: ${error.message}`);
      return null;
    }
  };

  const handleAnswer = async () => {
    const evaluation = await evaluateAnswer();
    if (evaluation) {
      setCurrentQuestion(prevQuestion => ({ ...prevQuestion, ...evaluation }));
      
      if (evaluation.result === 'yes') {
        setScore((prev) => prev + 1);
        setDifficulty((prev) => Math.min(prev + 1, 10));
      } else {
        setDifficulty((prev) => Math.max(prev - 1, 1));
      }
      
      // Update questionResults
      setQuestionResults(prev => [...prev, evaluation.result === 'yes']);
      
      // Generate a new question regardless of whether the answer was correct or not
      generateQuestion();
      setUserAnswer('');
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 1) {
      generateQuestion(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const finishTest = async () => {
    setTestFinished(true);
    // Add logic here to generate a final evaluation
  };

  useEffect(() => {
    if (activeStep === 2) {
      generateQuestion(true);
    }
  }, [activeStep]);

 const ResultGrid = () => (
    <Grid container spacing={1} sx={{ mt: 2 }}>
      {questionResults.map((result, index) => (
        <Grid item key={index}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: result ? 'success.main' : 'error.main',
              border: '1px solid',
              borderColor: 'grey.300',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );



  const renderQuestionInput = () => {
    if (!currentQuestion) return null;

    switch (currentQuestion.type) {
      case 'multiple_choice':
      case 'fill_in_blank':
      case 'reading_comprehension':
      case 'word_comprehension':
        return (
          <RadioGroup
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
          >
            {currentQuestion.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        );
      case 'true_false':
        return (
          <RadioGroup
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
          >
            <FormControlLabel value="true" control={<Radio />} label="True" />
            <FormControlLabel value="false" control={<Radio />} label="False" />
          </RadioGroup>
        );
      case 'translation':
        return (
          <TextField
            fullWidth
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            placeholder="Enter your translation"
          />
        );
      default:
        return null;
    }
  };


    return (
    <Box sx={{ width: '100%', maxWidth: 800, margin: 'auto', mt: 4 }}>


      <Box sx={{ mt: 4, display: 'flex' }}>
        <Box sx={{ flex: 1, mr: 2 }}>


          { !testFinished && currentQuestion && (
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              {currentQuestion.type === 'reading_comprehension' && (
                <Typography variant="body1" gutterBottom>
                  {currentQuestion.text}
                </Typography>
              )}
              <Typography variant="h6" gutterBottom>
                {currentQuestion.type === 'word_comprehension' ? `What does "${currentQuestion.word}" mean?` : currentQuestion.question}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Difficulty: {currentQuestion.difficulty}/10
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={difficulty * 10} 
                sx={{ mb: 2 }}
              />
              {renderQuestionInput()}
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleAnswer} variant="contained" color="primary">
                  Submit Answer
                </Button>
                <Button onClick={finishTest} variant="outlined" color="secondary">
                  Finish Test
                </Button>
              </Box>
              {currentQuestion.explanation && (
                <Typography sx={{ mt: 2 }} color={currentQuestion.result === 'yes' ? 'success.main' : 'error.main'}>
                  {currentQuestion.explanation}
                </Typography>
              )}
            </Paper>
          )}

          {errorMessage && (
            <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep < 2 && (
              <Button onClick={handleNext} disabled={!targetLanguage && activeStep === 0 || !nativeLanguage && activeStep === 1}>
                Next
              </Button>
            )}
          </Box>
        </Box>

        {/* Live Result View Matrix */}
        <Box sx={{ width: 200 }}>
          <Typography variant="h6" gutterBottom>Progress</Typography>
          <ResultGrid />
          <Typography variant="body2" sx={{ mt: 2 }}>
            Score: {score} / {totalQuestions}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageTest;