import React, { useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import { callApi } from './api'; // Assuming this is your API call function
import { useSnackbar } from './SnackbarHandler';
import Logger from './Logger';
const IdeaGenerator = ({ 
  user, 
  onIdeaGenerated, 
  systemMessage,
  position = 'end',
  size = 'medium',
}) => {
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleGenerateIdea = async () => {
    setLoading(true);
    try {
      const messages = [
        { role: 'system', content: systemMessage },
        { role: 'user', content: 'Generate an idea' }
      ];
      const response = await callApi(messages, undefined, true, user.uid);
      
      if (response && response.data && response.data.choices && response.data.choices.length > 0) {
        const ideaContent = response.data.choices[0].message.content;
        try {
          // Replace single quotes with double quotes to make it valid JSON
          const validJsonString = ideaContent.replace(/'/g, '"');
          const parsedIdea = JSON.parse(validJsonString);
          if (Array.isArray(parsedIdea) && parsedIdea.length > 0 && parsedIdea[0].idea) {
            onIdeaGenerated(parsedIdea[0].idea);
          } else {
            throw new Error('Invalid idea format');
          }
        } catch (parseError) {
          Logger.log('Error parsing idea:', parseError);
          showSnackbar('Fel format. Vänligen försök igen.', 'info');
          // If parsing fails, attempt to extract the idea from the string
          const extractedIdea = extractIdeaFromString(ideaContent);
          onIdeaGenerated(extractedIdea || ideaContent);
        }
      } else {
        throw new Error('Unexpected API response structure');
      }
    } catch (error) {
      Logger.log('Error generating idea:', error);
      showSnackbar('Ett fel uppstod. Vänligen försök igen.', 'error');
      onIdeaGenerated('Could not generate an idea. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const extractIdeaFromString = (str) => {
    const match = str.match(/'idea':\s*'([^']+)'/);
    return match ? match[1] : null;
  };

  return (
    <IconButton 
      onClick={handleGenerateIdea} 
      disabled={loading}
      size={size}
      sx={{
        position: 'absolute',
        ...(position === 'start' && { left: 8 }),
        ...(position === 'end' && { right: 8 }),
        ...(position === 'top' && { top: 8, right: 8 }),
        ...(position === 'bottom' && { bottom: 8, right: 8 }),
      }}
    >
      {loading ? <CircularProgress size={24} /> : <BoltIcon />}
    </IconButton>
  );
};

export default IdeaGenerator;



// With streaming:
// import React, { useState, useRef, useCallback } from 'react';
// import { IconButton, CircularProgress } from '@mui/material';
// import BoltIcon from '@mui/icons-material/Bolt';
// import { callApiWithStreaming } from './api'; // Import the streaming API function
// import { useSnackbar } from './SnackbarHandler';

// const IdeaGenerator = ({ 
//   user, 
//   onIdeaGenerated, 
//   systemMessage,
//   position = 'end',
//   size = 'medium',
// }) => {
//   const [loading, setLoading] = useState(false);
//   const { showSnackbar } = useSnackbar();
//   const abortControllerRef = useRef(null);
//   const streamedContentRef = useRef('');

//   const handleGenerateIdea = useCallback(async () => {
//     setLoading(true);
//     streamedContentRef.current = '';

//     try {
//       abortControllerRef.current = new AbortController();

//       const messages = [
//         { role: 'system', content: systemMessage },
//         { role: 'user', content: 'Generate an idea' }
//       ];

//       const onChunk = (chunk) => {
//         streamedContentRef.current += chunk;
//         // Update the input field in real-time
//         onIdeaGenerated(streamedContentRef.current);
//       };

//       await callApiWithStreaming(messages, onChunk, user.uid, abortControllerRef.current.signal);

//       // After streaming is complete, try to parse the content as JSON
//       try {
//         const validJsonString = streamedContentRef.current.replace(/'/g, '"');
//         const parsedIdea = JSON.parse(validJsonString);
//         if (Array.isArray(parsedIdea) && parsedIdea.length > 0 && parsedIdea[0].idea) {
//           onIdeaGenerated(parsedIdea[0].idea);
//         } else {
//           showSnackbar('Fel format. Använder rå text.', 'info');
//         }
//       } catch (parseError) {
//         Logger.log('Error parsing idea:', parseError);
//         showSnackbar('Kunde inte tolka som JSON. Använder rå text.', 'info');
//       }
//     } catch (error) {
//       if (error.name === 'AbortError') {
//         Logger.log('Stream was aborted');
//       } else {
//         console.error('Error generating idea:', error);
//         showSnackbar('Kunde inte generera en idé. Försök igen senare.', 'error');
//         onIdeaGenerated('Could not generate an idea. Please try again later.');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [user.uid, systemMessage, onIdeaGenerated, showSnackbar]);

//   React.useEffect(() => {
//     return () => {
//       if (abortControllerRef.current) {
//         abortControllerRef.current.abort();
//       }
//     };
//   }, []);

//   return (
//     <IconButton 
//       onClick={handleGenerateIdea} 
//       disabled={loading}
//       size={size}
//       sx={{
//         position: 'absolute',
//         ...(position === 'start' && { left: 8 }),
//         ...(position === 'end' && { right: 8 }),
//         ...(position === 'top' && { top: 8, right: 8 }),
//         ...(position === 'bottom' && { bottom: 8, right: 8 }),
//       }}
//     >
//       {loading ? <CircularProgress size={24} /> : <BoltIcon />}
//     </IconButton>
//   );
// };

// export default IdeaGenerator;