import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, TextField, Button, Menu, MenuItem, Paper, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Editor, EditorState, ContentState, Modifier, RichUtils } from 'draft-js';
import { Menu as ContextMenu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import 'draft-js/dist/Draft.css';
import { callApiWithStreaming } from './api';

const MENU_ID = 'selection-menu';

const WritingAssistant = () => {
  const [content, setContent] = useState([]);
  const [input, setInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [sections, setSections] = useState([{ id: 'main', title: 'Main Content', paragraphs: [] }]);
  const [outlineAnchorEl, setOutlineAnchorEl] = useState(null);
  const abortControllerRef = useRef(null);

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  useEffect(() => {
    const updatedSections = sections.map(section => ({
      ...section,
      paragraphs: content.filter(p => p.sectionId === section.id)
    }));
    setSections(updatedSections);
  }, [content]);

  const handleGenerate = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    const newParagraph = { 
      id: Date.now().toString(), 
      editorState: EditorState.createEmpty(),
      status: 'pending',
      sectionId: 'main'
    };
    setContent(prevContent => [...prevContent, newParagraph]);

    try {
      await callApiWithStreaming(
        [{ role: 'user', content: input }],
        (chunk) => {
          setContent(prevContent => {
            const lastIndex = prevContent.length - 1;
            const updatedContent = [...prevContent];
            const currentEditorState = updatedContent[lastIndex].editorState;
            const currentContent = currentEditorState.getCurrentContent();
            const newContent = ContentState.createFromText(currentContent.getPlainText() + chunk);
            updatedContent[lastIndex].editorState = EditorState.push(
              currentEditorState,
              newContent,
              'insert-characters'
            );
            return updatedContent;
          });
        },
        null,
        abortControllerRef.current.signal
      );
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error generating content:', error);
      }
    }

    setInput('');
  }, [input]);

  const handleEditorChange = (index, newEditorState) => {
    setContent(prevContent => {
      const updatedContent = [...prevContent];
      updatedContent[index].editorState = newEditorState;
      
      const selection = newEditorState.getSelection();
      if (!selection.isCollapsed()) {
        handleSelectionChange(index, selection);
      }
      
      return updatedContent;
    });
  };

  const handleSelectionChange = (index, selection) => {
    const editorState = content[index].editorState;
    const contentState = editorState.getCurrentContent();
    const selectedText = getSelectedText(contentState, selection);

    if (selectedText) {
      show({ event: window.event, props: { index, selectedText } });
    }
  };

  const getSelectedText = (contentState, selection) => {
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    if (startKey === endKey) {
      return contentState.getBlockForKey(startKey).getText().slice(startOffset, endOffset);
    }

    const blockMap = contentState.getBlockMap();
    const blocks = blockMap
      .skipUntil((block) => block.getKey() === startKey)
      .takeUntil((block) => block.getKey() === endKey)
      .concat(blockMap.get(endKey));

    return blocks.map((block, key) => {
      let blockText = block.getText();
      if (key === startKey) {
        blockText = blockText.slice(startOffset);
      }
      if (key === endKey) {
        blockText = blockText.slice(0, endOffset);
      }
      return blockText;
    }).join('\n');
  };

  const handleSelectionReformulate = async ({ props }) => {
    const { index, selectedText } = props;
    try {
      await callApiWithStreaming(
        [{ role: 'user', content: `Reformulate this text: "${selectedText}"` }],
        (chunk) => {
          setContent(prevContent => {
            const updatedContent = [...prevContent];
            const editorState = updatedContent[index].editorState;
            const contentState = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            
            const newContentState = Modifier.replaceText(
              contentState,
              selection,
              chunk
            );
            
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              'insert-characters'
            );
            
            updatedContent[index].editorState = newEditorState;
            return updatedContent;
          });
        }
      );
    } catch (error) {
      console.error('Error reformulating selected text:', error);
    }
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleAccept = () => {
    if (selectedIndex !== null) {
      setContent(prevContent => {
        const updatedContent = [...prevContent];
        updatedContent[selectedIndex].status = 'accepted';
        return updatedContent;
      });
    }
    handleMenuClose();
  };

  const handleDiscard = () => {
    if (selectedIndex !== null) {
      setContent(prevContent => prevContent.filter((_, index) => index !== selectedIndex));
    }
    handleMenuClose();
  };

  const handleReformulate = async () => {
    if (selectedIndex !== null) {
      const selectedContent = content[selectedIndex].editorState.getCurrentContent().getPlainText();
      try {
        await callApiWithStreaming(
          [{ role: 'user', content: `Reformulate this text: "${selectedContent}"` }],
          (chunk) => {
            setContent(prevContent => {
              const updatedContent = [...prevContent];
              const editorState = updatedContent[selectedIndex].editorState;
              const contentState = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              
              const newContentState = Modifier.replaceText(
                contentState,
                selection,
                chunk
              );
              
              const newEditorState = EditorState.push(
                editorState,
                newContentState,
                'insert-characters'
              );
              
              updatedContent[selectedIndex].editorState = newEditorState;
              return updatedContent;
            });
          }
        );
      } catch (error) {
        console.error('Error reformulating text:', error);
      }
    }
    handleMenuClose();
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setContent(prevContent => {
      const updatedContent = Array.from(prevContent);
      const [reorderedItem] = updatedContent.splice(sourceIndex, 1);
      updatedContent.splice(destinationIndex, 0, reorderedItem);
      return updatedContent;
    });
  };

  const getWordCount = (editorState) => {
    const plainText = editorState.getCurrentContent().getPlainText('');
    return plainText.trim().split(/\s+/).length;
  };

  const getTotalWordCount = () => {
    return content.reduce((total, paragraph) => total + getWordCount(paragraph.editorState), 0);
  };

  const handleAddSection = () => {
    const newSectionId = Date.now().toString();
    setSections(prevSections => [...prevSections, { id: newSectionId, title: 'New Section', paragraphs: [] }]);
  };

  const handleSectionTitleChange = (sectionId, newTitle) => {
    setSections(prevSections => 
      prevSections.map(section => 
        section.id === sectionId ? { ...section, title: newTitle } : section
      )
    );
  };

  const handleMoveParagraph = (paragraphId, targetSectionId) => {
    setContent(prevContent => 
      prevContent.map(paragraph => 
        paragraph.id === paragraphId ? { ...paragraph, sectionId: targetSectionId } : paragraph
      )
    );
  };

  const handleOutlineOpen = (event) => {
    setOutlineAnchorEl(event.currentTarget);
  };

  const handleOutlineClose = () => {
    setOutlineAnchorEl(null);
  };

  const handleAddHeader = (level) => {
    if (selectedIndex !== null) {
      setContent(prevContent => {
        const updatedContent = [...prevContent];
        const editorState = updatedContent[selectedIndex].editorState;
        const newEditorState = RichUtils.toggleBlockType(editorState, `header-${level}`);
        updatedContent[selectedIndex].editorState = newEditorState;
        return updatedContent;
      });
    }
    handleOutlineClose();
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, margin: 'auto', p: 2 }}>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Ange din skrivprompt här..."
        sx={{ mb: 2 }}
      />
      <Button variant="contained" onClick={handleGenerate} sx={{ mb: 2 }}>
        Generera innehåll
      </Button>
      <Button variant="outlined" onClick={handleAddSection} sx={{ mb: 2, ml: 2 }}>
        Lägg till avsnitt
      </Button>
      <Button variant="outlined" onClick={handleOutlineOpen} sx={{ mb: 2, ml: 2 }}>
        Outliner
      </Button>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Totalt antal ord: {getTotalWordCount()}
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        {sections.map(section => (
          <Box key={section.id} sx={{ mb: 4 }}>
            <TextField
              value={section.title}
              onChange={(e) => handleSectionTitleChange(section.id, e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Droppable droppableId={section.id}>
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {section.paragraphs.map((paragraph, index) => (
                    <Draggable key={paragraph.id} draggableId={paragraph.id} index={index}>
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          elevation={3}
                          sx={{ 
                            p: 2, 
                            mb: 2, 
                            borderLeft: paragraph.status === 'accepted' ? '4px solid green' : 'none'
                          }}
                        >
                          <Editor
                            editorState={paragraph.editorState}
                            onChange={(newState) => handleEditorChange(index, newState)}
                          />
                          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="caption">
                              Antal ord: {getWordCount(paragraph.editorState)}
                            </Typography>
                            <Button size="small" onClick={(e) => handleMenuOpen(e, index)}>
                              Alternativ
                            </Button>
                          </Box>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        ))}
      </DragDropContext>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAccept}>Godkänn</MenuItem>
        <MenuItem onClick={handleDiscard}>Kassera</MenuItem>
        <MenuItem onClick={handleReformulate}>Omformulera</MenuItem>
        <MenuItem onClick={() => handleMoveParagraph(content[selectedIndex].id, 'main')}>Flytta till huvudinnehåll</MenuItem>
        {sections.filter(s => s.id !== 'main').map(section => (
          <MenuItem key={section.id} onClick={() => handleMoveParagraph(content[selectedIndex].id, section.id)}>
            Flytta till {section.title}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={outlineAnchorEl}
        open={Boolean(outlineAnchorEl)}
        onClose={handleOutlineClose}
      >
        <MenuItem onClick={() => handleAddHeader(1)}>Rubrik 1</MenuItem>
        <MenuItem onClick={() => handleAddHeader(2)}>Rubrik 2</MenuItem>
        <MenuItem onClick={() => handleAddHeader(3)}>Rubrik 3</MenuItem>
      </Menu>
      <ContextMenu id={MENU_ID}>
        <Item onClick={handleSelectionReformulate}>Omformulera markerad text</Item>
      </ContextMenu>
    </Box>
  );
};

export default WritingAssistant;