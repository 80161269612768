import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ClassroomTeacherSkeleton = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 2, 
      cursor: 'pointer',
      padding: 1,
      borderRadius: 10,
    }}
  >
    <Skeleton variant="circular" width={36} height={36} animation="wave" />
    <Box sx={{ flexGrow: 1, minWidth: 0 }}>                    
      <Skeleton variant="text" width="60%" height={20} animation="wave" />
      <Skeleton variant="text" width="40%" height={16} animation="wave" />
    </Box>
    <Skeleton variant="circular" width={24} height={24} animation="wave" />
  </Box>
);

export default ClassroomTeacherSkeleton;