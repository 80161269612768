import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
    Drawer, List, ListItem, ListItemIcon, ListItemText,
     DialogTitle, IconButton, Avatar, Box,
    Typography, Button, Popover, Tooltip, LinearProgress, Fade, Chip
} from '@mui/material';
import SettingsDialog from './SettingsDialog';

import ChatIcon from '@mui/icons-material/Chat';
import Logger from './Logger';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import GridViewIcon from '@mui/icons-material/GridView';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Person3Icon from '@mui/icons-material/Person3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoteIcon from '@mui/icons-material/Note';
import AssessmentIcon from '@mui/icons-material/Assessment';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ClipLoader } from 'react-spinners';
import LoadingSpinner from './LoadingSpinner';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import QuizIcon from '@mui/icons-material/Quiz';
import LogoutIcon from '@mui/icons-material/Logout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { SvgIcon } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useTheme } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { useMediaQuery } from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import FeedbackIcon from '@mui/icons-material/Feedback';
import FeedbackDialog from './FeedbackDialog';
import TeacherList from './TeacherList';

import { db } from './firebase';
import { useAuth } from './useAuth';
import { collection, deleteDoc, getDocs, query, where, doc, onSnapshot } from 'firebase/firestore';
import { updateUserTokens } from './functions';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CreateIcon from '@mui/icons-material/Create';
import DescriptionIcon from '@mui/icons-material/Description';

import { signOut } from 'firebase/auth';
import { auth } from './firebase';

const Sidebar = ({  handleDialogOpen, isOpen, toggleSidebar , isDark, toggleDarkMode, setOverrideTheme}) => {


  const { user, updateUser, justLoggedIn, setJustLoggedIn } = useAuth();
      const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [activeItem, setActiveItem] = useState('Fråga AI');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const [showGuestItem, setShowGuestItem] = useState(false);

  const [tokens, setTokens] = useState(0);
  const userRef = useRef(user);


  const maxTokens = user.guest ? process.env.REACT_APP_GUEST_TOKEN_LIMIT : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT;
  const tokensExceeded = tokens > maxTokens;




  useEffect(() => {
    userRef.current = user;
  }, [user]);


  useEffect(() => {
    if (justLoggedIn) {
      navigate('/');
      setJustLoggedIn(false);
    }
  }, [justLoggedIn, navigate, setJustLoggedIn]);

  
useEffect(() => {
  if (user) {
    Logger.log("User in Sidebar useEffect:", user);
    const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
      if (doc.exists()) {
        const newTokens = doc.data().tokens || 0;
        Logger.log("New tokens:", newTokens, "Max tokens:", maxTokens);
        setTokens(newTokens);

        if (newTokens > maxTokens && window.location.pathname !== '/login') {
          Logger.log("Redirecting to login due to exceeded tokens");
          navigate('/login');
        }
      }
    });
    return () => unsubscribe();
  } else {
    Logger.log("No user in Sidebar useEffect");
    setTokens(0);
  }
}, [user, maxTokens, navigate]);


    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
            // Optionally, you can show an error message to the user here
        }
    };

// useEffect(() => {

//     // Moonshine. Tidigare: if (user && !user.guest) { // Tog bort user.guest så tokens uppdateras för dom ockås
//     if (user) {
//         const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
//             if (doc.exists()) {
//                 const newTokens = doc.data().tokens || 0;
//                 setTokens(newTokens);
//                 if (newTokens > maxTokens) {
//                     navigate('/login');
//                 }
//             }
//         });
//         return () => unsubscribe();
//     } else {
//         setTokens(user?.tokens || 0);
//         if (user?.tokens > maxTokens) {
//             navigate('/login');
//         }
//     }
// }, [user, maxTokens, navigate]);

  useEffect(() => {
    if (user.guest) {
      const timer = setTimeout(() => {
        setShowGuestItem(true);
      }, 2000); // Delay of 2 seconds

      return () => clearTimeout(timer);
    } else {
      setShowGuestItem(false);
    }
  }, [user.guest]);

    const handleDialogOpenInternal = (isLoginView) => {
        setIsLogin(isLoginView);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleItemClick = (path, itemName) => {
        navigate(path);
        setActiveItem(itemName);
        setIsDrawerOpen(false); 

if (isMobile) {
        toggleSidebar();
    }

    };

    useEffect(() => {
        if (user) {
            setOpenDialog(false);
            Logger.log("user tokens: ", user.tokens);
        }
    }, [user]);

    const activeStyles = {
        color: isDark === true ? '#fff' : '#8b5cf6',
        backgroundColor: isDark === true ? '#15202B' : '#eee9ff',
        borderRadius: '20px'
    };

const openFeedbackDialog = useCallback(() => {
  setFeedbackDialogOpen(true);
}, []);

    const menuItems = [




         { text: 'Hem', icon: <GridViewIcon />, path: '/' },
         { text: 'Fråga AI', icon: <ChatIcon />, path: '/fraga-ai' },

        

 //       { text: 'Karaktärer', icon: <Person3Icon />, path: '/larare' },

        { text: 'Quiz', icon: <QuizIcon />, path: '/quiz' },
        //{ text: 'Språktest', icon: <QuizIcon />, path: '/spraktest' },
{ text: 'Flashcards', icon: <ViewAgendaIcon />, path: '/flashcards' },
        { text: 'Högskoleprovet', icon: <SchoolIcon />, path: '/hogskoleprov',
                    chip: <Chip 
                label="BETA" 
                size="small" 
                sx={{ 
                    backgroundColor: isDark ? '#2a3b4c' : '#e0e7ff',
                    color: isDark ? '#60a5fa' : '#3b82f6',
                    fontWeight: 'bold',
                    fontSize: '0.7rem',
                    height: '20px',
                    marginLeft: '8px'
                }} 
            />
             },
        { text: 'Textlabbet', icon: <CreateIcon />, path: '/textlabbet' },
        { text: 'Debatt', icon: <QuestionAnswerIcon />, path: '/debatt' },
        
{ text: 'Sparat', icon: <BookmarkIcon />, path: '/sparat' },
        { 
            text: 'Skrivmaskinen', 
            icon: <KeyboardIcon />, 
            path: '/skrivmaskinen',
            chip: <Chip 
                label="BETA" 
                size="small" 
                sx={{ 
                    backgroundColor: isDark ? '#2a3b4c' : '#e0e7ff',
                    color: isDark ? '#60a5fa' : '#3b82f6',
                    fontWeight: 'bold',
                    fontSize: '0.7rem',
                    height: '20px',
                    marginLeft: '8px'
                }} 
            />
        },
        
        //{ text: 'Historielärare', icon: <HistoryEduIcon />, path: '/historia' },

        

        //{ text: 'Betygsätt min uppsats', icon: <RateReviewIcon />, path: '/rateEssay' },
        
        { text: 'AI Detektor', icon: <AssessmentIcon />, path: '/ai-detektor' },
        //{ text: 'Ord', icon: <AssessmentIcon />, path: '/hogskoleprovet-verbal-del' },
    ];

    const [showAllMenuItems, setShowAllMenuItems] = useState(false);

    const toggleShowAllMenuItems = () => {
        setShowAllMenuItems(!showAllMenuItems);
    };

    const visibleMenuItems = showAllMenuItems ? menuItems : menuItems.slice(0, 6);
    const hiddenMenuItemsCount = menuItems.length - 6;


    const handleAvatarClick = (event) => {
        if (user.guest) {
            goToLogin();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? 'simple-popover' : undefined;

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };


const [teachers, setTeachers] = useState([]);


  const handleDeleteTeacher = async (teacherId) => {
    try {
      await deleteDoc(doc(db, 'teachers', teacherId));
      setTeachers(prevTeachers => prevTeachers.filter(teacher => teacher.id !== teacherId));
    } catch (error) {
      console.error("Error deleting teacher:", error);
      // Hantera felet (t.ex. visa ett felmeddelande för användaren)
    }
  };


useEffect(() => {
    if (user  && !user.isTemporary) {
      const teachersCollection = collection(db, 'teachers');
      const q = query(teachersCollection, where("userId", "==", user.uid));
      
      Logger.log("getting teach from ", user.uid);



      const unsubscribeSnapshot = onSnapshot(q, (querySnapshot) => {
        const teachersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTeachers(teachersList);
        setLoading(false);
      });
      return () => unsubscribeSnapshot();
    } else {
      setTeachers([]);
      setLoading(false);
    }
  }, [user]);

  const fetchTeachers = async (userId) => {
    try {
      const teachersCollection = collection(db, 'teachers');
      const q = query(teachersCollection, where("userId", "==", userId));
      const teachersSnapshot = await getDocs(q);
      const teachersList = teachersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTeachers(teachersList);
    } catch (error) {
      console.error("Fel vid hämtning av lärare:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTeacherClick = (teacherId) => {
    navigate(`/chat/${teacherId}`);
  };
const onAddTeacher = () => {
    navigate('/larare/');
  };

const goToLogin = () => {
    navigate('/login/');
  };

  const onPublicTeachers = () => {
    navigate('/personal/');
  };



useEffect(() => {
  //Logger.log("User object changed:", user);
  //Logger.log("New display name:", user.displayName);
}, [user]);

 return (
    <>
        <IconButton
            sx={{ 
                display: { xs: 'block', md: isOpen ? 'none' : 'block' }, 
                zIndex: 999, 
                position: 'absolute', 
                top: 8, 
                left: 8 
            }}
            onClick={toggleSidebar}
        >
            <MenuIcon />
        </IconButton>


 






        <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: 270,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 270,
                    boxSizing: 'border-box',
                    display: { xs: isOpen ? 'block' : 'none', md: isOpen ? 'block' : 'none' },
                },
            }}
        >


<IconButton
  sx={{ 
    zIndex: 999, 
    position: 'absolute', 
    top: 8, 
    right: 8 
  }}
  onClick={toggleDarkMode}
>
  <Tooltip title={isDark ? "Ljust läge" : "Mörkt läge"}>
    {isDark ? <LightModeIcon sx={{opacity:'0.5'}} /> : <DarkModeIcon sx={{opacity:'0.5'}} />}
  </Tooltip>
</IconButton>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', background: isDark ? '#192734' : 'white' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', p: 1 }}>
                    <IconButton onClick={toggleSidebar}>
                        <CustomIcon />
                    </IconButton>
                </Box>

                <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom:'153px', paddingBottom:'20px' }}>


{loading ? (
    <Box sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100%' 
  }}>

<LoadingSpinner 

      loading={loading} 

    />
                            {/*<LoadingSpinner size="20" noWrapper="true" /> */}
                            </Box>
                        ) : (
                         <>

                    <List sx={{ padding: '7px' }}>
                                    {visibleMenuItems.map((item) => (
                            <ListItem
                                button
    component={Link}
    to={item.path}
                                key={item.text}
                                className="rounded"
    onClick={() => {
        setActiveItem(item.text);
        if (isMobile) toggleSidebar();
    }}
                                sx={{
                                    marginBottom: '3px',
                                    paddingTop: '7px',
                                    paddingBottom: '7px',
                                    '&:hover': {
                                        '& .MuiListItemIcon-root': {
                                            color: '#8b5cf6',
                                        },
                                        '& .MuiTypography-root': {
                                            color: isDark === true ? '#fff' : '#000',
                                            fontWeight: '400',
                                        },
                                    },
                                    ...(activeItem === item.text ? activeStyles : {}),
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: '32px', paddingRight: '8px', color: activeItem === item.text ? '#8b5cf6' : '#94a3b8' }}>
                                    {React.cloneElement(item.icon)}
                                </ListItemIcon>
                                <ListItemText
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {item.text}
                                    {item.chip}
                                </Box>
                            }
                                    primaryTypographyProps={{
                                        sx: {
                                            color: isDark === true ? '#fff' : '#000',
                                        },
                                    }}
                                    sx={{ marginLeft: '10px' }}
                                />
                            </ListItem>
                        ))}


    <Fade in={showGuestItem} timeout={1000}>
        <div> {/* Wrapper div is necessary for Fade to work properly */}
          {user.guest && (
            <ListItem 
              sx={{
                marginBottom: '3px',
                paddingTop: '7px',
                paddingBottom: '7px',
                backgroundColor: isDark ? '#22303C' : '#f7f7f7'
              }}
              className="rounded"
              button
              onClick={() => goToLogin()}
            >
              <ListItemIcon sx={{ minWidth: '32px', paddingRight: '8px' }}>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText sx={{ marginLeft: '10px', fontWeight:'500', fontSize:'16px' }} primary="Skapa konto" />
            </ListItem>
          )}
        </div>
      </Fade>
                        
{menuItems.length > 4 && (
                                        <ListItem
                                            button
                                            onClick={toggleShowAllMenuItems}
                                            className="rounded"
                                            sx={{ marginBottom: '3px', paddingTop: '7px', paddingBottom: '7px', color: '#94a3b8' }}
                                        >
                                            <ListItemIcon sx={{ minWidth: '32px', paddingRight: '8px' }}>
                                                <MoreHorizIcon sx={{ color: '#94a3b8' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                //primary={showAllMenuItems ? "Visa färre" : `${hiddenMenuItemsCount} fler`}
                                                primary={showAllMenuItems ? "Mindre" : `Mer`}
                                                sx={{ marginLeft: '10px', color: '#94a3b8 !important' }}
                                                primaryTypographyProps={{
                                                    fontSize: '15px',
                                                    color: '#94a3b8 !important'
                                                }}
                                            />
                                           {/* {showAllMenuItems ? <ArrowDropUpIcon sx={{ color: '#94a3b8' }} /> : <ArrowDropDownIcon sx={{ color: '#94a3b8' }} />}*/}
                                        </ListItem>
                                    )}



                    </List>

                    <Box sx={{fontWeight:'500', fontSize:'14px', background: isDark ? '#192734' : 'white', paddingTop:'25px', paddingLeft:'15px', textTransform:'uppercase'}}>Lärare</Box>

                    <TeacherList 
                        teachers={teachers} 
                        handleTeacherClick={handleTeacherClick} 
                        onDeleteTeacher={handleDeleteTeacher}
                        onAddTeacher={onAddTeacher}
                        isDark={isDark}
                        onPublicTeachers={onPublicTeachers}
                        toggleSidebar={toggleSidebar}
                        isMobile={isMobile}
                        
                    />

                      </>
                        )}
                </Box>


<Box sx={{
    position: 'absolute',
    bottom: 103, // Adjust this value to leave space for the user profile box below
    left: 0,
    right: 0,
    alignItems: 'center',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme => theme.palette.background.paper,


    '&:hover': {
        cursor: 'pointer',
        backgroundColor: isDark ? '#22303c' : '#eee9ff'
    },
'&::before': {
  content: '""',
  position: 'absolute',
  top: '-20px',
  left: 0,
  right: 0,
  height: '20px',
  background: isDark 
    ? 'linear-gradient(to bottom, transparent, #19242fba)'
    : 'linear-gradient(to bottom, transparent, #ffffff)',
  pointerEvents: 'none',
}
}} onClick={openFeedbackDialog}>
    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft:'13px' }}>
        <FeedbackIcon sx={{ marginRight: 2, color:'#94a3b8' }} />
        <Typography variant="subtitle1">Feedback</Typography>
    </Box>

</Box>



                {user && (
                <>


    <Box sx={{
      position: 'absolute',
      bottom: 55,
      left: 0,
      right: 0,
      padding: '10px 15px',
      backgroundColor: theme => theme.palette.background.paper,
      borderTop: '1px solid',
      borderColor: theme => theme.palette.divider,
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <Typography variant="body2" sx={{ 
          fontSize: '12px', 
          color: tokensExceeded ? '#ff1744' : theme => theme.palette.text.secondary 
        }}>
          {tokensExceeded ? "Skapa konto." : "Tokens:"}
        </Typography>

        <Typography variant="body2" sx={{ 
          fontSize: '12px', 
          color: tokensExceeded ? '#ff1744' : theme => theme.palette.text.secondary 
        }}>
          {tokens}/{maxTokens}
        </Typography>
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={(tokens / maxTokens) * 100}
        sx={{
          height: 5,
          borderRadius: 5,
  backgroundColor: tokensExceeded
    ? '#ff1744'
    : (isDark ? '#2a3b4c' : '#d4d4d8'),
          '& .MuiLinearProgress-bar': {
            borderRadius: 2,
            backgroundColor: tokensExceeded 
              ? '#ff1744' 
              : (isDark ? '#14a561' : '#14a561'),
          },
        }}
      />
    </Box>




                    <Box sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        alignItems: 'center',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        backgroundColor: theme => theme.palette.background.paper,
                        borderTop: '1px solid',
                        borderColor: theme => theme.palette.divider,
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: isDark ? '#22303c' : '#eee9ff'
                        }
                    }} onClick={handleAvatarClick}>
                        <Avatar src={user.photoURL} alt={user.displayName} sx={{height:'25px', width:'25px', marginLeft:'13px'}} />
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginLeft: 2 }}>
                            <Typography  variant="subtitle1">{user.displayName}</Typography>
                        </Box>
                        <IconButton size="small" sx={{ marginLeft: 'auto' }}>
                            <NavigateNextIcon />
                        </IconButton>
                    </Box>

                    </>
                )}
            </Box>
        </Drawer>
 <SettingsDialog open={open} onClose={() => setOpen(false) } onUserUpdate={updateUser} setOverrideTheme={setOverrideTheme}  />
        <Popover
            id={popoverId}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
 PaperProps={{
        sx: {
            borderRadius: '10px'

        }
    }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {user && (
                <Box sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Avatar src={user.photoURL} alt={user.displayName} />
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle1"><strong>{user.displayName}</strong></Typography>
                            <Typography variant="body2">{user.email}</Typography>
                            <Typography variant="body2" color="textSecondary">ID: {user.uid}</Typography>
                        </Box>
                    </Box>


                   

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '6px' }}>Användning</Typography>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', marginBottom: '25px',
                        alignItems: 'center', backgroundColor: isDark ? '#22303c' : '#eee9ff', borderRadius: '15px', padding: '15px'
                    }}>
                        <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: '500', color: isDark ? '#f7f7f7' : '#8b5cf6' }}>Tokens:</Typography>
                        <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: '500', color: isDark ? '#f7f7f7' :  '#8b5cf6' }}>{user.tokens}/{user.guest ? process.env.REACT_APP_GUEST_TOKEN_LIMIT : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT}</Typography>
                    </Box>

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '6px' }}>Inställningar</Typography>
<Box 
  sx={{
    display: 'flex', 
    justifyContent: 'space-between', 
    marginBottom: '25px',
    alignItems: 'center', 
    backgroundColor: isDark ? '#22303c' : '#eee9ff', 
    borderRadius: '15px', 
    padding: '15px',
    cursor: 'pointer'  
  }}
onClick={() => {
    setOpen(true);
handlePopoverClose();
  }}
>
  <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: '500', color: isDark ? '#f7f7f7' : '#8b5cf6' }}>
    Inställningar
  </Typography>
  <NavigateNextIcon sx={{ color: isDark ? '#f7f7f7' : '#8b5cf6' }} />
</Box>

                    <Button startIcon={<LogoutIcon />} variant="contained" color="primary" onClick={() => { handleLogout(); handlePopoverClose(); }}>Logga ut</Button>
                </Box>
            )}
        </Popover>

 

        <FeedbackDialog 
open={feedbackDialogOpen}
    onClose={() => setFeedbackDialogOpen(false)} 
    user={user}
/>
    </>
);
};

const CustomIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </SvgIcon>
);


export default Sidebar;
