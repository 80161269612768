import { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { signOut, updateProfile, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { handleNewUserSignUp } from './userSetupUtils';
import { createUserWithEmailAndPassword as firebaseCreateUser, signInWithEmailAndPassword as firebaseSignIn } from 'firebase/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Logger from './Logger';
const guestUser = {
  uid: 'guest-user',
  displayName: 'Gäst',
  tokens: 0,
  photoURL: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fgraduated.png?alt=media&token=9bcc4230-9671-4553-930e-e11e95d7fe74',
  preferredTheme: 'system',
  guest: true,
  isTemporary: true  
};

export function useAuth() {
  const [user, setUser] = useState(guestUser);
  const [loading, setLoading] = useState(true);
  const [justLoggedIn, setJustLoggedIn] = useState(false);


  useEffect(() => {
    // Clean localStorage
    //localStorage.removeItem('guestId');
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        await loadUserData(authUser);
      
      } else {
        await loadGuestData();
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const loadUserData = async (authUser) => {
    await authUser.reload();
    const userDoc = doc(db, "users", authUser.uid);
    const docSnap = await getDoc(userDoc);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setUser({
        ...authUser,
        tokens: userData.tokens || 0,
        photoURL: authUser.photoURL || userData.photoURL,
        //photoURL: userData.photoURL || authUser.photoURL,
        displayName: userData.displayName,
        preferredTheme: userData.preferredTheme || 'system',
        language: userData.language || 'Svenska',
        guest: false,
        // Add other fields as needed
      });
    } else {
      setUser({
        ...authUser,
        tokens: 0,
        preferredTheme: 'system',
        language: 'Svenska',
        guest: false,
        // Add other default values
      });
    }

    setJustLoggedIn(true);
  };

  const loadGuestData = async () => {
    let guestId = localStorage.getItem('guestId');
    if (!guestId) {
      guestId = `guest-${uuidv4()}`;
      localStorage.setItem('guestId', guestId);
    }
    const userDoc = doc(db, "users", guestId);
    const docSnap = await getDoc(userDoc);
    Logger.log("local guestId: ", guestId);
    if (docSnap.exists()) {
      setUser({ uid: guestId, ...docSnap.data(), guest: true });
    } else {
      const newUser = { 
        uid: guestId, 
        displayName: 'Gäst',
        photoURL: 'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fgraduated.png?alt=media&token=9bcc4230-9671-4553-930e-e11e95d7fe74',
        tokens: 0, 
        guest: true,
        preferredTheme: 'system',
        language: 'Svenska'
      };
      await setDoc(userDoc, newUser);
      setUser(newUser);
      Logger.log("setUser runs: ", newUser);

      Logger.log("handleNewUserSignUp runs");
      await handleNewUserSignUp(guestId);
    }

  };

    // useEffect(() => {
    //     if (user.guest) {
    //         window.google.accounts.id.initialize({
    //             client_id: "981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com",
    //             callback: useGoogleLogin,
    //             auto_select: true,
    //         });
    //         window.google.accounts.id.prompt();
    //     }
    // }, [user.guest]);

  const updateUser = async (newData) => {
    if (auth.currentUser) {
      const userDoc = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDoc, newData);
      
      if (newData.displayName || newData.photoURL) {
        await updateProfile(auth.currentUser, {
          displayName: newData.displayName,
          photoURL: newData.photoURL
        });
      }
      await loadUserData(auth.currentUser);
      return auth.currentUser;
    } else if (newData.email) {
      // This is for converting a guest user to a registered user
      const guestId = localStorage.getItem('guestId');
      if (guestId) {
        const userDoc = doc(db, "users", guestId);
        await updateDoc(userDoc, { ...newData, guest: false });
        localStorage.removeItem('guestId');
        await loadUserData(auth.currentUser);
      }
    } else {
      // Handle update for guest users
      const guestId = localStorage.getItem('guestId');
      if (guestId) {
        const userDoc = doc(db, "users", guestId);
        await updateDoc(userDoc, newData);
        await loadGuestData();
      }
    }
  };

  const handleGoogleLogin = async (response) => {
    try {
      const credential = GoogleAuthProvider.credential(response.credential);
      const result = await signInWithCredential(auth, credential);
      const user = result.user;

      // Check if user document exists in Firestore
      const userDoc = doc(db, "users", user.uid);
      const docSnap = await getDoc(userDoc);

      if (!docSnap.exists()) {
        // Create new user document if it doesn't exist
        await setDoc(userDoc, {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          tokens: 0,
          preferredTheme: 'system',
          language: 'Svenska',
          guest: false,
        });
      }

      // Load user data
      await loadUserData(user);
      await handleNewUserSignUp(user.uid);
      
      Logger.log("User logged in successfully:", user);
    } catch (error) {
      console.error("Error handling Google login:", error);
    }
  };

const createUserWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await firebaseCreateUser(auth, email, password);
    const user = userCredential.user;
    
    // Skapa användardokument i Firestore
    const userDoc = doc(db, "users", user.uid);
    await setDoc(userDoc, {
      email: user.email,
      displayName: user.email.split('@')[0], // Sätt ett standard displayname
      tokens: 0,
      preferredTheme: 'system',
      language: 'Svenska',
    });

    await loadUserData(user);
      await handleNewUserSignUp(user.uid);
      Logger.log("User with email and pass successfully:", user);
    return user;
  } catch (error) {
    throw error;
  }
};

const signIn = async (email, password) => {
  try {
    const userCredential = await firebaseSignIn(auth, email, password);
    await loadUserData(userCredential.user);
    Logger.log("User with email and pass signed in con succé:", user);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       setUser(guestUser);

// window.location.href = '/login';
//     } catch (error) {
//       console.error('Error logging out:', error);
//     }
//   };




  return { user, loading, updateUser, handleGoogleLogin, createUserWithEmailAndPassword, signIn,
    justLoggedIn,
    setJustLoggedIn  };
}