import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { 
  Drawer, 
  Box, 
  Typography, 
  IconButton, 
  CircularProgress,
  Grid,
  Paper,
  Chip,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import { getStyles } from '../index';

const UserCollectionsDrawer = ({ user, theme, open, onClose, onStartTraining }) => {
  const [collections, setCollections] = useState([]);
  const [userCollectionsLoading, setUserCollectionsLoading] = useState(false);
  const [isManaging, setIsManaging] = useState(false);
  const styles = getStyles(theme);

  useEffect(() => {
    if (open) {
      fetchUserCollections();
    }
  }, [open, user.uid]);

  const fetchUserCollections = async () => {
    setUserCollectionsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'flashcardCollections'));
      const userCollections = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(doc => doc.userId === user.uid)
        .sort((a, b) => (b.lastTrainingSession || 0) - (a.lastTrainingSession || 0));
      setCollections(userCollections);
    } catch (error) {
      console.error('Fel vid hämtning av användarens samlingar:', error);
    } finally {
      setUserCollectionsLoading(false);
    }
  };

  const handleDeleteCollection = async (collectionId, event) => {
    event.stopPropagation();
    setCollections(prevCollections => prevCollections.filter(collection => collection.id !== collectionId));
    try {
      await deleteDoc(doc(db, 'flashcardCollections', collectionId));
    } catch (error) {
      console.error('Fel vid borttagning av samling:', error);
      fetchUserCollections();
    }
  };

  const toggleManaging = () => {
    setIsManaging(!isManaging);
  };

  const getTimeSinceLastTraining = (lastTrainingSession) => {
    if (!lastTrainingSession) return 'Aldrig tränat';
    const now = Date.now();
    const diff = now - lastTrainingSession;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) return `${seconds} sekund${seconds !== 1 ? 'er' : ''} sedan`;
    if (minutes < 60) return `${minutes} minut${minutes !== 1 ? 'er' : ''} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? 'ar' : 'e'} sedan`;
    
    const days = Math.floor(hours / 24);
    if (days === 1) return 'Igår';
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return '1 vecka sedan';
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return '1 månad sedan';
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const getFlashcardResults = (flashcards) => {
    const results = { easy: 0, good: 0, hard: 0, fail: 0 };
    flashcards.forEach(card => {
      if (card.correct === 'easy') results.easy++;
      else if (card.correct === 'good') results.good++;
      else if (card.correct === 'hard') results.hard++;
      else if (card.correct === 'fail') results.fail++;
    });
    return results;
  };

  const resultChips = [
    { name: 'Väldigt svåra', color: '#FF4D4D', key: 'fail', icon: CancelIcon },
    { name: 'Svåra', color: '#C47510', key: 'hard', icon: CircleIcon },
    { name: 'Ganska lätta', color: '#27792B', key: 'good', icon: CheckCircleIcon },
    { name: 'Lätta', color: '#2196F3', key: 'easy', icon: CheckCircleIcon },
  ];

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ width: { xs: '75%', sm: '50%', md: '30%' }, paddingTop: '0px' }}
      sx={{ '& .MuiDrawer-paper': { width: { xs: '100%', sm: '30%' }, padding: '20px' } }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dina samlingar</Typography>
        <IconButton onClick={onClose} edge="end">
<MenuOpenIcon />
        </IconButton>
      </Box>

      {userCollectionsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : collections.length === 0 ? (
        <Typography sx={{ fontStyle: 'italic', my: 2 }}>
          Du har inga samlingar än. Generera egna flashcards eller använd en samling genererad av en annan användare.
        </Typography>
      ) : (
       <Grid container spacing={2}>
          {collections.map((collection) => (
            <Grid item xs={12} key={collection.id}>
              <Box 
                sx={{ 
                   ...styles.beautyBtnBox,
                  width:'100%',
                  p: 2, 
                  margin:'0px',
                  cursor: 'pointer',

                  position:'relative',
                  borderRadius:{xs:'5px', md: '8px'},
                }}
                className={isManaging ? 'wiggle' : ''}
                onClick={() => onStartTraining(collection)}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: '500', fontSize:'15px' }}>
                      {collection.collectionName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
  <AccessTimeIcon sx={{ fontSize: '16px', marginRight:'5px' }} />
  {getTimeSinceLastTraining(collection.lastTrainingSession)} • &nbsp;{collection.flashcards.length} kort

</Typography>


                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb:1 }}>
                      {(() => {
                        const results = getFlashcardResults(collection.flashcards);
                        return resultChips.map((chip) => (
                          results[chip.key] > 0 && (
                            <Chip
                              key={chip.name}
                              label={`${chip.name}: ${results[chip.key]}`}
                              size="small"
                              icon={<chip.icon style={{ color: 'inherit' }} />}
                              sx={{ backgroundColor: chip.color, color: 'white',
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              fontSize:'12px'


                               }}
                            />
                          )
                        ));
                      })()}
                    </Box>


{collection.isCopied ? (
  <Typography variant="body2" color="text.secondary" sx={{ position:'absolute', top: '10px', right:'10px', fontStyle:'italic',}}>
    Kopierat
  </Typography>
) : (
  <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
    
  </Typography>
)}

                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', ml: 2 }}>
                    {isManaging ? (
                      <IconButton size="small" onClick={(e) => handleDeleteCollection(collection.id, e)}>
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {collections.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Button
            label={isManaging ? "Klar" : "Hantera samlingar"}
            onClick={toggleManaging}
            color="primary"
            variant="contained"
            sx={{ cursor: 'pointer' }}
          >
          Hantera
          </Button>
        </Box>
      )}
    </Drawer>
  );
};

export default UserCollectionsDrawer;