import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.6);
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-left: 8px;
  animation: ${pulse} 1s ease-in-out infinite;
`;

const PulsatingDot = () => {
  return <Dot />;
};

export default PulsatingDot;