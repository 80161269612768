import React, { useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';

const TeacherList = ({ teachers, handleTeacherClick, onDeleteTeacher, onAddTeacher, activeItem, isDark, onPublicTeachers, toggleSidebar, isMobile }) => {
  const [teacherMenuAnchorEl, setTeacherMenuAnchorEl] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const navigate = useNavigate();
  const [showAllTeachers, setShowAllTeachers] = useState(false);
  const theme = useTheme();
       const styles = getStyles(theme);

  const handleMoreClick = (event, teacherId) => {
    event.preventDefault();
    event.stopPropagation();
    setTeacherMenuAnchorEl(event.currentTarget);
    setSelectedTeacherId(teacherId);
  };

  const handleMenuClose = () => {
    setTeacherMenuAnchorEl(null);
    setSelectedTeacherId(null);
  };

  const handleDelete = () => {
    onDeleteTeacher(selectedTeacherId);
    handleMenuClose();
  };

  const handleEdit = () => {
    navigate(`/larare/${selectedTeacherId}`);
    handleMenuClose();
  };

  const toggleShowAllTeachers = () => {
    setShowAllTeachers(!showAllTeachers);
  };

  const visibleTeachers = showAllTeachers ? teachers : teachers.slice(0, 3);
  const hiddenTeachersCount = teachers.length - 3;

  const getLanguageText = (languageToLearn) => {
    const languageMap = {
      spanish: 'Spansklärare',
      french: 'Fransklärare',
      german: 'Tysklärare',
    };
    return languageMap[languageToLearn] || '';
  };

  return (
    <List sx={{
      padding: '7px',
      margin: 0,
    }}>
      <ListItem 
        component={Link}
        to="/larare"
        onClick={() => { if (isMobile) toggleSidebar(); }}  
        className="rounded"
        sx={{ marginBottom: '3px', paddingTop: '7px', paddingBottom: '7px', color: isDark ? 'white' : '#333' }}   
      >
        <ListItemIcon sx={{ minWidth: '32px', paddingRight: '8px'}}>
          <AddIcon sx={{ color: isDark ? '#1097DA' : '#8b5cf6'}} />
        </ListItemIcon>
        <ListItemText 
          primary="Lägg till lärare" 
          sx={{ marginLeft: '10px' }}
          primaryTypographyProps={{ fontSize: '16px', fontWeight:'400' }} 
        />
      </ListItem>

      <ListItem 
        component={Link}
        to="/personal"
        onClick={() => { if (isMobile) toggleSidebar(); }}        
        className="rounded"

        sx={{ marginBottom: '3px', paddingTop: '7px', paddingBottom: '7px', color: isDark ? 'white' : '#333' }}   
      >
        <ListItemIcon sx={{ minWidth: '28px', paddingRight: '6px'}}>
          <ExploreIcon sx={{ color: isDark ? '#1097DA' : '#8b5cf6', fontSize:'26px'}} />
        </ListItemIcon>
        <ListItemText 
          sx={{ marginLeft: '10px' }}
          primary="Utforska" 
          primaryTypographyProps={{ fontSize: '16px', fontWeight:'400'  }} 
        />
      </ListItem>

      {visibleTeachers.map((teacher) => (
        <ListItem 
  onClick={(event) => {
    if (!event.defaultPrevented) {
      if (isMobile) toggleSidebar();
      navigate(`/chat/${teacher.id}`);
    }
  }}
          className="rounded"
          sx={{
            marginBottom: '3px',
            paddingTop: '7px',
            paddingBottom: '7px',
            cursor: 'pointer',
            color: isDark ? 'white' : '#333',
            fontSize: '16px', fontWeight:'400',
            '&:hover .more-icon, .more-icon.visible': {
              opacity: 1,
              visibility: 'visible',
            },
            '&:hover': {
              '& .MuiListItemIcon-root': {
                color: '#8b5cf6',
              },
              '& .MuiTypography-root': {
                color: isDark ? '#fff' : '#000',
                fontWeight: '400',
              },
            },
          }}
          key={teacher.id} 
          secondaryAction={
            <IconButton 
              edge="end" 
    onClick={(event) => {
        event.preventDefault();  // Prevent default, but don't stop propagation
        handleMoreClick(event, teacher.id);
      }}
              className={`more-icon ${teacherMenuAnchorEl ? 'visible' : ''}`}
              sx={{ 
                opacity: 0,
                visibility: 'hidden',
                transition: 'opacity 0.2s, visibility 0.2s',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <MoreHorizIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          }
        >
          {teacher.imageUrl && (
            <ListItemAvatar sx={{ minWidth: 'auto', mr: 1}}>
              <Avatar src={teacher.imageUrl} alt={teacher.characterName} sx={{width:'25px', height:'25px', mr:0}} />
            </ListItemAvatar>
          )}
          <ListItemText 
            primary={teacher.category === "Språk" && teacher.languageToLearn ? getLanguageText(teacher.languageToLearn) : teacher.characterName}
            primaryTypographyProps={{ fontSize: '15px' }} 
            sx={{ marginLeft: '10px' }} 
          />
        </ListItem>
      ))}

      {teachers.length > 3 && (
        <ListItem 
          button
          onClick={toggleShowAllTeachers}
          className="rounded"
          sx={{ marginBottom: '3px', paddingTop: '7px', paddingBottom: '7px' }}   
        >
          <ListItemIcon sx={{ minWidth: '32px', paddingRight: '8px'}}>
            <MoreHorizIcon sx={{ color: '#94a3b8' }} />
          </ListItemIcon>
          <ListItemText 
            primary={showAllTeachers ? "Visa färre" : `${hiddenTeachersCount} lärare till`}
            sx={{ marginLeft: '10px' }}
            primaryTypographyProps={{ 
              fontSize: '15px',
              color: '#94a3b8 !important'
            }} 
          />
          {showAllTeachers ? <ArrowDropUpIcon sx={{ color: '#94a3b8' }} /> : <ArrowDropDownIcon sx={{ color: '#94a3b8' }} />}
        </ListItem>
      )}

      <Menu
        anchorEl={teacherMenuAnchorEl}
        open={Boolean(teacherMenuAnchorEl)}
        onClose={handleMenuClose}
               PaperProps={{
                sx: {
                  ...styles.roundMenu,
                  borderRadius: '8px',
                  '& ul': {
                    border: theme.palette.mode === 'dark' ? '1px solid #35414db2' : '1px solid #eee',
                    padding: '0px',
                    paddingTop:'10px',
                    paddingBottom:'10px',
                    borderRadius: '8px' 
                  }
                }
              }}
      >
        <MenuItem onClick={handleEdit} sx={styles.menuItemStyle}>
          <EditIcon fontSize="small" style={{ marginRight: 8 }} />
          Redigera
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={styles.menuItemStyle}>
          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
          Radera
        </MenuItem>
      </Menu>
    </List>
  );
};

export default TeacherList;