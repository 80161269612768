import React, { useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/LightbulbOutlined';
import { callApi } from './api'; // Anta att detta är din API-anropsfunktion
import Logger from './Logger';
const Hint = ({ front, back, question, answer, user, theme }) => {
  const [hint, setHint] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGetHint = async () => {
    if (hint) {
      setHint('');
      return;
    }

    setLoading(true);
    try {
      let content;
      if (front && back) {
        content = `Flashcard front: ${front}\nFlashcard back: ${back}`;
      } else if (question && answer) {
        content = `Question: ${question}\nAnswer: ${answer}`;
      } else {
        throw new Error('Invalid props provided');
      }

      const messages = [
        { role: 'system', content: 'Generera en ledtråd på max 12 ord baserat på följande flashcard eller fråga/svar. Skriv ALLTID på svenska. Ge en hjälpsam men inte för avslöjande ledtråd. Följ denna struktur varje gång: [{ "clue": "the clue"}]' },
        { role: 'user', content }
      ];
      const response = await callApi(messages, undefined, true, user.uid);
      Logger.log("response", response.data);



  if (response && response.data && response.data.choices && response.data.choices.length > 0) {
        const hintContent = response.data.choices[0].message.content;
        try {
          const parsedHint = JSON.parse(hintContent);
          if (Array.isArray(parsedHint) && parsedHint.length > 0 && parsedHint[0].clue) {
            setHint(parsedHint[0].clue);
          } else {
            throw new Error('Unexpected hint format');
          }
        } catch (parseError) {
          console.error('Error parsing hint:', parseError);
          throw new Error('Failed to parse hint response');
        }
      } else {
        throw new Error('Unexpected API response structure');
      }



    } catch (error) {
      console.error('Error fetching hint:', error);
      setHint('Kunde inte hämta ledtråd. Försök igen senare.');
    } finally {
      setLoading(false);
    }
  };

  return (
   <Box
      onClick={handleGetHint}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.palette.mode === 'dark' ? 'white' : '#444',  // Sätt texten och ikonen till vit
      }}
    >
      <LightbulbIcon sx={{ mr: 0.6, fontSize: '20px' }} />  {/* Ändrade färgen till vit (ärver från Box) och minskade storleken */}
      {loading ? (
        <CircularProgress size={13} sx={{ color: theme.palette.mode === 'dark' ? 'white' : '#444' }} /> 
      ) : (
        <Typography
          sx={{
            fontSize: '0.87em',  // Minskade textstorleken (14px om base fontSize är 16px)
            fontWeight: 600,  // Satte fontWeight till 500 för en fetare text
          }}
        >
          {hint || 'Ledtråd'}
        </Typography>
      )}
    </Box>
  );
};

export default Hint;