// logger.js
const isProduction = process.env.NODE_ENV === 'production';
const showLogs = process.env.REACT_APP_DEBUG_MODE === 'true';

const logger = {
  log: (...args) => {
    if (!isProduction || showLogs) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (!isProduction || showLogs) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    // Always log errors, even in production
    console.error(...args);
  },
  info: (...args) => {
    if (!isProduction || showLogs) {
      console.info(...args);
    }
  },
  debug: (...args) => {
    if (!isProduction && showLogs) {
      console.debug(...args);
    }
  }
};

export default logger;