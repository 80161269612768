
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import LooksFiveIcon from '@mui/icons-material/Looks5';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import BookmarkIcon from '@mui/icons-material/Bookmark';


const QuestionNavigation = ({ 
  handlePreviousQuestion, 
  handleNextQuestion, 
  handleSaveQuestion, 
  handleAskAI, 
  isSaved, 
  currentQuestionIndex, 
  currentSectionIndex, 
  theme,
  styles
}) => (
  <>
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handlePreviousQuestion} 
        startIcon={<KeyboardArrowLeftIcon />}

        sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 }, width: { xs: '60%', md: 'inherit' } }}
        disabled={currentQuestionIndex === 0 && currentSectionIndex === 0}
      >
        Föregående
      </Button>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleNextQuestion} 
        startIcon={<KeyboardArrowRightIcon />}
        sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 }, width: { xs: '60%', md: 'inherit' } }}
      >
        Nästa
      </Button>


      <Button 
        variant="contained"
        color="primary" 
        sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 }, width: { xs: '60%', md: 'inherit' } }}
        disabled={isSaved}
        onClick={handleSaveQuestion}
        startIcon={<BookmarkIcon />}

      >
        {isSaved ? "Sparad!" : "Spara"}
      </Button>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleAskAI}
        startIcon={<GenerateIcon />}
sx={{backgroundColor:'#791373', color:'white', border: theme.palette.mode === 'dark' ? 'undefined' : 'none' ,borderColor:'#591355'}}


      >
        Fråga AI
      </Button>
    </Box>
    <Box sx={{
      backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff', 
      borderRadius:'10px', 
      width:'80%', 
      padding:'10px', 
      margin:'0 auto', 
      marginTop:'80px', 
      marginBottom:'40px',
      border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #d9d9d9' 
    }}>
      <Typography style={styles.h4} sx={{ textAlign:'center', marginTop:'10px' }}>
        <strong>Genvägar:</strong> <br /><br />
        <KeyboardArrowUpIcon />
        <KeyboardArrowDownIcon />
        <KeyboardArrowLeftIcon />
        <KeyboardArrowRightIcon />
        <SpaceBarIcon />
        <KeyboardReturnIcon />
        <LooksOneIcon />
        <LooksTwoIcon />
        <LooksThreeIcon />
        <LooksFourIcon />
        <LooksFiveIcon />

          </Typography>
             <Typography style={styles.h6} sx={{ textAlign:'center', marginTop:'15px', mb:3, fontSize:'14px' }}>
             (F = Fråga AI, Ö = Översikt, S = Spara)
</Typography>   
    </Box>
  </>
);

export default QuestionNavigation;