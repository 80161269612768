import React, { useState } from 'react';
import { Box, Typography, Button, Paper, Chip, Avatar, Modal, Card, CardContent, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import FlipIcon from '@mui/icons-material/Flip';

const TrainingSummary = ({ sessionSummary, currentCollection, startTraining, theme, styles }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showBack, setShowBack] = useState(false);

  const totalCards = Object.values(sessionSummary).reduce((a, b) => a + b, 0);
  
  const categories = [
    { name: 'Väldigt svåra', color: '#FF4D4D', count: sessionSummary.fail, icon: CancelIcon },
    { name: 'Svåra', color: '#FFA500', count: sessionSummary.hard, icon: CircleIcon },
    { name: 'Ganska lätta', color: '#4CAF50', count: sessionSummary.good, icon: CheckCircleIcon },
    { name: 'Lätta', color: '#2196F3', count: sessionSummary.easy, icon: CheckCircleIcon },
  ];

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setShowBack(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCard(null);
  };

  const handleFlipCard = () => {
    setShowBack(!showBack);
  };

  return (
    <>
      <Typography variant="h4" sx={{ ...styles.header, marginBottom: 2, marginTop:4 }}>Träningssammanfattning</Typography>
      <Box sx={{...styles.darkBorder, padding:'20px', backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white' , border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091', borderRadius: {xs: '0px', md: '8px'}, }} >
        <Box sx={{textAlign:'center', mb:4, fontWeight:'bold'}}>
{currentCollection.collectionName}
</Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          {categories.map((category) => (
            <Box key={category.name} sx={{ textAlign: 'center', mx: 2 }}>
              <Avatar sx={{ bgcolor: category.color, mb: 1, mx: 'auto' }}>
                <category.icon />
              </Avatar>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {category.name}
              </Typography>
              <Typography variant="h6">{category.count}</Typography>
            </Box>
          ))}
        </Box>
        
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Prestationsöversikt</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box sx={{ flexGrow: 1, mr: 2 }}>
              <Box sx={{ 
                height: '24px', 
                borderRadius: '12px', 
                overflow: 'hidden',
                display: 'flex'
              }}>
                {categories.map((category) => (
                  <Box 
                    key={category.name}
                    sx={{ 
                      width: `${(category.count / totalCards) * 100}%`, 
                      bgcolor: category.color,
                      height: '100%'
                    }}
                  />
                ))}
              </Box>
            </Box>
            <Typography variant="body2" sx={{ minWidth: '60px', textAlign: 'right' }}>
              {totalCards} kort
            </Typography>
          </Box>
        </Box>
        
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Detaljerad översikt</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {currentCollection.flashcards.map((card, index) => (
              <Chip 
                key={index}
                label={`Kort ${index + 1}`}
                color={
                  card.correct === 'fail' ? 'error' :
                  card.correct === 'hard' ? 'warning' :
                  card.correct === 'good' ? 'success' :
                  'primary'
                }
                variant="outlined"
                onClick={() => handleCardClick(card)}
                sx={{ cursor: 'pointer' }}
              />
            ))}
          </Box>
        </Box>
        
        <Box sx={{textAlign:'center'}}>
          <Button
            variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
            onClick={() => startTraining(currentCollection)}
            sx={{ 
              borderRadius:'30px',
              textTransform: 'none',
              fontWeight:'bold'
            }}
          >
            Träna på samlingen igen!
          </Button>
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="card-modal-title"
        aria-describedby="card-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: theme.palette.background.paper,
          boxShadow: 24,
          p: 4,
          borderRadius: '16px',
        }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Card sx={{ minWidth: 275, position: 'relative' }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                {showBack ? 'Baksida' : 'Framsida'}
              </Typography>
              <Typography variant="body1">
                {showBack ? selectedCard?.back : selectedCard?.front}
              </Typography>
            </CardContent>
            <IconButton
              aria-label="flip"
              onClick={handleFlipCard}
              sx={{
                position: 'absolute',
                right: 8,
                bottom: 8,
              }}
            >
              <FlipIcon />
            </IconButton>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default TrainingSummary;