import React from 'react';
import { styled } from '@mui/system';

const BlurryBackdrop = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(3px)',
  zIndex: 1200,
});

export default BlurryBackdrop;