import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where, getDoc, doc, addDoc } from 'firebase/firestore';
import { 
  Drawer, 
  Box, 
  Typography, 
  IconButton, 
  CircularProgress,
  Avatar,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import { getStyles } from '../index';
import { useSnackbar } from '../SnackbarHandler';
import { useNavigate } from 'react-router-dom';

const PublicCollectionsDrawer = ({ user, theme, open, onClose, onStartTraining, setCollections, setShowLibrary, setRefreshTrigger }) => {
  const [publicCollections, setPublicCollections] = useState([]);
  const [publicCollectionsLoading, setPublicCollectionsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      fetchPublicCollections();
    }
  }, [open]);

  const fetchPublicCollections = async () => {
    setPublicCollectionsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'flashcardCollections'));
      const publicCollections = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(doc => doc.isPublic === true);
      setPublicCollections(publicCollections);

      // Fetch user info
      const userIds = [...new Set(publicCollections.map(collection => collection.userId).filter(Boolean))];
      const userInfoObj = {};
      for (const userId of userIds) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          userInfoObj[userId] = {
            displayName: userData.displayName || userData.email || 'Okänd',
            avatarUrl: userData.photoURL || null
          };
        }
      }
      setUserInfo(userInfoObj);
    } catch (error) {
      console.error('Error fetching public collections:', error);
    } finally {
      setPublicCollectionsLoading(false);
    }
  };

  const formatUrlPart = (str) => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const copyAndStartTraining = async (publicCollection) => {
    onClose();
    try {
      // Check if the user is trying to copy their own collection
      if (publicCollection.userId === user.uid) {
        showSnackbar('Du kan inte kopiera din egen samling.', 'info');
        onStartTraining(publicCollection);
        navigate(`/flashcards/${publicCollection.id}/${formatUrlPart(publicCollection.collectionName)}`);
        return;
      }

      // Check if the collection has already been copied
      const q = query(
        collection(db, 'flashcardCollections'),
        where('userId', '==', user.uid),
        where('originalCollectionId', '==', publicCollection.id)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Collection has already been copied
        showSnackbar('Du har redan kopierat denna samling.', 'info');
        const copiedCollection = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        onStartTraining(copiedCollection);
        navigate(`/flashcards/${copiedCollection.id}/${formatUrlPart(copiedCollection.collectionName)}`);
        return;
      }

      // If not copied and not owned by the user, proceed with copying
      const { id, ...collectionWithoutId } = publicCollection;
      const newCollection = {
        ...collectionWithoutId,
        userId: user.uid,
        isPublic: false,
        isCopied: true,
        originalCollectionId: id,
        originalUserId: collectionWithoutId.userId
      };

      const docRef = await addDoc(collection(db, 'flashcardCollections'), newCollection);
      const copiedCollection = { id: docRef.id, ...newCollection };

      setCollections(prevCollections => [...prevCollections, copiedCollection]);
      onStartTraining(copiedCollection);
      setShowLibrary(false);
      setRefreshTrigger(prev => prev + 1);
      navigate(`/flashcards/${copiedCollection.id}/${formatUrlPart(copiedCollection.collectionName)}`);
      showSnackbar('Samling kopierad framgångsrikt!', 'success');
    } catch (error) {
      console.error('Error copying collection:', error);
      showSnackbar('Ett fel uppstod vid kopiering av samlingen.', 'error');
    }
  };

    return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ width: { xs: '75%', sm: '50%', md: '30%' }, paddingTop: '0px' }}
      PaperProps={{ sx: { width: { xs: '75%', sm: '50%', md: '30%' }, padding: '20px', paddingTop: '0px' } }}
    >
      <Box sx={styles.flexContainerSidebar}>
        <Typography variant="h6" sx={{...styles.header, ...styles.headerAboveBox}}>Bibliotek</Typography>
        <IconButton onClick={onClose} color="inherit">
          <MenuOpenIcon />
        </IconButton>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Box sx={styles.buttonsContainer}>
          {publicCollectionsLoading ? (
            <CircularProgress size={16} />
          ) : publicCollections.length === 0 ? (
            <Box sx={{ display: 'flex', textAlign: 'left' }}>
              <Typography sx={{fontStyle:'italic'}}>
                Det finns inga publika samlingar tillgängliga för tillfället.
              </Typography>
            </Box>
          ) : (
            publicCollections.map(collection => (
              <Box
                key={collection.id}
                onClick={() => copyAndStartTraining(collection)}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '15px',
                  marginBottom: '5px',
                  borderRadius: '10px',
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgb(251, 251, 251)',
                  border: theme.palette.mode === 'dark' ? '1px solid #58646f' : '1px solid #f2f2f2',

                  cursor: 'pointer',
                  "&:hover": {
                    border: theme.palette.mode === 'dark' ? '1px solid #717a82' : '1px solid #d7d3d3'
                  }
                }}
              >
                <Typography sx={{ fontSize: {xs:'16px', md:'18px'}, fontWeight: '500', marginBottom: '10px', fontSize:'17px' }}>
                  {collection.collectionName}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar 
                      src={userInfo[collection.userId]?.avatarUrl || null}
                      sx={{ width: 20, height: 20, mr: 1, bgcolor: theme.palette.primary.main }}
                    >
                      {(userInfo[collection.userId]?.displayName || 'O')[0].toUpperCase()}
                    </Avatar>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {userInfo[collection.userId]?.displayName || 'Okänd'}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {collection.flashcards.length} kort
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(65, 191, 19, 0.1)', borderRadius: '20px', px: 1, py: 0.5 }}>
                      <ThumbUpOutlinedIcon sx={{ fontSize: 16, mr: 0.5, color: '#41bf13' }} />
                      <Typography variant="body2" sx={{ color: '#41bf13', fontWeight: 'bold' }}>{collection.upvotes || 0}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default PublicCollectionsDrawer;