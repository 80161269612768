


// index.js
import React, {useState, useCallback, useEffect} from 'react';


import { createRoot } from 'react-dom/client';
import { CssBaseline, ThemeProvider, createTheme, useTheme } from '@mui/material';
import App from './App'; // Importera din App-komponent från App.js
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { SnackbarProvider } from './SnackbarHandler';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAuth } from './useAuth'; // Import the custom hook

import 'mafs/core.css';
import 'mafs/font.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';



const theme = createTheme({
  typography: {
    fontFamily:
      'Poppins, Open Sans, -apple-system, system-ui, ui-sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Poppins, sans-serif',

formLabel: {
      borderBottom: '1px dotted black',
      paddingBottom: '1px',
      display: 'inline-block',

    },

  },
  palette: {
    mode: 'light',
    background: {
      default: '#f1f5f9',
    },
    text: {
      primary: '#333333', // Set your desired default text color here
    },
  },
  components: {
    MuiMenu: {
      defaultProps: {
        TransitionProps: {
          timeout: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& ul, & ol': {
            margin: '0 !important',
            padding: '0',
          },
          '& ul': {
            paddingLeft: '20px', // Anpassa vänster marginal för punkterade listor
            paddingTop: '10px',
          },
          '& ol': {
            paddingLeft: '20px', // Anpassa vänster marginal för ordnade listor
          },
          // Stilar för <li> inom <ul> och <ol>
          '& li': {
            margin: '0px', // Avstånd mellan listobjekt
            paddingTop: '5px',
            '& p': {
              margin: '0',
              padding: '0',
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 0,
        leaveDelay: 0,
        enterTouchDelay: 0,
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          fontSize: '13px', // Ändra fontstorleken här
          padding: '8px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f1f5f9',
        },
      },
    },
MuiListItem: {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        color: '#8b5cf6',
        backgroundColor: '#eee9ff',
      },
      '&.Mui-selected:hover': {
        color: '#8b5cf6',
        backgroundColor: '#eee9ff',
      },
      '&:hover': {
        color: '#8b5cf6',
        backgroundColor: '#eee9ff',
        borderRadius: '0px !important'
      },
      '&:hover .MuiListItemIcon-root': {
        color: '#8b5cf6',
        backgroundColor: '#eee9ff'
      },
      '&.rounded': {
        borderRadius: '20px !important',
        '&:hover': {
          borderRadius: '20px !important'
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          borderRadius: '20px !important'
        }
      }
    },
  },
},
 MuiButton: {
  styleOverrides: {
    containedPrimary: {
      color: '#32324d',
      backgroundColor: 'white',
      border: '1px solid #e2e8f0',
      borderRadius: '8px',
      boxShadow: 'none', // Ta bort skuggan
      '&:hover': {
        borderColor: '#8d37ff',
        color: '#8d37ff',
        backgroundColor: 'white',
        boxShadow: 'none', // Ta bort skuggan
      },
    },
    containedSecondary: {
      color: '#d32f2f',
      backgroundColor: 'white',
      border: '1px solid #e2e8f0',
      borderRadius: '8px',
      boxShadow: 'none', // Ta bort skuggan
      '&:hover': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
        backgroundColor: 'white',
        boxShadow: 'none', // Ta bort skuggan
      },
    },
     outlined: {
color: '#32324d',
      backgroundColor: '#f9f9f9',
      border: '1px solid #e2e8f0',
      borderRadius: '8px',
      boxShadow: 'none', // Ta bort skuggan
      '&:hover': {
        borderColor: '#8d37ff',
        color: '#8d37ff',
        backgroundColor: 'white',
        boxShadow: 'none', // Ta bort skuggan
      },
    },
  },
  variants: [
    {
      props: { variant: 'purp' },
      style: {
        backgroundColor: '#8b5cf6',
        color: 'white',
        border: '1px solid transparent',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#6029dd',
        },
        '&:disabled': {
          backgroundColor: '#c4b5fd', // En ljusare nyans av lila för inaktiverat läge
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
    },
  ],
},











MuiTextField: {

  styleOverrides: {
    root: {
      backgroundColor: '#f0f0f03d',
      borderRadius: '15px',
      '& .MuiInputBase-root': {
        color: '#333',
      },
      '& .MuiInputLabel-root': {
        color: '#333',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#ededed',
          borderRadius: '15px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#d9d9d9',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#ededed',
        },
      },
    },
  },
},







MuiSelect: {
  styleOverrides: {
    root: {
      backgroundColor: '#f0f0f03d',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ededed',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#80808069',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#80808069',
      },
    },
  },
},
MuiOutlinedInput: {
  styleOverrides: {
    root: {
      borderRadius: '15px',
    },
    notchedOutline: {
      borderWidth: '1px',
      borderRadius: '15px',
    },
  },
},

  MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px', // Sätt din önskade border radius här


        },
      },
    },

    MuiDialogActions: {
  styleOverrides: {
    root: {
      margin: '20px',
    },
  },
},


},
});

const darkTheme = createTheme({



  typography: {
    fontFamily: 'Poppins, Open Sans, -apple-system, system-ui, ui-sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Poppins, sans-serif',


formLabel: {
      borderBottom: '1px dotted white',
      paddingBottom: '1px',
      display: 'inline-block',

    },

    formLabelSinUnderlined: {
      paddingBottom: '1px',
      display: 'inline-block',
    },

  },
  palette: {
    mode: 'dark',
    background: {
      default: '#15202B',
      paper: '#192734',
    },
    primary: {
      main: '#1DA1F2',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8899A6',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: '0.875rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
        },
        filledSuccess: {
          backgroundColor: '#33882b',
          color: '#fff !important',
       
        },
        filledError: {
          backgroundColor: '#d32f2f',
          color: '#fff',
        },
        filledWarning: {
          backgroundColor: '#ed6c02',
          color: '#fff',
        },
        filledInfo: {
          backgroundColor: '#0288d1',
          color: '#fff',
        },
      },
    },




     MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px', // Sätt din önskade border radius här


        },
      },
    },

    MuiDialogActions: {
  styleOverrides: {
    root: {
      margin: '20px',
    },
  },
},
MuiTextField: {
  styleOverrides: {
    root: {
      backgroundColor: '#1c262f',
      borderRadius: '15px',
      '& .MuiInputBase-root': {
        color: '#FFFFFF',
      },
      '& .MuiInputLabel-root': {
        color: '#8899A6',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#3b4651',
          borderRadius: '15px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#1DA1F2',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#1DA1F2',
        },
      },
    },
  },
},


  MuiSelect: {
    styleOverrides: {
  root: {
      backgroundColor: '#1c262f',
      borderRadius: '15px',
      '& .MuiInputBase-root': {
        color: '#FFFFFF',
      },
      '& .MuiInputLabel-root': {
        color: '#8899A6',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#3b4651',
          borderRadius: '15px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#1DA1F2',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#1DA1F2',
        },
      },
    },
      icon: {
        color: '#FFFFFF',
      },
      selectMenu: {
        backgroundColor: '#1c262f',
        color: '#FFFFFF',
      },
    },
  },





    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          textTransform: 'none',
          fontWeight: 600,
          transition: 'background-color 0.3s',
        },
        contained: {
          backgroundColor: '#1097DA',

          borderBottom: '3px solid rgba(37, 37, 37, 0.3);',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#1A91DA',
          },
          '&:active': { 
          borderBottom: '0px solid rgba(37, 37, 37, 0.3);',

          },
          '&:disabled': {
            backgroundColor: '#38444D',
            color: '#8899A6',
          },
        },

outlined: {
  backgroundColor: '#3a454f',
  borderColor:'transparent',
  borderBottom: '3px solid #4f5b66',

  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#3a454f',
      borderColor:'transparent',
    borderBottom: '3px solid #4f5b66',
  },
  '&:active': { 
    backgroundColor: '#3a454f',
  },
  '&:disabled': {
    backgroundColor: '#384755',

    color: '#8899A6',
  },
},


        startIcon: {
          marginRight: '8px',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 0,
        leaveDelay: 0,
        enterTouchDelay: 0,
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          fontSize: '13px', // Ändra fontstorleken här
          padding: '10px',
                backgroundColor: 'rgba(97, 97, 97, 1)', // Fully opaque dark gray
        },
      },
    },
 MuiList: {
  styleOverrides: {
    root: {
      padding: 0,
      backgroundColor: '#192734',
    },
  },
},
MuiListItem: {
  styleOverrides: {
    root: {
      padding: '12px 16px',
      transition: 'background-color 0.2s',

      '&:hover': {
        backgroundColor: '#22303C',
        // Ensure text color within this item becomes white on hover
        '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
          color: '#fff',
        },
      },
      
      '&.rounded': {
        borderRadius: '20px !important',
        '&:hover': {
          borderRadius: '20px !important'
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          borderRadius: '20px !important'
        }
      }
    },
  },
},
MuiListItemIcon: {
  styleOverrides: {
    root: {
      color: '#1DA1F2',
      minWidth: '40px',
    },
  },
},
MuiListItemText: {
  styleOverrides: {
    primary: {
      color: '#FFFFFF',
    },
    secondary: {
      color: '#8899A6',
    },
  },
},

  },
});



export { theme, darkTheme };


const createStyles = (theme) => ({



promptBtn: {


                    color:theme.palette.mode === 'dark' ? 'white'  : '#606266',
                    textTransform: 'none',
                    backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : 'white',
                    border: 'none',
                    borderRadius: 10,
                    padding: '4px 14px',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom:'80px',
                    left:'25px',
                    '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#3b4651' : '#f1f1f1',
                        color:  theme.palette.mode === 'dark' ? 'white' : '#505050',
                        border: 'none',
                        boxShadow: 'none',
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.mode === 'dark' ? 'white' : '#505050',
                        },
                    },
                    '&:focus': {
                        outline: 'none',
                        border: 'none',
                        boxShadow: 'none',
                    }

},


dashboardBox: {

                        boxShadow:'none', 
                      width:'100%',
                      margin:'0px',
                      padding:'0px',
                      transition: 'background-color 0.3s',
                      border: theme.palette.mode === 'dark' ? '1px solid #28343e' : '1px solid #f2f2f291',
                      backgroundColor: theme.palette.mode === 'dark' ? '#15202b5e' : '#ececec1c',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(21, 32, 43)' : theme.palette.action.hover,
                      },
},

iconBox: {

  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 34,
                  height: 34,
                  borderRadius: '8px',
                  backgroundColor:  theme.palette.mode === 'dark' ? '#15202b' : '#f1f5f9',
                  marginRight: 1.2,
                  flexShrink: 0,
},

dashboardHeader: {
fontSize: '18px',
                  lineHeight: 1,
                  margin: 0,
                  padding: 0,
                  marginTop:'0px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  marginLeft:'0px'
},

smallBox: {

      backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff', 
      borderRadius:'10px', 
      width:'100%', 
      padding:'10px', 

      marginTop:'40px', 
      marginBottom:'40px',
      border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #ededed' 

},

rowBg: {
  borderBottom:'1px solid #384959',
background: theme.palette.mode === 'dark' ? '#25323f' : '#f9f9f9', marginBottom:'2px', borderRadius:'0px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'10px', paddingBottom:'10px' 
},

catBtn: {

  fontWeight: 'normal',
                fontSize: '15px',
                backgroundColor: theme.palette.mode === 'dark' ? '#2f3d4a' : '#fff',
                border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#ededed'}`,
                paddingTop: {xs:'2px', md: '8px'},
                paddingBottom: {xs:'2px', md: '8px'},
                borderRadius:{xs:'30px', md: '5px'},
                fontWeight:'500',
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#555',
                "&:hover, &:focus, &:active, &.Mui-focusVisible": {
                  border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#f9f9f9'}`,
                  backgroundColor: theme.palette.mode === 'dark' ? '#384755' : '#ededed',
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                },
},


whiteBg: {
  background: theme.palette.mode == 'dark' ? undefined : 'white'
},
  flexContainerSidebar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  filterButton: {
    marginRight: '6px',
    textTransform: 'normal',
  },
  unfamiliar: {
    backgroundColor: theme.palette.mode === 'dark' ? '#661919' : '#FFEBEB',
    color: theme.palette.mode === 'dark' ? '#FF4D4D' : '#FF4D4D',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '20px',
    fontWeight: 'bold',
  },
  stillLearning: {
    backgroundColor: theme.palette.mode === 'dark' ? '#664511' : '#FFEFD5',
    color: theme.palette.mode === 'dark' ? '#FFA500' : '#FFA500',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '20px',
    fontWeight: 'bold',
  },
  familiar: {
    backgroundColor: theme.palette.mode === 'dark' ? '#204E5B' : '#E0F7FA',
    color: theme.palette.mode === 'dark' ? '#00BFFF' : '#00BFFF',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '20px',
    fontWeight: 'bold',
  },
  mastered: {
    backgroundColor: theme.palette.mode === 'dark' ? '#204D20' : '#E0FFE0',
    color: theme.palette.mode === 'dark' ? '#00FF00' : '#00FF00',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '20px',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative',
  },
  innerContainer: {
    paddingRight:  {xs: '10px', md: '24px'},
    paddingLeft:  {xs: '10px', md: '0px'},
    paddingBottom: '50px',
  },
  h2: {
    fontSize: '16px',
    color: theme.palette.mode === 'dark' ? 'rgb(219, 219, 219)'  : '#555',
    marginBottom: '10px',
  },
  h4: {
    fontSize: '14px',
    color: theme.palette.mode === 'dark' ? 'rgb(219, 219, 219)'  : '#444',
    marginBottom: '10px',
  },
  h5: {
    fontSize: '14px',
    color: theme.palette.mode === 'dark' ? 'rgb(219, 219, 219)'  : '#444',
    marginBottom: '10px',
  },
  h3: {
    fontSize: '22px',
    fontWeight: '600',
    marginBottom: '00px',
  },
  flashcardFrontBackInput: {
    backgroundColor: 'white',
    '& .MuiInputBase-input': {
      paddingTop: '35px', // Adjust padding if necessary
      paddingBottom: '0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },


  menuItemStyle: {
    fontSize:'14px',
        '& .MuiListItemText-primary': {
            fontSize: '14px', // Adjust this value to your desired font size in pixels
        },
        '& .MuiListItemIcon-root': {
            minWidth: '30px', // Adjust this to control the space for the icon
        },
        '& .MuiSvgIcon-root': {
            fontSize: '20px', // Adjust this value to your desired icon size in pixels
        },
    },


  header: {
    marginBottom:2,
    fontWeight: 'bold',
    fontSize: {xs: '18px', md: '25px'},
    marginTop: {xs: 2.2, md:3},
    marginLeft: {xs: 6, md:0}
  },
  headerAboveBox: {
    marginBottom:1,
    fontWeight: 'bold',
    fontSize: {xs: '18px', md: '25px'},
    marginTop: {xs: 2.2, md:3},
    marginLeft: 0
  },
  headerChat: {
    fontWeight: 'bold',
    fontSize: '25px',
    marginTop: 3,
    paddingBottom: 3,
    borderBottom: '1px solid #e0e0e0',
  },
  buttonsContainer: {
    display: 'flex',
    gap: 1,
    flexWrap: 'wrap',
    paddingBottom: 2,

    borderBottom: theme.palette.mode === 'dark' ? '1px solid #3b4651' : '1px solid #e0e0e0',
  },

additionalButtonsContainer: {

 marginTop: {xs:'2px', md: '15px'}, marginBottom:'0px', position:'absolute', right: {xs: '6px', md: '18px'}, top:'5px', borderBottom:'0px', display: 'flex', alignItems: 'center'
},

  textarea: {
    width:'100%'
  },
  messageContainer: {
    flex: 1,
    overflowY: 'auto',
    marginBottom: '120px',
    marginTop: '0px',
  },
  loaderContainer: {
    marginTop: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    padding: 2,
    display: 'flex',
    gap: 1,
    position: 'fixed',
    bottom: 0,
    left: 'calc(19% + 7px)',
    //width: {xs:'100%', md:'calc(100% - 19% - 7px)'},
    width:'calc(100% - 19% - 7px)',
    zIndex: 10,
  },
  deleteButton: {
    position: 'absolute',
    right: '20px',
    top: '19px',
    right: '90px',
    minWidth: 'auto',
  },
  smallerBtn: {
    color: theme.palette.mode === 'dark' ? '#8899a6' : '#333',
    textTransform: 'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#313539' : 'white',
    border: 'none',
    borderRadius: 10,
    padding: '4px 14px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '22px',
    left: '130px',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#f1f1f1',
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#505050',
      border: 'none',
      boxShadow: 'none',
      '& .MuiSvgIcon-root': {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#505050',
      },
    },
    '&:focus': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
    },
  },
purpleBtn: {
  color: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? '#3b4651' : 'white',
  border: 'none',
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  display: 'inline-block',
  margin: '5px',
  background: theme.palette.mode === 'dark' ? '#3b4651' : '#340d8c',
  fontSize: '15px',
  borderRadius: '20px',
  padding: '10px',
  paddingLeft: '25px',
  paddingRight: '25px',
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '2px',
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#505050' : '#8b5cf6',
    color: '#fff',
    border: 'none',
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#15202b' : '#505050',
    },
  },
  '&:focus': {
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
  },
},

beautyBtn: {
  color: theme.palette.mode === 'dark' ? '#ddd' : '#666',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? '#2a2f38' : '#f1f1f1',
  border: 'none',
  borderRadius: 10,
  padding: '4px 17px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '2px',
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1d252e' : '#f1f1f1',
    color: theme.palette.mode === 'dark' ? '#bbb' : 'black',
    border: 'none',
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#bbb' : '#505050',
    },
  },
  '&:focus': {
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
  },
},

beautyBtnBox: {
fontWeight: 'normal',
                fontSize: {xs:'12px', md:'15px'},
                backgroundColor: theme.palette.mode === 'dark' ? '#2f3d4a' : '#f9f9f9',
                border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#eee'}`,
                paddingTop: {xs:'2px', md: '8px'},
                paddingBottom: {xs:'2px', md: '8px'},
                borderRadius:{xs:'30px', md: '8px'},
                fontWeight:'500',
                boxShadow:'none',

                color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                "&:hover, &:focus, &:active, &.Mui-focusVisible": {
                  border: `1px solid ${theme.palette.mode === 'dark' ? '#5c666f' : '#f9f9f9'}`,
                  backgroundColor: theme.palette.mode === 'dark' ? '#384755' : '#eee',
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                },
},

beautyBtnTres: {
  color: theme.palette.mode === 'dark' ? '#ddd' : '#666',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#f1f1f1',
  border: 'none',
  borderRadius: 10,
  padding: '4px 17px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '2px',
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1d252e' : '#f1f1f1',
    color: theme.palette.mode === 'dark' ? '#bbb' : 'black',
    border: 'none',
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#bbb' : '#505050',
    },
  },
  '&:focus': {
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
  },
},

beautyBtnDos: {


                fontWeight: 'normal',
              fontSize: '13px',
              backgroundColor: theme.palette.mode === 'dark' ? '#373c44' : '#f9f9f9',
              border: `1px solid ${theme.palette.mode === 'dark' ? '#3b4651' : '#eee'}`,
              paddingTop: '2px',
              paddingBottom: '2px',
              fontWeight:'500',
              color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
              "&:hover, &:focus, &:active, &.Mui-focusVisible": {
                border: `1px solid ${theme.palette.mode === 'dark' ? '#3b4651' : '#f9f9f9'}`,
                backgroundColor: theme.palette.mode === 'dark' ? '#424a54' : '#eee',
              }
},


  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  exportButton: {
    marginRight: 1,
    minWidth: '120px',
    height: '42px',
    padding: '8px 16px',
  },
  deleteButtonNotes: {
    marginBottom: 2,
    minWidth: '180px',
    padding: '8px 16px',
  },
  paper: {
    marginBottom: 3,
    padding: 2,
  },
  sectionHeader: {
    marginBottom: 2,
    fontWeight: 'bold',
  },
  listItem: {
    backgroundColor: theme.palette.mode==='dark' ? '#22303C' : '#f9f9f9',

    borderRadius: 4,
    marginBottom: 1,
  },
  listItemIcon: {
    minWidth: '30px',
    marginRight: '8px',
  },
  cardClicked: { 
    backgroundColor: theme.palette.mode === 'dark' ? '#1b2735' : 'black', 
    fontSize: '10px' 
  },
  card: {
    width: 250,
    height: 200,
    padding: 2,
    display: 'flex',
    borderRadius: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 2,
    backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#ffffff',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
    borderColor: theme.palette.mode === 'dark' ? '#444' : 'black',
    '& svg': {
      color: theme.palette.mode === 'dark' ? '#bbb' : '#666',
    },
    transition: 'background-color 0.1s, color 0.1s',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#1f2a37' : '#eee9ff',
      color: '#8b5cf6',
      '& svg': {
        color: '#8b5cf6',
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? '#1f2a37' : '#eee9ff',
      color: '#8b5cf6',
      '& svg': {
        color: '#8b5cf6',
      },
    },
    '&:focus-within': {
      backgroundColor: theme.palette.mode === 'dark' ? '#1b2735' : 'black',
    },
    selected: {
      width: 250,
      height: 200,
      padding: 2,
      display: 'flex',
      backgroundColor: theme.palette.mode === 'dark' ? '#3c3f41' : '#eee9ff',
      color: '#8b5cf6',
      borderColor: theme.palette.mode === 'dark' ? '#555' : 'black',
      borderRadius: '10px',
      border: '2px solid #777',
      boxShadow: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 2,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 80,
  },
  typographyBody: {
    variant: 'body2',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '7px',
  },
  typographyCaption: {
    variant: 'caption',
    textAlign: 'center',
    fontSize: '13px',
    color: '#444',
    marginTop: '7px',
  },
  hover: {
    backgroundColor: '#eee9ff',
    color: '#8b5cf6',
    '& svg': {
      color: '#8b5cf6',
    },
  },
  active: {
    backgroundColor: '#ddd6fe',
    color: '#7c3aed',
    '& svg': {
      color: '#7c3aed',
    },
  },

 cardText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    border: theme.palette.mode === 'dark' ? '1px solid #3b4651' : '1px solid #cccccc69',
    borderRadius: '10px',
    backgroundColor: theme.palette.mode === 'dark' ? '#1b2735' : '#fff',
    boxShadow: theme.palette.mode === 'dark' ? '' : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  roundMenu: {
    borderRadius: '20px',
  },
  roundMenuItem: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'black' : '#eee9ff',
      color: '#6d28d9',
    },
  },

  newBox: {
    borderRadius: '10px',
    padding: '15px',
    width: {xs: '100%', md:'90%'},
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white' , border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
  },

  darkBorder: {
    borderRadius: '10px',
    border: '2px solid #8b5cf6',
    padding: '15px',
    backgroundColor: 'white',
    width: {xs: '100%', md:'90%'},
    marginTop: '20px',
    marginBottom: '20px',
  },
  roundImage: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
  },
  menuItems: {
    '&:hover': {
      color:theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6 !important',
      backgroundColor: theme.palette.mode === 'dark' ? 'black' : '#f1ebff !important',
    },
  },
  formControlLabel: {
    '& .Mui-checked': {
      backgroundColor: 'red', // Bakgrundsfärg vid active
      color: 'red', // Textfärg vid active
      '& .MuiSvgIcon-root': {
        color: 'red', // Ikonfärg vid active
      },
    },
  },

bottomCta: {

          textAlign: 'center',
        padding: 4,
        marginTop: 8,
        marginBottom: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgb(253, 253, 253)',
        boxShadow: theme.palette.mode === 'dark' ? '0 4px 6px rgba(0, 0, 0, 0.2)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
},

bottomCtaTitle: {
marginBottom: 2, fontWeight: 'bold', fontSize:'30px'

},


bottomCtaBody: {
marginBottom: 3, maxWidth: '600px', margin: '0 auto' 
  
},

shrinkButton: {

  padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'15px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
},

  toggleButtonGroupsTight: {
    marginBottom: '0px',
    marginTop: '0px',
    borderRadius: '26px',
    overflow: 'hidden',
    border: 'none',
  },
  chooseToggle: {
    backgroundColor: theme.palette.mode === 'dark' ? '#15202b !important' : 'white !important',
  },
  togglesTight: {
    backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : 'white',
    color: theme.palette.mode === 'dark' ? '#8899a6' : 'white',
    paddingTop: '5px',
    paddingBottom: '5px',
    '&:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.mode === 'dark' ? '#38444d' : '#dbdbdb'}`,
    },
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.mode === 'dark' ? '#38444d' : '#dbdbdb'}`,
    },
    '& svg': {
      marginRight: '5px',
    },
    '&.Mui-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#192734' : '#340d8c',
      color: theme.palette.mode === 'dark' ? '#1da1f2' : 'white',
      border: 'none',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#7c3aed',
        marginRight: '5px',
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#eee9ff',
      color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#eee9ff',
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '6d28d9',
        '& svg': {
          color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
        },
      },
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      },
    },
    '&:not(.Mui-selected)': {
      backgroundColor: theme.palette.mode === 'dark' ? '#313539' : '#f9f9f9',
      color: theme.palette.mode === 'dark' ? '#8899a6' : '#555',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#8899a6' : '#555',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#eee9ff',
      color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      },
    },
  },
  toggleButtonGroups: {
    marginBottom: '0px',
    borderRadius: '13px',
    overflow: 'hidden',
  },
  firstToggle: {
    paddingLeft: {xs: '8px', md: '20px'},
    paddingRight: {xs: '8px', md: '20px'},
    borderTopLeftRadius: '13px',
    borderBottomLeftRadius: '13px',
  },
  lastToggle: {
    borderTopRightRadius: '13px',
    borderBottomRightRadius: '13px',
    paddingRight: {xs: '8px', md: '20px'},
    paddingLeft: {xs: '8px', md: '20px'},
  },
  toggles: {
    paddingRight: {xs: '8px', md: '20px'},
    paddingLeft: {xs: '8px', md: '20px'},
    backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : 'ddd6fe',
    color: theme.palette.mode === 'dark' ? '#8899a6' : 'white',
    '&:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.mode === 'dark' ? '#38444d' : '#dbdbdb'}`,
    },
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.mode === 'dark' ? '#38444d' : '#dbdbdb'}`,
    },
    '& svg': {
      marginRight: '5px',
    },
    '&.Mui-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#192734' : '#ddd6fe',
      color: theme.palette.mode === 'dark' ? '#1da1f2' : '#7c3aed',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#7c3aed',
        marginRight: '5px',
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#eee9ff',
      color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#eee9ff',
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
        '& svg': {
          color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
        },
      },
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      },
    },
    '&:not(.Mui-selected)': {
      backgroundColor: theme.palette.mode === 'dark' ? '#313539' : '#f9f9f9',
      color: theme.palette.mode === 'dark' ? '#8899a6' : '#555',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#8899a6' : '#555',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : '#eee9ff',
      color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#1da1f2' : '#6d28d9',
      },
    },
  },







});

export const getStyles = (theme) => createStyles(theme);

const container = document.getElementById('root');
const root = createRoot(container);



const ThemedApp = () => {
  // const [overrideTheme, setOverrideTheme] = useState(null);
  // const { user, loading } = useAuth();

  // const isDark = overrideTheme !== null 
  //   ? overrideTheme === 'dark'
  //   : user?.preferredTheme === 'dark';

  // const currentTheme = isDark ? darkTheme : theme;

  // const toggleDarkMode = useCallback(() => {
  //   setOverrideTheme(prevOverride => 
  //     prevOverride === null
  //       ? (user?.preferredTheme === 'dark' ? 'light' : 'dark')
  //       : (prevOverride === 'dark' ? 'light' : 'dark')
  //   );
  // }, [user?.preferredTheme]);




  const [overrideTheme, setOverrideTheme] = useState(null);
  const { user, loading } = useAuth();

  const getPreferredTheme = useCallback(() => {
    if (overrideTheme !== null) {
      return overrideTheme;
    }

    if (user?.preferredTheme && user.preferredTheme !== 'system') {
      return user.preferredTheme;
    }

    const currentHour = new Date().getHours();
    return (currentHour >= 20 || currentHour < 6) ? 'dark' : 'light';
  }, [overrideTheme, user?.preferredTheme]);

  const [isDark, setIsDark] = useState(getPreferredTheme() === 'dark');

  useEffect(() => {
    const updateTheme = () => {
      setIsDark(getPreferredTheme() === 'dark');

    };

    updateTheme();

    const interval = setInterval(updateTheme, 60000); // Kontrollera varje minut

    return () => clearInterval(interval);
  }, [getPreferredTheme]);

  const currentTheme = isDark ? darkTheme : theme;

  const toggleDarkMode = useCallback(() => {
    setOverrideTheme(prevOverride => 
      prevOverride === null
        ? (isDark ? 'light' : 'dark')
        : (prevOverride === 'dark' ? 'light' : 'dark')
    );
  }, [isDark]);







  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <SnackbarProvider>
        <App isDark={isDark} toggleDarkMode={toggleDarkMode} setOverrideTheme={setOverrideTheme} />
      </SnackbarProvider>
<style>

      {`


  .blinking-cursor::after {
    content: '|';
    animation: blink 1s step-start infinite;
    margin-left: 2px;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

        @keyframes wiggle {
          0%, 100% {
            transform: rotate(-1deg);
          }
          50% {
            transform: rotate(1deg);
          }
        }

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

        .wiggle {
          animation: wiggle 0.3s infinite;
        }

@keyframes ellipsis-dot {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}
@keyframes fadeInn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.cursor {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-color: currentColor;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}
@keyframes pulsate {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
[contenteditable] {padding:12px;  }

[data-placeholder]:empty:before{
  content: attr(data-placeholder);
  color: #888;

}

p, ul li, ol li {
  line-height: 23px;
}
.focus-toggle {
  transition: opacity 0.3s ease-in-out;
}

.focus-toggle.focus-active {
  opacity: 0.05;
}

.focus-toggle.focus-active:hover {
  opacity: 1;
}

.pulsating-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: currentColor;
  border-radius: 50%;
  margin-left: 2px;
  vertical-align: middle;
  animation: pulsate 1s ease-in-out infinite;
}

      `}
    </style>
    </ThemeProvider>
  );
};

root.render(<ThemedApp />);
