import React, { useState, useRef, useCallback } from 'react';
import { Button, Box, Typography, CircularProgress, IconButton, Tooltip, Grid } from '@mui/material';
import { ReactReader } from 'react-reader';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TranslateIcon from '@mui/icons-material/Translate';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Logger from './Logger';
const dummyBooks = [
  { id: 1, title: 'Jag, Lars Hård', author: 'Jan Fridegård', file: '/FridegårdJ_JagLarsHård.epub' },
  { id: 2, title: 'Nils Holgerssons underbara resa genom Sverige', author: 'Selma Lagerlöf', file: '/FridegårdJ_JagLarsHård.epub' },
  { id: 3, title: 'Doktor Glas', author: 'Hjalmar Söderberg', file: '/FridegårdJ_JagLarsHård.epub' },
  { id: 4, title: 'Utvandrarna', author: 'Vilhelm Moberg', file: '/FridegårdJ_JagLarsHård.epub' },
];

const BookReader = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selection, setSelection] = useState('');
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [showLibrary, setShowLibrary] = useState(true);
  const fileInputRef = useRef(null);
  const renditionRef = useRef(null);

  const handleFileUpload = async (event) => {
    setIsLoading(true);
    setError(null);
    const uploadedFile = event.target.files[0];
    try {
      if (uploadedFile.type === 'application/epub+zip') {
        const epubArrayBuffer = await uploadedFile.arrayBuffer();
        setFile(epubArrayBuffer);
        setShowLibrary(false);
      } else {
        throw new Error('Invalid file format. Only EPUB is supported.');
      }
    } catch (err) {
      console.error('Error processing file:', err);
      setError('Unable to read the file. Please ensure it is a valid EPUB file.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelection = useCallback((cfiRange, contents) => {
    if (renditionRef.current && contents) {
      const selectedText = contents.window.getSelection().toString();
      if (selectedText && selectedText.trim().length > 0) {
        setSelection(selectedText);
        setShowControlPanel(true);
      } else {
        setSelection('');
        setShowControlPanel(false);
      }
    }
  }, []);

  const handleAction = (action) => {
    Logger.log(`Performing ${action} on:`, selection);
    // Add your logic here for each action
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength - 3) + '...';
  };

  const handleBookSelect = async (bookFile) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(bookFile);
      const epubArrayBuffer = await response.arrayBuffer();
      setFile(epubArrayBuffer);
      setShowLibrary(false);
    } catch (err) {
      console.error('Error loading book:', err);
      setError('Unable to load the book. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToLibrary = () => {
    setFile(null);
    setShowLibrary(true);
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', p: 2 }}>
      {showLibrary ? (
        <>
          <Button variant="contained" onClick={() => fileInputRef.current.click()} disabled={isLoading} sx={{ mb: 2 }}>
            {isLoading ? 'Loading...' : 'Upload Book'}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".epub"
            style={{ display: 'none' }}
          />
          <Grid container spacing={2}>
            {dummyBooks.map((book) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={book.id}>
                <Box
                  onClick={() => handleBookSelect(book.file)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    p: 2,
                    borderRadius: 1,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1">{book.title}</Typography>
                  <Typography variant="body2" color="text.secondary">{book.author}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Button startIcon={<ArrowBackIcon />} onClick={handleBackToLibrary} sx={{ mb: 2 }}>
            Back to Library
          </Button>
          {isLoading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          
          {showControlPanel && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, bgcolor: 'background.paper', mb: 2 }}>
              <Typography variant="body2" sx={{ mr: 2, maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {truncateText(selection, 50)}
              </Typography>
              <Tooltip title="Summarize">
                <IconButton onClick={() => handleAction('summarize')}><SummarizeIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Translate">
                <IconButton onClick={() => handleAction('translate')}><TranslateIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Quote">
                <IconButton onClick={() => handleAction('quote')}><FormatQuoteIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Simplify">
                <IconButton onClick={() => handleAction('simplify')}><AutoFixHighIcon /></IconButton>
              </Tooltip>
            </Box>
          )}
          
          <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
            {file && (
              <ReactReader
                url={file}
                title="EPUB Reader"
                getRendition={(rendition) => {
                  renditionRef.current = rendition;
                  rendition.on('selected', handleSelection);
                }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default BookReader;