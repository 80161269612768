import React from 'react';
import { Box, Typography, Card, CardContent, Button, Grid, Chip, IconButton, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ResumeIcon from '@mui/icons-material/PlayCircleOutline';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';

import { getStyles } from './index';
import { useNavigate } from 'react-router-dom';

export const getAIModelImage = (modelName) => {
  const modelOptions = [
    { value: "Studera.AI", img: `/img/AI.png` },
    { value: "Chat GPT 4o-mini", img: "/img/ChatGPT.png" },
    { value: "Chat GPT 4o", img: "/img/GPT4o.png" },
    { value: "Claude 3.5 Sonnet", img: "/img/Claude 3.5 Sonnet.png" },
    { value: "Claude 3 Sonnet",  img: "/img/Claude 3 Sonnet.png"},
    { value: "Gemini Pro",  img: "/img/Gemini Pro.png"},
    { value: "Mistral Large", img: "/img/Mistral Large.png"}
  ];

  const model = modelOptions.find(option => option.value === modelName);
  return model ? model.img : null;
};

export const AIModelSelect = ({ selectedModel, setSelectedModel, disabledOptions = [] }) => {
  const modelOptions = [
    { value: "Studera.AI", label: "Studera.AI", img: `/img/AI.png`, alt: "Vår egen AI-modell" },
    { value: "Chat GPT 4o-mini", label: "Chat GPT 4o-mini", img: "/img/ChatGPT.png", alt: "ChatGPT 4o-mini" },
    { value: "Chat GPT 4o", label: "Chat GPT 4o", img: "/img/GPT4o.png", alt: "GPT 4o" },
    { value: "Claude 3.5 Sonnet", label: "Claude 3.5 Sonnet", img: "/img/Claude 3.5 Sonnet.png", alt: "Claude 3.5 Sonett" },
    { value: "Claude 3 Sonnet", label: "Claude 3 Sonnet", img: "/img/Claude 3 Sonnet.png", alt: "Claude 3 Sonett" },
    { value: "Gemini Pro", label: "Gemini Pro", img: "/img/Gemini Pro.png", alt: "Gemini Pro" },
    { value: "Mistral Large", label: "Mistral Large", img: "/img/Mistral Large.png", alt: "Mistral Large" }

  ];

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>

      <Select
        labelId="model-select-label"

        id="model-select"
        value={selectedModel}
        onChange={(e) => setSelectedModel(e.target.value)}
      >
        {modelOptions.map((item) => (
          <MenuItem 
            key={item.value} 
            value={item.value}
            disabled={disabledOptions.includes(item.value)}
            sx={{ py: 1 }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Box 
                  component="img"
                  src={item.img}
                  alt={item.alt}
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    display: 'block',
                  }}
                />
              </Grid>
              <Grid item>
                <Typography>{item.label}</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export const IncompleteTestDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Ofullständigt prov</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h1" sx={{ fontSize: '5rem', mb: 2 }}>😢</Typography>
          <Typography>Du måste besvara alla frågor innan du kan avsluta provet.</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export const Message = ({ content, isUser }) => {
  const formattedContent = isUser ? content : content
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/\n/g, '<br />');

  return (
    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
      {isUser ? content : <span dangerouslySetInnerHTML={{ __html: formattedContent }} />}
    </Typography>
  );
};

export const TestSelector = ({ onSelectTest, userProgress, allTests, onDeleteTest }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleSelectTest = (testId) => {
    navigate(`/hp/${testId}`);
  };

  return (
    <Box sx={{ mt: 2, mb: 6 }}>
      <Grid container spacing={4}>
        {allTests.map((test) => {
          const testProgress = userProgress.find(p => p.testId === test.id);
          const answeredQuestionsCount = testProgress ? testProgress.results.length : 0;
          const totalQuestionsCount = testProgress ? testProgress.totalQuestions : 0;
          const progressPercentage = totalQuestionsCount > 0 ? (answeredQuestionsCount / totalQuestionsCount) * 100 : 0;



          return (
            <Grid item xs={12} sm={6} md={4} key={test.id}>
              <Card 
                sx={{ 
                  backgroundColor: theme.palette.mode === 'dark' 
                    ? (test.official ? '#192734' : testProgress?.testInProgress ? '#192734' : 'background.paper')
                    : (test.official ? '#fff' : testProgress?.testInProgress ? '#fff' : 'background.paper'),
                  border: theme.palette.mode === 'dark' ? '1px solid #405264' : '1px solid #b0bec569',
                  borderBottom: theme.palette.mode === 'dark' ? '7px solid #405264' : '7px solid #dee4e7',
                  borderRadius: '10px',
                  boxShadow: 'none',
                  padding: '0px'
                }}
              >
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative', padding:'0px' }}>
                  <Box>
                    <Box sx={{
                      background: test.official ? '#995945' : '#580984', 
                      border: test.isFromHpData ? '3px solid red' : 'inherit',
                      padding: '10px', 
                      textAlign: 'center', 
                      paddingTop: '40px', 
                      paddingBottom: '40px',
                    }}>
                      <Typography variant="h6" component="div" gutterBottom sx={{ mb:0, fontSize:'17px', color:theme.palette.mode === 'dark' ? undefined : 'white' }}>
                        {test.name}
                      </Typography>
                      <Typography variant="body2" component="div" sx={{ fontStyle: 'italic', fontSize: '12px', color: theme.palette.mode === 'dark' ? undefined : 'white' }}>
                        {!test.official ? "(AI-genererat)" : "(Officiellt högskoleprov)"}
                      </Typography>
                      {!test.official && test.isPublicCopy && (
  <Typography variant="body2" component="div" sx={{ fontStyle: 'italic', fontSize: '12px', textDecoration:'underline', fontWeight:'bold', color: theme.palette.mode === 'dark' ? undefined : 'white' }}>
    Kopia
  </Typography>
)}
                    </Box>
                    {!test.official && (
                      <IconButton 
                        sx={{position: 'absolute', top: '7px', right: '7px', color:'white'}} 
                        onClick={() => onDeleteTest(test.id)} 
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}

                    <Box sx={{padding:'15px', paddingBottom:'0px'}}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        {test.questionTypes && test.questionTypes.map((type, index) => (
                          <Chip
                            key={index}
                            label={type}
                            size="small"
                            sx={{
                              backgroundColor: theme.palette.mode === 'dark' ? '#3f4f5f' : '#e0e0e0',
                              color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                              fontSize: '0.8rem',
                            }}
                          />
                        ))}
                      </Box>
                    
                      {testProgress && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                            Besvarade frågor: {answeredQuestionsCount} / {totalQuestionsCount}
                          </Typography>
                          <LinearProgress 
                            variant="determinate" 
                            value={progressPercentage}
                            sx={{ mb: 1, height:'12px', borderRadius:'30px' }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {Math.round(progressPercentage)}%
                          </Typography>
                        </Box>
                      )}
                    
                      {testProgress && !testProgress.testInProgress && (
                        <Box sx={{ mb: 2 }}>
                          <Chip 
                            label="Slutfört" 
                            color="success" 
                            size="small" 
                            sx={{ mr: 1 }} 
                          />
                          <Typography variant="body2" color="text.secondary">
                            Resultat: {testProgress.results.filter(r => r.isCorrect).length}/{testProgress.results.length}
                          </Typography>
                        </Box>
                      )}

                      <Button 
                        variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
                        onClick={() => handleSelectTest(test.id)}
                        startIcon={testProgress?.testInProgress ? <ResumeIcon /> : <PlayArrowIcon />}
                      >
                        {testProgress?.testInProgress ? 'Fortsätt testet' : 'Starta provet'}
                      </Button>
{/*
                      <Box sx={{padding: '15px', fontSize: '10px', color: 'text.secondary'}}>


                        <Typography variant="caption" display="block">Debug Info:</Typography>
                        <pre style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>
                          {`User ID: ${testProgress?.userId || 'N/A'}
Test ID: ${test.id}
Current Section: ${testProgress?.currentSectionIndex ?? 'N/A'}
Current Question: ${testProgress?.currentQuestionIndex ?? 'N/A'}
Results: ${JSON.stringify(testProgress?.results || [])}
Time Left: ${testProgress?.timeLeft ?? 'N/A'}
Test In Progress: ${testProgress?.testInProgress ? 'Yes' : 'No'}
Total Questions: ${testProgress?.totalQuestions ?? 'N/A'}
Last Updated: ${testProgress?.lastUpdated ? 
  (testProgress.lastUpdated instanceof Date ? 
    testProgress.lastUpdated.toISOString() : 
    JSON.stringify(testProgress.lastUpdated)
  ) : 'N/A'}`}
                        </pre>



                      </Box>

*/}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
