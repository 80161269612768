import React from 'react';
import { Box, Checkbox, Typography, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AutoStories, Spellcheck, TextFields, ElectricBolt, Calculate, Extension, Language } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const QuestionTypeSelector = ({ 
  type, 
  selectedQuestionTypes, 
  handleQuestionTypeChange, 
  selectedThemes,
  handleThemeChange,
  questionCounts,
  handleQuestionCountChange,
  theme,
  questionTypeDescriptions
}) => {
  const icons = {
  ORD: <Spellcheck />,
  LÄS: <AutoStories />,
  MEK: <TextFields />,
  ELF: <Language />,
  XYZ: <Calculate />,
  DTK: <Extension />
  };

  const isSelected = selectedQuestionTypes.includes(type);
  const isDark = theme.palette.mode === 'dark';


const getTooltipTitle = (type) => {
  if (type === 'DTK') return "Kommer snart!";
  if (type === 'XYZ') return (
    <React.Fragment>
      <em>{"Just nu stödjer vi ekvationer, mer kommer snart."}</em>
    </React.Fragment>
  );
  return questionTypeDescriptions[type];
};



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mr: {xs:'6px', md: 2}, mb: 2, mt: 2 }}>
      <Tooltip   title={getTooltipTitle(type, questionTypeDescriptions)}  arrow placement="top">
        <Box
          sx={{
            backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(139, 92, 246, 0.05)',
            borderRadius: '12px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            minWidth:'158px',
            opacity: type === 'DTK' ? 0.5 : 1,

            '&:hover': {
              backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)',
              transform: 'translateY(-2px)',
            },
          }}
          onClick={() => type !== 'DTK' && handleQuestionTypeChange({ target: { name: type, checked: !isSelected } })}
        >
          <Box className="icon" sx={{ color: theme.palette.primary.main, mb: 1,

            '& .MuiSvgIcon-root': {  // Target MUI icons directly
              fontSize: '40px',
            color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#8b5cf6',
            },
           }}>
            {icons[type]}
          </Box>
          <Typography variant="subtitle1" sx={{ mb: 1, color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#4a5568', fontWeight:'500' }}>
            {type}
          </Typography>
<Checkbox
  checked={isSelected}
  onChange={() => {}}
  icon={<RadioButtonUncheckedIcon sx={{ fontSize:'35px'}} />}
  checkedIcon={<CheckCircleIcon />}
  sx={{
    '&.MuiCheckbox-root': {
      color: theme.palette.grey[400],

    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
   '& .MuiSvgIcon-root': {
      fontSize: '30px'  // This will set both icons to exactly 35 pixels
    }
  }}
/>

        </Box>
      </Tooltip>
      
      <Box sx={{ 
        opacity: isSelected ? 1 : 0,
        pointerEvents: isSelected ? 'auto' : 'none',
        height: isSelected ? 'auto' : '0',
        overflow: 'hidden',
        transition: 'opacity 0.2s, height 0.2s'
      }}>
      <Box>
        <FormControl sx={{ mt: 3, width: 140 }}>
          <InputLabel id={`${type}-theme-label`} sx={{fontWeight:'500'}}>Tema för {type}</InputLabel>
          <Select
            labelId={`${type}-theme-label`}
            value={selectedThemes[type]}
            onChange={(e) => handleThemeChange(type, e.target.value)}
            label={`Tema för ${type}`}
            sx={{borderRadius:'6px'}}
            inputProps={{
              sx: {
                padding: '10px',
                borderRadius: '10px',
                fontSize:'14px'
              },
            }}
          >
            <MenuItem value="AI får välja">AI får välja</MenuItem>
            {type === 'XYZ' ? [
              <MenuItem key="Algebra" value="Algebra">Algebra</MenuItem>,
              <MenuItem key="Geometri" value="Geometri">Geometri</MenuItem>,
              <MenuItem key="Aritmetik" value="Aritmetik">Aritmetik</MenuItem>,
              <MenuItem key="Kalkyl" value="Kalkyl">Kalkyl</MenuItem>,
              <MenuItem key="Trigonometri" value="Trigonometri">Trigonometri</MenuItem>
            ] : type === 'DTK' ? [
              <MenuItem key="Algebra" value="Algebra">Algebra</MenuItem>,
              <MenuItem key="Geometri" value="Geometri">Geometri</MenuItem>,
              <MenuItem key="Aritmetik" value="Aritmetik">Aritmetik</MenuItem>,
              <MenuItem key="Kalkyl" value="Kalkyl">Kalkyl</MenuItem>,
              <MenuItem key="Trigonometri" value="Trigonometri">Trigonometri</MenuItem>
            ] : [
              <MenuItem key="Finans" value="Finans">Finans</MenuItem>,
              <MenuItem key="Teknik" value="Teknik">Teknik</MenuItem>,
              <MenuItem key="Medicin" value="Medicin">Medicin</MenuItem>,
              <MenuItem key="Historia" value="Historia">Historia</MenuItem>,
              <MenuItem key="Vetenskap" value="Vetenskap">Vetenskap</MenuItem>,
              <MenuItem key="Utbildning" value="Utbildning">Utbildning</MenuItem>,
              <MenuItem key="Miljö" value="Miljö">Miljö</MenuItem>,
              <MenuItem key="Politik" value="Politik">Politik</MenuItem>,
              <MenuItem key="Psykologi" value="Psykologi">Psykologi</MenuItem>,
              <MenuItem key="Filosofi" value="Filosofi">Filosofi</MenuItem>,
              <MenuItem key="Konst" value="Konst">Konst</MenuItem>,
              <MenuItem key="Musik" value="Musik">Musik</MenuItem>,
              <MenuItem key="Litteratur" value="Litteratur">Litteratur</MenuItem>,
              <MenuItem key="Ekonomi" value="Ekonomi">Ekonomi</MenuItem>,
              <MenuItem key="Juridik" value="Juridik">Juridik</MenuItem>
            ]}
          </Select>
        </FormControl>
        </Box>
        <Box>
        <FormControl sx={{ width: 140, mt:3 }}>
          <InputLabel id={`${type}-count-label`}  sx={{fontWeight:'500'}}>Frågor för {type}</InputLabel>
          <Select
            labelId={`${type}-count-label`}
            value={questionCounts[type]}
            onChange={(e) => handleQuestionCountChange(type, e.target.value)}
            label={`Frågor för ${type}`}
            sx={{borderRadius:'6px'}}
            inputProps={{
              sx: {
                padding: '10px',
                borderRadius: '10px',
                fontSize:'14px'
              },
            }}
          >
            {type === 'LÄS' && <MenuItem value={1}>1</MenuItem>}
            {type === 'LÄS' && <MenuItem value={2}>2</MenuItem>}
            {type === 'DTK' && [1,2,3,4,5,10].map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
            {type === 'XYZ' && [1,2,3,4,5].map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
            {type === 'MEK' && <MenuItem value={1}>1</MenuItem>}
            {type === 'MEK' && <MenuItem value={2}>2</MenuItem>}
            {type === 'MEK' && <MenuItem value={3}>3</MenuItem>}
            {type === 'MEK' && <MenuItem value={4}>4</MenuItem>}
            {type === 'MEK' && <MenuItem value={5}>5</MenuItem>}
            {type === 'ELF' && <MenuItem value={1}>1</MenuItem>}
            {type === 'ELF' && <MenuItem value={2}>2</MenuItem>}
            {type === 'ORD' && <MenuItem value={2}>2</MenuItem>}
            {type === 'ORD' && <MenuItem value={5}>5</MenuItem>}

            {/* kommentera ut några av dessa vid live */}
            {type === 'ORD' && <MenuItem value={10}>10</MenuItem>}
            {type === 'ORD' && <MenuItem value={15}>15</MenuItem>}
            {type === 'ORD' && <MenuItem value={20}>20</MenuItem>}

            {type === 'ORD' && <MenuItem value={7} disabled>10</MenuItem>}
            {['LÄS', 'MEK', 'ELF'].includes(type) && (
              <>
                <MenuItem value={5} disabled>5</MenuItem>
                <MenuItem value={10} disabled>10</MenuItem>
              </>
            )}
            <MenuItem value={15} disabled>15</MenuItem>
            <MenuItem value={20} disabled>20</MenuItem>
          </Select>
        </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionTypeSelector;