// userSetupUtils.js
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase'; // Adjust the import path as necessary
import Logger from './Logger';
export const copyInitialTeachersForNewUser = async (userId, teacherIds) => {
  try {
    for (const teacherId of teacherIds) {
      const publicTeacherRef = doc(db, 'publicTeachers', teacherId);
      const publicTeacherSnap = await getDoc(publicTeacherRef);
      
      if (publicTeacherSnap.exists()) {
        const teacherData = publicTeacherSnap.data();
        
        const teacherCopy = {
          ...teacherData,
          userId: userId,
          createdAt: new Date(),
          updatedAt: new Date(),
          visibility: 'private',
          isPublicCopy: true,
          copiedFromPublicId: teacherId
        };

        await setDoc(doc(db, 'teachers', teacherId), teacherCopy);
        Logger.log(`Teacher ${teacherId} copied for new user ${userId}`);
      } else {
        console.error(`Public teacher with ID ${teacherId} not found`);
      }
    }
    Logger.log(`Initial teachers copied successfully for user ${userId}`);
  } catch (error) {
    console.error("Error copying initial teachers: ", error);
  }
};




export const copyInitialHpTestsForNewUser = async (userId, hpTestIds) => {
  try {
    for (const hpTestId of hpTestIds) {
      const publicHpTestRef = doc(db, 'hpPublic', hpTestId);
      const publicHpTestSnap = await getDoc(publicHpTestRef);
      
      if (publicHpTestSnap.exists()) {
        const hpTestData = publicHpTestSnap.data();
        
        const hpTestCopy = {
          ...hpTestData,
          uid: userId,
          createdAt: new Date(),
          official: true,
          isPublicCopy: true,
          isGenerated: true
        };
        await setDoc(doc(db, 'hpTestData', hpTestId), hpTestCopy);
        Logger.log(`HP Test ${hpTestId} copied for new user ${userId}`);
      } else {
        console.error(`Public HP Test with ID ${hpTestId} not found`);
      }
    }
    Logger.log(`Initial HP Tests copied successfully for user ${userId}`);
  } catch (error) {
    console.error("Error copying initial HP Tests: ", error);
  }
};

export const handleNewUserSignUp = async (userId) => {
  const initialTeacherIds = ['oAc5im2gfmlJXrF6XvPv', 'qWm0UZ6E1tWR1C12ud0P', 'dL1NVd9HMYxQvTL2j9VO']; // Replace with actual IDs
  const initialHpTestIds = ['4miSuszFn20l91NdCMGB', 'Dg0FdXG62lJDog5ltP47', 'OaDkNyupVvXGa62qNily', 'bCS8WWPyXKGNrpqGr5b8']; // Replace with actual HP Test IDs
  //await copyInitialHpTestsForNewUser(userId, initialHpTestIds);
  //await copyInitialTeachersForNewUser(userId, initialTeacherIds);
};