import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs, updateDoc, doc, getDoc} from 'firebase/firestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import { useSpring, animated } from '@react-spring/web';
import './App.css';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import Looks3Icon from '@mui/icons-material/Looks3';
import IconButton from '@mui/material/IconButton';
import { Helmet } from 'react-helmet-async';
import BookmarkIcon from '@mui/icons-material/BookmarkBorder';
import CheckCircleIcon from '@mui/icons-material/Check';
import { useSnackbar } from './SnackbarHandler';
import { formatDate } from './functions';
import Looks4Icon from '@mui/icons-material/Looks4';
import Voice from './Voice';
import Tooltip from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import { callOpenAITTS } from './api';
import AddIcon from '@mui/icons-material/Add';
import { addNote, deleteNote } from './NotesView';
import { useMediaQuery, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import Logger from './Logger';
import UserCollectionsDrawer from './flashcards/UserCollectionsDrawer';
import TrainingSummary from './flashcards/TrainingSummary';

import PublicCollectionsDrawer from './flashcards/PublicCollectionsDrawer';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import axios from 'axios';

import Hint from './Hint';
import FlashcardsCreateView from './FlashcardsCreateView'; // Importera FlashcardsCreateView

import { useTheme } from '@mui/material/styles';

import LinearProgress from '@mui/material/LinearProgress';

 import AIDialog from './AIDialog';

import { getStyles } from './index';
import { Howl } from 'howler';
import selectSoundFile from './sounds/select.mp3';
import select2 from './sounds/select2.mp3';
import done from './sounds/done.mp3';
import SegmentIcon from '@mui/icons-material/Segment';

import { useParams, useNavigate } from 'react-router-dom';
import RecommendationComponent from './RecommendationComponent';


const FlashcardsView = ({ user, isDark }) => {
  const [bookmarks, setBookmarks] = useState({});

const { collectionId, collectionName } = useParams();
const navigate = useNavigate();


  const [collections, setCollections] = useState([]);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showBack, setShowBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [sessionSummary, setSessionSummary] = useState(null);

  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const [creatorInfo, setCreatorInfo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
const [animateButton, setAnimateButton] = useState(null);

const [isCreateViewVisible, setIsCreateViewVisible] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenLibrary, setDrawerLibraryOpen] = useState(false);

const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
const nextSound = new Howl({ src: [select2], volume: 0.6 });
const doneSound = new Howl({ src: [done], volume: 0.2 });

const { showSnackbar } = useSnackbar();

 const theme = useTheme();
  const styles = getStyles(theme);
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

const [dynamicTitle, setDynamicTitle] = useState("Flashcards — Skapa flashcards med AI — Studera.AI");
const [dynamicDescription, setDynamicDescription] = useState("Skapa flashcards med AI. Du kan generera flashcards med AI genom att skriva in ett ämne eller en längre textmassa.");

const [originalCollectionId, setOriginalCollectionId] = useState('');
const [collectionInUse, setCollectionInUse] = useState('');


  const toggleBookmark = async () => {
    const currentCard = filterFlashcards(currentCollection)[currentCardIndex];
    const content = `Framsida: ${currentCard.front}\nBaksida: ${currentCard.back}`;
    const conversationType = `flashcards-${currentCollection.id}`;


      const noteId = await addNote(user.uid, content, null, conversationType);
      setBookmarks(prev => ({ ...prev, [currentCardIndex]: noteId }));

  };



const shuffleCards = () => {
  const shuffledFlashcards = [...currentCollection.flashcards];
  for (let i = shuffledFlashcards.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledFlashcards[i], shuffledFlashcards[j]] = [shuffledFlashcards[j], shuffledFlashcards[i]];
  }
  setCurrentCollection({ ...currentCollection, flashcards: shuffledFlashcards });
  setCurrentCardIndex(0);
  setShowBack(false);
};

const handleKeyDown = (e, currentCollection, showBack, flipCard, rateCard, showCreateView, aiDialog) => {

Logger.log("handleKeyDown loggas med Logger");

  // Check for CMD+N (Mac) or CTRL+N (Windows)
 if ((e.metaKey || e.ctrlKey) && e.key === 'ArrowRight') {
    e.preventDefault(); // Förhindra standard webbläsarbeteende
    showCreateView();
    return;
  }
Logger.log(e.key);

  if (!currentCollection) return;  // Exit early if there's no current collection


 if (e.key === 'F' || e.key === 'f') {
    e.preventDefault();
    handleAskAI();
    return;
  }




  if (e.key === ' ' || e.code === 'Space') {
    e.preventDefault();
    flipCard();
  } else if (showBack) {
    switch (e.key) {
      case '1':
        rateCard('fail');
        break;
      case '2':
        rateCard('hard');
        break;
      case '3':
        rateCard('good');
        break;
      case '4':
        rateCard('easy');
        break;
      default:
        break;
    }
  }
};




// Update the toggleDrawer function
const toggleDrawer = (open) => () => {
  Logger.log("toggleDrawer: ", open);
  setDrawerOpen(open);

};

// Update the toggleDrawerLibrary function
const toggleDrawerLibrary = (open) => () => {
  Logger.log("toggleDrawerLibrary: ", open);
  setDrawerLibraryOpen(open);

};


const showCreateView = () => {
  setIsCreateViewVisible(true);
  setCurrentCollection(null);
  setSessionSummary(null);

navigate(`/flashcards`);

};

  const filterFlashcards = (collection) => {
    if (!collection) return [];
    if (filter === 'all') {
      return collection.flashcards;
    }
    return collection.flashcards.filter(card => card.correct === filter);
  };




const formatUrlPart = (str) => {
  return str
    .replace(/\s+/g, '-')  // Ersätt mellanslag med bindestreck
    .replace(/[åä]/g, 'a') // Ersätt å och ä med a
    .replace(/[ö]/g, 'o')  // Ersätt ö med o
    .replace(/[^a-z0-9-]/gi, '')  // Ta bort alla andra specialtecken
    .toLowerCase();
};



  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      try {
        if (collectionId) {
          const querySnapshot = await getDocs(collection(db, 'flashcardCollections'));
          const allCollections = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          const selectedCollection = allCollections.find(c => c.id === collectionId);
          
          if (selectedCollection) {
            setCollectionInUse(selectedCollection);
            Logger.log("selectedCollection ", selectedCollection);



      setDynamicTitle(`${selectedCollection.collectionName} — Flashcards — Studera.AI`);
      setDynamicDescription(`Testa din kunskap i våra flashcards om ${selectedCollection.collectionName}. Svårighetsgraden är ${selectedCollection.difficulty}.`);


if (selectedCollection.originalCollectionId) {
  setOriginalCollectionId(selectedCollection.originalCollectionId);
  Logger.log("setOriginalCollectionId: ", selectedCollection.originalCollectionId);
} else {
  setOriginalCollectionId(collectionId);
  Logger.log("else setOriginalCollectionId: ", collectionId);
}


            // Hämta användarinformation här
            if (selectedCollection.userId) {


  const userIdToFetch = selectedCollection.isCopied && selectedCollection.originalUserId
    ? selectedCollection.originalUserId
    : selectedCollection.userId;

              Logger.log("här");
              const userDoc = await getDoc(doc(db, 'users', userIdToFetch));
              if (userDoc.exists()) {
                Logger.log("här 2");
                const userData = userDoc.data();
                setCreatorInfo({
                  displayName: userData.displayName || userData.email || 'Unknown',
                  avatarUrl: userData.photoURL || null,
                  upvotes: selectedCollection.upvotes || 0
                });
              }
            }
            
            startTraining(selectedCollection);
            navigate(`/flashcards/${selectedCollection.id}/${formatUrlPart(selectedCollection.collectionName)}`, { replace: true });
          } else {
            console.error('Collection not found');
          }
        }
      } catch (error) {
        console.error('Error fetching collections:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCollections();
  }, [user.uid, refreshTrigger, collectionId, navigate]);




const handleCollectionCreated = (newCollection) => {
  setRefreshTrigger(prev => prev + 1);
  setIsCreateViewVisible(false);

  // Moonshine
  //setCollections(prevCollections => [...prevCollections, newCollection]);
  startTraining(newCollection);

  navigate(`/flashcards/${newCollection.id}/${formatUrlPart(newCollection.collectionName)}`);


};

  const startTraining = (collection) => {

if (!collection) {
    showSnackbar('Collection not found', 'error');

    // Hantera felet, t.ex. visa ett meddelande till användaren
    return;
  }


    //doneSound.play();
        toggleDrawer(false)();
    toggleDrawerLibrary(false)();
    setCurrentCollection(collection);
    setCurrentCardIndex(0);
    setShowBack(false);
    setFilter('all');
    setSessionSummary(null);
    setIsCreateViewVisible(false);
    //setOriginalCollectionId('');
    Logger.log('Started training with collection:', collection);

  navigate(`/flashcards/${collection.id}/${formatUrlPart(collection.collectionName)}`);

  };

  const flipCard = () => {
    selectSound.play();
    setShowBack(!showBack);
    Logger.log('Flipped card:', showBack ? 'back to front' : 'front to back');
  };


const handleAskAI = () => {
  Logger.log("Opening AI Dialog from handleAskAI");
  setAiDialogOpen(true);
};

const rateCard = async (rating) => {
  nextSound.play();

  setAnimateButton(rating);
  setTimeout(() => setAnimateButton(null), 300);

  const updatedFlashcards = [...currentCollection.flashcards];
  const currentCard = updatedFlashcards[currentCardIndex];

  if (currentCard) {
    currentCard.correct = rating;
    Logger.log('Rated card:', currentCard, 'with rating:', rating);
  }

  // Optimistically update the UI
  //setCurrentCollection({ ...currentCollection, flashcards: updatedFlashcards });

  if (currentCardIndex < filterFlashcards({ ...currentCollection, flashcards: updatedFlashcards }).length - 1) {
    setCurrentCardIndex(currentCardIndex + 1);
    setShowBack(false);
  } else {
    const summary = calculateSessionSummary(updatedFlashcards);
    setSessionSummary(summary);

    const collectionRef = doc(db, 'flashcardCollections', currentCollection.id);
    await updateDoc(collectionRef, { 
      flashcards: updatedFlashcards,
      lastTrainingSession: Date.now() // Update this every time a card is rated
    });

  }

  try {
    const collectionRef = doc(db, 'flashcardCollections', currentCollection.id);
    await updateDoc(collectionRef, { flashcards: updatedFlashcards });
  } catch (error) {
    console.error('Error updating flashcard:', error);
    // Revert the optimistic update if the database update fails
    setCurrentCollection(currentCollection);
    showSnackbar('Failed to update flashcard. Please try again.', 'error');
  }
};
  const calculateSessionSummary = (flashcards) => {
    doneSound.play();
    const summary = flashcards.reduce(
      (acc, card) => {
        acc[card.correct] += 1;
        return acc;
      },
      { fail: 0, hard: 0, good: 0, easy: 0 }
    );
    return summary;
  };



useEffect(() => {
    const handleKeyDownWrapper = (e) => handleKeyDown(e, currentCollection, showBack, flipCard, rateCard, showCreateView, handleAskAI);
    
    window.addEventListener('keydown', handleKeyDownWrapper);
    return () => {
      window.removeEventListener('keydown', handleKeyDownWrapper);
    };
  }, [currentCollection, showBack, flipCard, rateCard, showCreateView]);


  const { transform, opacity } = useSpring({
    opacity: showBack ? 1 : 0,
    transform: `perspective(800px) rotateY(${showBack ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  useEffect(() => {
    if (currentCollection) {
      const filteredFlashcards = filterFlashcards(currentCollection);
      const newIndex = filteredFlashcards.findIndex(card => card === currentCollection.flashcards[currentCardIndex]);
      if (newIndex !== -1) {
        setCurrentCardIndex(newIndex);
      } else {
        setCurrentCardIndex(0);
      }
    }
  }, [filter, currentCollection]);

 // if (loading) return <SkeletonLoader width="100%" height="700px" />;

  return (

<>
    <Helmet>
      <title>{dynamicTitle}</title>
      <meta name="description" content={dynamicDescription} />
    </Helmet>

<Box sx={{...styles.container, backgroundColor: isDark === true ? '#15202B' : ''}} >

    <Box sx={styles.innerContainer}>



      <Typography variant="h4" sx={styles.header}>Flashcards</Typography>



<AIDialog
userId={user.uid}
  open={aiDialogOpen}
  onClose={() => {

    setAiDialogOpen(false);
  }}
initialQuestion={currentCollection && currentCollection.flashcards && currentCollection.flashcards[currentCardIndex] 
  ? `${currentCollection.flashcards[currentCardIndex].front} - ${currentCollection.flashcards[currentCardIndex].back}
Please explain these flashcards and help me understand the answer options. Skriv på svenska.`
  : 'Please explain the current flashcard and help me understand the answer options. Skriv på svenska.'}

  systemMessage="You are a helpful AI assistant designed to explain and assist flashcards practiceProvide clear, pedagogical explanations using analogies and examples when appropriate. Skriv på svenska, skriv kortfattat."
  theme={theme}
/>



        <UserCollectionsDrawer 
          user={user}
          theme={theme}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onStartTraining={startTraining}
          theme={theme}
        />




<PublicCollectionsDrawer 
  user={user}
  theme={theme}
  open={drawerOpenLibrary}
  onClose={toggleDrawerLibrary(false)}
  onStartTraining={startTraining}
  setCollections={setCollections}
  setShowLibrary={setDrawerLibraryOpen}
  setRefreshTrigger={setRefreshTrigger}
/>



<Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>



{isMobile ? (


 <Button variant="contained"  color="primary"  disableRipple  onClick={showCreateView} startIcon={<AddIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                /> 

) : (


<Button 
    variant="contained" 
    color="primary" 
    onClick={showCreateView}
    startIcon={<AddIcon />}
  >
    Skapa
  </Button>

)}



{isMobile ? (
 <Button variant="contained"  color="primary"  disableRipple  onClick={toggleDrawerLibrary(true)} startIcon={<ViewAgendaIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                /> 

) : (

<Button 
  variant="contained" 
  color="primary" 
  onClick={toggleDrawerLibrary(true)}
  startIcon={<ViewAgendaIcon />}
  sx={{ marginLeft: '0px' }}
>
Delade
</Button>

)}


  <Button
                    variant="contained" 
                    color="primary" 
                    disableRipple
                    onClick={toggleDrawer(true)}
                    startIcon={<SegmentIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                /> 


        






</Box>



      {sessionSummary ? (
 
 <TrainingSummary 
            sessionSummary={sessionSummary}
            currentCollection={currentCollection}
            startTraining={startTraining}
            theme={theme}
            styles={styles}
          />
      ) : (
        currentCollection ? (
          <Box sx={{ marginTop: '20px' }}>
            <Typography variant="h5" sx={styles.headerAboveBox}>
              {currentCollection.collectionName}
            </Typography>
 {/*
            <Box sx={{...styles.buttonsContainer, borderBottom:'0px'}}>
  <Button variant="outlined" onClick={() => setFilter('all')} sx={{...styles.beautyBtn, ...styles.beautyBtnBox }}>
    Alla kort ({currentCollection.flashcards.length})
  </Button>
  <Button 
    variant="outlined" 
    onClick={() => setFilter('fail')} 
    sx={{
      ...styles.beautyBtn, 
      ...styles.beautyBtnBox,
      transform: animateButton === 'fail' ? 'scale(1.05)' : 'scale(1)',
      transition: 'transform 0.15s ease-in-out',
    }}
  >
    Väldigt svåra ({currentCollection.flashcards.filter(card => card.correct === 'fail').length})
  </Button>
  <Button 
    variant="outlined" 
    onClick={() => setFilter('hard')} 
    sx={{
      ...styles.beautyBtn, 
      ...styles.beautyBtnBox,
      transform: animateButton === 'hard' ? 'scale(1.05)' : 'scale(1)',
      transition: 'transform 0.15s ease-in-out',
    }}
  >
    Svåra ({currentCollection.flashcards.filter(card => card.correct === 'hard').length})
  </Button>
  <Button 
    variant="outlined" 
    onClick={() => setFilter('good')} 
    sx={{
      ...styles.beautyBtn, 
      ...styles.beautyBtnBox,
      transform: animateButton === 'good' ? 'scale(1.05)' : 'scale(1)',
      transition: 'transform 0.15s ease-in-out',
    }}
  >
    Ganska lätta ({currentCollection.flashcards.filter(card => card.correct === 'good').length})
  </Button>
  <Button 
    variant="outlined" 
    onClick={() => setFilter('easy')} 
    sx={{
      ...styles.beautyBtn, 
      ...styles.beautyBtnBox,
      transform: animateButton === 'easy' ? 'scale(1.05)' : 'scale(1)',
      transition: 'transform 0.15s ease-in-out',
    }}
  >
    Lätta ({currentCollection.flashcards.filter(card => card.correct === 'easy').length})
  </Button>
</Box>
*/}

   <Box
      sx={{
        width: {xs:'100%', md:'800px'},
   height: {xs:'auto', md: '350px'}, // Change fixed height to auto for mobile
    minHeight: {xs: '200px', md: '350px'}, // Add minimum height
        position: 'relative',

      }}
    >


 <LinearProgress
    variant="determinate"
    value={(currentCardIndex / filterFlashcards(currentCollection).length) * 100}
    sx={{
      height: 10,
      borderRadius: 5,
      marginBottom: 2,
      '& .MuiLinearProgress-bar': {
        borderRadius: 5,
        backgroundColor: '#8b5cf6',
        transition: 'transform 0.5s ease-in-out',
      },
    }}
  />

      <animated.div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backfaceVisibility: 'hidden',
          opacity: opacity.to(o => 1 - o),
          transform,
        }}
      >

<Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
<Tooltip title="Blanda om">
  <IconButton onClick={shuffleCards} sx={{ color: theme.palette.text.primary }}>
    <ShuffleIcon sx={{color: isDark ? '#fff' : '#757575'  }} />
  </IconButton>
  </Tooltip>
</Box>

        <Box sx={{ position: 'absolute', top: 10, right: 50, zIndex: 1 }}>
          <Voice content={filterFlashcards(currentCollection)[currentCardIndex]?.front} fontSize={26} userId={user.uid} />
        </Box>


 <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
                <Tooltip title={bookmarks[currentCardIndex] ? "Sparat" : "Spara"}>
                  <IconButton onClick={toggleBookmark} sx={{ color: bookmarks[currentCardIndex] ? 'green' : theme.palette.text.primary }}>
                    {bookmarks[currentCardIndex] ? <CheckCircleIcon  /> : <BookmarkIcon  sx={{color: isDark ? '#fff' : '#757575'  }}  />}
                  </IconButton>
                </Tooltip>
              </Box>

<Box sx={{ position: 'absolute', top: '20px', left: '60px', zIndex: 1 }}>
<Hint front={filterFlashcards(currentCollection)[currentCardIndex]?.front} back={filterFlashcards(currentCollection)[currentCardIndex]?.back} user={user} theme={theme} />
</Box>

                <Typography variant="h6" sx={{...styles.cardText, fontSize:{xs:'17px', md:'22px', overflowY: 'auto',
    maxHeight: '100%', wordBreak: 'break-word'}}}>
                  {filterFlashcards(currentCollection)[currentCardIndex]?.front || 'Inga flashcards tillgängliga'}
                </Typography>
              </animated.div>
  <animated.div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backfaceVisibility: 'hidden',
          opacity,
          transform: transform.to(t => `${t} rotateY(180deg)`),
        }}
      >
                <Typography variant="h6" sx={{...styles.cardText, fontSize:{xs:'17px', md:'22px', overflowY: 'auto',
    maxHeight: '100%', wordBreak: 'break-word', display:'block',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }}}>
                  {filterFlashcards(currentCollection)[currentCardIndex]?.back || 'Inga flashcards tillgängliga'}
                </Typography>
              </animated.div>
            </Box>
<Box sx={{
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: { xs: 'center', md: 'center' },
  justifyContent: { md: 'flex-start' },
marginTop: { xs: '30px', md: '50px' },
marginBottom: { xs: '0px', md: '20px' },
}}>
  <Button
    variant="contained"
    onClick={flipCard}
    endIcon={(<SpaceBarIcon />)}
    sx={{
      mt: { xs: 2, md: 0 },
      mb: { xs: 2, md: 0 },
      width: { xs: '100%', md: 'auto' },
      maxWidth: { xs: '250px', md: 'none' },
      mr: { md: '32px' }
    }}
  >
    Vänd kort
  </Button>

   <Box sx={{
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: 'center',
  width: { xs: '100%', md: 'auto' },
}}>
  <Button
    variant="contained"
    color={isDark ? undefined : 'error'}
    onClick={() => rateCard('fail')}
    endIcon={<LooksOneIcon />}
    disabled={!showBack}
    sx={{
      backgroundColor: isDark ? '#FF4D4D' : undefined,
      color: isDark ? '#FFFFFF' : undefined,
      mt: { xs: 2, md: 0 },
      width: { xs: '100%', md: 'auto' },
      maxWidth: { xs: '250px', md: 'none' },
      mr: { md: '20px' }
    }}
  >
    Väldigt svår
  </Button>
  <Button
    variant="contained"
    color={isDark ? undefined : 'warning'}
    onClick={() => rateCard('hard')}
    endIcon={<LooksTwoIcon />}
    disabled={!showBack}
    sx={{
      backgroundColor: isDark ? '#CC8400' : undefined,
      color: isDark ? '#FFFFFF' : undefined,
      mt: { xs: 2, md: 0 },
      width: { xs: '100%', md: 'auto' },
      maxWidth: { xs: '250px', md: 'none' },
      mr: { md: '20px' }
    }}
  >
    Svår
  </Button>
  <Button
    variant="contained"
    color={isDark ? undefined : 'info'}
    onClick={() => rateCard('good')}
    endIcon={<Looks3Icon />}
    disabled={!showBack}
    sx={{
      backgroundColor: isDark ? '#0077AA' : undefined,
      color: isDark ? '#FFFFFF' : undefined,
      mt: { xs: 2, md: 0 },
      width: { xs: '100%', md: 'auto' },
      maxWidth: { xs: '250px', md: 'none' },
      mr: { md: '20px' }
    }}
  >
    Ganska lätt
  </Button>
  <Button
    variant="contained"
    color={isDark ? undefined : 'success'}
    onClick={() => rateCard('easy')}
    endIcon={<Looks4Icon />}
    disabled={!showBack}
    sx={{
      backgroundColor: isDark ? '#007700' : undefined,
      color: isDark ? '#FFFFFF' : undefined,
      mt: { xs: 2, md: 0 },
      width: { xs: '100%', md: 'auto' },
      maxWidth: { xs: '250px', md: 'none' },
    }}
  >
    Lätt
  </Button>
</Box>

</Box>

<Box

>

<Box sx={{textAlign:{xs: 'center', md:'left'}}}>
  <Button 
    variant="contained" 
    color="primary" 
    onClick={handleAskAI}
    startIcon={<GenerateIcon />}
    sx={{
      backgroundColor: '#791373',
      color: 'white',
      border: isDark ? 'undefined' : 'none',
      borderColor: '#591355',
      marginTop: {xs: '20px', md:'inherit'}
    }}
  >
    Fråga AI (F)
  </Button>
</Box>

<Box sx={{
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: { xs: 'stretch', md: 'flex-start' },
  justifyContent: 'space-between',
  gap: 2,
  mt: 7,
width: {xs:'100%', md:'800px'}
}}>




         {creatorInfo && (
    <Box sx={{ 
        ...styles.smallBox,
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff', 
      borderRadius: '10px',
      padding: '10px',
      marginTop: { xs: '30px', md: 0 },
      width: { xs: '100%', md: 'fit-content' }
    }}>
              <Avatar 
                src={creatorInfo.avatarUrl}
                sx={{ width: 37, height: 37, mr: 2, bgcolor: theme.palette.primary.main }}
              >
                {creatorInfo.displayName[0].toUpperCase()}
              </Avatar>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {creatorInfo.displayName}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center'}}>

                              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: '0.8rem'}}>
                            {formatDate(currentCollection.createdAt)}
                              </Typography>
                </Box>
              </Box>
            </Box>
          )}




<Box sx={{
  ...styles.smallBox,
  backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff',
  borderRadius: '10px',
  width: { xs: '100%', md: '48%' },
  padding: '0px',
  margin: { xs: '0 auto', md: 0 },
  marginTop: { xs: '80px', md: 0 },
}}>
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '13px', flexShrink: 0, padding: '20px', paddingRight:'0px' }}>
      GENVÄGAR
    </Typography>
    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
<Box sx={{
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  ml: 0, // Add this line for a small left margin
}}>
      <SpaceBarIcon />
      <LooksOneIcon />
      <LooksTwoIcon />
      <LooksThreeIcon />
      <LooksFourIcon />
      

      <Typography variant="body2" sx={{ ml: 1 }}>
        (F = Fråga AI)
      </Typography>
    </Box>
  </Box>
</Box>


<Box sx={{ width: { xs: '100%', md: 'auto' } }}>
<RecommendationComponent 
  itemId={originalCollectionId}
  userId={user.uid}
  collectionName="flashcardCollections"
  questionText="Skulle du rekommendera dessa flashcards till andra användare?"
/>
</Box>
</Box>
</Box>





          </Box>
        ) : (
          <Box sx={{ marginTop: '20px' }}>
            {isCreateViewVisible ? (
              <FlashcardsCreateView user={user}  onCollectionCreated={handleCollectionCreated} isDark={isDark} />
            ) : (
              <Box>
                <Typography variant="body1">
                  Välj en samling ovan eller skapa dina egna flashcards för att börja träna.
                </Typography>
              </Box>
            )}
          </Box>
        )
      )}
    </Box>
    </Box>
    </>
  );
};

export default FlashcardsView;
