import { collection, addDoc, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import BallotIcon from '@mui/icons-material/Ballot';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Logger from './Logger';

  const getQuestionTypeIcon = (questionType) => {
    switch(questionType) {
      case 'multipleChoice':
        return <BallotIcon sx={{ fontSize: 14 }} />;
      case 'fill-in-the-blank':
        return <ShortTextIcon sx={{ fontSize: 14 }} />;
      case 'true-or-false':
        return <FactCheckIcon sx={{ fontSize: 14 }} />;
      default:
        return null;
    }
  };

  const getQuestionTypeLabel = (questionType) => {
    const options = [
      { value: 'multipleChoice', label: 'Flervalsfrågor', icon: <BallotIcon /> },
      { value: 'fill-in-the-blank', label: 'Lucktext', icon: <ShortTextIcon /> },
      { value: 'true-or-false', label: 'Sant/Falskt', icon: <FactCheckIcon /> },
    ];
    const option = options.find(opt => opt.value === questionType);
    return option ? option.label : questionType;
  };

const saveQuizResult = async (quizId, results, user) => {
    try {
        const correctAnswers = results.filter(result => result.isCorrect).length;
        const totalQuestions = results.length;
        const score = (correctAnswers / totalQuestions) * 100;

        await addDoc(collection(db, 'quizResults'), {
            userId: user.uid,
            quizId: quizId,
            score: score,
            totalQuestions: totalQuestions,
            timestamp: Date.now(),
            detailedResults: results.map(result => ({
                question: result.question,
                selectedOption: result.selectedOption,
                correctOption: result.correct,
                isCorrect: result.isCorrect
            }))
        });

        Logger.log("results to be saved in saveQuizResult, one missing? ", results);


        const quizRef = doc(db, 'quiz', quizId);
        await updateDoc(quizRef, {
            results: results,
            lastTrainingSession: Date.now()
        });


        Logger.log("Quiz result saved successfully");
    } catch (error) {
        console.error('Error saving quiz result:', error);
    }
};




const generateDummyData = async (quizId) => {
    const generateRandomUserId = () => `user_${Math.random().toString(36).substr(2, 9)}`;

    const generateRandomResults = () => {
        const numQuestions = 10;
        return Array(numQuestions).fill().map((_, index) => ({
            question: `Sample Question ${index + 1}`,
            selectedOption: `Option ${Math.floor(Math.random() * 4) + 1}`,
            correct: `Option ${Math.floor(Math.random() * 4) + 1}`,
            isCorrect: Math.random() > 0.5
        }));
    };

    const generateRandomDate = (start, end) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    };

    const numUsers = 20; // Generate data for 20 users

    for (let i = 0; i < numUsers; i++) {
        const userId = generateRandomUserId();
        const numAttempts = Math.floor(Math.random() * 5) + 1; // 1 to 5 attempts per user

        for (let j = 0; j < numAttempts; j++) {
            const results = generateRandomResults();
            const timestamp = generateRandomDate(new Date(2023, 0, 1), new Date()).getTime();
            const score = (results.filter(r => r.isCorrect).length / results.length) * 100;

            await addDoc(collection(db, 'quizResults'), {
                userId: userId,
                quizId: quizId,
                score: score,
                totalQuestions: results.length,
                timestamp: timestamp,
                detailedResults: results
            });

            Logger.log(`Dummy data saved for User ${i + 1}, attempt ${j + 1}`);
        }
    }

    Logger.log('All dummy data generated and saved successfully');
};

const getQuizResults = async (quizId) => {
    const q = query(
        collection(db, 'quizResults'),
        where('quizId', '==', quizId)
    );

    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map(doc => doc.data());
    
    // Sort results on the client side
    return results.sort((a, b) => b.score - a.score);
};

const getTopScores = async (quizId, limitCount = 10) => {
    const results = await getQuizResults(quizId);
    
    // Group results by userId and calculate the best score and total attempts
    const groupedResults = results.reduce((acc, result) => {
        if (!acc[result.userId] || result.score > acc[result.userId].score) {
            acc[result.userId] = { ...result, attempts: 1 };
        } else {
            acc[result.userId].attempts += 1;
        }
        return acc;
    }, {});

    // Convert back to array and sort
    const sortedResults = Object.values(groupedResults)
        .sort((a, b) => b.score - a.score)
        .slice(0, limitCount);

    return sortedResults;
};

const getUserProgress = async (userId, quizId) => {
    const q = query(
        collection(db, 'quizResults'),
        where('userId', '==', userId),
        where('quizId', '==', quizId)
    );

    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
    }));

    // Sort by timestamp on the client side
    return results.sort((a, b) => a.timestamp - b.timestamp);
};

export { saveQuizResult, getQuizResults, getTopScores, getUserProgress, generateDummyData, getQuestionTypeIcon, getQuestionTypeLabel };