import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Switch, 
  FormControlLabel, 
  Typography, 
  Tooltip,
  Box
} from '@mui/material';
import { ref, uploadBytes, getDownloadURL } from './firebase';
import { db, storage, auth } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import { Avatar, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const TeacherDialog = ({ open, onClose }) => {
  const [teacherData, setTeacherData] = useState({
    characterName: '',
    tagline: '',
    description: '',
    greeting: '',
    voice: '',
    teachingStyle: '',
    personality: '',
    pedagogicalPhilosophy: '',
    communicationStyle: '',
    background: '',
    approachToMistakes: '',
    dyslexiaAdapted: false,
    adhdAdapted: false,
    imageUrl: ''
  });
  
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const maxLengths = {
    characterName: 50,
    tagline: 100,
    description: 500,
    greeting: 200,
    teachingStyle: 100,
    personality: 100,
    pedagogicalPhilosophy: 300,
    communicationStyle: 100,
    background: 300,
    approachToMistakes: 300,
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setTeacherData(prev => ({ ...prev, [name]: newValue }));
  };

const handleImageChange = (e) => {
  if (e.target.files[0]) {
    setImage(e.target.files[0]);
    setPreviewUrl(URL.createObjectURL(e.target.files[0]));
  }
};

  const handleSubmit = async () => {
    try {
      if (!auth.currentUser) {
        console.error("Ingen användare inloggad");
        return;
      }

      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `teacherImages/${auth.currentUser.uid}/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await addDoc(collection(db, "teachers"), {
        ...teacherData,
        imageUrl,
        userId: auth.currentUser.uid,
        createdAt: new Date()
      });

      onClose();
    } catch (error) {
      console.error("Fel vid tillägg av lärare: ", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Lägg till ny lärare</DialogTitle>
      <DialogContent>
      <Box>
        <input
          accept="image/*"
          type="file"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="image-upload"
        />
        <label htmlFor="image-upload">
          <IconButton component="span">
            <Avatar
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'orange',
                cursor: 'pointer'
              }}
            >
              {previewUrl ? (
                <img src={previewUrl} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              ) : (
                <AddAPhotoIcon sx={{ fontSize: 40 }} />
              )}
            </Avatar>
          </IconButton>
        </label>

        
</Box>

          <Typography variant="formLabelSinUnderline">Karaktärens namn</Typography>

        <TextField
          fullWidth
          margin="normal"
          name="characterName"
          value={teacherData.characterName}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.characterName }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.characterName.length}/{maxLengths.characterName}
          </Typography>
        </Box>

        <Tooltip title="Kort och catchy beskrivning av läraren">
          <Typography variant="formLabel">Slogan</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="tagline"
          value={teacherData.tagline}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.tagline }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.tagline.length}/{maxLengths.tagline}
          </Typography>
        </Box>

        <Tooltip title="Detaljerad beskrivning av lärarens egenskaper och stil">
          <Typography variant="formLabel">Beskrivning</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="description"
          multiline
          rows={4}
          value={teacherData.description}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.description }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.description.length}/{maxLengths.description}
          </Typography>
        </Box>

        <Tooltip title="Lärarens inledande hälsningsfras till studenter">
          <Typography variant="formLabel">Hälsningsfras</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="greeting"
          multiline
          rows={2}
          value={teacherData.greeting}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.greeting }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.greeting.length}/{maxLengths.greeting}
          </Typography>
        </Box>

        <FormControl fullWidth margin="normal">
          <Tooltip title="Välj lärarens röst">
            <Typography variant="formLabel">Röst</Typography>
          </Tooltip>
          <Select
            name="voice"
            value={teacherData.voice}
            onChange={handleChange}
          >
            <MenuItem value="röst1">Röst 1</MenuItem>
            <MenuItem value="röst2">Röst 2</MenuItem>
          </Select>
        </FormControl>

        <Tooltip title="Beskriv lärarens pedagogiska metoder">
          <Typography variant="formLabel">Undervisningsstil</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="teachingStyle"
          value={teacherData.teachingStyle}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.teachingStyle }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.teachingStyle.length}/{maxLengths.teachingStyle}
          </Typography>
        </Box>

        <Tooltip title="Beskriv lärarens personlighetsdrag">
          <Typography variant="formLabel">Personlighet</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="personality"
          value={teacherData.personality}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.personality }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.personality.length}/{maxLengths.personality}
          </Typography>
        </Box>

        <Tooltip title="Beskriv lärarens grundläggande pedagogiska övertygelser">
          <Typography variant="formLabel">Pedagogisk filosofi</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="pedagogicalPhilosophy"
          multiline
          rows={2}
          value={teacherData.pedagogicalPhilosophy}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.pedagogicalPhilosophy }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.pedagogicalPhilosophy.length}/{maxLengths.pedagogicalPhilosophy}
          </Typography>
        </Box>

        <Tooltip title="Beskriv hur läraren kommunicerar med studenter">
          <Typography variant="formLabel">Kommunikationsstil</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="communicationStyle"
          value={teacherData.communicationStyle}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.communicationStyle }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.communicationStyle.length}/{maxLengths.communicationStyle}
          </Typography>
        </Box>

        <Tooltip title="Beskriv lärarens erfarenheter och bakgrund">
          <Typography variant="formLabel">Bakgrund</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="background"
          multiline
          rows={2}
          value={teacherData.background}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.background }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.background.length}/{maxLengths.background}
          </Typography>
        </Box>

        <Tooltip title="Beskriv hur läraren hanterar misstag och frågor från studenter">
          <Typography variant="formLabel">Förhållningssätt till misstag och frågor</Typography>
        </Tooltip>
        <TextField
          fullWidth
          margin="normal"
          name="approachToMistakes"
          multiline
          rows={2}
          value={teacherData.approachToMistakes}
          onChange={handleChange}
          inputProps={{ maxLength: maxLengths.approachToMistakes }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            {teacherData.approachToMistakes.length}/{maxLengths.approachToMistakes}
          </Typography>
        </Box>

        <FormControlLabel
          control={
            <Switch
              checked={teacherData.dyslexiaAdapted}
              onChange={handleChange}
              name="dyslexiaAdapted"
            />
          }
          label="Dyslexi-anpassad"
        />
        <FormControlLabel
          control={
            <Switch
              checked={teacherData.adhdAdapted}
              onChange={handleChange}
              name="adhdAdapted"
            />
          }
          label="ADHD-anpassad"
        />
        <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginTop: 16 }}>
          Lägg till lärare
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TeacherDialog;