import React, { useState, useCallback, useRef } from 'react';
import { Button, IconButton, Dialog, DialogContent, Typography, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { useDropzone } from 'react-dropzone';
import StopIcon from '@mui/icons-material/Stop';
import { debounce } from 'lodash';
import { getStyles } from './index';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import Logger from './Logger';
import EqualizerIcon from './EqualizerIcon';
import MutedEqualizerIcon from './MutedEqualizerIcon';

const DropzoneDialog = ({ open, onClose, onDrop }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Image = reader.result.split(',')[1];
                    onDrop(`data:image/${file.type.split('/')[1]};base64,${base64Image}`);
                };
                reader.readAsDataURL(file);
            }
            onClose();
        },
        accept: 'image/*'
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogContent>
                <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
                    <input {...getInputProps()} />
                    <Typography variant="body1">Släpp en bild här, eller klicka för att välja en.</Typography>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const TextInput = React.forwardRef(({ input, setInput, sendMessage, currentChatId, awaitingReply, onImageUpload, isStreaming, onStopStreaming, isDark }, ref) => {
    const [localInput, setLocalInput] = useState(input);
    const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
    const contentRef = useRef(null);
    const fileInputRef = useRef(null);
 
const { showSnackbar } = useSnackbar(); 
const theme = useTheme();
const styles = getStyles(theme);


    const debouncedSetInput = useCallback(
        debounce((value) => {
            setInput(value);
        }, 300),
        [setInput]
    );


const cleanHTMLContent = (html) => {
    // Ta bort alla <br> och ersätt dem med en ny linje om du vill bevara ny rad
    return html.replace(/(<br\s*\/?>\s*)+$/, '');
};


    const sanitizeHTML = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const images = tempDiv.getElementsByTagName('img');
        let sanitizedHTML = '';

        for (let i = 0; i < images.length; i++) {
            const img = images[i];
            sanitizedHTML += img.outerHTML;
        }

        return sanitizedHTML;
    };

    const handleInputChange = useCallback(() => {
        if (contentRef.current) {
            const htmlContent = contentRef.current.innerHTML;
            const sanitizedContent = sanitizeHTML(htmlContent);

            setLocalInput(sanitizedContent);
            debouncedSetInput(sanitizedContent);
        }
    }, [debouncedSetInput]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (currentChatId) {


 Logger.log("contentRef.current.innerHTML: ", contentRef.current.innerHTML);
if (contentRef.current.innerHTML.length > 2000) {
      showSnackbar("Meddelandet till AI-modellen var för långt. ", 'info');
      return;
    }

                const contentToSend = cleanHTMLContent(contentRef.current.innerHTML);
                sendMessage(contentToSend);
                setLocalInput('');
                if (contentRef.current) {
                    contentRef.current.innerHTML = '';
                }
            } else {
                console.error('No active chat selected');
            }
        }
    }, [currentChatId, sendMessage]);

const handleSendClick = useCallback(() => {

 Logger.log("contentRef.current.innerHTML: ", contentRef.current.innerHTML);
if (contentRef.current.innerHTML.length > 2000) {
      showSnackbar("Meddelandet till AI-modellen var för långt.", 'info');
      return;
    }

    if (currentChatId) {
        const contentToSend = cleanHTMLContent(contentRef.current.innerHTML);
        sendMessage(contentToSend);
        setLocalInput('');
        if (contentRef.current) {
            contentRef.current.innerHTML = '';
        }
        Logger.log('Before sending:', contentRef.current.innerHTML);

    } else {
        console.error('No active chat selected');
    }
}, [currentChatId, sendMessage]);


    const handleImageUpload = (base64Image) => {

        showSnackbar("Bilduppladdning är för tillfället inaktiverad då det kräver enorma mängder tokens.<br /> Hör av dig via Feedback-länken till vänster om du vill att vi aktiverar det åt dig.", 'info', 5000);
        // if (contentRef.current) {
        //     const imageHTML = `<img src="${base64Image}" style="max-width: 100%; height: auto;" />`;
        //     contentRef.current.innerHTML = imageHTML;
        //     handleInputChange();
        //     if (onImageUpload) {
        //         onImageUpload(base64Image);
        //     }
        // }
    };

    const openFileDialog = () => {
        handleImageUpload();
        //fileInputRef.current.click();
    };

    const openDropzoneDialog = () => {
        setIsDropzoneOpen(true);
    };

    const closeDropzoneDialog = () => {
        setIsDropzoneOpen(false);
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', borderRadius: 30, backgroundColor: isDark === true ? '#212c36' : 'white', border: isDark === true ? '1px solid #3b4651' : '1px solid #ccc', padding: '5px', overflowY: 'auto', width: '100%' }}>
<div
    contentEditable
    ref={contentRef}
    onInput={handleInputChange}
    onKeyDown={handleKeyDown}
    data-placeholder="Skriv ett meddelande..."
    style={{
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: '100px',
        padding: '4px',
        paddingLeft:'15px',
        paddingRight:'10px',
        outline: 'none',
        whiteSpace: 'pre-wrap',

    }}

/>


<Tooltip title="Stöd för samtal kommer snart!">
                <IconButton

                    sx={{
                        minWidth: '30px',
                        minHeight: '30px',
                        marginRight:'5px',
                        padding: '0',
                        backgroundColor: 'transparent',

                        borderRadius: '20px',
                        boxShadow: 'none',
   
                    }}
                >
                    <MutedEqualizerIcon sx={{ color: isDark ? '#80858a' : '#555', "&:hover": { color: isDark ? 'white' : 'inherit' } }} />
                </IconButton>
</Tooltip>
                <IconButton
                    onClick={openDropzoneDialog}
                    sx={{
                        minWidth: '40px',
                        minHeight: '40px',
                        marginRight:'10px',
                        padding: '0',
                        backgroundColor: 'transparent',

                        borderRadius: '20px',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: isDark ? 'transparent' : '#eee9ff',
                            boxShadow: 'none',
                        }
                    }}
                >
                    <PhotoSizeSelectActualOutlinedIcon sx={{ color: isDark ? '#80858a' : '#555', "&:hover": { color: isDark ? 'white' : 'inherit' } }} />
                </IconButton>

                <Button
                    onClick={isStreaming ? onStopStreaming : handleSendClick}
                    disabled={awaitingReply && !isStreaming}
                    sx={{
                        minWidth: '40px',
                        minHeight: '40px',
                        padding: '0',
                        backgroundColor: '#ebebeb',
                        borderRadius: '20px',

                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: '#eee9ff',
                            boxShadow: 'none',
                            '& .MuiSvgIcon-root': {
                                color: '#8b5cf6',
                            }
                        }
                    }}
                >
{isStreaming ? (
            <StopIcon
              sx={{
                color: '#999',
                transition: 'color 0.3s ease',
                paddingLeft: '2px'
              }}
            />
          ) : (
            <SendIcon
              disabled={awaitingReply}
              sx={{
                color: awaitingReply ? '#999' : '#000',
                transition: 'color 0.3s ease',
                paddingLeft: '2px'
              }}
            />
          )}
                </Button>
            </div>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleImageUpload}
                // onChange={(e) => {
                //     const file = e.target.files[0];
                //     if (file) {
                //         const reader = new FileReader();
                //         reader.onloadend = () => {
                //             const base64Image = reader.result.split(',')[1];
                //             handleImageUpload(`data:image/${file.type.split('/')[1]};base64,${base64Image}`);
                //         };
                //         reader.readAsDataURL(file);
                //     }
                // }}
                style={{ display: 'none' }}
            />
            <DropzoneDialog open={isDropzoneOpen} onClose={closeDropzoneDialog} onDrop={handleImageUpload} />
        </>
    );
});

export default React.forwardRef((props, ref) => <TextInput {...props} ref={ref} />);
