import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Input, CircularProgress } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import mammoth from 'mammoth'; // Import mammoth for Word document processing
import { callApi } from './api';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

const HomeworkHelperView = () => {
    const [input, setInput] = useState('');
    const [instructions, setInstructions] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
        },
        header: {
            marginBottom: 2,
            marginTop: 3,
            fontSize: '25px',
            fontWeight: 'bold',
        },
        textarea: {
            width: '100%',
            maxWidth: '800px',
            marginBottom: '20px',
            background: 'white',
            marginTop: '40px'
        },
        buttonsContainer: {
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            paddingBottom: 2,
            borderBottom: '1px solid #e0e0e0'
        },
        paper: {
            padding: '20px',
            maxWidth: '900px',
            width: '100%',
            marginTop: '20px',
            marginBottom: '40px',
            borderRadius: '7px'
        },
        beautyBtn: {
            color: '#666',
            textTransform: 'none',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: 10,
            padding: '4px 17px',
            display: 'flex',
            alignItems: 'center',
            '& .MuiButton-startIcon': {
                marginRight: '4px',
            },
            '& .MuiButton-endIcon': {
                marginLeft: '2px', 
            },
            '&:hover': {
                backgroundColor: '#f1f1f1',
                color: '#505050',
                border: 'none',
                boxShadow: 'none',
                '& .MuiSvgIcon-root': {
                    color: '#505050',
                },
            },
            '&:focus': {
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
            }
        }
    };

    const handleTextChange = (event) => {
        setInput(event.target.value);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                try {
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setInput(result.value); // Update input state with extracted text
                } catch (error) {
                    console.error('Error extracting text from file:', error);
                    // Handle error if text extraction fails
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleHelp = async (textToUse) => {
        setIsLoading(true);

        try {
            const text = textToUse || input; // Use either specified text or current input
            const response = await callApi([
                { role: 'system', content: "Du är en expert som ska ge uppmuntrande svar på läxan som skrivs in i texten. Responsen ska delas upp i olika rubriker med <h3>rubrik</h3> och paragrafer ( använd listor sparsamt), även citat om det passar." },
//                { role: 'system', content: "Du är en expert som ska ge uppmuntrande svar på läxan som skrivs in i texten. Responsen ska delas upp i olika steg med steg 1, steg 2 osv där varje steg är en <h3>rubrik</h3>" },
                { role: 'user', content: text }
            ]);

            if (typeof response === 'string') {
                setInstructions(response);
            } else if (response && response.data) {
                setInstructions(response.data);
            } else {
                setInstructions('Ett oväntat fel inträffade');
            }
        } catch (error) {
            console.error('Error while fetching instructions:', error);
            setInstructions('Ett fel inträffade vid hämtning av instruktioner');
        } finally {
            setIsLoading(false);
        }
    };

    const exampleTasks = [
        "Förklara andra världskrigets viktigaste händelser",
        "Gå igenom Kubakrisen",
        "Diskutera orsakerna till Berlinmurens fall",
        "Sammanfatta industrialiseringens påverkan på Storbritannien",
        "Jämför och kontrastera demokrati och diktatur",
        "Analysera konsekvenserna av global uppvärmning",
        "Diskutera människorättigheter och deras betydelse idag",
        "Förklara moderna teknologiers inverkan på samhället",
        "Miljökonsekvenser av plastanvändning",
        "Ekonomisk utveckling i Sydostasien",
        "Migrationens påverkan på europeiska länder"
        // Add more example tasks as needed
    ];

    return (
        <Box sx={styles.container}>
            <Typography variant="h4" sx={styles.header}>Läxhjälp med AI</Typography>

            <Box sx={styles.buttonsContainer}>
                {exampleTasks.map((task, index) => (
                    <Button
                        sx={styles.beautyBtn}
                        key={index}
                        variant="outlined"
                        onClick={() => handleHelp(task)}
                    >
                        {task}
                    </Button>
                ))}
            </Box>

            <TextField
                variant="outlined"
                multiline
                rows={10}
                placeholder="Skriv in din läxa här"
                value={input}
                onChange={handleTextChange}
                sx={styles.textarea}
            />

            <div style={styles.buttonWrapper}>
                <Button
                    startIcon={<TrackChangesIcon />}
                    sx={{ marginRight: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleHelp(input)}
                    disabled={!input || isLoading}
                >
                    {isLoading ? 'Hämtar förklaring...' : 'Hjälp mig med läxan'}
                </Button>
                <label htmlFor="file-upload">
                    <Input
                        id="file-upload"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        startIcon={<FileUploadIcon />}
                    >
                        Ladda upp Word-fil
                    </Button>
                </label>
            </div>

            {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {!isLoading && instructions && (
                <Paper elevation={3} sx={styles.paper}>
                    <Typography variant="h6" sx={styles.instructionHeader}>Resultat:</Typography>
                    <div dangerouslySetInnerHTML={{ __html: instructions }} />
                </Paper>
            )}
        </Box>
    );
};

export default HomeworkHelperView;
