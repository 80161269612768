import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Select, MenuItem, InputLabel, FormControl, CircularProgress, LinearProgress } from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { callApi } from './api';

const EssayWriterView = () => {
    const [input, setInput] = useState('');
    const [essayType, setEssayType] = useState('');
    const [educationLevel, setEducationLevel] = useState('');
    const [tone, setTone] = useState('');
    const [includeReferences, setIncludeReferences] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [generatedEssay, setGeneratedEssay] = useState('');

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleGenerateEssay = async () => {
        setIsLoading(true);
        const content = `
            Uppsatstema: ${input}
            Typ av uppsats: ${essayType}
            Utbildningsnivå: ${educationLevel}
            Ton: ${tone}
            Inkludera källor och referenser: ${includeReferences ? 'Ja' : 'Nej'}
        `;

        try {
            const response = await callApi([
                { role: 'system', content: "Du är en expert som hjälper till att skriva uppsatser baserat på användarens val, omslut rubrikerna med faktiskta html-taggar <h1></h1> <h2></h2> <h3></h3> etc. Uppsatsen ska vara på minst 2500 ord med en lång innehållsförteckning med en struktur utifrån Utbildningsnivå.  Följande är användarens val:" },
                { role: 'user', content }
            ], 1000);

            if (typeof response === 'string') {
                setGeneratedEssay(response);
            } else if (response && response.data) {
                setGeneratedEssay(response.data);
            } else {
                setGeneratedEssay('Ett oväntat fel inträffade');
            }
        } catch (error) {
            console.error('Error while fetching essay:', error);
            setGeneratedEssay('Ett fel inträffade vid hämtning av uppsatsen');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyEssay = () => {
        navigator.clipboard.writeText(generatedEssay).then(() => {
            alert('Uppsatsen har kopierats till urklipp');
        });
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            padding: '20px',
        },
        header: {
            marginBottom: 3,
            marginTop: 3,
            fontSize: '25px',
            fontWeight: 'bold',
        },
        textarea: {
            width: '100%',
            maxWidth: '800px',
            marginBottom: '20px',
            background: 'white',
            marginTop: '20px'
        },
        formControl: {
            marginBottom: '20px',
            width: '100%',
            maxWidth: '800px'
        },
        buttonWrapper: {
            display: 'flex',
            gap: '10px',
            marginTop: '20px'
        },
        paper: {
            padding: '20px',
            maxWidth: '900px',
            width: '100%',
            marginTop: '20px',
            borderRadius: '7px'
        }
    };

    return (
        <Box sx={styles.container}>
            <Typography variant="h4" sx={styles.header}>Essay Writer</Typography>

            <TextField
                variant="outlined"
                multiline
                rows={4}
                placeholder="Vad ska uppsatsen handla om?"
                value={input}
                onChange={handleInputChange}
                sx={styles.textarea}
            />

            <FormControl variant="outlined" sx={styles.formControl}>
                <InputLabel>Typ av uppsats</InputLabel>
                <Select value={essayType} onChange={(e) => setEssayType(e.target.value)} label="Typ av uppsats">
                    <MenuItem value="klassisk">Klassisk</MenuItem>
                    <MenuItem value="jamforelse">Jämförelse</MenuItem>
                    <MenuItem value="argumentativ">Argumentativ</MenuItem>
                    <MenuItem value="overtalande">Övertygande</MenuItem>
                    <MenuItem value="kritik">Kritik</MenuItem>
                    <MenuItem value="memoar">Memoar</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="outlined" sx={styles.formControl}>
                <InputLabel>Utbildningsnivå</InputLabel>
                <Select value={educationLevel} onChange={(e) => setEducationLevel(e.target.value)} label="Utbildningsnivå">
                    <MenuItem value="hogstadium">Högstadium</MenuItem>
                    <MenuItem value="gymnasium">Gymnasium</MenuItem>
                    <MenuItem value="universitet">Universitet</MenuItem>
                    <MenuItem value="kandidat">Kandidat</MenuItem>
                    <MenuItem value="master">Master</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="outlined" sx={styles.formControl}>
                <InputLabel>Ton</InputLabel>
                <Select value={tone} onChange={(e) => setTone(e.target.value)} label="Ton">
                    <MenuItem value="akademisk">Akademisk</MenuItem>
                    <MenuItem value="sarkastisk">Sarkastisk</MenuItem>
                    <MenuItem value="informell">Informell</MenuItem>
                    <MenuItem value="bestamd">Bestämd</MenuItem>
                    <MenuItem value="vanskaplig">Vänskaplig</MenuItem>
                    <MenuItem value="humoristisk">Humoristisk</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="outlined" sx={styles.formControl}>
                <InputLabel>Inkludera källor och referenser?</InputLabel>
                <Select value={includeReferences ? 'ja' : 'nej'} onChange={(e) => setIncludeReferences(e.target.value === 'ja')} label="Inkludera källor och referenser?">
                    <MenuItem value="ja">Ja</MenuItem>
                    <MenuItem value="nej">Nej</MenuItem>
                </Select>
            </FormControl>

            <div style={styles.buttonWrapper}>
                <Button
                    startIcon={<GenerateIcon />}
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateEssay}
                    disabled={isLoading}
                >
                    {isLoading ? 'Genererar uppsats...' : 'Generera uppsatsen'}
                </Button>
            </div>

<Box sx={styles.loadingContainer}>
    {isLoading && (
        <>
            <CircularProgress />
       
        </>
    )}
</Box>


            {!isLoading && generatedEssay && (
                <Paper elevation={3} sx={styles.paper}>
                    <Typography variant="h6">Genererad uppsats:</Typography>
                    <div dangerouslySetInnerHTML={{ __html: generatedEssay }} />

                    <Button
                        startIcon={<CopyIcon />}
                        variant="outlined"
                        color="secondary"
                        onClick={handleCopyEssay}
                        sx={{ marginTop: '20px' }}
                    >
                        Kopiera texten
                    </Button>
                </Paper>
            )}
        </Box>
    );
};

export default EssayWriterView;
