import React, { useState, useEffect } from 'react';
import { Box, Radio, Typography, FormControlLabel, RadioGroup } from '@mui/material';

import BallotIcon from '@mui/icons-material/Ballot';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FactCheckIcon from '@mui/icons-material/FactCheck';



const QuestionTypeSelector = ({ setQuestionType, disableTrueFalse = false, theme }) => {
  const [selectedType, setSelectedType] = useState('multipleChoice');
  
  useEffect(() => {
    setQuestionType(selectedType);
  }, [selectedType, setQuestionType]);

  const handleChange = (value) => {
    if (!(disableTrueFalse && value === 'trueFalse')) {
      setSelectedType(value);
    }
  };

  const options = [
    {
      value: 'multipleChoice',
      label: 'Flervalsfrågor',
      description: 'Generera flervalsfrågor med ett korrekt svar',
      icon: <BallotIcon />,
    },
    {
      value: 'fill-in-the-blank',
      label: 'Lucktext',
      description: 'Skapa frågor där man fyller i saknade ord',
      icon: <ShortTextIcon />,
    },
    {
      value: 'true-or-false',
      label: 'Sant/Falskt',
      description: 'Generera påståenden som man bedömer som sanna eller falska',
      icon: <FactCheckIcon />,
    },
  ];

  return (
    <RadioGroup
      value={selectedType}
      onChange={(event) => handleChange(event.target.value)}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4, width: '100%' }}
    >
      {options.map((option) => (
        <Box 
          key={option.value} 
          sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
          onClick={() => handleChange(option.value)}
        >
          <Radio
            value={option.value}
            sx={{ mr: 1 }}
            checked={selectedType === option.value}
           // disabled={disableTrueFalse && option.value === 'trueFalse'}
          />
<Box 
  sx={(theme) => ({ 
    display: 'flex', 
    alignItems: 'center',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#3b4651' : theme.palette.grey[300]}`,
    width: '100%', 
    padding: '10px', 
    borderRadius: '20px', 
    cursor: disableTrueFalse && option.value === 'trueFalse' ? 'not-allowed' : 'pointer',
    bgcolor: selectedType === option.value 
      ? theme.palette.mode === 'dark' ? '#1c262f' : theme.palette.grey[200]
      : 'transparent',
    opacity: disableTrueFalse && option.value === 'trueFalse' ? 0.5 : 1,
  })}
>
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    marginRight: 1,
    flexShrink: 0
  }}>
              {React.cloneElement(option.icon, { style: { fontSize: 32, color:theme.palette.mode === 'dark' ? 'white' : '#555' } })}
            </Box>
            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
              <Typography variant="subtitle1" component="div" sx={{ fontWeight: '600', color: theme.palette.mode === 'dark' ? 'white' : '#333' }}>
                {option.label}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </RadioGroup>
  );
};

export default QuestionTypeSelector;