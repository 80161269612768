import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Avatar, useTheme, Chip } from '@mui/material';
import { getTopScores, getQuizResults, getUserProgress } from './quizFunctions';
import { LineChart, AreaChart, Area, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { EmojiEvents, LooksOne, LooksTwo, Looks3 } from '@mui/icons-material';

import { format, fromUnixTime } from 'date-fns';
import { sv } from 'date-fns/locale';




import { getStyles } from './index';

const trophyColors = ['#FFD700', '#C0C0C0', '#CD7F32'];
export const QuizLeaderboard = ({ quizId }) => {
  const [topScores, setTopScores] = useState([]);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    const fetchTopScores = async () => {
      try {
        const scores = await getTopScores(quizId);
        setTopScores(scores.slice(0, 10)); // Keep top 10 scores
      } catch (error) {
        console.error('Error fetching top scores:', error);
        setError(error.message);
      }
    };
    fetchTopScores();
  }, [quizId]);

  const isDarkMode = theme.palette.mode === 'dark';

  const getRowColor = (index) => {
    const baseColor = isDarkMode ? '#1e2a35' : '#f3f4f6';
    const goldColor = isDarkMode ? '#ffd70033' : '#ffd70066';
    const silverColor = isDarkMode ? '#c0c0c033' : '#c0c0c066';
    const bronzeColor = isDarkMode ? '#cd7f3233' : '#cd7f3266';

    switch (index) {
      case 0: return goldColor;
      case 1: return silverColor;
      case 2: return bronzeColor;
      default: return baseColor;
    }
  };

  const getRankIcon = (index) => {
    switch (index) {
      case 0: return <LooksOne fontSize="small" sx={{ color: '#FFD700' }} />;
      case 1: return <LooksTwo fontSize="small" sx={{ color: '#C0C0C0' }} />;
      case 2: return <Looks3 fontSize="small" sx={{ color: '#CD7F32' }} />;
      default: return <EmojiEvents fontSize="small" sx={{ color: theme.palette.text.secondary }} />;
    }
  };

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (topScores.length === 0) {
    return <Typography>No scores available.</Typography>;
  }

  return (
    <>
      <Typography variant="h2" sx={{...styles.header, fontSize:'18px', marginTop:'0px'}}>Topplistan</Typography>
    <TableContainer component={Paper} sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>User</TableCell>
            <TableCell align="right">Score</TableCell>
            <TableCell align="right">Questions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topScores.map((score, index) => (
            <TableRow key={index} sx={{ backgroundColor: getRowColor(index) }}>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {getRankIcon(index)}
                  <Typography sx={{ ml: 1 }}>{index + 1}</Typography>
                </Box>
              </TableCell>
              <TableCell>{score.userId || 'Unknown'}</TableCell>
              <TableCell align="right">{score.score?.toFixed(2) || 'N/A'}%</TableCell>
              <TableCell align="right">{score.totalQuestions || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};


export const UserPercentile = ({ quizId, userScore }) => {
    const [percentile, setPercentile] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        const calculatePercentile = async () => {
            const allResults = await getQuizResults(quizId);
            const totalParticipants = allResults.length;
            const betterScores = allResults.filter(result => result.score > userScore).length;
            const calculatedPercentile = ((totalParticipants - betterScores) / totalParticipants) * 100;
            setPercentile(Math.round(calculatedPercentile));
        };
        calculatePercentile();
    }, [quizId, userScore]);

    const getColor = (percentile) => {
        if (percentile >= 90) return '#4caf50';
        if (percentile >= 70) return '#2196f3';
        if (percentile >= 50) return '#ff9800';
        return '#f44336';
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,


        }}>
            <Typography variant="body1" sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#555' }}>
                Ditt resultat var bättre än
            </Typography>
            {percentile !== null && (
                <Typography 
                    variant="h6" 
                    component="span" 
                    sx={{
                        color: getColor(percentile),
                        fontWeight: 'bold',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'white' ,
                    }}
                >
                    {percentile}%
                </Typography>
            )}
            <Typography variant="body1" sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#555' }}>
                av alla andra som tagit testet.
            </Typography>
        </Box>
    );
};

export const UserProgressChart = ({ userId, quizId }) => {
    const [progressData, setProgressData] = useState([]);
    const theme = useTheme();
    const styles = getStyles(theme);

    useEffect(() => {
        const fetchProgressData = async () => {
            const data = await getUserProgress(userId, quizId);
            setProgressData(data.map((result, index) => ({
                attempt: index + 1,
                score: result.score,
                timestamp: result.timestamp,
                correctAnswers: result.detailedResults.filter(r => r.isCorrect).length,
                totalQuestions: result.detailedResults.length
            })));
        };
        fetchProgressData();
    }, [userId, quizId]);

    const formatXAxis = (unixTimestamp) => {
        const timestamp = unixTimestamp > 9999999999 ? unixTimestamp / 1000 : unixTimestamp;
        return format(fromUnixTime(timestamp), 'd MMM', { locale: sv });
    };

    return (
        <Box sx={{ width: '100%', height: 400 }}>
            <Typography variant="h2" sx={{...styles.header, fontSize:'18px', marginTop:'0px'}}>Dina framsteg</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={progressData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                    <XAxis
                        dataKey="timestamp"
                        tickFormatter={formatXAxis}
                        stroke={theme.palette.text.secondary}
                    />
                    <YAxis
                        yAxisId="left"
                        stroke={theme.palette.primary.main}
                        label={{ value: 'Poäng (%)', angle: -90, position: 'insideLeft' }}
                    />
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        stroke={theme.palette.secondary.main}
                        label={{ value: 'Rätta svar', angle: 90, position: 'insideRight' }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Area
                        yAxisId="left"
                        type="monotone"
                        dataKey="score"
                        stroke={theme.palette.primary.main}
                        fill={theme.palette.primary.main}
                        fillOpacity={0.3}
                        name="Poäng (%)"
                        dot={{ r: 4 }}
                        activeDot={{ r: 8 }}
                    />
                    <Area
                        yAxisId="right"
                        type="monotone"
                        dataKey="correctAnswers"
                        stroke={theme.palette.secondary.main}
                        fill={theme.palette.secondary.main}
                        fillOpacity={0.3}
                        name="Rätta svar"
                        dot={{ r: 4 }}
                        activeDot={{ r: 8 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    const theme = useTheme();

    if (active && payload && payload.length) {


        return (
            <Box sx={{
                p: 2,
                backgroundColor: theme.palette.background.paper,
                opacity: 0.9,
                border: `1px solid ${theme.palette.divider}`,
            }}>

                <Typography variant="body2" color="primary">{`Poäng: ${payload[0].value.toFixed(2)}%`}</Typography>
                <Typography variant="body2" color="secondary">
                    {`Rätta svar: ${payload[1].value}/${payload[1].payload.totalQuestions}`}
                </Typography>
            </Box>
        );
    }
    return null;
};