import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Divider, 
  Alert, 
  Card, 
  CardContent, 
  Avatar, 
  Chip,
  Button,
  Tooltip,
  Tabs, Tab
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RadialBarChart, RadialBar, ResponsiveContainer, PolarAngleAxis } from 'recharts';
import { getStyles } from './index';
import Logger from './Logger';
import { collection, query, where, orderBy, limit, getDocs, getDoc, doc, setDoc, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import QuizIcon from '@mui/icons-material/Quiz';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate, createUrlSlug } from './functions';
import { getQuestionTypeIcon, getQuestionTypeLabel } from './quizFunctions';
import DashboardShortcuts from './DashboardShortcuts';
import DashboardCreateShortcuts from './DashboardCreateShortcuts';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { useSnackbar } from './SnackbarHandler';
import { TeacherSkeleton, FlashcardSkeleton, QuizSkeleton } from './TeacherSkeleton'; // Import the new TeacherSkeleton component
import ClassroomTeacherSkeleton from './ClassroomTeacherSkeleton'; // Import the new TeacherSkeleton component
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

import Person3Icon from '@mui/icons-material/Person3';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Dashboard = ({ user }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [latestQuizzes, setLatestQuizzes] = useState([]);
  const [latestFlashcards, setLatestFlashcards] = useState([]);
  const [featuredTeacherIds] = useState(['pqSK7pqWXsyEsOkxpUy0', 'DWpJiCSPnhio3ak5wd3v', '2PZWbiwYxBWpPb3my2gG', 'fUmhgXxjiUs3OMVbqujs', 'dL1NVd9HMYxQvTL2j9VO', 'Vh3Z6JVs4JCL55EHIpk6', 'XlpR2N0y4Ii40nsocTeI', 'l8ht7hJh68u5VwcpCi80', '6bs1lGSApjJ1gbqbm3ua',  'fAeB8024WT5ucuHc7jSa', 'jQOVP4WABFtWpIkHZWYD', 'Utcue2zuwnrGeKviGFah', 'kvZhgusQRykTBUmNA8dG', 'nHNQmF42laxU8ccC6DDg', 'oAc5im2gfmlJXrF6XvPv', 'oilWGYQVvIMsH9L8KL1S', 'pTJ2H7NtCijwQH40VGIV', '2PZWbiwYxBWpPb3my2gG', 'tEgs9KCX71oC5mmFCZqi', 'IfaQPMxKoR9xASZDH2gJ', 'HaMWN84jwuvy1LKmsDme']);
  const [featuredTeachers, setFeaturedTeachers] = useState([]);
  const [classicTeachers, setClassicTeachers] = useState([]);
  const [classicTeacherIds] = useState(['14GRiHrsHh2f3YDKilDq', 'X6KLiiwYT6UJXaGYHBQ2', 'aB5MwN7s6sl2n43auVh3', 'kTQK63Gfd4nj9ZOIO3Yy', 'nTB3pwXxBdLHbhar839H', '3dRmkg2iFEyDa5vPKTUy', '7z140t4UC4jSLpvDevTF']);
  const [userInfo, setUserInfo] = useState({});
  const [quizUserInfo, setQuizUserInfo] = useState({}); 
  const maxTokens = user.guest ? process.env.REACT_APP_GUEST_TOKEN_LIMIT : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT;
  const usedTokens = user.tokens;
  const remainingTokens = maxTokens - usedTokens;
  const usedPercentage = (usedTokens / maxTokens) * 100;
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loadingTeachers, setLoadingTeachers] = useState(true); // New state for loading
  const [loadingClassicTeachers, setLoadingClassicTeachers] = useState(true);

const [quizSortType, setQuizSortType] = useState('upvotes');
const [sortedQuizzes, setSortedQuizzes] = useState([]);
const [flashcardSortType, setFlashcardSortType] = useState('upvotes');
const [sortedFlashcards, setSortedFlashcards] = useState([]);

  const [loadingFlashcards, setLoadingFlashcards] = useState(true);
  const [loadingQuizzes, setLoadingQuizzes] = useState(true);

  const data = [
    { name: 'Used', value: usedPercentage, fill: '#ff6b6b' },
  ];

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        setLoadingQuizzes(true);
        const quizzesRef = collection(db, 'quiz');
        const q = query(quizzesRef, where('isPublic', '==', true));
        const quizzesSnapshot = await getDocs(q);
        const quizzesList = quizzesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setLatestQuizzes(quizzesList);

        // Fetch user info for quizzes
        const userIds = [...new Set(quizzesList.map(quiz => quiz.userId).filter(Boolean))];
        const quizUserInfoObj = {};
        for (const userId of userIds) {
          const userDoc = await getDoc(doc(db, 'users', userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            quizUserInfoObj[userId] = {
              displayName: userData.displayName || userData.email || 'Okänd',
              avatarUrl: userData.photoURL || null
            };
          }
        }
        setQuizUserInfo(quizUserInfoObj);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      } finally {
        setLoadingQuizzes(false);
      }
    };


    const fetchFlashcards = async () => {
      try {
        setLoadingFlashcards(true);
        const flashcardsRef = collection(db, 'flashcardCollections');
        const q = query(flashcardsRef, where('isPublic', '==', true));
        const flashcardsSnapshot = await getDocs(q);
        const flashcardsList = flashcardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setLatestFlashcards(flashcardsList);

        // Fetch user info for flashcards
        const userIds = [...new Set(flashcardsList.map(flashcard => flashcard.userId).filter(Boolean))];
        const userInfoObj = {};
        for (const userId of userIds) {
          const userDoc = await getDoc(doc(db, 'users', userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            userInfoObj[userId] = {
              displayName: userData.displayName || userData.email || 'Okänd',
              avatarUrl: userData.photoURL || null
            };
          }
        }
        setUserInfo(userInfoObj);
      }  catch (error) {
        console.error('Error fetchingflash:', error);
      } finally {
       setLoadingFlashcards(false);
      }
};

    const fetchFeaturedTeachers = async () => {
      try {
        setLoadingTeachers(true); // Start loading
        const teachersData = await Promise.all(
          featuredTeacherIds.map(async (id) => {
            const teacherDoc = await getDoc(doc(db, 'publicTeachers', id));
            if (teacherDoc.exists()) {
              return { id: teacherDoc.id, ...teacherDoc.data() };
            }
            return null;
          })
        );
        setFeaturedTeachers(teachersData.filter(teacher => teacher !== null));
      } catch (error) {
        console.error('Error fetching featured teachers:', error);
      } finally {
        setLoadingTeachers(false); // End loading
      }
    };

    const fetchClassicTeachers = async () => {
      try {
        setLoadingClassicTeachers(true);
        const classicTeachersData = await Promise.all(
          classicTeacherIds.map(async (id) => {
            const teacherDoc = await getDoc(doc(db, 'publicTeachers', id));
            if (teacherDoc.exists()) {
              return { id: teacherDoc.id, ...teacherDoc.data() };
            }
            return null;
          })
        );
        setClassicTeachers(classicTeachersData.filter(teacher => teacher !== null));
      } catch (error) {
        console.error('Error fetching classic teachers:', error);
      } finally {
        setLoadingClassicTeachers(false);
      }
    };

    fetchClassicTeachers();
    fetchFeaturedTeachers();
    fetchQuizzes();
    fetchFlashcards();
  }, [featuredTeacherIds]);



useEffect(() => {
  let sorted;
  if (quizSortType === 'upvotes') {
    sorted = [...latestQuizzes].sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
  } else {
    sorted = [...latestQuizzes].sort((a, b) => b.createdAt - a.createdAt);
  }
  setSortedQuizzes(sorted.slice(0, 5)); // Only take the top 5 items
}, [latestQuizzes, quizSortType]);


useEffect(() => {

  let sorted;
  if (flashcardSortType === 'upvotes') {
    sorted = [...latestFlashcards].sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
  } else {
    sorted = [...latestFlashcards].sort((a, b) => b.createdAt - a.createdAt);
  }
  setSortedFlashcards(sorted.slice(0, 5)); // Only take the top 5 items
}, [latestFlashcards, flashcardSortType]);

const handleFlashcardSortChange = (event, newValue) => {
  setFlashcardSortType(newValue);
};

const handleQuizSortChange = (event, newValue) => {
  setQuizSortType(newValue);
};


  const formatUrlPart = (str) => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const copyAndStartTraining = async (publicCollection, event) => {
    event.preventDefault();
    try {
      // Check if the user is trying to copy their own collection
      if (publicCollection.userId === user.uid) {
        showSnackbar('Du kan inte kopiera din egen samling.', 'info');
        navigate(`/flashcards/${publicCollection.id}/${formatUrlPart(publicCollection.collectionName)}`);
        return;
      }

      // Check if the collection has already been copied
      const q = query(
        collection(db, 'flashcardCollections'),
        where('userId', '==', user.uid),
        where('originalCollectionId', '==', publicCollection.id)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Collection has already been copied
        showSnackbar('Du har redan kopierat denna samling. Du vidarebefordras till din kopia.', 'info');
        const copiedCollection = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        navigate(`/flashcards/${copiedCollection.id}/${formatUrlPart(copiedCollection.collectionName)}`);
        return;
      }

      // If not copied and not owned by the user, proceed with copying
      const { id, ...collectionWithoutId } = publicCollection;
      const newCollection = {
        ...collectionWithoutId,
        userId: user.uid,
        isPublic: false,
        isCopied: true,
        originalCollectionId: id,
        originalUserId: collectionWithoutId.userId
      };
      const docRef = await addDoc(collection(db, 'flashcardCollections'), newCollection);
      const copiedCollection = { id: docRef.id, ...newCollection };


      navigate(`/flashcards/${copiedCollection.id}/${formatUrlPart(copiedCollection.collectionName)}`);
      showSnackbar('Samling kopierad framgångsrikt!', 'success');
    } catch (error) {
      console.error('Error copying collection:', error);
      showSnackbar('Ett fel uppstod vid kopiering av samlingen.', 'error');
    }
  };



  const copyPublicQuiz = async (publicQuiz, event) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      if (publicQuiz.userId === user.uid) {
        showSnackbar('Du kan inte kopiera ditt eget quiz.', 'info');
        return;
      }

      const q = query(
        collection(db, 'quiz'),
        where('userId', '==', user.uid),
        where('originalQuizId', '==', publicQuiz.id)
      );
      const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // User has already copied this quiz
      const copiedQuiz = querySnapshot.docs[0].data();
      const urlSlug = createUrlSlug(copiedQuiz.quizName);
      showSnackbar('Du har redan kopierat detta quiz. Navigerar till din kopia.', 'info');
      navigate(`/quiz/${querySnapshot.docs[0].id}/${urlSlug}`);
      return;
    }

      const { id, ...quizData } = publicQuiz;
      const newQuizRef = await addDoc(collection(db, 'quiz'), {
        ...quizData,
        userId: user.uid,
        createdAt: new Date(),
        isPublic: false,
        isCopied: true,
        originalQuizId: id,
        originalUserId: quizData.userId
      });

      Logger.log("Quiz copied with ID: ", newQuizRef.id);
      showSnackbar('Quiz kopierat framgångsrikt!', 'success');
      
      const urlSlug = createUrlSlug(publicQuiz.quizName);
      navigate(`/quiz/${newQuizRef.id}/${urlSlug}`); // Navigate to the new quiz with SEO-friendly URL
    } catch (error) {
      console.error('Error copying public quiz:', error);
      showSnackbar('Ett fel uppstod vid kopiering av quizet.', 'error');
    }
  };


  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case 'Enkel':
        return theme.palette.mode === 'dark' ? '#1a5928' : '#e6f4e8';
      case 'Medel':
        return theme.palette.mode === 'dark' ? '#7d5f0a' : '#fff4d9';
      case 'Svårt':
        return theme.palette.mode === 'dark' ? '#8b2626' : '#ffe8e8';
      default:
        return 'transparent';
    }
  };

  const getDifficultyTooltip = (difficulty) => {
    switch (difficulty) {
      case 'Enkel':
        return 'Passar nybörjare eller för snabb repetition';
      case 'Medel':
        return 'Kräver grundläggande förståelse av ämnet';
      case 'Svårt':
        return 'Utmanande frågor för djupare kunskap';
      default:
        return '';
    }
  };


  const handleUseTeacher = async (teacher, event) => {
    event.preventDefault(); 
    try {

      const teacherCopy = {
        ...teacher,
        userId: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        visibility: 'private',
        isPublicCopy: true
      };

      await setDoc(doc(db, "teachers", teacher.id), teacherCopy);
      
      showSnackbar(`${teacher.characterName} har stigit in i klassrummet.`, 'success');
      navigate(`/chat/${teacher.id}`);
    } catch (error) {
      console.error("Fel vid kopiering av lärare: ", error);

    }
  };


  const getTeacherType = (teacherName) => {
    switch (teacherName) {
      case 'Johan Bauer':
        return 'Tysklärare';
      case 'David Eriksson':
        return 'Mattelärare';
      case 'Katarina Lindström':
        return 'Historialärare';
      case 'Maria Lindström':
        return 'Mental hälsa/Psykolog';
      case 'Emma Johnson':
        return 'Engelsklärare';
      case 'Marie Dubois':
        return 'Fransklärare';
      case 'Isabel García':
        return 'Spansklärare';
      // Add more cases as needed
      default:
        return 'General Teacher';
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{...styles.innerContainer, width:{xs: '100%', md: '100%'}, margin:'0 auto'}}>
        <Typography variant="h1" sx={styles.header}>
          {user.guest ? "Välkommen till Studera.AI" : `Välkommen, ${user.displayName}!`}
        </Typography>
<Box sx={{color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333', mb:3}}>
  <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>
    Generera quiz, flashcards och högskoleprov med AI.  
    Skapa och chatta med AI-drivna karaktärer, varför inte diskutera filosofi med Platon, fysik med Einstein eller litteratur med Virginia Woolf?
    {/*Du kan även förfina din argumentationsteknik genom att delta i AI-drivna debatter om aktuella och tidlösa frågor.*/}
  </Typography>
  <Typography sx={{ display: { xs: 'block', sm: 'none' } }}>
    Skapa karaktärer, quiz, flashcards och högskoleprov med AI. 
    Varför inte chatta med lärare som Einstein och Platon, eller moderera en debatt mellan Freud och Jung?
  </Typography>
</Box>
        <Divider sx={{ my: {xs:0, md:2} }} />
        <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>


          <Box sx={{ ...styles.newBox, padding: '20px', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, height: 40 }}>
              <Box sx={{...styles.iconBox}}>
                <SwitchAccessShortcutIcon sx={{ color: '#b6c8db' }} />
              </Box>
              <Typography variant="h2" sx={{...styles.header, ...styles.dashboardHeader}}>
                Genvägar
              </Typography>
            </Box>

            <DashboardShortcuts />
            <Divider sx={{ my: 2 }} />
            <DashboardCreateShortcuts />




          </Box>
        
          <Box sx={{ ...styles.newBox, padding: '20px', width: {xs: '100%', md: '35%'} }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, height: 40 }}>
              <Box sx={{...styles.iconBox}}>
                <BarChartIcon sx={{ color: '#b6c8db' }} />
              </Box>
              <Typography variant="h2" sx={{...styles.header, ...styles.dashboardHeader}}>
                Användning
              </Typography>
            </Box>

            <Typography variant="body2" sx={{ mt: 1 }}>
              Se hur många tokens du har kvar nedan. Just nu har du använt {usedTokens} av totalt {maxTokens}.
            </Typography>

            <Box sx={{height:'270px'}}>
              <ResponsiveContainer width="100%" height="100%">
                <RadialBarChart 
                  cx="50%" 
                  cy="50%" 
                  innerRadius="65%" 
                  outerRadius="100%" 
                  barSize={22} 
                  data={data} 
                  startAngle={180} 
                  endAngle={0}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                >
                  <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                  <RadialBar
                    dataKey="value"
                    cornerRadius={10}
                    background={{ fill: '#27a86b' }}
                  />
                  <text 
                    x="50%" 
                    y="38%" 
                    textAnchor="middle" 
                    dominantBaseline="middle" 
                    style={{ fontSize: '22px', fontWeight: '600' }}
                    fill={theme.palette.mode === 'dark' ? 'white' : '#333'}
                  >
                    {remainingTokens}
                  </text>
                  <text 
                    x="50%" 
                    y="48%" 
                    textAnchor="middle" 
                    dominantBaseline="middle" 
                    style={{ fontSize: '14px', fontWeight: 'normal' }}
                    fill={theme.palette.mode === 'dark' ? 'white' : '#333'}
                  >
                    Tokens kvar
                  </text>
                </RadialBarChart>
              </ResponsiveContainer>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: -14 }}>
              <Box sx={{ width: 12, height: 12, backgroundColor: '#27a86b', mr: 1, borderRadius:'100px' }} />
              <Typography variant="body2" sx={{ mr: 2 }}>Återstående</Typography>
              <Box sx={{ width: 12, height: 12, backgroundColor: '#ff6b6b', mr: 1,  borderRadius:'100px' }} />
              <Typography variant="body2">Använt</Typography>
            </Box>
            {user.guest && (
              <>
                <Divider sx={{ mt: 3, mb:0 }} />
                <Alert icon={false} severity="success" sx={{ borderRadius: '10px', marginTop: '20px' }}>
                  <Typography variant="body2" sx={{ mt: 0, fontStyle:'italic' }}>
                    Du är inloggad som gäst och har <strong>{process.env.REACT_APP_GUEST_TOKEN_LIMIT}</strong> tokens att använda. För att få tillgång till fler funktioner och över <strong>50 000</strong> tokens mer, vänligen skapa ett konto - det är helt gratis.
                  </Typography>
                </Alert>
              </>
            )}
            {!user.guest && (
              <>
                <Divider sx={{ mt: 3, mb:0 }} />
                <Alert icon={false} severity="success" sx={{ borderRadius: '10px', marginTop: '20px' }}>
                  <Typography variant="body2" sx={{ mt: 0, fontStyle:'italic' }}>
                    Vill du ha tillgång till ännu fler tokens? Kontakta oss genom att klicka på Feedback-länken till vänster.
                  </Typography>
                </Alert>
              </>
            )}
          </Box>

        </Box>


        <Box sx={{ display: 'flex', gap: 2, mt: 2, flexDirection: { xs: 'column', md: 'row' } }}>



 <Box sx={{ ...styles.newBox, padding: '20px', flexGrow: 1 }}>


            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, height: 40 }}>
              <Box sx={{...styles.iconBox}}>
                <Person3Icon sx={{ color: '#b6c8db' }} />
              </Box>
              <Typography variant="h2" sx={{...styles.header, ...styles.dashboardHeader}}>
                Chatta med våra lärare
              </Typography>
            </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {loadingTeachers
        ? Array.from(new Array(21)).map((_, index) => (
            <TeacherSkeleton key={index} />
          ))
        : featuredTeachers.map((teacher) => (
            <Box 
              key={teacher.id} 
              sx={{ 
                width: 'calc(33.33% - 16px)',
                minWidth: '200px',
                '@media (max-width: 900px)': {
                  width: 'calc(50% - 8px)',
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                },
              }}
            >
          <Link 
            to={`/chat/${teacher.id}`} 
            onClick={(e) => handleUseTeacher(teacher, e)}
            style={{ textDecoration: 'none'}}
          >
    <Box 

      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '8px', 
        cursor: 'pointer',
        padding: 1,
        '&:hover': { 
          bgcolor: theme.palette.action.hover,
        },
        borderRadius: 10,
      }}
    >
      <Avatar
        src={teacher.imageUrl}
        alt={teacher.characterName}
        sx={{ width: 36, height: 36 }}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>                    
        <Typography variant="subtitle1" sx={{color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#4a5568', fontWeight:'500', fontSize:'14px'}}>{teacher.characterName}</Typography>

      </Box>
      <ChevronRightIcon sx={{ color: theme.palette.text.secondary, opacity:0.4 }} />
    </Box>
    </Link>
            </Box>
          ))}
        </Box>


            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2,  flexDirection: { xs: 'column', md: 'row' } }}>
              <Button 
                variant="outlined" 
                color="primary" 
                component={Link}
                to="/personal"
              >
                Alla lärare
              </Button>
              <Button 
                variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
                color="primary" 
                component={Link}
                startIcon={<GenerateIcon style={{ color: '#fff' }} />}
                to="/larare"
              >
                Skapa karaktär med AI
              </Button>
            </Box>

 </Box>
          <Box sx={{ ...styles.newBox, padding: '20px', width:{xs:'100%', md: '36%'} }}>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, height: 40 }}>
              <Box sx={{...styles.iconBox}}>
                <LocalLibraryIcon sx={{ color: '#b6c8db' }} />
              </Box>
              <Typography variant="h2" sx={{...styles.header, ...styles.dashboardHeader}}>
                Klassiska lärare
              </Typography>
            </Box> 


         {loadingClassicTeachers ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Array.from(new Array(7)).map((_, index) => (
        <ClassroomTeacherSkeleton key={index} />
      ))}
    </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {classicTeachers.map((teacher) => (

          <Link 
            to={`/chat/${teacher.id}`} 
            onClick={(e) => handleUseTeacher(teacher, e)}
            style={{ textDecoration: 'none'}}
          >
                <Box 
                  key={teacher.id}

                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    cursor: 'pointer',
                    padding: 1,
                    '&:hover': { 
                      bgcolor: theme.palette.action.hover,
                    },
                    borderRadius: 10,
                  }}
                >
                  <Avatar
                    src={teacher.imageUrl}
                    alt={teacher.characterName}
                    sx={{ width: 36, height: 36 }}
                  />
                  <Box sx={{ flexGrow: 1, minWidth: 0 }}>                    
                    <Typography variant="subtitle1" sx={{color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#4a5568', fontWeight:'500', fontSize:'14px'}}>{getTeacherType(teacher.characterName)}</Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ color: theme.palette.text.secondary,  fontSize:'13px' }}
                    >
                      {teacher.characterName}
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ color: theme.palette.text.secondary, opacity:0.4 }} />
                </Box>
                </Link>
              ))}
            </Box>
          )}

          </Box>

          </Box>


        <Box sx={{ display: 'flex', gap: 2, mt: 2, flexDirection: { xs: 'column', md: 'row' } }}>
          <Box sx={{ ...styles.newBox, padding: '20px', width:{xs:'100%', md: '50%'} }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, height: 40 }}>
              <Box sx={{...styles.iconBox}}>
                <ViewAgendaIcon sx={{ color: '#b6c8db' }} />
              </Box>
              <Typography variant="h2" sx={{...styles.header, ...styles.dashboardHeader}}>
                Flashcards
              </Typography>
            </Box> 

  <Tabs value={flashcardSortType} onChange={handleFlashcardSortChange} sx={{ mb: 2 }}>
    <Tab label="Populära" value="upvotes" />
    <Tab label="Senaste" value="recent" />
  </Tabs>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
              {loadingFlashcards
              ? Array.from(new Array(5)).map((_, index) => (
                  <FlashcardSkeleton key={index} />
                ))
              : sortedFlashcards.map((flashcard) => {
                  const urlSlug = createUrlSlug(flashcard.collectionName);
                  return (
                    <Link 
                      key={flashcard.id}
                      to={`/flashcards/${flashcard.id}/${urlSlug}`} 
                      onClick={(e) => copyAndStartTraining(flashcard, e)}
                      style={{ textDecoration: 'none'}}
                    >
                      <Card 
                        sx={{ 
                          ...styles.newBox, 
                          ...styles.dashboardBox,
                          backgroundImage:'none',
                        }}
                      >
                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding:'10px', paddingBottom:'10px !important' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: '600', mb: 1, color: theme.palette.mode === 'dark' ? 'white' : '#444' }}>{flashcard.collectionName}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar 
                                  src={userInfo[flashcard.userId]?.avatarUrl || null}
                                  sx={{ width: 20, height: 20, mr: 1, bgcolor: theme.palette.primary.main }}
                                >
                                  {(userInfo[flashcard.userId]?.displayName || 'O')[0].toUpperCase()}
                                </Avatar>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                  {userInfo[flashcard.userId]?.displayName || 'Okänd'}
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {flashcard.category && (
                                  <Chip 
                                    label={flashcard.category} 
                                    size="small"
                                    sx={{ 
                                      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)',
                                      color: theme.palette.text.primary,
                                      fontSize: '0.75rem',
                                      fontWeight:'500'
                                    }}
                                  />
                                )}
                                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(65, 191, 19, 0.1)', borderRadius: '20px', px: 1, py: 0.5 }}>
                                  <ThumbUpOutlinedIcon sx={{ fontSize: 16, mr: 0.5, color: '#41bf13' }} />
                                  <Typography variant="body2" sx={{ color: '#41bf13', fontWeight: 'bold' }}>{flashcard.upvotes || 0}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  );
                })}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button 
                variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
                color="primary" 
                component={Link}
                startIcon={<GenerateIcon style={{ color: '#fff' }} />}
                to="/flashcards"
              >
                Generera flashcards
              </Button>
            </Box>
          </Box>

          <Box sx={{ ...styles.newBox, padding: '20px', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, height: 40 }}>
              <Box sx={{...styles.iconBox}}>
                <QuizIcon sx={{ color: '#b6c8db' }} />
              </Box>
              <Typography variant="h2" sx={{...styles.header, ...styles.dashboardHeader}}>
                Quiz
              </Typography>
            </Box>


  <Tabs value={quizSortType} onChange={handleQuizSortChange} sx={{ mb: 2 }}

  >
    <Tab label="Populära" value="upvotes" />
    <Tab label="Senaste" value="recent" />
  </Tabs>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
           {loadingQuizzes
              ? Array.from(new Array(5)).map((_, index) => (
                  <QuizSkeleton key={index} />
                ))
              : sortedQuizzes.map((quiz) => {
                  const urlSlug = createUrlSlug(quiz.quizName);
                  return (
                    <Link 
                      key={quiz.id}
                      to={`/quiz/${quiz.id}/${urlSlug}`} 
                      onClick={(e) => copyPublicQuiz(quiz, e)}
                      style={{ textDecoration: 'none'}}
                    >
                      <Card 
                        sx={{ 
                          ...styles.newBox, 
                          ...styles.dashboardBox,
                          backgroundImage:'none',
                        }}
                      >
                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding:'10px', paddingBottom:'10px !important' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '60%' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: '600', mb: 1, fontSize:'15px' }}>{quiz.quizName}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar 
                                src={quizUserInfo[quiz.userId]?.avatarUrl || null}
                                sx={{ width: 20, height: 20, mr: 1, bgcolor: theme.palette.primary.main }}
                              >
                                {(quizUserInfo[quiz.userId]?.displayName || 'O')[0].toUpperCase()}
                              </Avatar>
                              <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                {quizUserInfo[quiz.userId]?.displayName || 'Okänd'}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color:'#666' }}>
                              {getQuestionTypeIcon(quiz.questionType)}
                              <Typography variant="body2" sx={{fontSize:'13px', color: theme.palette.text.secondary}}>{getQuestionTypeLabel(quiz.questionType)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              {quiz.category && (
                                <Chip 
                                  label={quiz.category} 
                                  size="small"
                                  sx={{ 
                                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)',
                                    color: theme.palette.text.primary,
                                    fontSize: '0.75rem',
                                    fontWeight:'500'
                                  }}
                                />
                              )}
                              <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'rgba(65, 191, 19, 0.1)', borderRadius: '20px', px: 1, py: 0.5 }}>
                                <ThumbUpOutlinedIcon sx={{ fontSize: 16, mr: 0.5, color: '#41bf13' }} />
                                <Typography variant="body2" sx={{ color: '#41bf13', fontWeight: 'bold' }}>{quiz.upvotes || 0}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  );
                })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2,  flexDirection: { xs: 'column', md: 'row' } }}>
              <Button 
                variant="outlined" 
                color="primary" 
                component={Link}
                to="/alla-quiz"
              >
                Alla quiz
              </Button>
              <Button 
                variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
                color="primary" 
                component={Link}
                startIcon={<GenerateIcon style={{ color: '#fff' }} />}
                to="/quiz"
              >
                Generera quiz
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;