import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { callOpenAITTS } from './api';
import Logger from './Logger';
const Voice = ({ content, voice = 'alloy', fontSize, userId = null }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(new Audio());
  const abortControllerRef = useRef(null);

  const handleVoice = async () => {
    Logger.log("userid voice: ", userId);
    Logger.log("content: ", content);
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    } else {
      if (!audioBlob) {
        setIsLoading(true);
        try {
          abortControllerRef.current = new AbortController();
          const blob = await callOpenAITTS(content, voice, abortControllerRef.current.signal, userId);
          setAudioBlob(blob);
          audioRef.current.src = URL.createObjectURL(blob);
        } catch (error) {
          if (error.name === 'AbortError') {
            Logger.log('TTS request was aborted');
          } else {
            console.error('Error generating speech:', error);
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
      }
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.onended = () => setIsPlaying(false);
    return () => {
      audio.onended = null;
      URL.revokeObjectURL(audio.src);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const iconSize = fontSize || 24;
  const progressSize = iconSize / 0.8; // Konvertering för att matcha CircularProgress med ikonens storlek
  const buttonStyle = { width: `${iconSize * 1.5}px`, height: `${iconSize * 1.5}px` };

  return (
    <Tooltip title={isPlaying ? "Stoppa" : "Läs upp"}>
      <IconButton onClick={handleVoice} size="small" style={buttonStyle}>
        {isLoading ? (
          <CircularProgress size={progressSize} />
        ) : isPlaying ? (
          <VolumeOffIcon sx={{ fontSize: iconSize }} />
        ) : (
          <VolumeUpIcon sx={{ fontSize: iconSize }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default Voice;