import React from 'react';
import { 
  TextField, FormControl, Select, MenuItem, Switch, 
  Typography, Box, Tooltip
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';

const AdditionalFlashFields = ({ 
  aiInstructions, setAiInstructions, 
  numFlashcards, setNumFlashcards,
  category, setCategory, 
  language, setLanguage, 
  difficulty, setDifficulty,
  visibility, handleVisibilityChange, 
  theme
}) => {
  return (
    <Box>
      <Tooltip title="Håll det kort, koncist och tydligt för bättre resultat.">
        <Typography variant="formLabel" sx={{mt:1}}>Kompletterande instruktioner till AI:n</Typography>
      </Tooltip>
      <TextField
        fullWidth
        sx={{mt:1, mb:3}}
        margin="normal"
        multiline
        rows={2}
        placeholder="T.ex. 'Ta inte med information mellan 1963-1968', eller 'undvik oregelbundna verb'."
        value={aiInstructions}
        onChange={(e) => setAiInstructions(e.target.value)}
        inputProps={{ maxLength: 100 }}
      />

      <FormControl fullWidth>
        <Typography variant="formLabelSinUnderline">Antal flashcards</Typography>
        <Select
          labelId="num-flashcards-label"
          id="num-flashcards-select"
          value={numFlashcards}
          sx={{mt:1, mb:2}}
          onChange={(e) => setNumFlashcards(e.target.value)}
        >
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15} disabled>15</MenuItem>
          <MenuItem value={20} disabled>20</MenuItem>
          <MenuItem value={30} disabled>30</MenuItem>
          <MenuItem value={40} disabled>40</MenuItem>
          <MenuItem value={50} disabled>50</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Typography variant="formLabelSinUnderline">Kategori</Typography>
        <Select
          sx={{mt:1, mb:2}}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <MenuItem value="Välj kategori" disabled>Välj kategori</MenuItem>
          <MenuItem value="Historia">Historia</MenuItem>
          <MenuItem value="Matematik">Matematik</MenuItem>
          <MenuItem value="Vetenskap">Vetenskap</MenuItem>
          <MenuItem value="Fysik">Fysik</MenuItem>
          <MenuItem value="Språk">Språk</MenuItem>
          <MenuItem value="Litteratur">Litteratur</MenuItem>
          <MenuItem value="Film">Film</MenuItem>
          <MenuItem value="Konst">Konst</MenuItem>
          <MenuItem value="Musik">Musik</MenuItem>
          <MenuItem value="Idrott">Idrott</MenuItem>
          <MenuItem value="Teknik">Teknik</MenuItem>
          <MenuItem value="Filosofi">Filosofi</MenuItem>
          <MenuItem value="Religion">Religion</MenuItem>
          <MenuItem value="Biologi">Biologi</MenuItem>
          <MenuItem value="Psykologi">Psykologi</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Typography variant="formLabelSinUnderline">Språk</Typography>
        <Select
          value={language}
          sx={{mt:1, mb:2}}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <MenuItem value="Svenska">Svenska</MenuItem>
          <MenuItem value="Engelska">Engelska</MenuItem>
          <MenuItem value="Tyska">Tyska</MenuItem>
          <MenuItem value="Franska">Franska</MenuItem>
          <MenuItem value="Spanska">Spanska</MenuItem>
          <MenuItem value="Arabiska" disabled>Arabiska</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Typography variant="formLabelSinUnderline">Svårighetsgrad</Typography>
        <Select
          value={difficulty}
          sx={{mt:1, mb:2}}
          onChange={(e) => setDifficulty(e.target.value)}
        >
          <MenuItem value="Lätt">Lätt</MenuItem>
          <MenuItem value="Medel">Medel</MenuItem>
          <MenuItem value="Svårt">Svårt</MenuItem>
        </Select>
      </FormControl>

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#394653' : '#f5f5f5', marginBottom:'30px', borderRadius:'10px', padding:'12px', paddingTop:'5px', paddingBottom:'5px', mt:2 }}>
        <Box display="flex" alignItems="center">
          <Tooltip title="Ska samlingen vara tillgänglig för andra att använda?">
            <Typography variant="formLabel">Synlighet</Typography>
          </Tooltip>
          {visibility === 'public' ? (
            <PublicIcon sx={{ marginLeft: 1, fontSize:'20px' }} />
          ) : (
            <LockIcon sx={{ marginLeft: 1, fontSize:'20px' }} />
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="caption" sx={{ mr: 1 }}>
            {visibility === 'public' ? 'Publik' : 'Privat'}
          </Typography>
          <Switch
            checked={visibility === 'public'}
            onChange={handleVisibilityChange}
            name="visibility"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdditionalFlashFields;