import React, { useState, useEffect, useRef } from 'react';
import { db } from './firebase';
import { addDoc, collection } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddIcon from '@mui/icons-material/Add';
import { Alert, FormControl, Input, Snackbar, Collapse, Tooltip, Fade, InputAdornment, Slider } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import axios from 'axios';
import mammoth from 'mammoth';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import Logger from './Logger';
import { decreaseTokens } from './functions';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import WikipediaIcon from './WikipediaIcon';
import TopicIcon from '@mui/icons-material/Subject';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { fetchChats, fetchConversations } from './messageFunctions';
import { MenuItem, Select } from '@mui/material';
import AdditionalFlashFields from './AdditionalFlashFields';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { getStyles } from './index';
import Suggestions from './Suggestions';
import { callApi } from './api';
import LoadingSpinner from './LoadingSpinner';
import { useSnackbar } from './SnackbarHandler';
import { AIModelSelect } from './TestComponents2';
import FilterListIcon from '@mui/icons-material/FilterList';
import IdeaGenerator from './IdeaGenerator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WordIcon from './Icons/WordIcon'; 
import YouTubeIcon from '@mui/icons-material/YouTube';
import { sanitizeInput } from './functions';
const cheerio = require('cheerio');





const FlashcardsCreateView = ({ user, onCollectionCreated, isDark }) => {
  const [collectionName, setCollectionName] = useState('');
  const [flashcards, setFlashcards] = useState([{ front: '', back: '', correct: 'false' }]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [importString, setImportString] = useState('');
  const [showImport, setShowImport] = useState(false);
  const [showAIInput, setShowAIInput] = useState(true); // State to toggle AI input box
  const [aiInput, setAIInput] = useState(''); // State to store AI input
  const [inputType, setInputType] = useState('subject'); // State to manage which radio button is selected
const [subjectInput, setSubjectInput] = useState('');
const [textInput, setTextInput] = useState('');
const [wikiUrlInput, setWikiUrlInput] = useState('');
const [conversationTypes, setConversationTypes] = useState([]);
const [selectedConversationType, setSelectedConversationType] = useState('Välj konversation');
const [isLoading, setIsLoading] = useState(false);
 const [error, setError] = useState(null);
const [isPublic, setIsPublic] = useState(true);
const [viewIsLoading, setViewIsLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState('');
const [selectedModel, setSelectedModel] = useState("Studera.AI");
const [selectedSuggestion, setSelectedSuggestion] = useState('');
const selectedSuggestionRef = useRef('');
const [progress, setProgress] = useState(0);
const progressIntervalRef = useRef(null);

const [visibility, setVisibility] = useState('public');
const collectionNameRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [numFlashcards, setNumFlashcards] = useState(10);
  const [category, setCategory] = useState('Välj kategori');
  const [language, setLanguage] = useState('Svenska');
  const [difficulty, setDifficulty] = useState('Medel');
  const [aiInstructions, setAiInstructions] = useState('');

    const [loadingSaving, setLoadingSaving] = useState(false);
const [characterCount, setCharacterCount] = useState(5000);
const { showSnackbar } = useSnackbar();


  const subjectInputRef = useRef(null);
  const textInputRef = useRef(null);
  const wikiUrlInputRef = useRef(null);



  const [renderedFlashcards, setRenderedFlashcards] = useState([]);
  const [renderInterval, setRenderInterval] = useState(null);



const progressRef = useRef(null);
  const progressPercentRef = useRef(null);


const [characterCountYouTube, setCharacterCountYouTube] = useState(5000);
  

  const youtubeUrlInputRef = useRef(null);


const fetchYoutubeTranscript = async (url, maxLength = 5000) => {
  try {
    console.log('Attempting to fetch transcript for URL:', url);
    const response = await axios.post('http://localhost:3001/api/youtube-transcript', { url });
    
    console.log('Server response:', response);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch transcript. Status: ${response.status}`);
    }
    
    // Limit the transcript to maxLength characters
    const fullTranscript = response.data.transcript;
    return fullTranscript.length > maxLength ? fullTranscript.slice(0, maxLength) : fullTranscript;
  } catch (error) {
    console.error('Detailed error in fetchYoutubeTranscript:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    showSnackbar("Kunde inte hämta YouTube-transkript. Kontrollera URL:en och försök igen.", "error");
    return '';
  }
};



  const handleIdeaGenerated = (idea) => { subjectInputRef.current.value = idea; };
  const handleWikiIdeaGenerated = (idea) => { wikiUrlInputRef.current.value = idea; };

  const updateProgress = (value) => {
    if (progressRef.current) {
      progressRef.current.style.width = `${value}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(value)}%`;
    }
  };
 useEffect(() => {
    if (flashcards.length > 1) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [flashcards]);

useEffect(() => {
    return () => {
      if (renderInterval) {
        clearInterval(renderInterval);
      }
    };
  }, [renderInterval]);


useEffect(() => {
  setViewIsLoading(false);
}, []);


 if (viewIsLoading) {
    return <LoadingSpinner />;
  }

// useEffect(() => {
//   Logger.log("Theme mode: ", theme.palette.mode);
// });

//     useEffect(() => {


//             Logger.log("isDark: ", isDark);

//     }, [isDark]);

const handlePublicChange = (event) => {
  const newVisibility = event.target.checked ? 'public' : 'private';
  setVisibility(newVisibility);
  setIsPublic(newVisibility === 'public');
};

  const handleCollectionNameChange = (e) => {
    setCollectionName(e.target.value);
  };
// const fetchConversationTypes = async () => {
//   const q = query(collection(db, 'conversations'), where('userId', '==', user.uid));
//   const querySnapshot = await getDocs(q);
//   const conversationsData = querySnapshot.docs.map(doc => doc.data().conversationType);
//   const uniqueTypes = [...new Set(conversationsData)];
//   setConversationTypes(uniqueTypes);
// };


const handleSelectYouTube = (selected) => {
  
  setInputType('youtube'),

   setCollectionName(selected.text);
setFlashcards([{ front: '', back: '', correct: 'false' }]);

  if (selected.text) {

    youtubeUrlInputRef.current.value = selected.value;

  }

 if (youtubeUrlInputRef.current) {
    youtubeUrlInputRef.current.value = selected.value;
  } 
}



const handleSelectWiki = (selected) => {
  
  setInputType('wiki-url'),

  setCollectionName(selected.text);

setFlashcards([{ front: '', back: '', correct: 'false' }]);

  if (selected.text) {

    wikiUrlInputRef.current.value = selected.value;

  }

 if (wikiUrlInputRef.current) {
    wikiUrlInputRef.current.value = selected.value;
  } 
}


  const handleSelect = (selected) => {
    Logger.log('Selected text:', selected.text);


setFlashcards([{ front: '', back: '', correct: 'false' }]);

    if (selected.value) {

    let hej = selected.text + " - " + selected.value;


selectedSuggestionRef.current = hej;

    Logger.log("selectedSuggestion: ", selectedSuggestion);
    setInputType('subject');
   // setSubjectInput(selected.text);
    setCollectionName(selected.text);

if (subjectInputRef.current) {
    subjectInputRef.current.value = selected.value;
  }



      Logger.log('Selected value:', selected.value);
      // Use the background value if it exists
    } else {
      // Use the text if there's no background value
      Logger.log('No background value, using text:', selected.text);
    }
    // Do something with the selected suggestion
  };



const handleChatSelect = (event) => {
  const chatId = event.target.value;
  setSelectedChat(chatId);

  if (chatId) {
    setIsLoading(true);
    Logger.log("Fetching conversations for chat:", chatId);
    
    // Hitta den valda chatten för att få conversationType
    const selectedChatData = chats.find(chat => chat.id === chatId);
    
    if (selectedChatData) {
      fetchConversations(user, (fetchedMessages) => {
        Logger.log("Fetched messages:", fetchedMessages);
        if (fetchedMessages && fetchedMessages.length > 0) {
          const messagesText = fetchedMessages.map(msg => msg.content).join('\n');
          setTextInput(messagesText);
        } else {
          Logger.log("No messages found for this chat");
          setTextInput("Inga meddelanden hittades för denna chatt.");
        }
        setIsLoading(false);
      }, selectedChatData.conversationType, chatId);
    } else {
      console.error("Selected chat not found in chats array");
      setError("Den valda chatten kunde inte hittas.");
      setIsLoading(false);
    }
  }
};

const handleInputTypeChange = (event, newInputType) => {
  if (newInputType !== null) {
    setInputType(newInputType);
    if (newInputType === 'chattar') {
 loadChats();
      //setTextInput('');
    } else {
      setSelectedChat('');
        setTextInput('');
    }
  }
};


  const loadChats = () => {
    setIsLoading(true);
    Logger.log("Loading chats for user:", user.uid);
    
    // Hämta chattar för alla conversationTypes
    Promise.all([
      fetchChats(user, 'chat'),
      fetchChats(user, 'history'),
      fetchChats(user, 'spanish')
    ])
      .then(results => {
        const allChats = results.flat();
        Logger.log("Fetched chats:", allChats);
        setChats(allChats);
        if (allChats.length === 0) {
          Logger.log("No chats found for this user.");
          setError('Inga chattar hittades för denna användare.');
        }
      })
      .catch(error => {
        console.error('Error fetching chats:', error);
        setError('Ett fel uppstod vid hämtning av chattar.');
      })
      .finally(() => setIsLoading(false));
  };


  
  const handleFileChange = async (event) => {
    Logger.log("handleFileChange körs");
    const file = event.target.files[0];
    if (file) {
      Logger.log("handleFileChange2 körs")
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        try {
          const result = await mammoth.extractRawText({ arrayBuffer });
          Logger.log("handleFileChange3 körs ", result.value);
          textInputRef.current.value = result.value;
          setTextInput(result.value);
        } catch (error) {
          setError('Fel vid läsning av Word-fil: ' + error.message);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFrontChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].front = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleBackChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].back = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleAddCard = (index = -1) => {
    const newFlashcards = [...flashcards];
    newFlashcards.splice(index + 1, 0, { front: '', back: '', correct: 'false' });
    setFlashcards(newFlashcards);
    setTimeout(() => {
      document.getElementById(`front-${index + 1}`).focus();
    }, 0);
  };

  const handleDeleteCard = (index) => {
    const newFlashcards = flashcards.filter((_, i) => i !== index);
    setFlashcards(newFlashcards);
  };

  const handleTabPress = (index, isBack, e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      if (isBack) {
        if (index === flashcards.length - 1) {
          handleAddCard(index);
        } else {
          document.getElementById(`front-${index + 1}`).focus();
        }
      } else {
        document.getElementById(`back-${index}`).focus();
      }
    }
  };

  const handleCorrectChange = (index, isCorrect) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].correct = isCorrect;
    setFlashcards(newFlashcards);
  };

  const handleSubmit = async () => {
    if (!collectionName.trim()) {
      showSnackbar('Samlingens namn får inte vara tomt.', 'info');
      collectionNameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    if (flashcards.some(card => !card.front.trim() || !card.back.trim())) {
      showSnackbar('Inga fält för framsida eller baksida får vara tomma.', 'info');
      return;
    }

    setLoadingSaving(true);
    setMessage('');
    let userId = user.uid;
    Logger.log("userId flashcard create: ", userId);



    try {
    // Sanitize the collection name
    const sanitizedCollectionName = sanitizeInput(collectionName);

    // Sanitize the flashcards
    const sanitizedFlashcards = flashcards.map(card => ({
      front: sanitizeInput(card.front),
      back: sanitizeInput(card.back),
      correct: card.correct
    }));

    // Sanitize other string fields
    const sanitizedCategory = sanitizeInput(category);
    const sanitizedLanguage = sanitizeInput(language);
    const sanitizedDifficulty = sanitizeInput(difficulty);
    const sanitizedAiInstructions = sanitizeInput(aiInstructions);
    const sanitizedSelectedModel = sanitizeInput(selectedModel);

    const collectionRef = await addDoc(collection(db, 'flashcardCollections'), {
      userId,
      collectionName: sanitizedCollectionName,
      flashcards: sanitizedFlashcards,
      isPublic,
      createdAt: Date.now(),
      numFlashcards,
      category: sanitizedCategory,
      language: sanitizedLanguage,
      difficulty: sanitizedDifficulty,
      aiInstructions: sanitizedAiInstructions,
      selectedModel: sanitizedSelectedModel,
      lastTrainingSession: Date.now(),
      upvotes: 0,
      downvotes: 0,
      userVotes: {}
    });

    const newCollection = {
      id: collectionRef.id,
      userId,
      collectionName: sanitizedCollectionName,
      flashcards: sanitizedFlashcards,
      isPublic
    };


 //      const collectionRef = await addDoc(collection(db, 'flashcardCollections'), {
 //        userId,
 //        collectionName,
 //        flashcards,
 //        isPublic,
 //        createdAt: Date.now(),
 //        numFlashcards,
 //        category,
 //        language,
 //        difficulty,
 //        aiInstructions,
 //        selectedModel,
 //              lastTrainingSession: Date.now(),
 //      upvotes: 0,
 //      downvotes: 0,
 //      userVotes: {}

 //      });

 // const newCollection = {
 //      id: collectionRef.id,
 //      userId,
 //      collectionName,
 //      flashcards,
 //      isPublic
 //    };

      onCollectionCreated(newCollection);
      showSnackbar(`Samlingen har skapats framgångsrikt!`, 'success');
      setCollectionName('');
      setFlashcards([{ front: '', back: '', correct: 'false' }]);


   setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 50);

      
    } catch (error) {
      showSnackbar('Fel vid skapande av samling: ' + error.message, 'info');
    } finally {
      setLoadingSaving(false);
    }
  };

  const handleImportChange = (e) => {
    setImportString(e.target.value);
  };

  const handleImport = () => {
    try {
      const importArray = JSON.parse(`[${importString.replace(/(\w+):/g, '"$1":')}]`);

      const formattedFlashcards = importArray.map(pair => {
        const [front, back] = Object.entries(pair)[0];
        return {
          front: front,
          back: back,
          correct: 'false'
        };
      });

      setFlashcards(formattedFlashcards);
      setShowImport(false);
    } catch (error) {
      setError('Fel vid importering: ' + error.message);
    }
  };

const handleAIInputChange = (e) => {
  if (inputType === 'subject') {
    setSubjectInput(subjectInputRef.current.value);
  } else if (inputType === 'text') {
    setTextInput(e.target.value);
  } else if (inputType === 'wiki-url') {
    setWikiUrlInput(e.target.value);
  }
};



const fetchWikipediaContent = async (url, maxLength = 5000) => {
  const pageTitle = url.split('/').pop();
  const lang = url.includes('sv.wikipedia.org') ? 'sv' : 'en';
  try {
    const response = await axios.get(`https://${lang}.wikipedia.org/api/rest_v1/page/html/${pageTitle}`);
    const $ = cheerio.load(response.data);
    let content = '';
    $('p, ul, ol').each((i, element) => {
      if (content.length < maxLength) {
        if (element.name === 'p') {
          content += $(element).text() + '\n\n';
        } else if (element.name === 'ul' || element.name === 'ol') {
          $(element).find('li').each((j, li) => {
            content += '• ' + $(li).text() + '\n';
          });
          content += '\n';
        }
      } else {
        return false; // Stop the .each() loop when maxLength is reached
      }
    });
    content = content.substring(0, maxLength); // Ensure we don't exceed maxLength
    Logger.log("Extracted text: ", content);
    return content;
  } catch (error) {
    // Error handling remains the same
    console.error('Error fetching Wikipedia content:', error);
    // ... rest of the error handling code ...
    return '';
  }
};




  const simulateProgress = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    
    let currentProgress = 0;
    let speed = 1;
    
    progressIntervalRef.current = setInterval(() => {
      speed = Math.max(0.1, Math.min(1.5, speed + (Math.random() - 0.5) * 0.2));
      currentProgress += speed;
      
      if (currentProgress > 70) {
        speed *= 0.95;
      }
      
      currentProgress = Math.min(currentProgress, 95);
      
      updateProgress(currentProgress);
      
      if (currentProgress >= 95) {
        clearInterval(progressIntervalRef.current);
      }
    }, 150);
    
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  };

  const handleGenerateFlashcards = async () => {
        updateProgress(0);
    setLoading(true);
    setMessage('');
    setFlashcards([]);

setTimeout(() => {
  const progressBar = document.querySelector('.progress-bar-container');
  if (progressBar) {
    progressBar.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}, 500);    

    Logger.log("inputType ", inputType);
    Logger.log("aiInput ", aiInput);

 Logger.log("Generating flashcards with:", {
      numFlashcards,
      category,
      language,
      difficulty,
      aiInstructions
    });


  let userInput = '';
    if (inputType === 'subject') {
      userInput = subjectInputRef.current.value;
    } else if (inputType === 'text') {
      userInput = textInputRef.current.value;
    } else if (inputType === 'wiki-url') {
      userInput =  await fetchWikipediaContent(wikiUrlInputRef.current.value, characterCount);
    }else if (inputType === 'chattar') {
userInput = textInput;

    }   else if (inputType === 'youtube') {
   userInput = await fetchYoutubeTranscript(youtubeUrlInputRef.current.value, characterCountYouTube);
  Logger.log("content: ", userInput);
    if (!userInput) {
      return; // Exit if no transcript was fetched
    }
    }



  if (selectedSuggestionRef.current) {
    userInput = selectedSuggestionRef.current;
  }

  Logger.log("content ", userInput);

    try {


      const stopSimulation = simulateProgress();
Logger.log("Selected model:", selectedModel);


  let diff = difficulty === "Svårt" ? "Extremt svåra flashcards, ytterst sällsynta." : difficulty;


const messages = [
          { role: 'system', content: `Generate EXACTLY ${numFlashcards} flashcards with the following format with "front" and "back" fields based on the users input. Everything should be in ${language}. Difficulty level: ${difficulty}. ${aiInstructions}. Follow this exact format every time: [{ "front": "question", "back": "answer" }, { "front": "question2", "back": "answer2" }].` },
          { role: 'user', content: userInput }
        ];
      const response = await callApi(messages, undefined, true, user.uid);

      Logger.log("response: ", response);


      let messageContent = response.data.choices[0].message.content.trim();
      Logger.log("messageContent", messageContent);
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Kontrollera om JSON-strukturen är korrekt
      const parsedFlashcards = JSON.parse(messageContent);
      Logger.log("parsedFlashcards", parsedFlashcards);

      if (!Array.isArray(parsedFlashcards)) {
        throw new Error('The response format is incorrect. Expected an array of flashcards.');
      }

      // Se till att varje objekt har rätt nycklar

      const formattedFlashcards = parsedFlashcards.map(card => {
        if (card.front && card.back) {
          return {
            front: card.front,
            back: card.back,
            correct: 'false'
          };
        } else {
          throw new Error('Flashcard format is incorrect. Each card must have "front" and "back" fields.');
        }
      });

      //setFlashcards(formattedFlashcards);



      let index = 0;
    for (let i = 0; i < formattedFlashcards.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 100)); // 100ms fördröjning
      setFlashcards(prev => [...prev, formattedFlashcards[i]]);
    }

      //setRenderInterval(interval);





      setAIInput('');
stopSimulation();
    updateProgress(100);
    selectedSuggestionRef.current = '';


    } catch (error) {
      showSnackbar('Fel vid generering av flashcards. Vänligen klicka på Generera igen. ', 'info');
      updateProgress(0);

    } finally {
      setLoading(false);
updateProgress(0);
    }
  };



  return (
    <Fade in={!viewIsLoading}>
    <Box sx={styles.container}>
    <Box sx={{...styles.innerContainer, padding:{xs:'0px', md:undefined}}}>


       {error && (
   <Snackbar
      open={!!error}
      onClose={() => setError(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={4000} // Optional: Automatically hides the alert after 6 seconds
    >
      <Alert
        onClose={() => setError(null)}
        severity="info"
        sx={{ width: '100%' }} // Ensures the Alert takes full width of the Snackbar
      >
        {` ${error}`}
      </Alert>
    </Snackbar>
      )}

      <Typography variant="h1" sx={styles.headerAboveBox}>Generera flashcards med AI</Typography>

        




      {showAIInput && (
<Box sx={{...styles.darkBorder, backgroundColor: isDark === true ? '#212c36' : 'white' , border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091', borderRadius: {xs: '0px', md: '8px'}, }} >

{/* 
<Typography style={styles.h5} sx={{marginTop:'10px'}}>Exempel på vad du kan generera:</Typography>

<ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li style={{ marginBottom: '10px' }}>
                        <Typography>
                            <FiberManualRecordIcon style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }} />
                            Matematiska koncept på framsidan och dess ekvation på baksidan.
                        </Typography>
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                        <Typography>
                            <FiberManualRecordIcon style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }} />
                            Spanska konjunktiv i dåtid på framsidan och översättning på baksidan.
                        </Typography>
                    </li>                    
                    <li style={{ marginBottom: '10px' }}>
                        <Typography>
                            <FiberManualRecordIcon style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }} />
                            Filosofiska koncept på framsidan och dess upphovsman på baksidan.
                        </Typography>
                    </li>

                    <li style={{ marginBottom: '10px' }}>
                        <Typography>
                            <FiberManualRecordIcon style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }} />
                            Händelser från romarriket på framsidan och dess årtal på baksidan.
                        </Typography>
                    </li>
                
                </ul>

*/}

<Box>
<Tooltip placement="top" title="Skriv in ett ämne, ladda upp en PDF/Word-fil, skriv in ett kapitel från en bok, eller välj en Wiki-sida.">
<Typography variant="formLabel" sx={{mt:1, fontWeight:'500'}}>Välj källa för dina flashcards:</Typography>
</Tooltip>

</Box>
<FormControl component="fieldset" sx={{ mt: 2, width:'100%' }}>
  <div style={{ 
    overflowX: 'auto', 
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    display: 'flex',
  }}>


  <ToggleButtonGroup
    value={inputType}
    exclusive
    onChange={handleInputTypeChange}
      sx={{
        ...styles.toggleButtonGroups,
        display: 'inline-flex',
        flexShrink: 0,
      }}
    aria-label="input type"
  >
    <ToggleButton value="subject" aria-label="subject" 

 sx={{...styles.toggles, ...styles.firstToggle }}
    >
            <TopicIcon /> 
Ämne
    </ToggleButton>
    <ToggleButton value="text" aria-label="text"  sx={styles.toggles}>
            <TextSnippetIcon  />
{isMobile ? 'Text' : 'Längre text'}
    </ToggleButton>
    <ToggleButton value="wiki-url" aria-label="wiki-url"  sx={{...styles.toggles}}>
      <WikipediaIcon /> 

{isMobile ? 'Wiki-URL' : 'Wiki-URL'}

    </ToggleButton>


      <ToggleButton value="youtube" aria-label="youtube-url" sx={{...styles.toggles, ...styles.lastToggle}}>
        <YouTubeIcon /> YouTube
      </ToggleButton>

{/*
<Tooltip title="Snart kan du generera flashcards baserat på dina chattar.">
<span>
<ToggleButton 
  value="chattar"  
  aria-label="chattar" 
  disabled={true}

  sx={(theme) => ({
    ...styles.toggles,
    ...styles.lastToggle,

    '&.Mui-disabled': {
      opacity: 0.5,
      pointerEvents: 'auto',

      backgroundColor: theme.palette.mode === 'dark' ? '#192734' : '#f0f0f0',
      color: theme.palette.mode === 'dark' ? '#657786' : '#999',
      '& svg': {
        color: theme.palette.mode === 'dark' ? '#657786' : '#999',
      },
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#192734' : '#f0f0f0',
        color: theme.palette.mode === 'dark' ? '#657786' : '#999',
        '& svg': {
          color: theme.palette.mode === 'dark' ? '#657786' : '#999',
        },
      },
    },
  })}
>
  <TextFieldsIcon />
  Chattar
</ToggleButton>
</span>
</Tooltip>
*/}
{isLoading && <CircularProgress size={15} />}
  </ToggleButtonGroup>

  </div>
</FormControl>


{inputType === 'chattar' && (
  <>
       <Select
          value={selectedChat}
          onChange={handleChatSelect}
          fullWidth
          sx={{ mt: 2, mb: 2, backgroundColor: 'white' }}
        >
          <MenuItem value="" disabled>
            Välj chatt
          </MenuItem>
          {chats.map((chat) => (
            <MenuItem key={chat.id} value={chat.id}>
              {chat.name || `Chatt ${chat.id.slice(0, 6)}`}
            </MenuItem>
          ))}
        </Select>
        {isLoading && <CircularProgress />}
        {selectedChat && (
          <TextField
            label="Meddelanden från vald chatt"
            value={textInput}
            onChange={handleAIInputChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"

          />
        )}
  </>
)}

      
 {inputType === 'subject' && (

 <>
  <TextField
    placeholder="Skriv in ämne (t.ex. 'Andra världskrigets viktigaste händelser och årtal')"
    inputRef={subjectInputRef}
multiline
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IdeaGenerator 
              user={user} 
              onIdeaGenerated={handleIdeaGenerated}
              systemMessage="ge mig en idé på flashcards på 4-5 meningar (exempel: Skapa flashcards om betydelsefulla uppfinningar genom historien. På framsidan ska namnet på uppfinningen stå. På baksidan ska uppfinnarens namn och året för uppfinningen anges, samt en kort beskrivning av uppfinningens påverkan på samhället eller vetenskapen.). Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
              position="top"
              size="small"
            />
          </InputAdornment>
        ),
      }}

              rows={3}
    fullWidth
    margin="normal"

  />


<Box>
<Suggestions 

        listType="flashcardIdeas" 
        maxSuggestions={6} 
        onSelect={handleSelect} 
      />
</Box>

</>

)}
{inputType === 'text' && (
  <TextField
    placeholder="Skriv in en längre text (t.ex. ett bokkapitel, läxa eller Word-dokument)" 
inputRef={textInputRef}
    fullWidth
    multiline
    rows={4}
    margin="normal"

  />



)}
{inputType === 'wiki-url' && (
<>
  <TextField
    placeholder="Skriv in en Wiki-URL (t.ex. https://en.wikipedia.org/wiki/History_of_literature)"
 inputRef={wikiUrlInputRef}
    fullWidth
    margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                ),
        endAdornment: (
          <InputAdornment position="end">
            <IdeaGenerator 
              user={user} 
              onIdeaGenerated={handleWikiIdeaGenerated}
              systemMessage="Ge mig en idé på en wikipedia-url till en populär wikipedia-sida (exempel: https://sv.wikipedia.org/wiki/Selma_Lagerl%C3%B6f) Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
              position="end"
              size="small"
            />
          </InputAdornment>
        ),
              }}




  />

<Box sx={{mb:2, mt:-1}}>
<Suggestions 
        listType="wikiPages" 
        maxSuggestions={6} 
        onSelect={handleSelectWiki} 
      />  
</Box>



    <Box sx={{...styles.newBox, background: theme.palette.mode === 'dark' ? '#1c262f' : '#f1f5f978', padding:'20px', mb:4, width:'100%'}}>
        <Typography gutterBottom sx={{fontWeight:'500'}}>
      Hur många tecken från artikeln vill du hämta?
    </Typography>
    <Slider
      defaultValue={5000}
      color="primary"
      sx={{
    width: '95%', // or any other value, like '50%', '20em', etc.
      marginLeft:'15px', mt:1
  }}
      step={200}
  marks={[
    { value: 500, label: '500' },
    { value: 1000, label: isMobile ? '' : '1000' },
    { value: 1500, label: isMobile ? '' : '1500' },
    { value: 2000, label: isMobile ? '' : '2000' },
    { value: 2500, label: '2500' },
    { value: 3000, label: isMobile ? '' : '3000' },
    { value: 3500, label: isMobile ? '' : '3500' },
    { value: 4000, label: isMobile ? '' : '4000' },
    { value: 4500, label: isMobile ? '' : '4500' },
    { value: 5000, label: '5000' },
  ]}
      min={500}
      max={5000}
      valueLabelDisplay="auto"
      onChange={(event, newValue) => setCharacterCount(newValue)}

    />

          <Alert severity="info"  sx={{ borderRadius: '8px', mt:2 }}>
      <i>Max är just nu 5000. Kontakta oss om du behöver mer.</i>
          </Alert>
    </Box>

</>
)}


 {inputType === 'youtube' && (
 <>
              <TextField
                placeholder="Ange YouTube-URL (t.ex. https://www.youtube.com/watch?v=dQw4w9WgXcQ)"
                inputRef={youtubeUrlInputRef}
                variant="outlined"
                sx={{ marginBottom: 2, mt:2, width: '100%'}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
<YouTubeIcon />
                    </InputAdornment>
                  ),
                }}
              />

<Box sx={{mb:2, mt:-2}}>
<Suggestions 
        listType="youtubeIdeas" 
        maxSuggestions={6} 
        onSelect={handleSelectYouTube} 
      />  
</Box>


    <Box sx={{...styles.newBox, background: theme.palette.mode === 'dark' ? '#1c262f' : '#f1f5f978', padding:'20px', mb:4, width:'100%'}}>
        <Typography gutterBottom sx={{fontWeight:'500'}}>
      Hur många tecken från YouTube-videon vill du hämta?
    </Typography>
    <Slider
      defaultValue={5000}
      sx={{
    width: '95%', // or any other value, like '50%', '20em', etc.
      marginLeft:'15px', mt:1
  }}
      step={200}
  marks={[
    { value: 500, label: '500' },
    { value: 1000, label: isMobile ? '' : '1000' },
    { value: 1500, label: isMobile ? '' : '1500' },
    { value: 2000, label: isMobile ? '' : '2000' },
    { value: 2500, label: '2500' },
    { value: 3000, label: isMobile ? '' : '3000' },
    { value: 3500, label: isMobile ? '' : '3500' },
    { value: 4000, label: isMobile ? '' : '4000' },
    { value: 4500, label: isMobile ? '' : '4500' },
    { value: 5000, label: '5000' },
  ]}
      min={500}
      max={5000}
      valueLabelDisplay="auto"
      onChange={(event, newValue) => setCharacterCountYouTube(newValue)}

    />


        <Alert severity="info"  sx={{ borderRadius: '8px', marginTop: '10px' }}>
            <i>Max är just nu 5000. Kontakta oss om du behöver mer.</i>

          </Alert>
          </Box>
</>
            )}




<Box>
<label htmlFor="ai-file-upload">
              <Input
                id="ai-file-upload"
                type="file"
                sx={{ display: 'none' }}
                onChange={handleFileChange}
              />
{inputType === 'text' && (
  <Button
    variant="outlined"
    component="span"
    startIcon={<WordIcon />}
  >
    Ladda upp Word-fil
  </Button>
)}
            </label>
</Box>




<Tooltip title="Resultaten kommer variera beroende på vilken AI-modell du väljer.">
<Typography variant="formLabel" sx={{mt:4, fontWeight:'500'}}>AI-modell</Typography>
</Tooltip>
<AIModelSelect 
        disabledOptions={["Gemini Pro", "Mistral Large"]}  
        selectedModel={selectedModel} 
        setSelectedModel={setSelectedModel} 
      />

<Tooltip title="Gör det enklare att hitta dina flashcards i ditt bibliotek senare.">
<Typography variant="formLabel" sx={{mt:2, fontWeight:'500'}}>Namn på samlingen</Typography>
</Tooltip>

      <TextField
      ref={collectionNameRef}
        placeholder="Namn på samlingen"
        value={collectionName}
        onChange={handleCollectionNameChange}
        margin="normal"
        fullWidth
        sx={{  mt:1 }}
      />


<Button 
        variant="outlined" 
        sx={{...styles.beautyBtnTres, mt:1}} 

        endIcon={showMoreOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={() => setShowMoreOptions(!showMoreOptions)}
      >
        {showMoreOptions ? 'Dölj fler alternativ' : 'Visa fler alternativ'}
      </Button>

<Collapse in={showMoreOptions}>
  <div style={{ marginTop: '20px' }}>
    <AdditionalFlashFields
      aiInstructions={aiInstructions}
      setAiInstructions={setAiInstructions}
      numFlashcards={numFlashcards}
      setNumFlashcards={setNumFlashcards}
      category={category}
      setCategory={setCategory}
      language={language}
      setLanguage={setLanguage}
      difficulty={difficulty}
      setDifficulty={setDifficulty}
      visibility={visibility}
      handleVisibilityChange={handlePublicChange}
      theme={theme}
    />
  </div>
</Collapse>




          <Box sx={{ display: 'flex', gap: 2, mt: 4 }}
          className="progress-bar-container">
            <Button
              onClick={handleGenerateFlashcards}
              variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
              color="primary"
              disabled={loading}
              startIcon={!loading ? <GenerateIcon style={{ color: '#fff' }} /> : <CircularProgress size={18} />}
            >
              Generera flashcards med AI
            </Button>
            </Box>

            {/*
<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
  <Button
    onClick={() => setShowImport(!showImport)}
    variant="contained"
    color="primary"
    startIcon={<FileUploadIcon />}
    sx={{opacity:'0.5', "&:hover": {opacity:'1'}}}
  >
    Importera JSON
  </Button>
</Box>

*/}
<Box sx={{ display: 'flex', gap: 2}}>

      {showImport && (
<Box sx={{...styles.darkBorder, backgroundColor: isDark === true ? '#15202B' : 'white' , border: isDark ? 'none' : '',


}} >
          <Typography variant="h3" sx={styles.h3}>Importera JSON</Typography>
          <TextField
            label="JSON-sträng"
            value={importString}
            onChange={handleImportChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            sx={{ backgroundColor: isDark ? '#212c36' : 'white' }}
            helperText={`Exempel: {"casa": "hus"}, {"gato": "katt"}, {"perro": "hund"}`}
          />
          
          <Button
            variant="contained"
            color="secondary"
            onClick={handleImport}
            sx={{ mt: 2, mb: 2, minWidth: 'auto' }}
            startIcon={<ArrowCircleDownIcon />}
          >
            Importera kort
          </Button>
        </Box>
      )}

            
          </Box>
          <Alert severity="info"  sx={{ borderRadius: '8px', marginTop: '20px' }}>
            <strong>Tips:</strong> Var så specifik som möjligt för bästa resultat, t.ex. "Spanska konjunktiv i dåtid på framsidan och översättning på baksidan, t.ex. tuviera - jag hade."
          </Alert>


{loading && (
  <>
  <Box sx={{ width: '100%', mb: 2, mt:4,
backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f8f8f8',
  padding: '20px',
  paddingTop:'7px',
  paddingBottom:'20px',
  borderRadius: '10px'

   }}>
    <Typography variant="body2" color="text.secondary" sx={{...styles.h5, mt:2, mb:1}}>

      Genererar flashcards...
    </Typography>
   <Box sx={{ width: '100%', height: 20, bgcolor: 'background.paper', borderRadius: 5, overflow: 'hidden' }}>
      <Box
        ref={progressRef}
        sx={{
          width: '0%',
          height: '100%',
          backgroundColor: theme.palette.mode === 'dark' ? '#6F38EA' : '#6339c4',
          transition: 'width 0.1s linear'
        }}
      />
    </Box>
    <Typography 
      ref={progressPercentRef} 
      variant="body2" 
      color="text.secondary" 
      align="right"
    >
      0%
    </Typography>
    </Box>
  </>
)}

        </Box>
      )}

      {flashcards.length === 0 && !loading && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddCard()}
          sx={{ mt: 2, mb: 2, width: '170px' }}
          startIcon={<AddIcon />}
        >
          Lägg till kort
        </Button>
      )}

      {flashcards.map((flashcard, index) => (


<Fade in={true} key={index} timeout={50}>
        <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            id={`front-${index}`}
            label="Framsida"
            value={flashcard.front}
            onChange={(e) => handleFrontChange(index, e)}
            onKeyDown={(e) => handleTabPress(index, false, e)}
            fullWidth
            multiline
            rows={3}
            margin="normal"



            sx={{...styles.flashcardFrontBackInput, backgroundColor: isDark ? '#212c36' : 'white', borderColor: isDark ? '#3b4651' : ''}}
          />
          <TextField
            id={`back-${index}`}
            label="Baksida"
            value={flashcard.back}
            onChange={(e) => handleBackChange(index, e)}
            onKeyDown={(e) => handleTabPress(index, true, e)}
            fullWidth
            multiline
            rows={3}
            margin="normal"
            sx={{...styles.flashcardFrontBackInput, backgroundColor: isDark ? '#212c36' : 'white', borderColor: isDark ? '#3b4651' : ''}}
          />
          <IconButton onClick={() => handleDeleteCard(index)} aria-label="delete" color="secondary">
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => handleAddCard(index)} aria-label="add" color="primary">
            <AddIcon />
          </IconButton>
        </Box>
</Fade>
      ))}


     {flashcards.length > 1 && (
<>

          <Alert severity="info"  sx={{ borderRadius: '8px', marginTop: '20px', marginBottom:'20px' }}>
            <strong>Inte nöjd?</strong> Var mer specifik i dina instruktioner och generera igen. Ge gärna exempel till AI-modellen.
          </Alert>
</>
   )}

          <Alert severity="info" sx={{ borderRadius: '8px', marginTop: '20px', marginBottom:'20px', width:'auto' }}>
            <strong>Tips:</strong> Använd TAB när du lägger till kort manuellt så slipper du använda musen.
          </Alert>



      {flashcards.length > 0 && (

<>

<Box>
        <Button
variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
          color="primary"
          onClick={handleSubmit}
          disabled={loadingSaving}
          sx={{ width: 'auto', marginBottom: '50px' }}
          startIcon={!loadingSaving ? <SaveIcon /> : <CircularProgress size={18} />}
        >
          Starta träning
        </Button>
        </Box>
        </>
      )}
      {message && <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>{message}</Typography>}
    </Box>
    </Box>
    </Fade>
  );
};

export default FlashcardsCreateView;
