import React from 'react';

import { Box, Skeleton, Card, CardContent } from '@mui/material';

export const FlashcardSkeleton = () => (
  <Card sx={{ mb: 0, boxShadow:'none' }}>
    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px', boxShadow:'none'  }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
        <Skeleton variant="text" width="80%" height={24} animation="wave" />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="circular" width={20} height={20} animation="wave" sx={{ mr: 1 }} />
            <Skeleton variant="text" width={60} height={20} animation="wave" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Skeleton variant="text" width={60} height={30} animation="wave" />
            <Skeleton variant="text" width={40} height={30} animation="wave" />
          </Box>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export const QuizSkeleton = () => (
  <Card sx={{ mb: 0, boxShadow:'none'  }}>
    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px', boxShadow:'none'  }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '60%' }}>
        <Skeleton variant="text" width="80%" height={24} animation="wave" />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Skeleton variant="circular" width={20} height={20} animation="wave" sx={{ mr: 1 }} />
          <Skeleton variant="text" width={60} height={20} animation="wave" />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={80} height={20} animation="wave" />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Skeleton variant="text" width={60} height={30} animation="wave" />
            <Skeleton variant="text" width={40} height={30} animation="wave" />
        </Box>
      </Box>
    </CardContent>
  </Card>
);


export const TeacherSkeleton = () => (
  <Box 
    sx={{ 
      width: 'calc(33.33% - 16px)',
      minWidth: '200px',
      '@media (max-width: 900px)': {
        width: 'calc(50% - 8px)',
      },
      '@media (max-width: 600px)': {
        width: '100%',
      },
    }}
  >
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2, 
        padding: 1,
        borderRadius: 10,
      }}
    >
      <Skeleton variant="circular" width={36} height={36} animation="wave" />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>                    
        <Skeleton variant="text" width="80%" height={20} animation="wave" />
        <Skeleton variant="text" width="60%" height={16} animation="wave" />
      </Box>
    </Box>
  </Box>
);

export default TeacherSkeleton;