import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDownOutlined';
import { db } from './firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import Logger from './Logger';
const RecommendationComponent = ({ itemId, userId, collectionName, questionText }) => {
  const [recommendation, setRecommendation] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [fadeOutBox, setFadeOutBox] = useState(false);
  const [upvotes, setUpvotes] = useState(0);
  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    Logger.log("recommend id: ", itemId);
    const fetchRecommendation = async () => {
      if (itemId) {
        const docRef = doc(db, collectionName, itemId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setRecommendation(data.userVotes?.[userId] || null);
          setHasVoted(data.userVotes?.hasOwnProperty(userId) || false);
          //setFadeOutBox(data.userVotes?.hasOwnProperty(userId) || false);
          setUpvotes(data.upvotes || 0);
        }
      }
    };
    fetchRecommendation();
  }, [itemId, userId, collectionName]);

  const handleRecommend = async (isRecommended) => {

    if (itemId) {
      const docRef = doc(db, collectionName, itemId);
      const docSnap = await getDoc(docRef);
      const currentData = docSnap.data();
      const updatedData = {
        [`userVotes.${userId}`]: isRecommended,
        upvotes: (currentData.upvotes || 0) + (isRecommended ? 1 : 0),
        downvotes: (currentData.downvotes || 0) + (isRecommended ? 0 : 1),
      };
      if (hasVoted) {
        updatedData.upvotes -= currentData.userVotes[userId] ? 1 : 0;
        updatedData.downvotes -= currentData.userVotes[userId] ? 0 : 1;
      }
      await updateDoc(docRef, updatedData);
      setRecommendation(isRecommended);
      setHasVoted(true);
      setShowThankYou(true);
      setUpvotes(updatedData.upvotes);
      //setTimeout(() => setFadeOutBox(true), 2000);
       Logger.log("Röstade just på HP-ID: ", itemId);
    }
  };

  //if (fadeOutBox) return null;

  return (
    <Box sx={{
      ...styles.smallBox,
      padding: '10px',
      marginTop: '0px',
      display: 'inline-flex',
      width:'auto',
      alignItems: 'center',
      position: 'relative',
    }}>
      <IconButton
        onClick={() => handleRecommend(true)}
        disabled={hasVoted}
        color={recommendation === true ? "primary" : "default"}
      >
        <ThumbUpIcon fontSize="small" />
      </IconButton>
      <Typography sx={{ mx: 0, fontWeight:'bold', fontSize:'15px', marginRight:'7px', color: theme.palette.mode === 'dark' ? 'white' : '#555' }}>{upvotes}</Typography>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <IconButton
        onClick={() => handleRecommend(false)}
        disabled={hasVoted}
        color={recommendation === false ? "primary" : "default"}
      >
        <ThumbDownIcon fontSize="small" />
      </IconButton>

    </Box>

  );
};

export default RecommendationComponent;