import React, { useState, useEffect, useRef,  lazy, Suspense } from 'react';
import axios from 'axios';
import { db } from './firebase';
import { collection, addDoc, getDocs, query, where, deleteDoc, doc, getDoc } from 'firebase/firestore';

import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/Check';
import { updateUserTokens } from './functions';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { addNote } from './NotesView';
import ExploreIcon from '@mui/icons-material/Explore';
import Logger from './Logger';
import { formatDate } from './functions';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Helmet } from 'react-helmet-async';
import DrawerComponent from './quiz/DrawerComponent';
import QuizExplanation from './quiz/QuizExplanation';
import QuestionTypeSelector from './quiz/QuestionTypeSelector';
import WordIcon from './Icons/WordIcon'; 
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TopicIcon from '@mui/icons-material/Subject';
import { Link as LinkIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingSpinner from './LoadingSpinner';
import IdeaGenerator from './IdeaGenerator';
import BookmarkIcon from '@mui/icons-material/BookmarkBorder';
import { YoutubeTranscript } from 'youtube-transcript';
import { saveQuizResult, generateDummyData } from './quizFunctions';
import { QuizLeaderboard, UserPercentile, UserProgressChart } from './quizResultComponents';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { Card, CardContent, Drawer, RadioGroup, FormControlLabel, Fade, Collapse, Slider, Divider, Alert, Input, FormControl, ToggleButton, ToggleButtonGroup, Radio, IconButton, InputAdornment } from '@mui/material';
import WikipediaIcon from './WikipediaIcon';

import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';

import Looks4Icon from '@mui/icons-material/Looks4';
import AIDialog from './AIDialog';
import SegmentIcon from '@mui/icons-material/Segment';
import CircleIcon from '@mui/icons-material/Circle';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import mammoth from 'mammoth';
import Hint from './Hint';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FunctionsIcon from '@mui/icons-material/Functions';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import Suggestions from './Suggestions';
import RecommendationComponent from './RecommendationComponent';
import { callApi, callClaude } from './api';
import { Howl } from 'howler';
import selectSoundFile from './sounds/select.mp3';
import select2 from './sounds/select2.mp3';
import done from './sounds/done.mp3';
import { useSnackbar } from './SnackbarHandler';

import { AIModelSelect } from './TestComponents2';
import Tooltip from '@mui/material/Tooltip';

import { sanitizeInput } from './functions';
import { useParams, useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';


import Avatar from '@mui/material/Avatar';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';


import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import { useMediaQuery } from '@mui/material';


import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import * as pdfjsLib from 'pdfjs-dist/webpack';




// Set up the worker source
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


const cheerio = require('cheerio');

const AdditionalFields = lazy(() => import('./AdditionalFields'));

const CenteredButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  margin: '0 auto',
  textAlign: 'center',
  marginTop: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#000', // Använd en mörkare blågrå nyans för mörkt tema
  fontWeight: 'bold',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', // Vit text för båda teman
  borderColor: theme.palette.mode === 'dark' ? '#5a6a8a' : '#28146c', // Använd en något ljusare blå för mörkt tema
  width: {xs:'100%', md:'25%'},
  padding: '10px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? '#2d3a4d' : '#000', // Mörkare blågrå vid hover
    color: '#ffffff', // Vit text vid hover
    backgroundColor: theme.palette.mode === 'dark' ? '#5a6a8a' : '#28146c', // Ljusa blågrå för hover i mörkt tema
  },
  '&:disabled': {
    borderColor: 'transparent', // Remove border on disabled state
  },
}));




const OptionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '45%',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c' : '#f9f9f9', // Använd en mörk blågrå nyans för mörkt tema
  color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#000000', // Ljusgrå text för mörkt tema och svart för ljust tema
  fontSize: '1rem',
  borderRadius: '10px',
  borderColor: theme.palette.mode === 'dark' ? '#4a5d6b' : '#eaeaea', // Mörkare blågrå för mörkt tema
  display: 'flex',
  justifyContent: 'left',
  textAlign: 'left',
  padding: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6',
    },

  '&:active': {

    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6', // Lila text vid fokus
    backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#eee9ff', // Mörkare blågrå vid fokus i mörkt tema
  },

  '&:focus': {
    outline: 'none',
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6', // Lila text vid fokus
    backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#eee9ff', // Mörkare blågrå vid fokus i mörkt tema
  },
  '&.MuiButton-contained': {
     outline: 'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#1097da' : '#28146c', // Mörkare blågrå för mörkt tema och lila för ljust tema
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', // Vit text för båda teman
    borderColor: theme.palette.mode === 'dark' ? '#1675a3' : '#1675a3', // Mörkare blågrå border för mörkt tema
    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', // Vit ikonfärg för båda teman
    },
  },
}));







const QuizView = ({ user }) => {


  const [quiz, setQuiz] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [creatorInfo, setCreatorInfo] = useState(null);

  const [viewIsLoading, setViewIsLoading] = useState(true);

  const [results, setResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [inputType, setInputType] = useState('topic');
  const [inputValue, setInputValue] = useState('');
  const [numQuestions, setNumQuestions] = useState(10);
  const [quizName, setQuizName] = useState('');
  const [isManaging, setIsManaging] = useState(false);
  const [quizInProgress, setQuizInProgress] = useState(false);
  const [currentQuizId, setCurrentQuizId] = useState(null);
  const [loadingQuiz, setLoadingQuiz] = useState(false); // State for loading quiz
  const [loadingMyQuizzes, setLoadingMyQuizzes] = useState(false); // State for loading quiz

  const [selectedValue, setSelectedValue] = useState('topic');


const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);

const [showLibrary, setShowLibrary] = useState(false);

const [isPublic, setIsPublic] = useState(true);
const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
const [characterCount, setCharacterCount] = useState(5000);
const [characterCountYouTube, setCharacterCountYouTube] = useState(5000);
  

  const youtubeUrlInputRef = useRef(null);


const fetchYoutubeTranscript = async (url, maxLength = 5000) => {
  try {
    console.log('Attempting to fetch transcript for URL:', url);
    const response = await axios.post('http://localhost:3001/api/youtube-transcript', { url });
    
    console.log('Server response:', response);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch transcript. Status: ${response.status}`);
    }
    
    // Limit the transcript to maxLength characters
    const fullTranscript = response.data.transcript;
    return fullTranscript.length > maxLength ? fullTranscript.slice(0, maxLength) : fullTranscript;
  } catch (error) {
    console.error('Detailed error in fetchYoutubeTranscript:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    showSnackbar("Kunde inte hämta YouTube-transkript. Kontrollera URL:en och försök igen.", "error");
    return '';
  }
};


const [originalQuizId, setOriginalQuizId] = useState('');

const [dynamicTitle, setDynamicTitle] = useState("Quiz — Skapa quiz med AI — Studera.AI");
const [dynamicDescription, setDynamicDescription] = useState("Skapa quiz med AI. Du kan generera ett quiz med AI genom att skriva in ett ämne eller en längre textmassa.");

const [questionType, setQuestionType] = useState('multiple-choice');

  const currentQuestion = quiz[currentQuestionIndex];
  const [quizCreatedAt, setQuizCreatedAt] = useState([]);
  const [openAIDialogs, setOpenAIDialogs] = useState({});
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { showSnackbar } = useSnackbar();


const { quizId, urlQuizName } = useParams();
const navigate = useNavigate();

  const [showCreateQuizForm, setShowCreateQuizForm] = useState(true);

const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
const nextSound = new Howl({ src: [select2], volume: 0.6 });
const doneSound = new Howl({ src: [done], volume: 0.2 });

const [selectedLanguage, setSelectedLanguage] = useState("Svenska");
const [selectedModel, setSelectedModel] = useState("Studera.AI");
const selectedSuggestionRef = useRef('');

const [progress, setProgress] = useState(0);


const progressIntervalRef = useRef(null);

const progressRef = useRef(null);
  const progressPercentRef = useRef(null);


const [aiDialogOpen, setAiDialogOpen] = useState(false);

const [currentQuiz, setCurrentQuiz] = useState('');



const [category, setCategory] = useState('Välj kategori');
const [difficulty, setDifficulty] = useState('Medel');
const [aiInstructions, setAiInstructions] = useState('');


  const subjectInputRef = useRef(null);
  const textInputRef = useRef(null);
  const wikiUrlInputRef = useRef(null);
  const [bookmarks, setBookmarks] = useState({});


  const [quizIdea, setQuizIdea] = useState('');

  const handleIdeaGenerated = (idea) => { subjectInputRef.current.value = idea; };
  const handleWikiIdeaGenerated = (idea) => { wikiUrlInputRef.current.value = idea; };


  const toggleBookmark = async () => {
    const currentQuestion = quiz[currentQuestionIndex];
    const content = `<strong>Fråga:</strong><br /> ${currentQuestion.question}\n <br /><br /><strong>Alternativ:</strong><br /> ${currentQuestion.options.join(', ')}\n <br /><br />Rätt svar: ${currentQuestion.correct}`;
    const conversationType = `quiz-${currentQuizId}`;

    if (bookmarks[currentQuestionIndex]) {
      // If already bookmarked, you might want to remove the bookmark
      // This depends on how you've implemented the addNote and removeNote functions
      // await removeNote(user.uid, bookmarks[currentQuestionIndex]);
      setBookmarks(prev => {
        const newBookmarks = { ...prev };
        delete newBookmarks[currentQuestionIndex];
        return newBookmarks;
      });
    } else {
      const noteId = await addNote(user.uid, content, null, conversationType);
      setBookmarks(prev => ({ ...prev, [currentQuestionIndex]: noteId }));
    }
  };

  const handleOpenAIDialog = (index) => {
    setOpenAIDialogs(prev => ({ ...prev, [index]: true }));
  };

  const handleCloseAIDialog = (index) => {
    setOpenAIDialogs(prev => ({ ...prev, [index]: false }));
  };
const handleAskAI = () => {
  Logger.log("Opening AI Dialog from handleAskAI");
  setAiDialogOpen(true);
};

const formatUrlPart = (str) => {
  return str
    .replace(/\s+/g, '-')
    .replace(/[åä]/g, 'a')
    .replace(/[ö]/g, 'o')
    .replace(/[^a-z0-9-]/gi, '')
    .toLowerCase();
};

  const updateProgress = (value) => {
    if (progressRef.current) {
      progressRef.current.style.width = `${value}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(value)}%`;
    }
  };



const handleSelectWiki = (selected) => {
  
  setInputType('wikipedia'),
  setInputValue(selected.value);
  setQuizName(selected.text);

  if (selected.text) {

    wikiUrlInputRef.current.value = selected.value;

  }

 if (wikiUrlInputRef.current) {
    wikiUrlInputRef.current.value = selected.value;
  } 
}


const handleSelectYouTube = (selected) => {
  
  setInputType('youtube'),
  setInputValue(selected.value);
  setQuizName(selected.text);

  if (selected.text) {

    youtubeUrlInputRef.current.value = selected.value;

  }

 if (youtubeUrlInputRef.current) {
    youtubeUrlInputRef.current.value = selected.value;
  } 
}


const handleSelect = (selected) => {
  
  setInputType('topic'),
  setInputValue(selected.value);
  setQuizName(selected.text);



  if (selected.text) {

    selectedSuggestionRef.current = selected.value;

  }

 if (subjectInputRef.current) {
    subjectInputRef.current.value = selected.value;
  } 
}





const handleNewQuiz = () => {

selectedSuggestionRef.current = '';


  setShowCreateQuizForm(true);
  setShowSummary(false);
  setQuiz([]);
  setQuizInProgress(false);
  setCurrentQuizId(null);  // Lägg till denna rad
  setResults([]);  // Lägg till denna rad

// återställ alla fält

  setInputType('topic');
  setInputValue('');
  setOriginalQuizId('');
  setNumQuestions(10);
  setQuizName('');
  setSelectedLanguage('Svenska');
  setSelectedModel('Studera.AI');
  setQuestionType('multiple-choice');
  setIsPublic(true);
  setCurrentQuestionIndex(0);
  setSelectedOption(null);

navigate(`/quiz`);


};

 const simulateProgress = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    
    let currentProgress = 0;
    let speed = 1;
    
    progressIntervalRef.current = setInterval(() => {
      speed = Math.max(0.1, Math.min(1.5, speed + (Math.random() - 0.5) * 0.2));
      currentProgress += speed;
      
      if (currentProgress > 70) {
        speed *= 0.95;
      }
      
      currentProgress = Math.min(currentProgress, 95);
      
      updateProgress(currentProgress);
      
      if (currentProgress >= 95) {
        clearInterval(progressIntervalRef.current);
      }
    }, 150);
    
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  };


const fetchQuiz = async (inputType, content, numQuestions, category, difficulty, aiInstructions) => {
    updateProgress(0);



  // let content = '';
  // if (inputType === 'wikipedia') {
  //   content = await fetchWikipediaContent(inputValue);
  // } else {
  //   content = inputValue;
  //}

  // if (selectedSuggestionRef.current) {
  //   content = selectedSuggestionRef.current;
  // }


Logger.log("content to generate: ", content);
Logger.log("from selectedSuggestion: ", selectedSuggestionRef.current);

if (content.trim() === '') {
  content = 'Ge mig ett quiz med blandade frågor som har med nåt som kräver åtminstone lite bildning.'; // Eller något annat standardvärde du vill använda
}


Logger.log("inputType: ", inputType);
Logger.log("inputValue: ", inputValue);
Logger.log("numQuestions: ", numQuestions);
  Logger.log("Selected language:", selectedLanguage);
  Logger.log("Selected model:", selectedModel);
  Logger.log("questionType: ", questionType);
  Logger.log("content: ", content);

  // let instructions = '';
  // if (questionType === "fill-in-the-blank")
  //   instructions = "Generate a fill in the blanks quiz with ${numQuestions} questions based on the provided text. Everything should be in Swedish. Each question should be a JSON object with a 'question' field containing the text with a blank (____),an 'options' field containing 4 options, and a 'correct' field. Ensure all questions are returned in a single JSON array. Format example: [{ 'question': 'En sekvens av ______ baser bestämmer genetisk information', 'answer': 'Kvävehaltiga', 'options': ['Kvävehaltiga', 'Guld', 'Brom', 'Gas'] }]";
  // else
  //   instructions = `Generate a quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: [{ "question": "Who won the FIFA World Cup in 2018?", "options": ["Brazil", "France", "Germany", "Argentina"], "correct": "France" }]`;

  let instructions = '';
  if (questionType === "fill-in-the-blank") {
    instructions = `Generate a fill in the blanks quiz with ${numQuestions} questions based on the provided text. Everything should be in ${selectedLanguage}. Each question should be a JSON object with a 'question' field containing the text with a blank (_), an 'options' field containing 4 options, and a 'correct' field. Ensure all questions are returned in a single JSON array. Format example: [{ 'question': 'En sekvens av _ baser bestämmer genetisk information', 'answer': 'Kvävehaltiga', 'options': ['Kvävehaltiga', 'Guld', 'Brom', 'Gas'] }]`;
  } else if (questionType === "true-or-false") {
    instructions = `Generate a true or false quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing only "True" and "False" as options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: [{ "question": "Is the Earth flat?", "options": ["Sant", "Falskt"], "correct": "Falskt" }]`;
  }
   else {
    instructions = `Generate a quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: [{ "question": "Who won the FIFA World Cup in 2018?", "options": ["Brazil", "France", "Germany", "Argentina"], "correct": "France" }]`;
  }

if (category && category !== "Välj kategori") {
  instructions += ` The quiz should be focused on the category: ${category}.`;
}

  if (difficulty) {
    instructions += ` The difficulty level should be ${difficulty}.`;
  }

  if (aiInstructions) {
    instructions += ` Additional instructions: ${aiInstructions}`;
  }


let messages = '';

if (selectedModel === 'Claude')
  messages = [ { role: 'user', content: `${instructions}\n\nTopic: ${content}` }];
else
  messages = [ { role: 'system', content: instructions }, { role: 'user', content: `Topic: ${content}` } ];

  Logger.log("message to api: ", messages);

  try {


 

    const stopSimulation = simulateProgress();
let response = "";

if (selectedModel === 'Claude')
  response = await callApi(messages, 4000, true, user.uid);
else
  response = await callApi(messages, 4000, true, user.uid);


Logger.log("response: ", response);

    let messageContent = '';
// if (selectedModel === 'Claude')
//   messageContent = response.data.content[0].text;
// else
  messageContent = response.data.choices[0].message.content.trim();


     
    Logger.log("API response content: ", messageContent);

    if (messageContent.startsWith('```json')) {
      messageContent = messageContent.slice(7, -3).trim();
    } else if (messageContent.startsWith('```')) {
      messageContent = messageContent.slice(3, -3).trim();
    }

// Escapea dubbla citattecken inom strängar
messageContent = messageContent.replace(/(:\s*")(.+?)(?=")/g, function (match, p1, p2) {
  return p1 + p2.replace(/"/g, '\\"');
});

    let parsedResponse;
    try {
      parsedResponse = JSON.parse(messageContent);



          Logger.log('Total tokens for quiz:', response.data.usage.total_tokens);
          updateUserTokens(user.uid, response.data.usage.total_tokens);




    } catch (parseError) {
      console.error('Error parsing JSON response:', parseError);
      return [];
    }

    Logger.log("Parsed response: ", parsedResponse);

stopSimulation();
    updateProgress(100);


setTimeout(() => {
    updateProgress(0);
  }, 2000);

    return parsedResponse;
  } 

   catch (error) {
    console.error('Error fetching quiz:', error);
    updateProgress(0);
    showSnackbar("Knakar i fogarna: Försök generera quizzet igen.", 'info');
    handleNewQuiz();
    return [];
  } 
  finally {
    //updateProgress(0);
  }
};





const toggleDrawer = (open) => () => {
  setDrawerOpen(open);
};



const handlePublicChange = (event) => {
    setIsPublic(event.target.checked);
};
const toggleLibrary = () => {
  setShowLibrary(!showLibrary);
};



  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        let text = '';
        
        if (file.type === 'application/pdf') {
          try {
            const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
            for (let i = 1; i <= pdf.numPages; i++) {
              const page = await pdf.getPage(i);
              const content = await page.getTextContent();
              text += content.items.map(item => item.str).join(' ') + '\n';
            }
          } catch (error) {
            console.error('Error parsing PDF:', error);
            // Handle the error (e.g., show a message to the user)
          }
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          try {
            const result = await mammoth.extractRawText({ arrayBuffer });
            text = result.value;
          } catch (error) {
            console.error('Error parsing Word document:', error);
            // Handle the error (e.g., show a message to the user)
          }
        }

        if (textInputRef.current) {
          textInputRef.current.value = text;
          setInputValue(text);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };


useEffect(() => {
  setCurrentQuestionNumber(currentQuestionIndex + 1);
}, [currentQuestionIndex]);







  // Funktion för att läsa tangenttryckningar
  useEffect(() => {
    const handleKeyDown = (event) => {
Logger.log("isAIDialogOpen handleKeyDown: ", aiDialogOpen);
     if (aiDialogOpen) {
        return;
      }      
      if (!quizInProgress) return; 

 


      switch (event.key) {
    case '1':
    case '2':
    case '3':
    case '4':
      // Välj alternativ baserat på tangenttryckning
      const optionIndex = parseInt(event.key) - 1;
      if (optionIndex >= 0 && optionIndex < quiz[currentQuestionIndex].options.length) {
        handleOptionSelect(quiz[currentQuestionIndex].options[optionIndex]);
      }
      break;
    case ' ':
          case 'Enter':
            event.preventDefault();
            Logger.log("selectedOption: ", selectedOption);
            if (selectedOption === null) {
              showSnackbar("Välj ett svarsalternativ.", 'info');
            } else {
              handleNextQuestion();
            }
      break;
    case 'F':
    case 'f':
      handleAskAI();
      break;
    default:
      break;
  }
    };

    // Lägg till händelselyssnare för tangenttryckningar
    document.addEventListener('keydown', handleKeyDown);

    // Städa upp event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentQuestionIndex, quiz, quizInProgress, aiDialogOpen, selectedOption]);



   const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'inputType') {
      setInputType(value);
    } else if (name === 'quizName') {
      setQuizName(value);
    }
    setSelectedValue(e.target.value);
  };


const generateRandomQuizName = () => {
  // const prefix = selectedSuggestionRef.current.slice(0, 10);
  // const randomId = Math.floor(1000 + Math.random() * 9000);
  // return `${prefix}-${randomId}`;
  return selectedSuggestionRef.current;
};


const fetchWikipediaContent = async (url, maxLength = 5000) => {
  const pageTitle = url.split('/').pop();
  const lang = url.includes('sv.wikipedia.org') ? 'sv' : 'en';
  try {
    const response = await axios.get(`https://${lang}.wikipedia.org/api/rest_v1/page/html/${pageTitle}`);
    const $ = cheerio.load(response.data);
    let content = '';
    $('p, ul, ol').each((i, element) => {
      if (content.length < maxLength) {
        if (element.name === 'p') {
          content += $(element).text() + '\n\n';
        } else if (element.name === 'ul' || element.name === 'ol') {
          $(element).find('li').each((j, li) => {
            content += '• ' + $(li).text() + '\n';
          });
          content += '\n';
        }
      } else {
        return false; // Stop the .each() loop when maxLength is reached
      }
    });
    content = content.substring(0, maxLength); // Ensure we don't exceed maxLength
    Logger.log("Extracted text: ", content);
    return content;
  } catch (error) {
    // Error handling remains the same
    console.error('Error fetching Wikipedia content:', error);
    // ... rest of the error handling code ...
    return '';
  }
};



const loadQuiz = async () => {


    if (!quizName.trim()) {
      showSnackbar("Vänligen ange ett quiz-namn.", 'warning');
      return;
    }

    let content = '';
    if (inputType === 'topic') {
      content = subjectInputRef.current.value;
    } else if (inputType === 'text') {
      content = textInputRef.current.value;
    } else if (inputType === 'wikipedia') {
      content = await fetchWikipediaContent(wikiUrlInputRef.current.value, characterCount);
    if (!content) {
      return; // Exit if no transcript was fetched
    }
    }  else if (inputType === 'youtube') {
   content = await fetchYoutubeTranscript(youtubeUrlInputRef.current.value, characterCountYouTube);
  Logger.log("content: ", content);
    if (!content) {
      return; // Exit if no transcript was fetched
    }
    }

if (inputType !== 'wikipedia' && content.length > 5000) {
  showSnackbar("Meddelandet till AI-modellen var för långt ( > 5000). ", 'info');
  return;
}

  setLoadingQuiz(true); // Start loading state
  setCurrentQuizId(null); // Reset currentQuizId before loading a new quiz

  try {


setTimeout(() => {
  const progressBar = document.querySelector('.progress-bar-container');
  if (progressBar) {
    progressBar.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}, 500);

    const fetchedQuiz = await fetchQuiz(inputType, content, numQuestions, category, difficulty, aiInstructions);
    
    // Generate a quiz name if it's not provided
    let quizNameToSave = quizName || generateRandomQuizName();
const sanitizedQuizName = sanitizeInput(quizName || generateRandomQuizName());
    // Save the quiz to the database
    const docRef = await addDoc(collection(db, 'quiz'), {
      userId: user.uid,
      results: fetchedQuiz,
      quizName: sanitizedQuizName,
      createdAt: Date.now(),
      byAdmin: "No",
      isPublic: isPublic,
      questionType: questionType,
      category,
      difficulty,
      aiInstructions,
      lastTrainingSession: Date.now(),
      language: selectedLanguage,
      aiModel: selectedModel,
      upvotes: 0,
      downvotes: 0,
      userVotes: {}
    });

    Logger.log("Quiz saved with ID: ", docRef.id);

    setQuiz(fetchedQuiz);
    setQuizInProgress(true);
    setCurrentQuizId(docRef.id);

    // Navigate to the new quiz URL
    const formattedQuizName = formatUrlPart(sanitizedQuizName);
    navigate(`/quiz/${docRef.id}/${formattedQuizName}`);

  } catch (error) {
    console.error('Error generating and saving quiz:', error);
    showSnackbar("Det uppstod ett fel vid generering av quizet.", 'error');
  } finally {
    setLoadingQuiz(false); // End loading state
    setProgress(0);

   setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 500);


  }
};




  const loadQuizzes = async () => {
    try {
      setLoadingMyQuizzes(true);
      const q = query(collection(db, 'quiz'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const quizzesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      Logger.log("Fetched quizzes: ", quizzesData);
      setQuizzes(quizzesData);


setViewIsLoading(false);


    } catch (error) {
      console.error('Error fetching quizzes:', error);
    } finally {
      setLoadingMyQuizzes(false);
    }
  };


const loadQuizFromParams = async (id) => {
  try {
    const docRef = doc(db, 'quiz', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const quizData = docSnap.data();
      
      if (!quizData.results || quizData.results.length === 0) {
        showSnackbar('Något gick fel. Försök igen eller generera nytt.', 'info');
        handleNewQuiz();
        return;
      
      }
      

      //Moonshine: ej säker på denna raden jag la till ,kanske ta bort
      setQuiz(quizData);
      
      if (quiz)
      Logger.log("quizzz: ", quizData.createdAt);
setQuizCreatedAt(quizData.createdAt);

      handleQuizLoad({ id, ...quizData });
      
setViewIsLoading(false);

      setDynamicTitle(`${quizData.quizName} — Quiz — Studera.AI`);
      setDynamicDescription(`Testa din kunskap i vårt quiz om ${quizData.quizName}. Svårighetsgraden är ${quizData.difficulty}.`);

      if (quizData.originalQuizId) {
        setOriginalQuizId(quizData.originalQuizId);
      } else {
        setOriginalQuizId(quizId);
      }


       // Fetch creator info
if (quizData.userId) {
  const userIdToFetch = quizData.isCopied && quizData.originalUserId
    ? quizData.originalUserId
    : quizData.userId;

  const userDoc = await getDoc(doc(db, 'users', userIdToFetch));
  if (userDoc.exists()) {
    const userData = userDoc.data();
    setCreatorInfo({
      displayName: userData.displayName || userData.email || 'Unknown',
      avatarUrl: userData.photoURL || null,
      upvotes: quizData.upvotes || 0
    });
  }
}

      // Update URL if the name doesn't match
      const formattedQuizName = formatUrlPart(quizData.quizName);
      if (formattedQuizName !== urlQuizName) {
        navigate(`/quiz/${id}/${formattedQuizName}`, { replace: true });
      }
    } else {
      showSnackbar('No such quiz!', 'info');
      // Optionally, navigate away or show a different view
    }
  } catch (error) {
    console.error('Error loading quiz from params:', error);
    showSnackbar('Error loading quiz. Please try again.', 'error');
    // Optionally, navigate away or show a different view
  }
};



useEffect(() => {
  //loadQuizzes();
  if (quizId) {
    loadQuizFromParams(quizId);
  } else {
    setViewIsLoading(false);
  }
}, [user, quizId]);



useEffect(() => {
  if (quiz.length > 0) {
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults(quiz.map(q => ({ ...q, selectedOption: null, isCorrect: false })));
    setShowSummary(false);
  }
}, [quiz]);
  // Funktion för att hantera val av alternativ
const handleOptionSelect = (option) => {



// selectSound.play();
 nextSound.play();

  Logger.log(`handleOptionSelect called with option: ${option}`);
  setSelectedOption(option);
  
  // Uppdatera results omedelbart när ett alternativ väljs
  const currentQuestion = quiz[currentQuestionIndex];
  const isCorrect = option === currentQuestion.correct;
  const updatedResults = [...results];
  updatedResults[currentQuestionIndex] = { ...currentQuestion, selectedOption: option, isCorrect };
  setResults(updatedResults);
};

useEffect(() => {
  Logger.log(`selectedOption updated: ${selectedOption}`);
}, [selectedOption]);

const handleNextQuestion = async () => {
  selectSound.play();
  Logger.log(`handleNextQuestion called. CurrentQuestionIndex: ${currentQuestionIndex}`);
  Logger.log(`Current quiz state:`, quiz);
  Logger.log(`Current results:`, results);

  if (!quiz || quiz.length === 0 || currentQuestionIndex >= quiz.length) {
    Logger.log('Quiz ended or invalid state');
    return;
  }

  if (currentQuestionIndex < quiz.length - 1) {
    Logger.log(`Moving to next question. New index: ${currentQuestionIndex + 1}`);
    setCurrentQuestionIndex(prevIndex => {
      Logger.log(`Updating currentQuestionIndex from ${prevIndex} to ${prevIndex + 1}`);
      return prevIndex + 1;
    });
    setSelectedOption(null);
  } else {
    doneSound.play();
    Logger.log('Quiz completed');


    const finalResults = [...results];
    finalResults[currentQuestionIndex] = {
      ...quiz[currentQuestionIndex],
      selectedOption,
      isCorrect: selectedOption === quiz[currentQuestionIndex].correct
    };

    setResults(finalResults);
    
    setShowSummary(true);

    // oklart om hjäler
setCurrentQuestionIndex(0);


    setQuizInProgress(false);

      if (currentQuizId) {
        Logger.log('Saving results to database');
        //await generateDummyData(currentQuizId);
        await saveQuizResult(currentQuizId, finalResults, user);
      }

    if (!currentQuizId) {
      Logger.log('Saving results to database');
      await saveResultsToDatabase(results);
    }
  }
};



const handleModelChange = (event, newModel) => {
  if (newModel !== null) {
    setSelectedModel(newModel);
  }
};


const saveResultsToDatabase = async (resultsToSave) => {
    try {
        let quizNameToSave;
        
        if (quizName) {
            quizNameToSave = quizName;
        } else if (inputType === 'wikipedia') {
            quizNameToSave = decodeURIComponent(inputValue.split('/').pop().replace(/_/g, ' '));
        } else if (selectedSuggestionRef.current) {
            quizNameToSave = generateRandomQuizName();
        } else {
            quizNameToSave = inputValue;
        }

        Logger.log("Förbereder sparande av ", quizNameToSave);
        Logger.log("Results to save: ", resultsToSave);
        
        const docRef = await addDoc(collection(db, 'quiz'), {
            userId: user.uid,
            results: resultsToSave,
            quizName: quizNameToSave,
            createdAt: Date.now(),
            byAdmin: "No",
            isPublic: isPublic,
            category,
            difficulty,
            aiInstructions
        });
        
        Logger.log("Quiz saved with ID: ", docRef.id);
        setInputValue('');
        setCurrentQuizId(docRef.id);
        loadQuizzes();


        await saveQuizResult(docRef.id, resultsToSave);
//const formattedQuizName = formatUrlPart(quizNameToSave);
  //  navigate(`/quiz/${docRef.id}/${formattedQuizName}`);

    } catch (error) {
        console.error('Error saving quiz results:', error);
    }
};


  const handleQuizLoad = (quiz) => {
        toggleDrawer(false)();

    Logger.log("Loading quiz: ", quiz);
    setQuiz(quiz.results);
    setQuizName(quiz.quizName);
    setCurrentQuizId(quiz.id);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults([]);
    setShowSummary(false);
    setQuizInProgress(true);

const formattedQuizName = formatUrlPart(quiz.quizName);
  navigate(`/quiz/${quiz.id}/${formattedQuizName}`);

  };

  const handleDeleteQuiz = async (quizId) => {
    try {
      await deleteDoc(doc(db, 'quiz', quizId));
      loadQuizzes();
    } catch (error) {
      console.error('Error deleting quiz:', error);
    }
  };

  const toggleManaging = () => {
    setIsManaging(!isManaging);
  };

// const backgroundColor = theme.palette.mode === 'dark' ? "#1b2b3c" : "#f1f1f1";
// const foregroundColor =  theme.palette.mode === 'dark' ? "#2d3a48" : "gray";

  if (viewIsLoading) {
 return (
  <LoadingSpinner />
  );
  }








  return (
    <>
    <Helmet>
      <title>{dynamicTitle}</title>
      <meta name="description" content={dynamicDescription} />
    </Helmet>



<Fade in={!viewIsLoading}>

    <Box sx={{ ...styles.container, textAlign: 'left'}}>
<Box sx={{...styles.innerContainer}}>




      <Typography variant="h1" sx={styles.header}>Quiz</Typography>


<AIDialog
userId={user.uid}
  open={aiDialogOpen}
onClose={() => {
  Logger.log("Closing AI Dialog");
  setAiDialogOpen(false);
}}
  initialQuestion={currentQuestion ? 
    `Question: ${currentQuestion.question}\nOptions: ${currentQuestion.options.join(', ')}\nPlease explain this question and help me understand the answer options, the name of the quiz is ${quizName}. Skriv på svenska.`
    : "No question available"
  }
  systemMessage="You are a helpful AI assistant designed to explain and assist with the quiz question. Provide a short and simple explanation. Skriv på svenska"
  theme={theme}
/>


 <DrawerComponent 
  user={user}
  theme={theme}
  open={drawerOpen}
  onClose={toggleDrawer(false)}
/>


<Box sx={{...styles.buttonsContainer, ...styles.additionalButtonsContainer}}>



{isMobile ? (
  <Button variant="contained"  color="primary"  disableRipple  onClick={(e) => {  e.preventDefault(); navigate('/alla-quiz');}} startIcon={<ExploreIcon sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6'}} />} sx={styles.shrinkButton}/> 
) : (
<Button variant="contained" color="primary" onClick={(e) => {  e.preventDefault(); navigate('/alla-quiz');}} startIcon={<ExploreIcon sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6'}} />}> Alla quiz </Button>
)}



{isMobile ? (
  <Button variant="contained"  color="primary"  disableRipple  onClick={handleNewQuiz} startIcon={<AddIcon />} sx={styles.shrinkButton}/> 
) : (
  <Button variant="contained" color="primary" onClick={handleNewQuiz} startIcon={<AddIcon />}> Nytt quiz </Button>
)}




<Button
                    variant="contained" 
                    color="primary" 
                    disableRipple
                    onClick={toggleDrawer(true)}
                    startIcon={<SegmentIcon />}
                    sx={{
                        padding: '8px',
                        marginRight:'0px',
                        paddingLeft:'14px',
                        paddingRight:'3px',
                        minWidth: 'auto',
                        textTransform: 'none',
                    }}
                /> 



</Box>




{showSummary && (
  <Box sx={{ 
    ...styles.container, 
    textAlign: 'left', 
    width: {xs:'100%', md: '100%'}, 

    borderRadius: '10px',
    padding: '20px',

  }}>






    {(() => {
      const correctAnswers = results.filter(result => result.isCorrect).length;
      return (
        <>
          <Box sx={{ 
            backgroundColor: theme.palette.background.paper, 
            padding: 3, 
            borderRadius: '8px', 
            marginBottom: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="h5" sx={{fontSize:'20px'}}>Du fick {correctAnswers} av {results.length} rätt!</Typography>
            <Typography variant="h4" sx={{ color: theme.palette.secondary.main, fontSize:'20px' }}>
              {((correctAnswers / results.length) * 100).toFixed(0)}%
            </Typography>
          </Box>

          <UserPercentile 
            quizId={currentQuizId} 
            userScore={results.filter(result => result.isCorrect).length / results.length * 100} 
          />

          <Box sx={{ marginTop: 4 }}>
            {results.map((result, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: 4,
                  padding: 3,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: '8px',
                  borderLeft: `5px solid ${result.isCorrect ? theme.palette.success.main : theme.palette.error.main}`
                }}
              >
                <Typography variant="h6" sx={{ marginBottom: 2 }}>Fråga {index + 1}:</Typography>
                <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 'bold' }}>{result.question}</Typography>
                <Box sx={{ marginLeft: 2 }}>
                  {result.options.map((option, optIndex) => (
                    <Typography 
                      key={optIndex} 
                      variant="body2" 
                      sx={{ 
                        marginBottom: 1,
                        color: option === result.correct ? theme.palette.success.main : 
                               (option === result.selectedOption && !result.isCorrect) ? theme.palette.error.main :
                               'inherit',
                        fontWeight: option === result.correct || option === result.selectedOption ? 'bold' : 'normal'
                      }}
                    >
                      {option === result.selectedOption ? '➤ ' : '   '}
                      {option}
                      {option === result.correct && ' ✓'}
                    </Typography>
                  ))}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                  {result.isCorrect ? (
                    <CheckCircleIcon sx={{ color: theme.palette.success.main, marginRight: 1 }} />
                  ) : (
                    <CancelIcon sx={{ color: theme.palette.error.main, marginRight: 1 }} />
                  )}
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    {result.isCorrect ? 'Korrekt!' : `Inkorrekt. Det korrekta svaret var: ${result.correct}`}
                  </Typography>
                </Box>


                      <Button
                        variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
                        color="primary"
                        onClick={() => handleOpenAIDialog(index)}
                        sx={{ marginTop: 2 }}
                        startIcon={<GenerateIcon />}
                      >
                        Fråga AI
                      </Button>

                      <AIDialog
                        userId={user.uid}
                        open={openAIDialogs[index] || false}
                        onClose={() => handleCloseAIDialog(index)}
                        initialQuestion={`Fråga: ${result.question}\nAlternativ: ${result.options.join(', ')}\nKorrekt svar: ${result.correct}\nValt svar: ${result.selectedOption}\nFörklara denna fråga och hjälp mig förstå svarsalternativen. Skriv på svenska.`}
                        systemMessage="Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med quizfrågor. Ge en kort och enkel förklaring. Skriv på svenska."
                        theme={theme}
                      />

              </Box>
            ))}
          </Box>
        </>
      );
    })()}



               <Box sx={{ display: 'flex', gap: 2 }}>

          <Box sx={{ ...styles.newBox, padding: '20px', flexGrow: 1, paddingBottom:'50px' }}>
          <UserProgressChart userId={user.uid} quizId={currentQuizId} />
          </Box>
          </Box>

  </Box>
)}


      {!quizInProgress && !showSummary && showCreateQuizForm && (
        <>
          <Typography variant="h4" sx={{ ...styles.header, ...styles.headerAboveBox, marginBottom: 2, marginTop:4 }}>Skapa quiz med AI</Typography>

<Box sx={{...styles.darkBorder, width:'100%', padding:'20px', backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white' , border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',}} >

    <div>


<Box>
<Tooltip placement="top" title="Skriv in ett ämne, ladda upp en PDF/Word-fil, skriv in ett kapitel från en bok, eller välj en Wiki-sida.">
<Typography variant="formLabel" sx={{mt:1, fontWeight:'500'}}>Välj källa för ditt quiz:</Typography>
</Tooltip>

</Box>
<FormControl component="fieldset" sx={{ mt: 2, marginBottom: '22px', width: '100%' }}>
  <div style={{ 
    overflowX: 'auto', 
    WebkitOverflowScrolling: 'touch',
    width: '100%',
    display: 'flex',
  }}>
    <ToggleButtonGroup
      value={inputType}
      exclusive
      onChange={handleInputChange}
      name="inputType"
      sx={{
        ...styles.toggleButtonGroups,
        display: 'inline-flex',
        flexShrink: 0,
      }}
      aria-label="input type"
    >
      <ToggleButton onClick={() => setInputType('topic')} name="inputType" value="topic" aria-label="subject" sx={{...styles.toggles, ...styles.firstToggle}}>
        <TopicIcon /> 
        Ämne
      </ToggleButton>
      <ToggleButton name="inputType" onClick={() => setInputType('text')} value="text" aria-label="text" sx={styles.toggles}>
        <TextSnippetIcon />
        Längre text
      </ToggleButton>
      <ToggleButton name="inputType" onClick={() => setInputType('wikipedia')} value="wikipedia" aria-label="wiki-url" sx={styles.toggles}>
        <WikipediaIcon /> Wiki-URL
      </ToggleButton>
      <ToggleButton name="inputType" onClick={() => setInputType('youtube')} value="youtube" aria-label="youtube-url" sx={{...styles.toggles, ...styles.lastToggle}}>
        <YouTubeIcon /> YouTube
      </ToggleButton>
    </ToggleButtonGroup>
  </div>
</FormControl>


            <RadioGroup row value={inputType} onChange={handleInputChange} name="inputType" sx={{display:'none'}}>


              <FormControlLabel
                value="topic"
                control={<Radio icon={<TopicIcon sx={{ fontSize: 0, visibility: 'hidden' }} />} checkedIcon={<TopicIcon sx={{ fontSize: 0, visibility: 'hidden' }} />} />}
                label={
          <Card sx={selectedValue === 'topic' ? styles.card.selected : styles.card}>
                              <CardContent>
                      <div style={styles.iconContainer}>
                        <FunctionsIcon   sx={styles.icon} />
                      </div>
                      <Typography sx={styles.typographyBody}>Ämne</Typography>
                      <Typography sx={styles.typographyCaption}>Skriv in ett ämne för att generera ett quiz.</Typography>
                    </CardContent>
                  </Card>
                }
              />

              <FormControlLabel
                value="text"
                control={<Radio icon={<TextFieldsIcon sx={{ fontSize: 0, visibility: 'hidden' }} />} checkedIcon={<TextFieldsIcon sx={{ fontSize: 0, visibility: 'hidden' }} />} />}
                label={
          <Card sx={selectedValue === 'text' ? styles.card.selected : styles.card}>
                              <CardContent>
                      <div style={styles.iconContainer}>
                        <TextSnippetIcon sx={styles.icon} />
                      </div>
                      <Typography sx={styles.typographyBody}>Valfri text</Typography>
                      <Typography sx={styles.typographyCaption}>Skriv in en längre text för att generera ett quiz.</Typography>
                    </CardContent>
                  </Card>
                }

              />
              <FormControlLabel

                value="wikipedia"
                control={<Radio icon={<WikipediaIcon sx={{ fontSize: 0, visibility: 'hidden' }} />} checkedIcon={<WikipediaIcon sx={{ fontSize: 0, visibility: 'hidden' }} />} />}
                label={
          <Card sx={selectedValue === 'wikipedia' ? styles.card.selected : styles.card}>
                    <CardContent>
                      <div style={styles.iconContainer}>
                        <WikipediaIcon sx={styles.icon} />
                      </div>
                      <Typography sx={styles.typographyBody}>Wikipedia</Typography>
                      <Typography sx={styles.typographyCaption}>Skriv in valfri wiki-url och få ett quiz genererat.</Typography>
                    </CardContent>
                  </Card>
                }
              />




            </RadioGroup><br />
    </div>
         {inputType === 'topic' && (
         <>
            <TextField
              placeholder="T.ex. Världshistoriens stora krig, eller Anna Karenina av Lev Tolstoj"
              inputRef={subjectInputRef}
              variant="outlined"
              multiline
              rows={3}
              sx={{ marginBottom: 2, width: '100%'}}

      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IdeaGenerator 
              user={user} 
              onIdeaGenerated={handleIdeaGenerated}
              systemMessage="ge mig en idé på ett quiz på 4-5 meningar (exempel: Skapa ett quiz om orsakerna, händelseförloppet och konsekvenserna av franska revolutionen. Inkludera frågor om viktiga personer, politiska ideologier och revolutionens påverkan på Europa.). Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
              position="top"
              size="small"
            />
          </InputAdornment>
        ),
      }}

            />
<Box sx={{mb:6, mt:-2}}>
<Suggestions 
        listType="quizIdeas" 
        maxSuggestions={6} 
        onSelect={handleSelect} 
      />  
</Box>
</>
          )}

          {inputType === 'text' && (
            <TextField
              placeholder="Skriv in en längre text (t.ex. en läxa eller kapitel från en bok)"
              inputRef={textInputRef}
              variant="outlined"
              multiline
              rows={6}
              sx={{ marginBottom: 2, width: '100%'}}
            />
          )}

          {inputType === 'wikipedia' && (
          <>
            <TextField
              placeholder="Ange Wikipedia-URL (t.ex. https://sv.wikipedia.org/wiki/Anna_Karenina)"
              inputRef={wikiUrlInputRef}
              variant="outlined"
              sx={{ marginBottom: 2, width: '100%'}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                ),
        endAdornment: (
          <InputAdornment position="end">
            <IdeaGenerator 
              user={user} 
              onIdeaGenerated={handleWikiIdeaGenerated}
              systemMessage="Ge mig en idé på en wikipedia-url till en populär wikipedia-sida (exempel: https://sv.wikipedia.org/wiki/Selma_Lagerl%C3%B6f) Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
              position="end"
              size="small"
            />
          </InputAdornment>
        ),
              }}
            />


<Box sx={{mb:2, mt:-2}}>
<Suggestions 
        listType="wikiPages" 
        maxSuggestions={6} 
        onSelect={handleSelectWiki} 
      />  
</Box>


    <Box sx={{...styles.newBox, background: theme.palette.mode === 'dark' ? '#1c262f' : '#f1f5f978', padding:'20px', mb:8, width:'100%'}}>
        <Typography gutterBottom sx={{fontWeight:'500'}}>
      Hur många tecken från artikeln vill du hämta?
    </Typography>
    <Slider
      defaultValue={5000}
      color="primary"
      sx={{
    width: '95%', // or any other value, like '50%', '20em', etc.
      marginLeft:'15px', mt:1
  }}
      step={200}
  marks={[
    { value: 500, label: '500' },
    { value: 1000, label: isMobile ? '' : '1000' },
    { value: 1500, label: isMobile ? '' : '1500' },
    { value: 2000, label: isMobile ? '' : '2000' },
    { value: 2500, label: '2500' },
    { value: 3000, label: isMobile ? '' : '3000' },
    { value: 3500, label: isMobile ? '' : '3500' },
    { value: 4000, label: isMobile ? '' : '4000' },
    { value: 4500, label: isMobile ? '' : '4500' },
    { value: 5000, label: '5000' },
  ]}
      min={500}
      max={5000}
      valueLabelDisplay="auto"
      onChange={(event, newValue) => setCharacterCount(newValue)}

    />

          <Alert severity="info"  sx={{ borderRadius: '8px', mt:2 }}>
      <i>Max är just nu 5000. Kontakta oss om du behöver mer.</i>
          </Alert>
    </Box>


</>

          )}



 {inputType === 'youtube' && (
 <>
              <TextField
                placeholder="Ange YouTube-URL (t.ex. https://www.youtube.com/watch?v=dQw4w9WgXcQ)"
                inputRef={youtubeUrlInputRef}
                variant="outlined"
                sx={{ marginBottom: 2, width: '100%'}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
<YouTubeIcon />
                    </InputAdornment>
                  ),
                }}
              />

<Box sx={{mb:2, mt:-2}}>
<Suggestions 
        listType="youtubeIdeas" 
        maxSuggestions={6} 
        onSelect={handleSelectYouTube} 
      />  
</Box>


    <Box sx={{...styles.newBox, background: theme.palette.mode === 'dark' ? '#1c262f' : '#f1f5f978', padding:'20px', mb:8, width:'100%'}}>
        <Typography gutterBottom sx={{fontWeight:'500'}}>
      Hur många tecken från YouTube-videon vill du hämta?
    </Typography>
    <Slider
      defaultValue={5000}
      sx={{
    width: '95%', // or any other value, like '50%', '20em', etc.
      marginLeft:'15px', mt:1
  }}
      step={200}
  marks={[
    { value: 500, label: '500' },
    { value: 1000, label: isMobile ? '' : '1000' },
    { value: 1500, label: isMobile ? '' : '1500' },
    { value: 2000, label: isMobile ? '' : '2000' },
    { value: 2500, label: '2500' },
    { value: 3000, label: isMobile ? '' : '3000' },
    { value: 3500, label: isMobile ? '' : '3500' },
    { value: 4000, label: isMobile ? '' : '4000' },
    { value: 4500, label: isMobile ? '' : '4500' },
    { value: 5000, label: '5000' },
  ]}
      min={500}
      max={5000}
      valueLabelDisplay="auto"
      onChange={(event, newValue) => setCharacterCountYouTube(newValue)}

    />


        <Alert severity="info"  sx={{ borderRadius: '8px', marginTop: '10px' }}>
            <i>Max är just nu 5000. Kontakta oss om du behöver mer.</i>

          </Alert>
          </Box>
</>
            )}


<Box sx={{marginBottom:'30px', marginTop:'-10px'}}>
      <label htmlFor="word-file-upload">
        <Input
          id="word-file-upload"
          type="file"
          sx={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".docx"
        />
        {inputType === 'text' && (
          <Button
            variant="outlined"
            color="primary"
            component="span"
            sx={{ marginBottom:'20px', marginRight: '10px' }}
            startIcon={<WordIcon size={16} />}
          >
            Ladda upp Word-fil
          </Button>
        )}
      </label>

          


     <label htmlFor="pdf-file-upload">
        <Input
          id="pdf-file-upload"
          type="file"
          sx={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".pdf"
        />
        {inputType === 'text' && (
          <Button
            variant="outlined"
            color="primary"
            component="span"
            sx={{ marginBottom:'20px' }}
            startIcon={<PictureAsPdfIcon sx={{color:'#d90808'}} />}
          >
            Ladda upp PDF-fil
          </Button>
        )}
      </label>


</Box>
<Box>


</Box>

<Typography variant="formLabelSinUnderline" sx={{fontWeight:'500',}}>Typ av frågor:</Typography>
<Box sx={{mt:2}}>
<QuestionTypeSelector 
  selected={questionType}
  setQuestionType={setQuestionType}
  disableTrueFalse={true}
  theme={theme}
/>
</Box>


<Tooltip title="Resultaten kommer variera beroende på vilken AI-modell du väljer.">
<Typography variant="formLabel" sx={{fontWeight:'500'}}>AI-modell</Typography>
</Tooltip>
<AIModelSelect 
        
        selectedModel={selectedModel} 
        setSelectedModel={setSelectedModel} 
        disabledOptions={["Gemini Pro", "Mistral Large"]}  
      />


<Tooltip title="Gör det enklare att hitta quizet i ditt bibliotek senare.">
<Typography variant="formLabel" sx={{mt:2, fontWeight:'500'}}>Quiz-namn</Typography>
</Tooltip>
            <TextField
              placeholder="T.ex. Bröderna Karamazov av Dostojevskij"
              name="quizName"
              value={quizName}
              onChange={handleInputChange}
              variant="outlined"
              sx={{ mt:1, width: '100%', mb:4}}
                InputLabelProps={{
    shrink: true,
  }}
 
            />





<Button
  onClick={() => setShowAdditionalFields(!showAdditionalFields)}
  variant="outlined"
  endIcon={showAdditionalFields ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  sx={{...styles.beautyBtnTres, mt: 2, mb: 4 }}
  aria-expanded={showAdditionalFields}
  aria-controls="additional-fields"
>
  {showAdditionalFields ? 'Dölj fler alternativ' : 'Visa fler alternativ'}
</Button>

    <Collapse in={showAdditionalFields}>
<Suspense 
  fallback={
    <div>
      <CircularProgress size={16} />
    </div>
  }
>
        {showAdditionalFields && (
          <AdditionalFields
            aiInstructions={aiInstructions}
            setAiInstructions={setAiInstructions}
            numQuestions={numQuestions}
            setNumQuestions={setNumQuestions}
            category={category}
            setCategory={setCategory}
            difficulty={difficulty}
            setDifficulty={setDifficulty}
            questionType={questionType}
            setQuestionType={setQuestionType}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            isPublic={isPublic}
            handlePublicChange={handlePublicChange}
            theme={theme}
          />
        )}
      </Suspense>
    </Collapse>

<Box className="progress-bar-container">
<Button
variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
  color="primary"
  onClick={loadQuiz}
  disabled={loadingQuiz} // Disable button when loadingQuiz is true
  sx={{ textTransform: 'none' }} // Optional: Ensures no text transformation
  startIcon={loadingQuiz ? <CircularProgress size={18} /> : <GenerateIcon style={{ color: '#fff' }} />}
>
  {loadingQuiz ? "Genererar quiz med AI..." : "Generera quiz med AI"} 
</Button>


{loadingQuiz && (
  <>
  <Box sx={{ width: '100%', mb: 2, mt:4,
backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f8f8f8',
  padding: '20px',
  paddingTop:'7px',
  paddingBottom:'20px',
  borderRadius: '10px'

   }}>
    <Typography variant="body2" color="text.secondary" sx={{...styles.h5, mt:2, mb:1}}>

      Genererar quiz...
    </Typography>
   <Box sx={{ width: '100%', height: 20, bgcolor: 'background.paper', borderRadius: 5, overflow: 'hidden' }}>
      <Box
        ref={progressRef}
        sx={{
          width: '0%',
          height: '100%',
          backgroundColor: theme.palette.mode === 'dark' ? '#6F38EA' : '#6339c4',
          transition: 'width 0.1s linear'
        }}
      />
    </Box>
    <Typography 
      ref={progressPercentRef} 
      variant="body2" 
      color="text.secondary" 
      align="right"
    >
      0%
    </Typography>
    </Box>
  </>
)}

</Box>






          </Box>


<QuizExplanation theme={theme} />


        </>
      )}

      {quiz.length > 0 && quizInProgress && (
        <>
<Box sx={{...styles.darkBorder, width:'100%', position:'relative', padding: {xs:'20px', md: '40px'}, marginTop:'50px', backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white' , border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091', borderRadius: {xs: '0px', md: '8px'}, }} >


 <LinearProgress
        variant="determinate"
        value={(currentQuestionIndex / quiz.length) * 100}
        sx={{
          height: 10,
          borderRadius: 5,
          marginBottom: 2,
          '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            backgroundColor: '#8b5cf6',
            transition: 'transform 0.5s ease-in-out',
          },
        }}
      />


            <Typography style={styles.h2}>{quizName}</Typography>
            

                <Typography style={styles.h3}>{currentQuestion.question}</Typography>
    <Typography style={{...styles.h4, marginTop:'5px'}}>Fråga {currentQuestionNumber} av {quiz.length}</Typography>

            <Box sx={{ display: 'flex', mt:3, flexDirection: { xs: 'column', sm: 'row' }, flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {currentQuestion.options.map((option, index) => (
                <OptionButton
                  key={index}
                  variant={selectedOption === option ? 'contained' : 'outlined'}
                  onClick={() => handleOptionSelect(option)}
                   key={index}
                   sx={{
        width: { xs: '100%', md: '45%', }, ml:0
      }}
                >
                  {index === 0 && <LooksOneIcon sx={{marginRight:'8px', color:'#8b5cf6'}} />} {/* För första alternativet */}
                  {index === 1 && <LooksTwoIcon sx={{marginRight:'8px', color:'#8b5cf6'}} />} {/* För andra alternativet */}
                  {index === 2 && <Looks3Icon sx={{marginRight:'8px', color:'#8b5cf6'}} />} {/* För tredje alternativet */}
                  {index === 3 && <Looks4Icon sx={{marginRight:'8px', color:'#8b5cf6'}} />} {/* För fjärde alternativet */}
                  {option} {/* Visar texten för alternativet */}
                </OptionButton>
              ))}
            </Box>

<Box sx={{ position: 'absolute', bottom: 15, left: 15, zIndex: 1 }}>
<Hint question={currentQuestion.question} answer={currentQuestion.options.join(', ')} user={user} theme={theme} />
</Box>

<Box sx={{ marginTop:'45px'}}>
            <CenteredButton
              variant="contained"
              color="secondary"
              onClick={handleNextQuestion}
              disabled={!selectedOption}
              endIcon={<SpaceBarIcon />}
              sx={{width: {xs:'100%', md:'25%'}}}

            >
              Nästa
            </CenteredButton>

            <CenteredButton
              variant="contained"
              color="secondary"
              onClick={handleAskAI}
              sx={{}}
              startIcon={<GenerateIcon />}

sx={{backgroundColor:'#791373', color:'white', border: theme.palette.mode === 'dark' ? undefined : 'none' ,borderColor:'#591355',
width: {xs:'100%', md:'25%'}, mb:3
}}
            >
              Fråga AI
            </CenteredButton>            
</Box>

            <Box sx={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1 }}>
              <Tooltip title={bookmarks[currentQuestionIndex] ? "Sparat" : "Spara"}>
                <IconButton 
                  onClick={toggleBookmark} 
                  sx={{ 
                    color: bookmarks[currentQuestionIndex] 
                      ? 'green' 
                      : theme.palette.text.primary 
                  }}
                >
                  {bookmarks[currentQuestionIndex] ? <CheckCircleIcon /> : <BookmarkIcon />}
                </IconButton>
              </Tooltip>
            </Box>




          </Box>

<Box sx={{
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: { xs: 'stretch', md: 'flex-start' },
  justifyContent: 'space-between',
  gap: 2,
  mt: 3,
}}>




  {creatorInfo && (
    <Box sx={{ 
        ...styles.smallBox,
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff', 
      borderRadius: '10px',
      padding: '10px',
      marginTop: { xs: '30px', md: 0 },
      width: { xs: '100%', md: 'fit-content' }
    }}>
      <Avatar 
        src={creatorInfo.avatarUrl}
        sx={{ width: 40, height: 40, mr: 2, bgcolor: theme.palette.primary.main }}
      >
        {creatorInfo.displayName[0].toUpperCase()}
      </Avatar>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {creatorInfo.displayName}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
          <ThumbUpOutlinedIcon sx={{ fontSize: 16, mr: 0.5, color: '#41bf13' }} />
          <Typography variant="body2" sx={{ color: '#41bf13', fontWeight: 'bold' }}>
            {creatorInfo.upvotes} 
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: '0.8rem', ml:1 }}>
            ·  &nbsp; {formatDate(quizCreatedAt)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )}

<Box sx={{
  ...styles.smallBox,
  backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff',
  borderRadius: '10px',
  width: { xs: '100%', md: '40%' },
  padding: '0px',
  margin: { xs: '0 auto', md: 0 },
  marginTop: { xs: '80px', md: 0 },
}}>
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '13px', flexShrink: 0, padding: '20px', paddingRight:'0px' }}>
      GENVÄGAR
    </Typography>
    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
<Box sx={{
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  ml: 0, // Add this line for a small left margin
}}>
      <LooksOneIcon />
      <LooksTwoIcon />
      <LooksThreeIcon />
      <LooksFourIcon />
      <SpaceBarIcon />
      <KeyboardReturnIcon />
      <Typography variant="body2" sx={{ ml: 1 }}>
        (F = Fråga AI)
      </Typography>
    </Box>
  </Box>
</Box>


  <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
    <RecommendationComponent 
      itemId={originalQuizId}
      userId={user.uid}
      collectionName="quiz"
      questionText="Skulle du rekommendera detta quiz till andra användare?"
    />
  </Box>
</Box>
        </>
      )}
      </Box>
    </Box>
    </Fade>
    </>
  );
};

export default QuizView;
