// SkeletonLoader.js
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from '@mui/material/styles';

const SkeletonLoader = ({ width = "100%", height = "100%", speed = 0.6 }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const backgroundColor = isDarkMode ? "#1b2b3c" : "#f1f1f1";
    const foregroundColor = isDarkMode ? "#2d3a48" : "gray";

    return (
        <ContentLoader
            speed={speed}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
        >
            <rect x="0" y="0" rx="5" ry="5" width="90%" height="50" />
            <rect x="0" y="60" rx="5" ry="5" width="80%" height="30" />
            <rect x="0" y="100" rx="5" ry="5" width="70%" height="50" />
            <rect x="0" y="160" rx="5" ry="5" width="65%" height="40" />
            <rect x="0" y="210" rx="5" ry="5" width="85%" height="20" />
            <rect x="0" y="240" rx="5" ry="5" width="90%" height="40" />
            <rect x="0" y="290" rx="5" ry="5" width="75%" height="30" />
            <rect x="0" y="330" rx="5" ry="5" width="60%" height="40" />
            <rect x="0" y="380" rx="5" ry="5" width="70%" height="40" />
            <rect x="0" y="430" rx="5" ry="5" width="55%" height="50" />
        </ContentLoader>
    );
};

export default SkeletonLoader;