import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Typography, Button, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { getStyles } from './index';
import CloseIcon from '@mui/icons-material/Close';
import BlurryBackdrop from './BlurryBackdrop';

const PromptDialog = ({ open, onClose, prompts, onAddPrompt, onDeletePrompt }) => {
  const [newPrompt, setNewPrompt] = useState('');

  const theme = useTheme();
  const styles = getStyles(theme);

  const handleCloseOverview = () => {
    onClose();
  };

  const handleAddPrompt = () => {
    if (newPrompt.trim()) {
      onAddPrompt(newPrompt);
      setNewPrompt('');

    }
  };

  

  return (
    <Dialog open={open} onClose={onClose}
    BackdropComponent={BlurryBackdrop}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.mode === 'dark' ? '#000915' : '#f9f9f9',

        },
      }} 
    >
      <DialogTitle>
        Hantera prompts
        <IconButton
          aria-label="close"
          onClick={handleCloseOverview}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.mode == 'dark' ? 'white' : 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{padding:'50px'}}>

<Tooltip title="En välformulerad prompt hjälper AI att ge mer relevanta och användbara svar.">
<Typography variant="formLabel" sx={{mt:4}}>Prompt</Typography>
</Tooltip>

        <TextField
          autoFocus
          margin="dense"

          fullWidth
          placeholder="T.ex. ELI5 "
          value={newPrompt}
          onChange={(e) => setNewPrompt(e.target.value)}
        />

        <List sx={{ borderRadius: '10px', mt: 3 }}>
          {prompts.map((prompt) => (
            <ListItem key={prompt.id} 
              sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                mb: 1,
              }}
            >
              <ListItemText primary={`${prompt.text}`} sx={{borderRadius:'10px'}} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onDeletePrompt(prompt.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button sx={{margin:'20px'}} onClick={handleAddPrompt} startIcon={<AddIcon />}
          variant={theme.palette.mode === 'light' ? "purp" : "contained"} 
          color="primary" 
        >
          Lägg till
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;