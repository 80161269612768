// functions.js
import { doc, updateDoc, increment } from "firebase/firestore";
import { db } from './firebase'; // Se till att du importerar din Firebase-instans korrekt
import { formatDistanceToNow, format } from 'date-fns';
import { sv } from 'date-fns/locale';
import Logger from './Logger';

/**
 * Decrease tokens by 50 for a specific user.
 * @param {string} userId - The user ID.
 * @returns {Promise<void>}
 */



export const sanitizeInput = (input) => {
  if (typeof input !== 'string') {
    return '';
  }
  
  // Remove HTML tags
  let sanitized = input.replace(/<[^>]*>/g, '');
  
  // Encode special characters, excluding single quotes
  sanitized = sanitized
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
  
  return sanitized;
};

export async function decreaseTokens(userId) {
    const userDocRef = doc(db, "users", userId);

    try {
        await updateDoc(userDocRef, {
            tokens: increment(-50)
        });
        Logger.log("Tokens successfully decreased by 50.");
    } catch (error) {
        console.error("Error decreasing tokens: ", error);
    }
}

export async function updateUserTokens(userId, tokens) {
    const isGuest = userId.startsWith('guest-');

    const collectionName = "users";
    const userDocRef = doc(db, collectionName, userId);
    try {
        await updateDoc(userDocRef, {
            tokens: increment(tokens)
        });
        Logger.log("Tokens successfully increased by ", tokens, " for user ", userId, " which is guest ", isGuest);
    } catch (error) {
        console.error("Error updating tokens: ", error);
    }
}


  export const createUrlSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/å/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/ö/g, 'o')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

export const formatDate = (timestamp) => {
  let date;
  
  if (timestamp && typeof timestamp.toDate === 'function') {
    // Firestore Timestamp
    date = timestamp.toDate();
  } else if (typeof timestamp === 'number') {
    // Unix timestamp in milliseconds (like Date.now())
    date = new Date(timestamp);
  } else {
    console.error('Unexpected timestamp format:', timestamp);
    return 'Okänt datum';
  }

  if (isNaN(date.getTime())) {
    console.error('Invalid date:', timestamp);
    return 'Okänt datum';
  }

  const now = new Date();
  const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    const distance = formatDistanceToNow(date, { addSuffix: true, locale: sv });
    // Custom shortening logic
    return distance
      .replace('ungefär ', '')
      .replace(' sekunder', ' sek')
      .replace(' minuter', ' min')
      .replace(' timmar', ' tim')
      .replace(' timme', ' timme')
      .replace('mindre än en minut', '< 1 min');
  } else if (diffInDays === 1) {
    return 'Igår';
  } else if (diffInDays < 7) {
    return `${diffInDays} dagar sedan`;
  } else {
    return format(date, 'd MMM yyyy', { locale: sv });
  }
};

// export async function updateUserTokens(userId, tokens) {
//     const userDocRef = doc(db, "users", userId);

//     try {
//         await updateDoc(userDocRef, {
//             tokens: increment(tokens)
//         });
// Logger.log("Tokens successfully increased by ", tokens, " for user ", userId);
//     } catch (error) {
//         console.error("Error decreasing tokens: ", error);
//     }
// }